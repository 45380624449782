import { Grid } from "@mui/material"
import React from "react"
import '../Profile.scss'

export const LinkProfile = ({ activeTab, setDeleteUser }) => {

    return (<>
        {activeTab === 3 &&
            <Grid>
                <a href="https://enerclic.es/contacta/" target='_blank' rel="noreferrer" >
                    <span className="menu-arrow-profile">
                        <h2> Atención al Cliente</h2>
                    </span>
                </a>
            </Grid>
        }
        {activeTab === 4 &&
            <Grid items xs={12}>
                <Grid>
                    <a href="https://enerclic.es/politica-privacidad/" target='_blank' rel="noreferrer" >
                        <span className="menu-arrow-profile">
                            <h2> Política de Privacidad</h2>
                        </span>
                    </a>
                </Grid>
                <Grid>
                    <a href="https://enerclic.es/aviso-legal/" target='_blank' rel="noreferrer" >
                        <span className="menu-arrow-profile">
                            <h2> Aviso Legal</h2>
                        </span>
                    </a>
                </Grid>
                <Grid>
                    <a>
                        <span onClick={() => setDeleteUser(true)} className="menu-arrow-profile">
                            <h2>Eliminar mi cuenta Enerclic</h2>
                        </span>
                    </a>
                </Grid>
            </Grid>
        }
    </>
    )
}