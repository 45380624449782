import React from 'react'
import { Grid } from '@mui/material'
import { ChangeBatery, Iconhome, IconRed, IconPanel } from 'utils/icon-centrocontrol'
import { toKiloW, toKilowDate, toKilowPercent } from 'utils/functionsforGraphs'
import { changeBattery } from 'utils/Utils'
import { getPercent } from 'utils/functionsforGraphs'
import "../styleSelfConsumption.scss"

export const CardSelfConsumption = ({ data, seeWeb, setActiveTab, activeTab, selfConsumption }) => {

    let totalEnergy = data?.consumo?.eaconsdia ? data?.consumo?.eaconsdia : ' - '
    let importSelf = getPercent(data?.produccion?.pact, totalEnergy)
    let exportSelf = getPercent(data?.red?.pact, totalEnergy)

    const handleClick = (section) => {
        seeWeb(section)
        setActiveTab(5)
    }

    return (

        <span className='card-arrow'>

            <span className='card-column '>
                <>
                    <div className="cardMargin-self">
                        <Grid item sm={12} lg={12} xl={12} style={{ margin: "10px 0px" }}>
                            <div className={activeTab === 5 && selfConsumption === "consumption" ? "Card-self-Active" : "Card-self"}
                                onClick={() => {
                                    handleClick('consumption');
                                }}>
                                <span className="header-Card-self">
                                    <span className="grid-Card-self">
                                        <Iconhome />
                                        <h2 className="title-card-self">
                                            Consumo
                                        </h2>
                                    </span>
                                    <span className="grid-Card-self">
                                        <p className="element-card-self-mini" id="consumption">
                                            Potencia: {toKilowDate(data?.consumo?.datetime, data?.consumo?.pact)} kW
                                        </p>
                                    </span>
                                </span>
                                <div className='divider-self' />
                                <span className="cardData-self">
                                    <p className="element-card-self">
                                        Consumida: {toKilowDate(data?.consumo?.datetime, totalEnergy)} kWh
                                    </p>
                                </span>
                            </div>
                        </Grid>
                        <Grid item sm={12} lg={12} xl={12} style={{ margin: "10px 0px" }}>
                            <div className={activeTab === 5 && selfConsumption === "red" ? "Card-self-Active" : "Card-self"}
                                onClick={() => {
                                    handleClick('red');
                                }}>
                                <span className="header-Card-self">
                                    <span className="grid-Card-self">
                                        <IconRed />
                                        <h2 className="title-card-self">
                                            Red
                                        </h2>
                                    </span>
                                    <span className="grid-Card-self">
                                        <span className="power-card-self-section">
                                            <p className="element-card-self-mini" id="red">
                                                Potencia: {toKilowDate(data?.red?.datetime, data?.red?.pact)} kW
                                            </p>
                                            {importSelf === 100 &&
                                                <p className="element-card-sub-self  blueImport">
                                                    Exportando: {toKilowPercent(exportSelf)} %
                                                </p>
                                            }
                                            {importSelf < 100 &&
                                                <p className="element-card-sub-self  blueImport">
                                                    Importando: {toKilowPercent(exportSelf)} %
                                                </p>
                                            }
                                        </span>
                                    </span>
                                </span>
                                <div className='divider-self' />
                                <span className="cardData-self">
                                    <p className="element-card-self">
                                        Importada: {toKilowDate(data?.red?.datetime, data?.red?.eaconsdia)} kWh
                                    </p>
                                    <p className="element-card-self">
                                        Exportada: {toKilowDate(data?.red?.datetime, data?.red?.eagendia)} kWh
                                    </p>
                                </span>
                            </div>
                        </Grid>
                        <Grid item sm={12} lg={12} xl={12} style={{ margin: "10px 0px" }}>
                            <div className={activeTab === 5 && selfConsumption === "production" ? "Card-self-Active" : "Card-self"}
                                onClick={() => { handleClick('production') }}>
                                <span className="header-Card-self">
                                    <span className="grid-Card-self">
                                        <IconPanel />
                                        <h2 className="title-card-self">
                                            Generación
                                        </h2>
                                    </span>
                                    <span className="grid-Card-self">
                                        <span className="power-card-self-section">
                                            <p className="element-card-self-mini" id="production">
                                                Potencia: {toKilowDate(data?.produccion?.datetime, data?.produccion?.pact)} kW
                                            </p>
                                            <p className="element-card-sub-self">
                                                Autoconsumo: {toKilowPercent(importSelf)} %
                                            </p>
                                        </span>
                                    </span>
                                </span>
                                <div className='divider-self' />
                                <span className="cardData-self">
                                    <p className="element-card-self">
                                        Generada: {toKilowDate(data?.produccion?.datetime, data?.produccion?.eagendia)} kWh
                                    </p>
                                </span>
                            </div>
                        </Grid>
                    </div>
                    <div className="cardMargin-self">
                    </div><div className="cardMargin-self">
                    </div></>
                <div className="cardMargin-self">
                    <Grid item sm={12} lg={12} xl={12} className={data?.bateria ? null : "active-Batery"} style={{ margin: "10px 0px" }}>
                        <div id="card-batery" className={data?.bateria && activeTab === 5 && selfConsumption === "batery" ? "Card-self-Active" : data?.bateria ? "Card-self" : "card-self-batery"}
                            onClick={
                                data?.bateria ? () => {
                                    handleClick('batery')
                                } : null}>
                            <span className="header-Card-self">
                                <span className="grid-Card-self">
                                    <ChangeBatery change={changeBattery(data?.bateria?.soc > 0 ? data?.bateria?.soc : 0)} width="20" height="20"
                                        id={'batery'} alt="Batery" />
                                    <h2 className="title-card-self">
                                        Baterías
                                    </h2>
                                </span>
                                <span className="grid-Card-self">
                                    <span className="power-card-self-section">
                                        <p className="element-card-self-mini" id="production">
                                            Potencia: {toKiloW(data?.bateria?.p)} kW
                                        </p>
                                        <p className="element-card-sub-self  blueImport" >
                                            {data?.bateria && data?.bateria?.p > 0 ? "Cargando" : data?.bateria && data?.bateria?.p >> 0 ? "Descargando" :
                                                null
                                            }
                                        </p>
                                    </span>
                                </span>
                            </span>
                            <div className='divider-self' />
                            <span className="cardData-self" >
                                <p className="element-card-self">
                                    Carga: {toKilowPercent(data?.bateria?.soc) + "%"}
                                </p>
                            </span>
                        </div>
                    </Grid>
                </div>
            </span >
        </span>
    )
}


