import { LogoMinerva } from 'utils/icon-centrocontrol'
import "./navbar.scss"

export default function Navbar({
  children,
  className,
  companyLogos,
  logo = <LogoMinerva className='h-full' />,
  onClick
}) {
  
  return (
    <nav className={`${className} navbar-style`} onClick={onClick}>
      <div className='logo-container'>{logo}</div>
      <div className='logos-container'>
        {companyLogos &&
          companyLogos.map((url, i) => (
            <img src={url} key={i} className='h-full' alt='logo' />
          ))}
      </div>
      <div className='items-container'>
        {children}
      </div>
    </nav>
  )
}
