import React, { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import Grid from '@mui/material/Grid'
import { headersScada, headersCentroControl } from 'services/headers'
import { FiltroConfiguracionAlarms } from "./FilterAlarm/FiltroConfiguracionAlarms"
import { TablaConfigAlarms } from "./TableAlarm/TablaConfigAlarms"
import { urls } from "utils/constant"
import 'views/Alarms/styleAlarm.scss'
import { GlobalContext } from "context/GlobalContext"
import { CategoryType } from "utils/MiddleWareProjects"

/**
 * @param {{ instalacioneSeleccionadas: string[] | number[], dispositivos: {}[] }} 
 * @param instalacioneSeleccionadas Instalaciones de las que se gestionan las alarmas
 * @param dispositivos Array de los dispositivos que tienen las instalaciones
 * @returns Devuelve la tabla de la configuracion de las alarmas de la instalacion
 */
export const ConfiguracionAlarms = ({ instalacioneSeleccionadas, dispositivos }) => {
    const { actualProject } = useContext(GlobalContext)
    const location = useLocation()
    const navegation = location.pathname.split('/')[1]
    const token = sessionStorage?.token
    const [actualizarAlarma, setActualizarAlarma] = useState(false);
    const [tipoDispositivo, setTipoDispositivo] = useState()
    const [tipoAlarma, setTipoAlarma] = useState()
    const [dispositivosProyecto, setDispositivosProyecto] = useState()
    const [alarmasProyecto, setAlarmasProyecto] = useState()
    const [alarmasPendientes, setAlarmasPendientes] = useState()
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [allDevices, setAllDevices] = useState()

    /**
     * @returns Devuelve los tipos de alarma que pueden tener las instalaciones
     */
    const fetchTypeAlarms = async () => {
        try {
            const res = await fetch(urls.scada + 'alarms/getTypeAlarms', { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                let json = await res.json()
                setAlarmasProyecto(json)
            }
            return true
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    /** 
    * @param {boolean} histo 
    * @returns Devuelve las alarmas activas o historicas de las instalaciones seleccionadas
    */
    const fetchCheckConfiguredAlarms = async () => {
        try {
            const res = await fetch(urls.scada + 'alarms/checkConfiguredAlarms', { method: 'POST', headers: headersScada(token), body: JSON.stringify(instalacioneSeleccionadas) })
            if (res.status === 200) {
                let json = await res.json()
                setAlarmasPendientes(json)
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const fetchTypeDeviceProject = async () => {
        try {
            const res = await fetch(urls.ccontrol + 'infoDataAlarms', { method: 'POST', headers: headersCentroControl(token), body: JSON.stringify(instalacioneSeleccionadas) })
            if (res.status === 200) {
                let json = await res.json()
                let tiposDispositivos = []
                dispositivos?.forEach(padre => {
                    if (padre?.id_tipo_dispositivo !== undefined && tiposDispositivos?.indexOf(padre?.id_tipo_dispositivo) === -1) {
                        tiposDispositivos.push(padre.id_tipo_dispositivo)
                    }
                })
                json?.forEach(device => {
                    if (device?.id_tipo_dispositivo !== undefined && tiposDispositivos?.indexOf(device?.id_tipo_dispositivo) === -1) {
                        tiposDispositivos.push(device.id_tipo_dispositivo)
                    }
                })
                setDispositivosProyecto(tiposDispositivos)
                setAllDevices(dispositivos.concat(json))
            }
        } catch (err) {
            console.error("ERROR. API Centro Control", err)
        }
    }

    const initAlarms = async () => {
        if (navegation === 'view' || (CategoryType(actualProject?.category) !== 'Distribución' && CategoryType(actualProject?.category) !== 'Autoconsumo' && navegation === 'admin')) {
            let tiposDispositivos = []
            dispositivos?.forEach(padre => {
                if (padre?.id_tipo_dispositivo !== undefined && tiposDispositivos?.indexOf(padre?.id_tipo_dispositivo) === -1) {
                    tiposDispositivos.push(padre.id_tipo_dispositivo)
                }
                padre?.childrenDevices?.forEach(hijo => {
                    if (hijo?.id_tipo_dispositivo !== undefined && tiposDispositivos?.indexOf(hijo?.id_tipo_dispositivo) === -1) {
                        tiposDispositivos.push(hijo.id_tipo_dispositivo)
                    }
                    hijo?.childrenDevices?.forEach(nieto => {
                        if (nieto?.id_tipo_dispositivo !== undefined && tiposDispositivos?.indexOf(nieto?.id_tipo_dispositivo) === -1) {
                            tiposDispositivos.push(nieto.id_tipo_dispositivo)
                        }
                    })
                })
            })
            setDispositivosProyecto(tiposDispositivos)
            setAllDevices(dispositivos)
        } else {
            await fetchTypeDeviceProject()
        }
        await fetchTypeAlarms()
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        initAlarms()
    }, [])

    useEffect(() => {
        fetchCheckConfiguredAlarms()
    }, [actualizarAlarma])

    return (
        <div className="mt-2">
            <Grid className='mt-3 mb-5' container spacing={2} >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {alarmasProyecto && dispositivosProyecto && <FiltroConfiguracionAlarms dispositivosCrearAlarma={allDevices} alarmasProyecto={alarmasProyecto} dispositivosProyecto={dispositivosProyecto} tipoDispositivo={tipoDispositivo} setTipoDispositivo={setTipoDispositivo} tipoAlarma={tipoAlarma} setTipoAlarma={setTipoAlarma} setActualizarAlarma={setActualizarAlarma} token={token} instalacioneSeleccionadas={instalacioneSeleccionadas} dispositivosProyecto2={dispositivos} open={open} setOpen={setOpen} master={dispositivos} alarmasPendientes={alarmasPendientes} />}
                </Grid>
            </Grid>
            <TablaConfigAlarms loadingFetch={loading} actualizarAlarma={actualizarAlarma} setActualizarAlarma={setActualizarAlarma} token={token} instalacioneSeleccionadas={instalacioneSeleccionadas} tipoAlarma={tipoAlarma} tipoDispositivo={tipoDispositivo} />
        </div >
    )
}