
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'

export const ModalConfigGlobalEms = ({ configEms, master, setConfigOrderInitial, configOrder, configOrderInitial, open, setOpen }) => {
    const [loading, setLoading] = useState(false)
    const [meter, setMeter] = useState(false)
    const [empty, setEmpty] = useState(false)
    const dispatch = useDispatch()
    const token = sessionStorage?.token

    const functionSave = () => {
        fetchChangeEms()
    }

    const fetchChangeEms = async () => {
        setLoading(true)
        let obj = []
        configOrder?.forEach((ele, index) => {
            if (ele?.ID_plant !== configOrderInitial?.[index]?.ID_plant) {
                obj.push(ele)
            }
        })
        fetch(urls.scada + `configPlantInstallation/${master?.numserie}?idCCMaster=${master?.numserie}`, { method: 'PATCH', headers: headersScada(token), body: JSON.stringify(obj)})
        .then(res => {
            if (res.status === 200) {
                const copyArray1 = configOrder.map(obj => ({ ...obj }))
                setConfigOrderInitial(copyArray1)
                dispatch(createToast({ status: 200, message: "Configuración actualizada correctamente", reload: false }))
            } else {
                dispatch(createToast({ status: 400, message: "Ha habido un error al actualizar la configuración", reload: false }))
            } 
        }).catch((error) => {
            console.error("Ha habido un erroe en API Scada", error)
        }).finally(() => {
            setOpen(false)
            setLoading(false)
        })
    }
     
    useEffect(() =>  {
        let anyMeter = false
        configOrder?.forEach(ele => {
            if (ele?.ID_plant === '-') {
                setEmpty(true)
            }
            if ((configEms?.potmax?.enable || configEms?.surplus?.enable) && ele?.ID_plant === 'CONS_0001') {
                anyMeter = true
            }
        })
        setMeter(anyMeter)
    }, [])
    
    return (
        <Modal onClick={(e) => e.stopPropagation()} maxWidth="sm" open={open} setOpen={setOpen} disabled={loading || empty || (!meter && (configEms?.potmax?.enable || configEms?.surplus?.enable))} title={`Guardar configuración de ${master?.nick}`} loading={loading} functSave={functionSave} buttonAction="Aceptar" >
            <Grid item xs={12} className="text-center mt-2">
                <span>
                    <h3 style={{ fontSize: '1.2rem' }} className="font-weight-lighter">
                        ¿Estás seguro que deseas actualizar la configuración de la planta?
                    </h3>
                    {empty &&
                        <h3 style={{ color: 'red' }} className="font-weight-lighter">
                            Hay algún dispositivo que no tiene seleccionado el tipo al que pertenece. Por favor, seleccione el tipo para continuar.
                        </h3>
                    }
                    {(!meter && (configEms?.potmax?.enable || configEms?.surplus?.enable)) &&
                        <h3 style={{ color: 'red' }} className="font-weight-lighter">
                            Existe una configuración de EMS que requiere un punto de frontera. Por favor, seleccione uno para continuar.
                        </h3>
                    }
                </span>
            </Grid>
        </Modal>
    )
}