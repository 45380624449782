import React from 'react'
import { Draggable } from '@hello-pangea/dnd'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import "./LayOut.scss"
 
const DraggableItem = ({ item, index, columnId, isActive, disabled, itemClassName }) => {
  const isDisabled = disabled ? true : !isActive(item.device_type) && columnId === "Devices"
  const isDevices = columnId === "Devices"

  return (
    <Draggable key={item?.id} draggableId={item?.id} index={index} isDragDisabled={isDisabled}>
      {(provided) => {
        const draggableIcon = (
          <div
            ref={provided?.innerRef}
            {...provided?.draggableProps}
            {...provided?.dragHandleProps}
            className={`${!isDevices ? itemClassName : ""} `}
          >
            <div className={`${isDevices ? "device-draggable" : ""} draggable-item-container`}> 
              {!isDevices && item?.icon_type !== 25 && item?.icon_type !== 26 && item?.isMoved && (
                <div className="item-name">
                  {item?.name?.length > 7 ? `${item?.name?.substring(0, 7)}...` : item?.name}
                </div>
              )}
              {React.cloneElement(item?.content, {
                fill: columnId === "Devices" && disabled ? undefined : columnId === "Devices" && !isActive(item?.device_type) ? "#C6C6C6" : undefined
              })}
            </div>
          </div>
        )
        return (isDevices || item?.icon_type === 25 || item?.icon_type === 26) ? draggableIcon : (
          <HtmlTooltip title={<span>{item?.name}</span>}>
            {draggableIcon}
          </HtmlTooltip>
        )
      }}
    </Draggable>
  )
}
 
export default DraggableItem