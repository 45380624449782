import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { Loader } from 'components/Loader/Loader'
import { Modal } from 'components/Modal/Modal'
import { headersUsers } from 'services/headers'
import { IconProfile } from 'utils/icon-centrocontrol'
import { urls } from "utils/constant"
import { ChangePassProfile } from './Tabs/ChangePassProfile'
import { ModalLogOut } from '../Logout/ModalLogOut'
import { ProfileLogos } from './Logo/ProfileLogos'
import { DataProfile } from './Tabs/dataProfile'
import { LinkProfile } from './Tabs/LinkProfile'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'
import './Profile.scss'

export const Profile = () => {
    const navigate = useNavigate()
    const token = sessionStorage?.token
    window.document.title = 'Mi Perfil - Enerclic'
    const dispatch = useDispatch()
    let lastLogin = localStorage.getItem("lastLogin")
    const [closeSessions, setCloseSessions] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingButton, setLoadingButton] = useState(false)
    const [loadingDeleteUser, setLoadingDeleteUser] = useState(false)
    const [deleteUser, setDeleteUser] = useState(false)
    const [profile, setProfile] = useState({})
    const [activeTab, setActiveTab] = useState(0)
    let email = sessionStorage.email
    const [name, setName] = useState()
    const [isValidName, setIsValidName] = useState(false)
    const [lastName, setLastName] = useState()
    const [idTelegram, setIdTelegram] = useState()
    const [isValidLastName, setIsValidLastName] = useState(false)
    const [isValidTelegram, setIsValidTelegram] = useState(false)
    const [valid, setValid] = useState(true)
    const [passOld, setPassOld] = useState()
    const [validmsg, setValidmsg] = useState(false)
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState("")
    const [customPass, setCustomPass] = useState([])
    const [openLogOut, setOpenLogOut] = useState(false)

    let validateForm = password?.length > 7 && confirmPassword?.length > 7 && passOld && customPass?.length === 0 && validmsg

    const formatLastLogin = (fecha) => {
        if (fecha) {
            let splitLogin = fecha.split(" ")
            let date = splitLogin[0]
            let hours = splitLogin[1]
            const newFormatDate = hours + " " + date
            return (newFormatDate)
        }
    }

    const fetchProfile = () => {
        setLoading(true)
        fetch(urls.users + `user`, {
            method: "GET",
            headers: headersUsers(('bearer ' + token)),
        })
        .then(res => res.json())
        .then(data => {
            setProfile(data)
        })
        .catch(err => {
            console.error("ERROR. API Users", err)
        })
        .finally(() =>
            setLoading(false)
        )
    }

    const fetchProfileNew = () => {
        fetch(urls.users + `user`, {
            method: "GET",
            headers: headersUsers(('bearer ' + token)),
        })
        .then(res => res.json())
        .then(data => {
            setProfile(data)
        })
        .catch(err => {
            console.error("ERROR. API Users", err)
        })
    }

    const fetchDeleteUser = () => {
        setLoadingDeleteUser(true)
        fetch(urls.users + `user/delete`, {
            method: "DELETE",
            headers: headersUsers(('bearer ' + token)),
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(createToast({ status: 200, message: "Correo enviado correctamente" }))
                } else {
                    dispatch(createToast({ status: 400, message: "Error al enviar el correo" }))
                }
            })
            .catch(err => {
                console.error("ERROR. API Users", err)
            })
            .finally(() =>
            setLoadingDeleteUser(false)
        )
    }

    const updateUser = () => {
        setLoadingButton(true)
        let status
        let message
        fetch(urls.users + `user/update`, {
            method: 'PUT',
            redirect: 'follow',
            headers: headersUsers(('bearer ' + token)),
            body: JSON.stringify({
                "name": name,
                "lastname": lastName,
                "telegram": idTelegram
            })
        }).then(res => {
            status = res.status
            if (res.status === 200) {
                if (name) {
                    message = "Datos actualizados con éxito"
                    sessionStorage.setItem('name', name)
                    fetchProfileNew()
                }
                return res.json()
            } else {
                message = "Ha habido un problema al actualizar los datos, inténtelo de nuevo más tarde"
                return res.json()
            }
        }).then(data => {
            dispatch(createToast({ status: status, message: message }))
        }).catch(err => {
            console.error("ERROR. API USER", err)
        })
        .finally(() =>
            setLoadingButton(false)
        )
    }

    const sendNewPass = () => {
        setLoadingButton(true)
        let status
        fetch(urls.users + 'user/updatepass', {
            method: 'PUT', headers: headersUsers('bearer ' + token),
            body: JSON.stringify({ 'pass': password, 'passOld': passOld, "cerrarSesion": closeSessions, "email": email })
        })
        .then(res => {
            status = res.status
            return res.json()
        }).then(data => {
            if (status === 200) {
                dispatch(createToast({ status: 200, message: data.message }))
            } else {
                dispatch(createToast({ status: 400, message: data.message }))
            }
            if (status === 200 && closeSessions) {
                navigate(`/auth/login`)
            }
            setDeleteUser(false)
        })
        .catch(err => console.error('No se ha podido conectar con la API...users', err))
        .finally(() =>
            setLoadingButton(false)
        )
    }

    useEffect(() => {
            fetchProfile()
    }, [])

    useEffect(() => {
        setName(profile?.name)
        setLastName(profile?.lastname)
        setIdTelegram(profile?.telegram)
    }, [profile])
    
    useEffect(() => {
        if (profile?.name !== name || profile?.lastname !== lastName || profile?.telegram !== idTelegram) {
            if (isValidName === true || isValidLastName === true || isValidTelegram === true) {
                setValid(true)
            } else {
                setValid(false)
            }
        } else {
            setValid(true)
        }
    }, [isValidName, isValidLastName, name, lastName, profile, idTelegram])

    useEffect(() => {
        setPassOld()
        setPassword()
        setValidmsg(false)
        setConfirmPassword("")
        setCustomPass([])
        setOpenLogOut(false)
        setIsValidTelegram(false)
        setIsValidLastName(false)
        setCloseSessions(false)
    },[activeTab])

    useEffect(() => {
        validateForm = password?.length > 7 && confirmPassword?.length > 7 && passOld && customPass?.length === 0 && validmsg && password === confirmPassword
    },[password, confirmPassword, passOld, customPass, validmsg])

    return (
        <>
            {loading ?
                <Loader />
                :
                <Grid container className='pl-7 pr-7 mb-7'>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='mb-4'>
                        <span className="header-projectList">
                            <div className='header-title'>
                                <IconProfile />
                                <h2 className='title-section'> Mi Cuenta </h2>
                            </div>

                        </span>
                    </Grid>
                    <Grid item xs={12} className="container-menu-profile">
                        <Grid item xs={2} >
                            <Grid item xs={10} className="menu-sidebar-profile"  >
                                <p onClick={() => setActiveTab(0)} className={`${activeTab === 0 ? "button-menu-profile-selected" : "button-menu-profile"}`}>Mis datos</p>
                                <p onClick={() => setActiveTab(1)} className={`${activeTab === 1 ? "button-menu-profile-selected" : "button-menu-profile"}`}>Cambiar contraseña</p>
                                {/* <p onClick={() => setActiveTab(2)} className={`${activeTab === 2 ? "button-menu-profile-selected" : "button-menu-profile"}`}>Mis facturas</p> */}
                                <p onClick={() => setActiveTab(3)} className={`${activeTab === 3 ? "button-menu-profile-selected" : "button-menu-profile"}`}>Atención al cliente</p>
                                <p onClick={() => setActiveTab(4)} className={`${activeTab === 4 ? "button-menu-profile-selected" : "button-menu-profile"}`}>Datos y privacidad</p>
                                <p onClick={() => setOpenLogOut(!openLogOut)} className="button-menu-profile">Cerrar sesión</p>
                            </Grid>
                        </Grid>

                        {activeTab === 0 && <Grid item xs={4} md={4} lg={4} xl={4}>
                            <p className="label-profile-bold">Logos</p>
                            <ProfileLogos />
                        </Grid>}
                        <Grid item xs={12} lg={activeTab !== 0 ? 10 : 6}>
                            <> {activeTab === 0 &&
                                <Grid item lg={6}>
                                    <DataProfile profile={profile} name={name} setName={setName} idTelegram={idTelegram} setIdTelegram={setIdTelegram}
                                        isValidName={isValidName} setIsValidName={setIsValidName} setIsValidTelegram={setIsValidTelegram}
                                        lastName={lastName} setLastName={setLastName} setValid={setValid} loadingButton={loadingButton}
                                        isValidLastName={isValidLastName} setIsValidLastName={setIsValidLastName} updateUser={updateUser} valid={valid} loading={loading} />
                                </Grid>}
                            </>
                            {activeTab === 1 &&
                                <Grid item lg={6}>
                                    <ChangePassProfile sendNewPass={sendNewPass} disabled={validateForm} loading={loading} passOld={passOld} setPassOld={setPassOld}
                                        setValidmsg={setValidmsg} password={password} setPassword={setPassword} loadingButton={loadingButton}
                                        confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword}
                                        customPass={customPass} setCustomPass={setCustomPass} setCloseSessions={setCloseSessions} closeSessions={closeSessions} />
                                </Grid>
                            }
                            {activeTab === 2 && <Grid item lg={6}><h2>No contamos con esa información</h2> </Grid>}
                            {activeTab !== 0 && activeTab !== 1 && activeTab !== 2 &&
                                <Grid item lg={6}>  <LinkProfile activeTab={activeTab} setDeleteUser={setDeleteUser} /> </Grid>
                            }
                        </Grid >
                    </Grid>
                    <div className="lastLogin-profile">
                        {lastLogin &&
                            <p>Último inicio de sesión: <span style={{ fontWeight: "700" }}>{formatLastLogin(lastLogin)}</span></p>}
                    </div>
                    <Modal open={deleteUser} setOpen={setDeleteUser} functSave={fetchDeleteUser} loading={loadingDeleteUser} title='Dar de baja mi usuario' buttonAction="Dar de baja">
                        <Grid container className="text-center mt-2">
                            <h4 className="font-weight-lighter" style={{ fontSize: '1.2rem' }}>
                                ¿Deseas dar de baja tu usuario?
                            </h4>
                        </Grid>
                        <hr className='m-0' />
                    </Modal>
                    <ModalLogOut open={openLogOut} setOpen={() => setOpenLogOut(!openLogOut)} />
                </Grid >
            }
        </>
    )
}