import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { DropDownDevices } from 'views/Devices/MenuDevices/DropDownDevices'
import "./StyleFilterScada.scss"

export const FilterScada = ({ device, setDevicesSelect, devicesSelect, master, selfConsumption }) => {
    const [checkingAll, setCheckingAll] = useState(true)

    const CheckedDevFilter = (numserie) => {
        setDevicesSelect([])
        if (!devicesSelect?.includes(numserie)) {
            setDevicesSelect([...devicesSelect, numserie])
        } else {
            const newArray = devicesSelect?.filter(item => item !== numserie)
            setDevicesSelect(newArray)
            setCheckingAll(false)
        }
    }

    const checkAll = () => {
        let check
        if (device?.length === devicesSelect?.length) {
            check = false
            setDevicesSelect([])
        } else {
            check = true
            setDevicesSelect(device?.map(dev => dev?.numserie))
        }
        setCheckingAll(check)
    }

    useEffect(() => {
        if (selfConsumption) {
            setDevicesSelect(device?.map(dev => dev?.numserie))
        }
    }, [device])

    useEffect(() => {
        if (!selfConsumption) {
            setDevicesSelect(device?.map(dev => dev?.numserie))
        }
    }, [])

    useEffect(() => {
        if (devicesSelect?.length === device?.length) {
            setCheckingAll(true)
        }
    }, [devicesSelect])

    return (
        <>
            <Grid item xs={12} className="container-filter-component">
                <div className="checkAll" >
                    <input type="button" onClick={checkAll} value='Todos' className={checkingAll ? 'button-filter-active pd-filter' : 'button-filter pd-filter'} id="filterButtonAll" />
                </div>
                {device?.length > 0 && device?.map((dev, key) => {
                    return (
                        <button key={key} className="button-filter" onClick={() => CheckedDevFilter(dev?.numserie)}>
                            <SwitchStyled value={dev?.numserie} name='dv' checked={devicesSelect?.includes(dev?.numserie)} onChange={(e) => CheckedDevFilter(dev?.numserie, e)} className="checkboxFilter switch-small" />
                            <div id={dev.numserie} onClick={setDevicesSelect} >
                                <p className="filter-name">{dev.nick}</p>
                            </div>
                            <span className={dev} id="button-menu-device"  >
                                <DropDownDevices
                                    selectedDevice={dev}
                                    icon="text"
                                    device={dev}
                                    instalacion={master}
                                    masterPermission={master}
                                    masterPermissionNew={master?.permissions}
                                />
                            </span>
                        </button>
                    )
                })}
            </Grid>
        </>
    )
}
