import React, { useEffect, useState } from "react"
import { DragDropContext } from "@hello-pangea/dnd"
import { Grid } from "@mui/material"
import { ButtonStyled } from "components/Buttons/ButtonStyled"
import { Loader } from "components/Loader/Loader"
import { headersScada } from "services/headers"
import { devices, getUniqueID, initialColumns, compareIconTypesWithPosition, switchConfig, restoreDevice, getIconComponent, areAllArraysEmpty } from "utils/Utils"
import { IconNewObject, IconSave, IconDeleteAlarm } from "utils/icon-centrocontrol"
import { urls } from "utils/constant"
import { ModalMoveDeviceRelay } from "views/Modals/ModalLayout/ModalMoveDeviceRelay"
import { ModalDeleteLayout } from "views/Modals/ModalLayout/ModalDeleteLayout"
import { ModalSaveLayout } from "views/Modals/ModalLayout/ModalSaveLayout"
import { ModalAddRelay } from "views/Modals/ModalLayout/ModalAddRelay"
import DropContainer from "./DropContainer"
import "./LayOut.scss"
 
export const deviceStatus = {
  Devices: {
    name: "Devices",
    items: devices
  },
  Relay1: {
    name: "Relay1",
    columns: initialColumns("Relay1")
  }
}
 
function LayOutBoard({ dispositivo }) {
  const [openMoveDeviceModal, setOpenMoveDeviceModal] = useState(false)
  const [openAddRelayModal, setOpenAddRelayModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isSavedDisabled, setIsSaveDisabled] = useState(false)
  const [openSaveModal, setOpenSaveModal] = useState(false)
  const [configuration, setConfiguration] = useState([])
  const [clonedDevice, setClonedDevice] = useState(null)
  const [columns, setColumns] = useState(deviceStatus)
  const [nameDevices, setNameDevices] = useState([])
  const [deviceType, setDeviceType] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [relayCount, setRelayCount] = useState(1)
  const [layoutData, setLayoutData] = useState()
  const [moves, setMoves] = useState({})
  const isDeleteDisabled = !layoutData
  const relayKeys = Object?.keys(columns)?.filter(key => key?.startsWith('Relay'))
  const token = sessionStorage?.token
 
  const onDragEnd = (result, columns, setColumns, moves, setMoves) => {
    const { source, destination } = result
    const updatedMoves = { ...moves }
    if (!destination) {
      return
    }
    const getRelayIdAndColumnId = (droppableId) => {
      const splitId = droppableId?.split("-")
      const relayId = splitId[0]
      const columnId = droppableId
      return { relayId, columnId }
    }
    const { relayId: sourceRelayId, columnId: sourceColumnId } = getRelayIdAndColumnId(source?.droppableId)
    const { relayId: destinationRelayId, columnId: destinationColumnId } = getRelayIdAndColumnId(destination?.droppableId)
    // Mover dentro de la misma lista (columna o Devices)
    if (source?.droppableId === destination?.droppableId) {
      const column = source?.droppableId === "Devices"
        ? columns?.Devices
        : columns?.[sourceRelayId]?.columns?.find(col => col?.id === sourceColumnId)
      const newItems = Array?.from(column?.items)
      const [movedItem] = newItems?.splice(source?.index, 1)
      newItems?.splice(destination?.index, 0, movedItem)
      setColumns(prevColumns => {
        if (source?.droppableId === "Devices") {
          return {
            ...prevColumns,
            Devices: {
              ...prevColumns?.Devices,
              items: newItems,
            },
          }
        } else {
          return {
            ...prevColumns,
            [sourceRelayId]: {
              ...prevColumns?.[sourceRelayId],
              columns: prevColumns?.[sourceRelayId]?.columns?.map(col =>
                col?.id === sourceColumnId ? { ...col, items: newItems } : col
              ),
            },
          }
        }
      })
      return
    }
    // Mover de Devices a una columna de relay
    if (source?.droppableId === "Devices" && destination?.droppableId !== "Devices") {
      const device = columns?.Devices?.items?.[source?.index]
      const clonedDevice = { ...device, id: getUniqueID() }
      const itemType = clonedDevice?.device_type
      const updatedRelayColumns = columns[destinationRelayId]?.columns?.map(column => {
        if (column?.id === destinationColumnId) {
          const newItems = Array?.from(column?.items)
          newItems?.splice(destination?.index, 0, clonedDevice)
          return {
            ...column,
            items: newItems,
          }
        }
        return column
      })
      if (itemType !== 25 && itemType !== 26) {
        updatedMoves[itemType] = updatedMoves?.[itemType] ? updatedMoves?.[itemType] - 1 : 0
        setOpenMoveDeviceModal(!openMoveDeviceModal)
        setDeviceType(itemType)
        setClonedDevice(clonedDevice)
      }
      setColumns(prevColumns => ({
        ...prevColumns,
        [destinationRelayId]: {
          ...prevColumns[destinationRelayId],
          columns: updatedRelayColumns
        }
      }))
      setMoves(updatedMoves)
      return
    }
 
    // Mover de una columna de relay a Devices
    if (destination?.droppableId === "Devices") {
      const sourceColumn = columns?.[sourceRelayId]?.columns?.find(col => col?.id === sourceColumnId)
      const device = sourceColumn?.items?.[source?.index]
      const itemType = device?.device_type
      const updatedRelayColumns = columns?.[sourceRelayId]?.columns?.map(column => {
        if (column?.id === sourceColumnId) {
          return {
            ...column,
            items: column?.items?.filter((_, i) => i !== source?.index),
          }
        }
        return column
      })
      if (itemType !== 25 && itemType !== 26) {
        updatedMoves[itemType] = updatedMoves?.[itemType] ? updatedMoves?.[itemType] + 1 : 1
      }
 
      setColumns(prevColumns => {
        // Filtrar los dispositivos para asegurarse de que no haya duplicados por tipo
        const filteredDevices = prevColumns?.Devices?.items?.filter(item => item?.icon_type !== device?.icon_type)
        const restoredDevice = restoreDevice(device)
        setNameDevices(prevNameDevices => [...prevNameDevices, {nick: device?.name, numserie: device?.numserie}])
        return {
          ...prevColumns,
          Devices: {
            ...prevColumns?.Devices,
            items: [...filteredDevices, restoredDevice]
          },
          [sourceRelayId]: {
            ...prevColumns[sourceRelayId],
            columns: updatedRelayColumns
          }
        }
      })
      setMoves(updatedMoves)
      return
    }
 
    // Mover entre columnas de diferentes relays o del mismo relay
    const sourceColumn = columns?.[sourceRelayId]?.columns?.find(col => col?.id === sourceColumnId)
    const destinationColumn = columns?.[destinationRelayId]?.columns?.find(col => col?.id === destinationColumnId)
    const sourceItems = Array?.from(sourceColumn?.items)
    const destinationItems = Array?.from(destinationColumn?.items)
    const [movedItem] = sourceItems?.splice(source?.index, 1)
    destinationItems?.splice(destination?.index, 0, movedItem)
    setColumns(prevColumns => {
      const updatedSourceColumns = prevColumns?.[sourceRelayId]?.columns?.map(col =>
        col?.id === sourceColumnId ? { ...col, items: sourceItems } : col
      )
      const updatedDestinationColumns = prevColumns?.[destinationRelayId]?.columns?.map(col =>
        col?.id === destinationColumnId ? { ...col, items: destinationItems } : col?.id === sourceColumnId ? { ...col, items: sourceItems } : col
      )
      return {
        ...prevColumns,
        [sourceRelayId]: {
          ...prevColumns[sourceRelayId],
          columns: updatedSourceColumns
        },
        [destinationRelayId]: {
          ...prevColumns[destinationRelayId],
          columns: updatedDestinationColumns
        }
      }
    })
    setMoves(updatedMoves)
  }
 
  const handleRemoveRelay = () => {
    if (relayCount > 1) {
      const updatedMoves = { ...moves }
      const lastRelayId = `Relay${relayCount}`
 
      setColumns((prevColumns) => {
        const devicesToRemove = prevColumns[lastRelayId]?.columns?.flatMap(col => col?.items?.map(item => item?.device_type)) || []
 
        // Actualizar los movimientos incrementando el contador para cada tipo de dispositivo
        devicesToRemove?.forEach((deviceType) => {
          updatedMoves[deviceType] = (updatedMoves[deviceType] || 0) + 1
        })
 
        const newColumns = { ...prevColumns }
        delete newColumns[lastRelayId]
 
        return newColumns
      })
 
      setRelayCount(relayCount - 1)
 
      setMoves(updatedMoves)
    }
  }
 
  const updateMovesCount = (data) => {
    if (!dispositivo || !dispositivo?.[0]) {
      return {}
    }
 
    const movesCount = {}
 
    if (dispositivo?.[0]?.childrenDevices && dispositivo?.[0]?.childrenDevices?.length > 0) {
      const devices = dispositivo?.[0]?.childrenDevices
 
      devices?.forEach((child) => {
        const typeId = child?.id_tipo_dispositivo
        movesCount[typeId] = (movesCount?.[typeId] || 0) + 1
      })
 
      devices?.forEach(device => {
        if (device?.childrenDevices) {
          device?.childrenDevices?.forEach(child => {
            const typeId = child?.id_tipo_dispositivo
            movesCount[typeId] = (movesCount?.[typeId] || 0) + 1
          })
        }
      })
    }
 
    if (dispositivo?.[0]?.id_tipo_dispositivo !== null && dispositivo?.[0]?.id_tipo_dispositivo !== undefined) {
      movesCount[dispositivo?.[0]?.id_tipo_dispositivo] = (movesCount?.[dispositivo?.[0]?.id_tipo_dispositivo] || 0) + 1
    }
 
    if (data && data?.length > 0) {
      data?.forEach((type) => {
        if (movesCount[type] !== undefined) {
          movesCount[type] -= 1
        }
      })
    }
    return movesCount
  }
 
  const isActive = (type, columnId) => {
    const hasMovesLeft = moves?.[type] && moves[type] > 0
    let isDeviceActive = false
 
    const checkDeviceTypeInChildren = (devices, type) => {
      if (!devices) {
        return false
      }
      return devices?.some(device => {
        if (Number(device?.id_tipo_dispositivo) === Number(type)) {
          return true
        }
        if (device?.childrenDevices) {
          return checkDeviceTypeInChildren(device?.childrenDevices, type)
        }
        return false
      })
    }
 
    if (dispositivo && dispositivo?.[0]?.childrenDevices) {
      isDeviceActive = checkDeviceTypeInChildren(dispositivo?.[0]?.childrenDevices, type)
    }
 
    if (dispositivo && Number(dispositivo?.[0]?.id_tipo_dispositivo) === Number(type)) {
      isDeviceActive = true
    }
 
    if (type === 25 || type === 26) {
      return true
    }
 
    if (columnId === "Devices") {
      return isDeviceActive && hasMovesLeft
    } else {
      return isDeviceActive
    }
  }
 
  const getLayout = async ({ numserie }) => {
 
    try {
      const res = await fetch(`${urls.scada}distributionPanel/${numserie}`, {
        method: "GET",
        headers: headersScada(token)
      })
      if (res.ok) {
 
        let data = await res.text()
        let configuration
 
        // Comprueba si la configuración está vacía o no
        if (data === "Panel de distribución sin configuración") {
            configuration = []
            setLayoutData(null)
        } else {
            try {
                data = JSON.parse(data)
 
                if (data?.confPanel) {
                    configuration = JSON.parse(data?.confPanel)
                    setLayoutData(configuration)
                } else {
                    configuration = []
                    setLayoutData(null)
                }
            } catch (error) {
                console.error("Error al parsear el JSON:", error)
                configuration = []
                setLayoutData(null)
            }
        }
       
        // Extraer el icon_type para comprobar los movimientos
        let transformedConfig = []
 
        configuration?.forEach(subArray => {
            subArray?.forEach(nestedArray => {
                if (Array?.isArray(nestedArray)) {
                    nestedArray?.forEach(item => {
                        if (item?.icon_type !== undefined) {
                            transformedConfig?.push(switchConfig(item?.icon_type))
                        }
                    })
                }
            })
        })
 
        const newMoves = updateMovesCount(transformedConfig)
        setMoves(newMoves)
       
        const initial = {
          Devices: {
              name: "Devices",
              items: devices
          },
          Relay1: {
              name: "Relay1",
              columns: initialColumns("Relay1")
          }
      }
     
      // Configuramos los relays adicionales en base a la configuración
      configuration?.forEach((relay, index) => {
          const relayId = `Relay${index + 1}`
          const columns = initialColumns(relayId)
     
          relay?.forEach((nestedArray, positionIndex) => {
              if (nestedArray === null) {
                  // Si no hay nada en esa posición, ponemos un array vacío en items
                  columns[positionIndex].items = []
              } else {
                  // Si hay items, los mapeamos y los agregamos
                  columns[positionIndex].items = nestedArray?.map(iconType => {
                    const { device_type, icon_type, name, numserie, content, isMoved } = getIconComponent(
                        iconType?.icon_type,
                        iconType?.name,        
                        iconType?.numserie    
                    )
                    return {
                        id: getUniqueID(),
                        device_type,
                        icon_type,
                        name,
                        numserie,
                        content,
                        isMoved
                    }
                })
               
              }
          })
 
          initial[relayId] = {
              name: relayId,
              columns: columns
          }
      })
     
      const count = configuration?.length === 0 ? 1 : configuration?.length;
      setColumns(initial)
      setRelayCount(count)
 
      } else {
        console.error("Error en la solicitud")
      }
    } catch (error) {
      console.error("Error en la solicitud", error)
    }
  }
 
  useEffect(() => {
    if (dispositivo) {
      getLayout({ numserie: dispositivo[0]?.numserie })
    }
  }, [dispositivo])
 
  useEffect(() => {
    let configurationPanel = []
    Object?.keys(columns)?.forEach((key) => {
        if (key?.startsWith("Relay")) {
            const relay = columns[key]
            let relayConfig = new Array(12)?.fill([])
 
            if (relay?.columns) {
                relay?.columns?.forEach((column, index) => {
                    if (index < 12) {
                        if (column?.items && column?.items?.length > 0) {
                            relayConfig[index] = column?.items?.map(item => ({
                                name: item?.name,
                                numserie: item?.numserie,
                                icon_type: item?.icon_type
                            }))
                        } else {
                            relayConfig[index] = []
                        }
                    }
                })
            }
            configurationPanel?.push(relayConfig)
        }
    })
    setConfiguration(configurationPanel)
  }, [columns])
  
  useEffect(() => {
    const areConfigurationsEqual = compareIconTypesWithPosition(layoutData, configuration)
    if ((layoutData === null || layoutData === undefined) && configuration?.length === 0) {
      setIsSaveDisabled(true)
    } else if ((layoutData === null || layoutData === undefined) && configuration?.length === 1) {
      const arrays = areAllArraysEmpty(configuration)
      arrays ? setIsSaveDisabled(true) : setIsSaveDisabled(areConfigurationsEqual)
    } else {
      setIsSaveDisabled(areConfigurationsEqual)
    }  
  }, [layoutData, configuration])
 
  return (
    <>
      {isLoading ? 
        <Loader />
      :
        <div className="pt-4">
          <Grid container>
            <Grid item xs={3} md={3} lg={3} xl={2}></Grid>
            <Grid item xs={9} md={9} lg={9} xl={10}>
              <div className="flex space-between px-3">
                <div className="flex gap-4">
                  <ButtonStyled className="button-with-text mb-3 gap-1" onClick={() => setOpenAddRelayModal(!openAddRelayModal)} disabled={relayCount >= 4}>
                    <span className="px-1">Añadir relay</span>
                    <IconNewObject className="icon-hover-with-text" />
                  </ButtonStyled>
                  <ButtonStyled className="boton-delete-alarm mb-3 gap-1" onClick={handleRemoveRelay} disabled={relayCount <= 1}>
                    <span className="px-1">Borrar relay</span>
                    <IconDeleteAlarm className="boton-delete-hijo" />
                  </ButtonStyled>
                </div>
                <div className="flex gap-4">
                  <ButtonStyled className="boton-delete-alarm mb-3 gap-1" onClick={() => setOpenSaveModal(!openSaveModal)} disabled={isSavedDisabled}>
                    <span className="px-1">Guardar cuadro</span>
                    <IconSave className="boton-delete-hijo" />
                  </ButtonStyled>
                  <ButtonStyled className="boton-delete-alarm mb-3 gap-1" onClick={() => setOpenDeleteModal(!openDeleteModal)} disabled={isDeleteDisabled}>
                    <span className="px-1">Borrar cuadro</span>
                    <IconDeleteAlarm className="boton-delete-hijo" />
                  </ButtonStyled>
                </div>
              </div>
            </Grid>
          </Grid>
          <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns, moves, setMoves)}>
            <Grid container>
              <Grid item xs={3} md={3} lg={3} xl={2}>
                <DropContainer columnId="Devices" column={columns?.Devices} isActive={(type) => isActive(type, "Devices")} />
              </Grid>
              <Grid item xs={9} md={9} lg={9} xl={10} className="relay-main-container">
                {relayKeys?.map((relayKey) => (
                  <div key={relayKey} className="relay-container flex-col">
                    {columns[relayKey]?.columns?.map((column) => (
                      <DropContainer key={column?.id} columnId={column?.id} column={column} direction="horizontal" isActive={(type) => isActive(type, relayKey)} />
                    ))}
                  </div>
                ))}
              </Grid>
            </Grid>
          </DragDropContext>
          {openSaveModal && (
            <ModalSaveLayout
              open={openSaveModal}
              setOpen={setOpenSaveModal}
              layoutData={layoutData}
              configuration={configuration}
              setLayoutData={setLayoutData}
              numserie={dispositivo[0]?.numserie}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
          {openDeleteModal && (
            <ModalDeleteLayout
              open={openDeleteModal}
              setOpen={setOpenDeleteModal}
              setIsLoading={setIsLoading}
              setColumns={setColumns}
              setRelayCount={setRelayCount}
              setLayoutData={setLayoutData}
              setConfiguration={setConfiguration}
              numserie={dispositivo[0]?.numserie}
              isLoading={isLoading}
              configuration={configuration}
              updateMovesCount={updateMovesCount}
              setMoves={setMoves}
            />
          )}
          {openAddRelayModal && (
            <ModalAddRelay open={openAddRelayModal} setOpen={setOpenAddRelayModal} relayCount={relayCount} setRelayCount={setRelayCount} setColumns={setColumns} />
          )}
          {<ModalMoveDeviceRelay
            open={openMoveDeviceModal}
            setOpen={setOpenMoveDeviceModal}
            deviceType={deviceType}
            dispositivo={dispositivo}
            clonedDevice={clonedDevice}
            setClonedDevice={setClonedDevice}
            setColumns={setColumns}
            nameDevices={nameDevices}
            setNameDevices={setNameDevices}
            moves={moves}
            setMoves={setMoves}
            configuration={configuration}
          />}
        </div>
      }
    </>
  )
}
export default LayOutBoard