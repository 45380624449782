// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.miniCircleFather {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15%;
}

.miniCircle .doughnut-style {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 200px;
  height: 200px;
}
.miniCircle canvas {
  width: 120px !important;
  height: 120px !important;
}

.img-mini-style {
  width: auto;
  height: 33px;
  right: 20%;
  position: absolute;
}

.img-style {
  right: 37%;
  position: absolute;
}

.img-style-consumption {
  right: 38%;
  position: absolute;
}

.img-style-red {
  right: 43%;
  position: absolute;
}

.battery-style {
  position: absolute;
  right: 20%;
  display: flex;
  flex-direction: column;
}

.battery-style-p {
  position: absolute;
  right: 13%;
  font-weight: 600;
  bottom: 8%;
  border-radius: 5px;
  padding: 2px 15px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}`, "",{"version":3,"sources":["webpack://./src/views/Scada/SelfConsumption/GraphsUtils/miniCircle.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AACJ;;AAGI;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;AAAR;AAEI;EACI,uBAAA;EACA,wBAAA;AAAR;;AAIA;EACI,WAAA;EACA,YAAA;EACA,UAAA;EACA,kBAAA;AADJ;;AAIA;EACI,UAAA;EACA,kBAAA;AADJ;;AAIA;EACI,UAAA;EACA,kBAAA;AADJ;;AAIA;EACI,UAAA;EACA,kBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,UAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,2CAAA;AADJ","sourcesContent":[".miniCircleFather {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding-bottom: 15%;\n\n}\n.miniCircle {\n    .doughnut-style {\n        display: flex;\n        justify-content: flex-end;\n        align-items: center;\n        width: 200px;\n        height: 200px;\n    }\n    canvas {\n        width: 120px !important;\n        height: 120px !important;\n    }\n}\n\n.img-mini-style {\n    width: auto;\n    height: 33px;\n    right: 20%;\n    position: absolute;\n}\n\n.img-style {\n    right: 37%;\n    position: absolute;\n}\n\n.img-style-consumption {\n    right: 38%;\n    position: absolute;\n}\n\n.img-style-red {\n    right: 43%;\n    position: absolute;\n}\n\n.battery-style {\n    position: absolute;\n    right: 20%;\n    display: flex;\n    flex-direction: column;\n}\n\n.battery-style-p {\n    position: absolute;\n    right: 13%;\n    font-weight: 600;\n    bottom: 8%;\n    border-radius: 5px;\n    padding: 2px 15px;\n    background: #fff;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
