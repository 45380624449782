import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { Loader } from 'components/Loader/Loader'
import { headersScada } from 'services/headers'
import { urls, dispositivos } from 'utils/constant'
import { ConfiguracionEMS } from './ConfiguracionEMS'
import "./styleEms.scss"

export const EmsCC = ({ instSelect, dispositivo, inst_type }) => {
    const token = sessionStorage?.token
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const installations = dispositivo?.filter(dev => instSelect?.includes(dev?.numserie?.toString()) && dev?.id_tipo_dispositivo === dispositivos.ccmaster)?.map(device => device.numserie)

    const fetchEms = async (numserie) => {
        try {
            const res = await fetch(urls.scada + `emsConfig/${numserie}?idCcMaster=${numserie}`, { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                let json = await res.json()
                return json
            } else {
                return []
            }
        } catch (err) {
            console.error("ERROR. API Centro Control", err)
        }
    }

    const arrayEms = async () => {
        setData([])
        if (instSelect?.length > 0) {
            setLoading(true)
            let result = await Promise.all(dispositivo?.filter(dev => installations?.includes(dev?.numserie)).map(device => fetchEms(device?.numserie).then(response => ({ ...response, master: device, }))))
            setLoading(false)
            const arrayNew = result?.flatMap(array => array)
            setData(arrayNew)
        }
    }

    useEffect(() => {
        arrayEms()
    }, [instSelect])

    return (
        <>
            {loading && data?.length <= 0 && <Loader />}
            {!loading && installations?.length <= 0 && <Grid items xs={12} className="calendar-historical-flex">
                <h4 align='center' className='mt-4'>No hay ningún máster en esta instalación</h4>
            </Grid>}
            {!loading && data?.length <= 0 && installations?.length > 0 &&
                <Grid items xs={12} className="calendar-historical-flex">
                    <h4 align='center' className='mt-4'>Ha habido un error al comunicarse con el máster</h4>
                </Grid>
            }
            {!loading && data?.length > 0 && <ConfiguracionEMS data={data} master={instSelect} inst_type={inst_type} />}
        </>
    )
}

