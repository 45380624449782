import React, { useState } from "react"
import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPowerOff } from "@fortawesome/free-solid-svg-icons"
import { ButtonStyledIcon } from "components/Buttons/ButtonStyledIcon"
import { StyledTableCellHead } from "components/Table/TableStyle"
import { StyledTableCell } from "components/Table/TableStyle"
import { StyledTableRow } from "components/Table/TableStyle"
import { headersScada } from "services/headers"
import { ImageDevice } from "utils/ImageDevice/ImageDevice"
import { IconEditPencil } from "utils/icon-centrocontrol"
import { formatDate, ordenarDatos } from "utils/Utils"
import { urls } from "utils/constant"
import { DropdownMaster } from "views/Master/MenuMaster/DropdownMaster"
import { ModalCalendar } from "views/Modals/ModalCalendar"
import { ModalSwitchOnOff } from "./Modal/ModalSwitchOnOff"
import { StyledTableCellOrdenado } from "components/Table/TableStyle"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"

export const TableManualCalendar = ({ setTableManual, manualControl, loading, setLoading, checkedInternal, loadingAlias, setLoadingAlias, error, setError, success, setSuccess, keySwitch, setKeySwitch, pulse, setPulse, setLoadingOn, loadingOn, centroControl, setUpdateCalendar, masterPermission }) => {
    const token = sessionStorage?.token
    const [open, setOpen] = useState(false)
    const [index, setIndex] = useState(0)
    const [master, setMaster] = useState()
    const [modal, setModal] = useState(false)
    const [relay, setRelay] = useState([])
    const [salidaId, setSalidaId] = useState()
    const [loadingRelay, setLoadingRelay] = useState(true)
    const [config, setConfig] = useState()
    const [sortConfig, setSortConfig] = useState({ key: 'nick', direction: 'asc' })

    const fetchRelay = ({ numserie }) => {
        setLoadingRelay(true)
        fetch(urls.scada + `calendarRelay/${numserie}?idCCMaster=${numserie}`, { method: 'GET', headers: headersScada(token) })
            .then(res => res.json())
            .then(data => {
                setRelay(data)
            })
            .catch(err => console.error("ERROR. API Scada", err))
            .finally(() =>
                setLoadingRelay(false)
            )
    }

    const handleOnOffModal = (devNumSerie, salidaId) => {
        fetchRelay({ numserie: devNumSerie })
        setMaster({ numserie: devNumSerie })
        setSalidaId(salidaId)
        setModal(true)
    }

    const selectedSalida = relay.find(item => item.ID_entry === salidaId)

    const reloadRelay = (devNumSerie) => {
        fetchRelay({ numserie: devNumSerie })
    }

    return (
        <>
            {manualControl.length > 0 ?
                <TableContainer component={Paper} className="mt-3 mb-3">
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead >
                            <TableRow className="row-table-device-sticky">
                                <StyledTableCellHead align="center" style={{ padding: "5px 0px" }}>
                                </StyledTableCellHead>
                                <StyledTableCellOrdenado celda={'nick'} funcion={() => { ordenarDatos(manualControl, sortConfig, setSortConfig, 'nick') }} sortConfig={sortConfig}>Dispositivo</StyledTableCellOrdenado>
                                <StyledTableCellHead align="start" style={{ padding: "5px 0px" }}>Fecha</StyledTableCellHead>
                                <StyledTableCellHead align="center" style={{ padding: "5px 0px" }}>Salida 1</StyledTableCellHead>
                                <StyledTableCellHead align="center" style={{ padding: "5px 0px" }}>Salida 2</StyledTableCellHead>
                                <StyledTableCellHead align="center" style={{ padding: "5px 0px" }}></StyledTableCellHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {manualControl && manualControl?.map((dev, i) => {
                                return (
                                    <StyledTableRow>
                                        <StyledTableCell>
                                            <DropdownMaster
                                                token={token}
                                                device={dev?.numserie}
                                                master={dev}
                                                permissions={masterPermission[dev.numserie]}
                                                active={dev?.activo}
                                                instalacion={dev}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align="start" className="d-flex align-items-center justify-content-start">
                                            <ImageDevice id_type_device={dev?.id_tipo_dispositivo} width={20} className="mr-2" />
                                            {dev?.nick}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" >
                                            <p>{formatDate(dev.datetime)}</p>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" style={{ paddingButton: "15px " }}>
                                            <span className="d-flex align-items-center justify-content-start flex-column" onClick={() => { dev.out1_mode && handleOnOffModal(dev?.numserie, 1) }}>
                                                <small >{dev?.out1_alias ? dev?.out1_alias : "Salida 1"}</small>
                                                <span key={keySwitch} className={`${dev?.out1_mode && (checkedInternal(Number(dev?.out1_mode)) ? "button-on-off-active" : "button-on-off-inactive")} button-on-off button-on-off-cc`}>
                                                    {!dev?.out1_alias &&
                                                        <HtmlTooltip title={<span>El máster no tiene conexión</span>}>
                                                            <FontAwesomeIcon icon={faPowerOff} size="x" />
                                                        </HtmlTooltip>}
                                                    {dev.out1_alias && <FontAwesomeIcon icon={faPowerOff} size="x" />}
                                                </span>
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" style={{ paddingButton: "15px " }}>
                                            <span className="d-flex align-items-center justify-content-start flex-column" onClick={() => { dev?.out2_mode && handleOnOffModal(dev?.numserie, 2) }}>
                                                <small>{dev?.out2_alias ? dev?.out2_alias : "Salida 2"}</small>
                                                <span key={keySwitch} className={`${dev?.out2_mode && (checkedInternal(Number(dev?.out2_mode)) ? "button-on-off-active" : "button-on-off-inactive")} button-on-off button-on-off-cc`}>
                                                    {!dev?.out2_alias &&
                                                        <HtmlTooltip title={<span>El máster no tiene conexión</span>}>
                                                            <FontAwesomeIcon icon={faPowerOff} size="x" />
                                                        </HtmlTooltip>}
                                                    {dev?.out2_alias && <FontAwesomeIcon icon={faPowerOff} size="x" />}
                                                </span>
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <ButtonStyledIcon className='boton-edit-alarm' title='Editar' onClick={() => { setIndex(i); setOpen(!open); setMaster(dev.numserie); setConfig(dev) }}>
                                                <IconEditPencil className='boton-edit-hijo' />
                                            </ButtonStyledIcon>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Grid items xs={12} className="d-flex justify-content-center align-items-center mt-5">
                    <h4 align='center' className='mt-4'>No hay ningún master conectado</h4>
                </Grid>
            }
            {open &&
                <ModalCalendar loadingOn={loadingOn} data={config} setTableManual={setTableManual} manualControl={manualControl} setUpdateCalendar={setUpdateCalendar} centroControl={centroControl} setLoadingOn={setLoadingOn} lodingOn={loadingOn} pulse={pulse} setPulse={setPulse} keySwitch={keySwitch} setKeySwitch={setKeySwitch} open={open} setOpen={setOpen} loading={loading} setLoading={setLoading}
                    checkedInternal={checkedInternal} loadingAlias={loadingAlias} setLoadingAlias={setLoadingAlias} error={error} index={index} setIndex={setIndex} setError={setError} success={success} setSuccess={setSuccess} idCcMaster={master} />
            }
            {modal &&
                <ModalSwitchOnOff centroControl={centroControl} setTableManual={setTableManual} manualControl={manualControl}
                    modal={modal} setModal={setModal} data={selectedSalida} setLoadingOn={setLoadingOn} reloadRelay={reloadRelay}
                    idCcMaster={master?.numserie} loadingOn={loadingOn} loading={loadingRelay} setLoading={setLoadingRelay} />
            }
        </>
    )
}