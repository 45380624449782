import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material'
import { BeatLoader } from 'react-spinners'
import moment from 'moment'
import { urls, alarmas } from 'utils/constant'
import { toKiloW } from 'utils/functionsforGraphs'
import { datoNull } from 'views/Scada/MABT/utils'
import { Loader } from "components/Loader/Loader"
import { StyledTableCell } from 'components/Table/TableStyle'
import { StyledTableRow } from 'components/Table/TableStyle'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { headersScada } from 'services/headers'
import { IconVtnT, IconStatusCcommunication, IconOffline, IconOnline, IconAlarmWith } from 'utils/icon-centrocontrol'
import { showDate, nombreAlarma } from 'utils/Utils'
import { StyledTableCellHead } from 'components/Table/TableStyle'
import { headersCentroControl } from 'services/headers'

export const CurrentDataTableMABTDevice = (props) => {
    const now = moment().format('YYYY-MM-DD')
    const token = sessionStorage?.token
    const [loading, setLoading] = useState(false)
    const [children, setChildren] = useState([])
    const [valueVtn, setValueVtn] = useState()
    let url

    let variableTableSelfConsumption = [
        { label: "Dispositivo", key: "nick" },
        { label: "fecha", key: "fecha" },
        { label: "P. Activa R (kW)", key: "pac1" },
        { label: "P. Activa S (kW)", key: "pac2" },
        { label: "P. Activa T (kW)", key: "pac3" },
        { label: "P. Reactiva R (kW)", key: "pre1" },
        { label: "P. Reactiva S (kW)", key: "pre2" },
        { label: "P. Reactiva T (kW)", key: "pre3" },
        { label: "Corriente R (A)", key: "i1" },
        { label: "Corriente S (A)", key: "i2" },
        { label: "Corriente T (A)", key: "i3" },
        { label: "Tensión R (V)", key: "v1" },
        { label: "Tensión S (V)", key: "v2" },
        { label: "Tensión T (V)", key: "v3" }]

    const cargarDatosTabla = async () => {
        setLoading(true)
        try {
            const res = await fetch(urls.ccontrol + `userNumChildren/${props?.numserieDevice}`, { method: "GET", headers: headersCentroControl(token) })
            if (res.status === 200) {
                const json = await res.json()
                if(json?.length > 0) {
                    const vtnV = await fetchVtn(json[json?.length-1])
                    const datos = await Promise.all(json.map(fetchData))
                    const datosfilter = datos.filter(element => element !== undefined)
                    setChildren(datosfilter)
                    setValueVtn(vtnV)
                }
            } else {
                return []
            }
        } catch (err) {
            setLoading(false)
            console.error("ERROR. API Scada", err)
        }
        setLoading(false)
    }

    const fetchData = async (numserie) => {
        url = `getHistoricalDataScada/${numserie}?idCCMaster=${props?.numserieDevice}&iniDate=${now}&endDate=${now}&groupDate=days`
        try {
            const res = await fetch(urls.scada + url, { method: "GET", headers: headersScada(token) })
            const json = await res.json()
            return {
                alarmCount: props?.masterPermission?.[props?.numserieDevice].alarmCount,
                alarms: props?.masterPermission?.[props?.numserieDevice].alarms,
                alarmConfig: props?.alarms.filter((alarm) => alarm?.numserie === numserie),
                name: numserie,
                nick: json[0]?.nick,
                data: json[0],
                infoDevice: numserie
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const fetchVtn = async (numserie) => {
        url = `getCurrentData/${numserie}?idCCMaster=${props?.numserieDevice}`
        try {
            const res = await fetch(urls.scada + url, { method: "GET", headers: headersScada(token) })
            const json = await res.json()
            return json
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }
    
    useEffect(() => {
        cargarDatosTabla()
    }, [props?.numserieDevice])

    useEffect(() => {
        props?.setCsvData([])
        children?.forEach(master => {
            props?.setCsvData(prev => [...prev, {
                "Dispositivo": master?.nick,
                "fecha": master?.data?.fecha,
                "P. Activa R (kW)": master?.data?.pac1,
                "P. Activa S (kW)": master?.data?.pac2,
                "P. Activa T (kW)": master?.data?.pac3,
                "Corriente R (A)": master?.data?.i1,
                "Corriente S (A)": master?.data?.i2,
                "Corriente T (A)": master?.data?.i3,
                "Tensión R Max (V)": master?.data?.v1,
                "Tensión S Max (V)": master?.data?.v2,
                "Tensión T Max (V)": master?.data?.v3,
            }
            ])
        })
    }, [children])

    useEffect(() => {
        props?.setCsvHeaders(variableTableSelfConsumption)
    }, [])
    
    return (
        <>
            {loading ?
                <div className="justify-content-center">
                    <Loader/>
                </div>
                :
                <div className="principal-cc" style={{ overflowY: "scroll" }}>
                    <h3 className='flex-baseline texto-vtn mt-3 mb-0'>
                        <IconVtnT />
                        <div className='ml-2'>
                            VTN:
                        </div>
                        <span className='ml-2 text-center box-texto'> {loading ? <BeatLoader color="#ea5e0b" size={5} /> : datoNull(valueVtn?.currentData?.vtn) + (datoNull(valueVtn?.currentData?.vtn) !== '-' ? ' V' : '')} </span>
                    </h3>
                    <div className="table-responsive-md">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead style={{ borderBottom: '2px solid #fe5000', borderRadius: '50px' }}>
                                    <TableRow style={{ borderBottom: '2px solid black', borderRadius: '50px' }}>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                Dispositivos
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center pointer-button">
                                                <HtmlTooltip
                                                    title={
                                                        <span>
                                                            Alarma de comunicación
                                                        </span>
                                                    }
                                                >
                                                    <IconStatusCcommunication />
                                                </HtmlTooltip>
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead>
                                            <div className="d-flex justify-content-center align-items-center pointer-button">
                                                <HtmlTooltip
                                                    title={
                                                        <span>
                                                            Alarmas activas
                                                        </span>
                                                    }
                                                >
                                                    <IconAlarmWith color="black" width="19" height="19" viewBox="0 0 19 19" />
                                                </HtmlTooltip>
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                P. Activa <br /> R (A)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                P. Activa <br /> S (A)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                P. Activa <br /> T (A)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                P. Reactiva <br /> R (A)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                P. Reactiva <br /> S (A)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                P. Reactiva <br /> T (A)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                Corriente <br /> R (A)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                Corriente <br /> S (A)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                Corriente <br /> T (A)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                Tensión <br /> R (V)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                Tensión <br /> S (V)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                Tensión  <br /> T (V)<br />
                                            </div>
                                        </StyledTableCellHead>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {children?.map((master) => {
                                        const alarmConexion = master?.alarms?.find(alarm => alarmas?.conexionDataLogger === alarm?.alarm_id)
                                        const alarmComunication = master?.alarms?.find(alarm => alarmas?.comunicacion === alarm?.alarm_id && master?.infoDevice === alarm?.numserie)
                                        const configComunication = master?.alarmConfig?.find(config => alarmas?.comunicacion === config?.alarm_id)
                                        const alarms = master?.alarmCount > 0 ? master?.alarms?.filter(alarm => alarm?.numserie === master?.infoDevice) : false
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell align="center" className="ml-2">
                                                    {master?.data?.nick?.length > 20 ?
                                                        <HtmlTooltip title={<span>{master?.nick}</span>}>
                                                            <span> {master?.data?.nick?.length > 20 ? master?.data?.nick?.substr(0, 19) + '...' : master?.data?.nick}</span>
                                                        </HtmlTooltip>
                                                        :
                                                        <span> {master?.data?.nick ? master?.data?.nick : 'Sin nombre ' + (master?.data?.mabt_output ? master?.data?.mabt_output : '')}</span>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="ml-2">
                                                    <div className="d-flex justify-content-center align-items-center pointer-button">
                                                        {configComunication && alarmComunication ?
                                                            <HtmlTooltip title={<span>Sin comunicación - {showDate(alarmComunication?.datetime_active)}</span>}>
                                                                <IconOffline />
                                                            </HtmlTooltip>
                                                            :
                                                            !alarmComunication && configComunication && !alarmConexion ?
                                                                <HtmlTooltip title={<span>Comunicando - {showDate(master?.data?.fecha)}</span>}>
                                                                    <IconOnline />
                                                                </HtmlTooltip>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="ml-2">
                                                    {alarms?.length > 0 &&
                                                        <HtmlTooltip
                                                            title={
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        Alarmas activas: {alarms.length}
                                                                    </Grid>
                                                                    <ul>
                                                                        {alarms?.map(element => {
                                                                            return (
                                                                                <li>
                                                                                    <Grid item xs={12}>
                                                                                        <span>
                                                                                            {showDate(element.datetime_active)}
                                                                                        </span>
                                                                                        <span>
                                                                                            {' ' + nombreAlarma(element.alarm_id)}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </Grid>
                                                            }
                                                        >
                                                            <IconAlarmWith color="black" width="19" height="19" wiewBox="0 0 19 19" />
                                                        </HtmlTooltip>
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    {toKiloW(master?.data?.pac1)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    {toKiloW(master?.data?.pac2)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    {toKiloW(master?.data?.pac3)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    {toKiloW(master?.data?.pre1)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    {toKiloW(master?.data?.pre2)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    {toKiloW(master?.data?.pre3)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    {toKiloW(master?.data?.i1)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    {toKiloW(master?.data?.i2)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    {toKiloW(master?.data?.i3)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    {toKiloW(master?.data?.v1)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    {toKiloW(master?.data?.v2)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    {toKiloW(master?.data?.v3)}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div >
            }
        </>
    )
}