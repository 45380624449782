import { createSlice } from '@reduxjs/toolkit'

export const infoInstalSlice = createSlice({
  name: 'infoInstal',
  initialState: {},
  reducers: {
    clearInfoInstal: (state, action) => {
      return {}
    },
    addInfoInstal: (state, action) => {
      if(state[action.payload.numserie] === undefined) {
        state[action.payload.numserie] = action.payload.info
      }
    }
  },
})

export const { addInfoInstal, clearInfoInstal } = infoInstalSlice.actions

export default infoInstalSlice.reducer