import React from 'react'
import { Avatar, FormControl, Grid, Typography } from '@mui/material'
import { DropZone } from 'components/DropZone/DropZone'
import { Modal } from 'components/Modal/Modal'
import { positions } from 'utils/Utils'
import { SwitchStyled } from 'components/Switch/SwitchStyled'

export const NewLogo = ({open, setOpen, fetchAddLogo, loading, files, position, setPosition, logos, handleFiles}) => {
    const functCancel = () => {
        handleFiles([])
        setPosition(-1)
        setOpen(!open)
    }

    return (
        <Modal open={open} setOpen={setOpen} functCancel={functCancel} functSave={fetchAddLogo} loading={loading} disabled={files.length === 0 || position === -1} title='Añadir logo'>
            <Grid item xs={12} md={12} lg={12} xl={12} className='pr-4 pl-4 pb-3'>
                <FormControl fullWidth variant="outlined" size="small" className='pb-2'>
                    <div className='pb-1'>
                        <small className="color-black">
                            Selecciona la posición:
                        </small>
                    </div>
                    <Grid container>
                        {positions.map((pos, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={5} className={`flex-center justify-start pt-2 pb-2 shaded ${index % 2 === 0 ? 'shaded' : 'not-shaded'}`}>
                                    <Typography className='font-size-12 pl-2'>{pos.position}</Typography>
                                </Grid>
                                <Grid item xs={1} className={`flex-center justify-start pt-2 pb-2 shaded ${index % 2 === 0 ? 'shaded' : 'not-shaded'}`} style={{ stroke: position === pos.value ? '#FF8A1F' : '#fff' }}>
                                    <SwitchStyled
                                        className="switch-small"
                                        checked={position === pos.value}
                                        onChange={(e) => setPosition(pos.value)}
                                    />
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </FormControl>
                <div className='pb-1'>
                    <small className="color-black">
                        Subir logo:
                    </small>
                </div>
                {!loading && logos &&
                    <DropZone onFileUpload={(file) => { handleFiles(file) }} acceptedExtensions={['image/jpeg', 'image/jpg', 'image/png']} maxFilesAcept={3 - Object.keys(logos).length} disabled={Object.keys(logos).length === 3} messageZone='Arrastra y suelta archivos .jpg, .jpeg o .png aquí, o haz clic para seleccionar archivos (El archivo no puede superar los 100kb)' maxSize={100} titleFiles='Nombre del fichero:'/>
                }
            </Grid>
            {files?.length > 0 && 
                <Grid item xs={12} md={12} lg={12} xl={12} className='pl-4 pr-4'>
                    <div className='pb-1'>
                        <h4 className="color-black">
                            Previsualización logo
                        </h4>
                    </div>
                    <div className='flex-space-between'>
                        {files?.map((logo, index) => {
                            return(
                                <Avatar key={index} sx={{ bgcolor: 'white',  maxWidth: '10em', width: 'auto', height: '5em', border: "1px solid #cbcbcb", borderRadius: '0' }}>
                                    <img className="pt-2 pb-2 pr-3 pl-3" style={{ maxWidth: '10em', height: '5em' }} src={URL.createObjectURL(logo)} alt='logo' />
                                </Avatar>
                            )
                        })}
                    </div>
                </Grid>
            }
        </Modal>
    )
}