import React, { useState } from "react"
import { Button, Menu } from "@mui/material"
import { PermissionsForMenuMaster } from "views/Menu/PermissionsforMenu"
import { IconMenu } from "utils/icon-centrocontrol"

export const DropdownMaster = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }
  const handleClose = () => setAnchorEl(null)

  return (
    <>
      {props?.master?.permissions !== "user" &&
        props.permissions !== "user" && (
          <>
            <Button className="btn-square p-0" onClick={handleClick}>
              <span className="btn-wrapper--icon">
                <IconMenu width='10' height='16' color={props?.color} />
              </span>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              classes={{ list: "p-0" }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <PermissionsForMenuMaster
                token={props?.token}
                scada={props?.scada}
                project={props?.project}
                master={props?.master}
                permissions={
                  props?.master?.permissions
                    ? props?.master?.permissions
                    : props?.permissions
                }
                handleClose={handleClose}
                data={props?.data?.filter(
                  (mas) => Number(mas?.numserie) === Number(props?.master?.numserie)
                )}
                licence={props?.licence}
                groups={props?.groups}
                subgroups={props?.subgroups}
              />
            </Menu>
          </>
        )}
    </>
  )
}