import React, { useEffect, useState } from 'react'
import { Card, Grid, Table, TableBody, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import { StyledTableCell, StyledTableRow, StyledTableCellOrdenado, StyledTableCellHead } from 'components/Table/TableStyle'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { Loader } from "components/Loader/Loader"
import { IconOnline, IconOffline, IconStatusCcommunication, IconAlarmWith } from 'utils/icon-centrocontrol'
import { showDate, ordenarDatos, nombreAlarma } from 'utils/Utils'
import { toKiloW } from 'utils/functionsforGraphs'
import { alarmas } from 'utils/constant'

export const CurrentDataTableMABT = (props) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null })
    const [loading, setLoading] = useState(false)

    const handleDeviceMABT = (numserie, nick) => {
        props?.setViewDevice(1)
        props?.setNumserieDevice(numserie)
        props?.setDataFiltered([{ numserie: numserie, nick: nick }])
    }

    let variableTableSelfConsumption = [
        { label: "Dispositivo", key: "nick" },
        { label: "Fecha", key: "fecha" },
        { label: "fecha)", key: "fecha" },
        { label: "P. Activa R (kW)", key: "pac1" },
        { label: "P. Activa S (kW)", key: "pac2" },
        { label: "P. Activa T (kW)", key: "pac3" },
        { label: "P. Reactiva R (kW)", key: "pre1" },
        { label: "P. Reactiva S (kW)", key: "pre2" },
        { label: "P. Reactiva T (kW)", key: "pre3" },
        { label: "Corriente R (A)", key: "i1" },
        { label: "Corriente S (A)", key: "i2" },
        { label: "Corriente T (A)", key: "i3" },
        { label: "Tensión R (V)", key: "v1" },
        { label: "Tensión S (V)", key: "v2" },
        { label: "Tensión T (V)", key: "v3" }]

    useEffect(() => {
        setLoading(true)
        if (props?.noRepeatData) {
            ordenarDatos(props?.noRepeatData, { direction: 'asc' }, setSortConfig, 'nick')
        }
        setLoading(false)
    }, [props?.noRepeatData])

    useEffect(() => {
        props?.setCsvData([])
        props?.noRepeatData?.forEach(master => {
            props?.setCsvData(prev => [...prev, {
                "Dispositivo": master?.nick,
                "Instalación": master?.nick_master,
                "fecha": master?.fecha,
                "P. Activa R (kW)": master?.pac1,
                "P. Activa S (kW)": master?.pac2,
                "P. Activa T (kW)": master?.pac3,
                "P. Reactiva R (kW)": master?.pre1,
                "P. Reactiva S (kW)": master?.pre2,
                "P. Reactiva T (kW)": master?.pre3,
                "Corriente R (A)": master?.i1,
                "Corriente S (A)": master?.i2,
                "Corriente T (A)": master?.i3,
                "Tensión R Max (V)": master?.v1,
                "Tensión S Max (V)": master?.v2,
                "Tensión T Max (V)": master?.v3,
            }
            ])
        })
    }, [props?.noRepeatData])


    useEffect(() => {
        props?.setCsvHeaders(variableTableSelfConsumption)
    }, [])

    return (
        <Card className="card-box principal-cc" style={{ overflowY: "scroll", border: "none !important" }}>
            {loading ?
                <div className="justify-content-center">
                    <Loader/>
                </div>
                :
                <div className="table-responsive-md">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead style={{ borderBottom: '2px solid #fe5000', borderRadius: '50px' }}>
                                <TableRow style={{ borderBottom: '2px solid black', borderRadius: '50px' }}>
                                    <StyledTableCellOrdenado align="center" celda={'nick'} funcion={() => { ordenarDatos(props?.noRepeatData, sortConfig, setSortConfig, 'nick') }} sortConfig={sortConfig}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            Dispositivo
                                        </div>
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellHead align="center">
                                        <div className="d-flex justify-content-center align-items-center pointer-button">
                                            <HtmlTooltip
                                                title={
                                                    <span>
                                                        Alarma de comunicación
                                                    </span>
                                                }
                                            >
                                                <IconStatusCcommunication />
                                            </HtmlTooltip>
                                        </div>
                                    </StyledTableCellHead>
                                    <StyledTableCellHead>
                                        <div className="d-flex justify-content-center align-items-center pointer-button">
                                            <HtmlTooltip
                                                title={
                                                    <span>
                                                        Alarmas activas
                                                    </span>
                                                }
                                            >
                                                <IconAlarmWith color="black" width="19" height="19" viewBox="0 0 19 19" />
                                            </HtmlTooltip>
                                        </div>
                                    </StyledTableCellHead>
                                    <StyledTableCellOrdenado align="center" celda={'pac1'} funcion={() => { ordenarDatos(props?.noRepeatData, sortConfig, setSortConfig, 'pac1') }} sortConfig={sortConfig}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            P. Activa <br /> R (A)<br />
                                        </div>
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado align="center" celda={'pac2'} funcion={() => { ordenarDatos(props?.noRepeatData, sortConfig, setSortConfig, 'pac2') }} sortConfig={sortConfig}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            P. Activa <br /> S (A)<br />
                                        </div>
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado align="center" celda={'pac3'} funcion={() => { ordenarDatos(props?.noRepeatData, sortConfig, setSortConfig, 'pac3') }} sortConfig={sortConfig}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            P. Activa <br /> T (A)<br />
                                        </div>
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado align="center" celda={'pre1'} funcion={() => { ordenarDatos(props?.noRepeatData, sortConfig, setSortConfig, 'pre1') }} sortConfig={sortConfig}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            P. Reactiva <br /> R (A)<br />
                                        </div>
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado align="center" celda={'pre2'} funcion={() => { ordenarDatos(props?.noRepeatData, sortConfig, setSortConfig, 'pre2') }} sortConfig={sortConfig}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            P. Reactiva <br /> S (A)<br />
                                        </div>
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado align="center" celda={'pre3'} funcion={() => { ordenarDatos(props?.noRepeatData, sortConfig, setSortConfig, 'pre3') }} sortConfig={sortConfig}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            P. Reactiva <br /> T (A)<br />
                                        </div>
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado align="center" celda={'i1'} funcion={() => { ordenarDatos(props?.noRepeatData, sortConfig, setSortConfig, 'i1') }} sortConfig={sortConfig}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            Corriente <br /> R (A)<br />
                                        </div>
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado align="center" celda={'i2'} funcion={() => { ordenarDatos(props?.noRepeatData, sortConfig, setSortConfig, 'i2') }} sortConfig={sortConfig}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            Corriente <br /> S (A)<br />
                                        </div>
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado align="center" celda={'i3'} funcion={() => { ordenarDatos(props?.noRepeatData, sortConfig, setSortConfig, 'i3') }} sortConfig={sortConfig}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            Corriente <br /> T (A)<br />
                                        </div>
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado align="center" celda={'v1'} funcion={() => { ordenarDatos(props?.noRepeatData, sortConfig, setSortConfig, 'v1') }} sortConfig={sortConfig}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            Tensión <br /> R (V)<br />
                                        </div>
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado align="center" celda={'v2'} funcion={() => { ordenarDatos(props?.noRepeatData, sortConfig, setSortConfig, 'v2') }} sortConfig={sortConfig}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            Tensión <br /> S (V)<br />
                                        </div>
                                    </StyledTableCellOrdenado>
                                    <StyledTableCellOrdenado align="center" celda={'v3'} funcion={() => { ordenarDatos(props?.noRepeatData, sortConfig, setSortConfig, 'v3') }} sortConfig={sortConfig}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            Tensión  <br /> T (V)<br />
                                        </div>
                                    </StyledTableCellOrdenado>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props?.noRepeatData && props?.noRepeatData?.map((master, i) => {
                                    const alarmComunication = master?.alarms?.find(alarm => alarmas.conexionDataLogger === alarm.alarm_id && master.infoDevice.numserie === alarm.numserie)
                                    const configComunication = master?.alarmConfig?.find(config => alarmas.conexionDataLogger === config.alarm_id)
                                    return (
                                        <React.Fragment key={i}>
                                            {master?.numserie ?
                                                <StyledTableRow>
                                                    <StyledTableCell align="center" className="ml-2">
                                                        <div>
                                                            <a onClick={() => { handleDeviceMABT(master?.numserie, master?.nick) }}>
                                                                <HtmlTooltip title={<span>{master?.nick}</span>}>
                                                                    <span> {master?.nick?.length > 20 ? master?.nick?.substr(0, 19) + '...' : master?.nick}</span>
                                                                </HtmlTooltip>
                                                            </a>
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" className="ml-2">
                                                        <div className="d-flex justify-content-center align-items-center pointer-button">
                                                            {alarmComunication && configComunication ?
                                                                <HtmlTooltip title={<span>Sin comunicación - {showDate(alarmComunication?.datetime_active)}</span>}>
                                                                    <IconOffline />
                                                                </HtmlTooltip>
                                                                :
                                                                !alarmComunication && configComunication ?
                                                                    <HtmlTooltip title={<span>Comunicando - {showDate(master?.data?.fecha)}</span>}>
                                                                        <IconOnline />
                                                                    </HtmlTooltip>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" className="ml-2">
                                                        {master.alarmCount > 0 &&
                                                            <HtmlTooltip
                                                                title={
                                                                    <Grid container>
                                                                        <Grid item xs={12}>
                                                                            Alarmas activas: {master.alarmCount}
                                                                        </Grid>
                                                                        <ul>
                                                                            {master?.alarms?.map(element => {
                                                                                return (
                                                                                    <li>
                                                                                        <Grid item xs={12}>
                                                                                            <span>
                                                                                                {showDate(element.datetime_active)}
                                                                                            </span>
                                                                                            <span>
                                                                                                {' ' + nombreAlarma(element.alarm_id)}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </Grid>
                                                                }
                                                            >
                                                                <IconAlarmWith color="black" width="19" height="19" wiewBox="0 0 19 19" />
                                                            </HtmlTooltip>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.pac1)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.pac2)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.pac3)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.pre1)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.pre2)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.pre3)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.i1)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.i2)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.i3)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.v1)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.v2)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.v3)}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                :
                                                null
                                            }
                                        </React.Fragment>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
        </Card >
    )
}