import React from 'react'
import { BeatLoader } from 'react-spinners'
import SpeedIcon from '@mui/icons-material/Speed'
import Grid from '@mui/material/Grid'
import graywArrow from 'assets/img/gifs/bluewArrow_gris.png'
import greenArrow from 'assets/img/gifs/4-greenArrow.gif'
import blueArrow from 'assets/img/gifs/4-bluewArrow.gif'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { IconAlarmWith, IconOnline, IconOffline, IconVtnT } from 'utils/icon-centrocontrol'
import { showDate, truncString, nombreAlarma, convertDatetime } from 'utils/Utils'
import { alarmas } from 'utils/constant'
import { DropDownDevices } from "views/Devices/MenuDevices/DropDownDevices"
import { calcularPorcentajeColumna, calcularPorcentajeFila, calcularSumaFila, datoNull } from './utils'
import './styleMabt.scss'

/**
 * @param {{ datosLineas: string[] | number[], vtn: {}[] }} 
 * @returns 
 */
export const MabtLineasDatos = ({ confAlarms, loading, datosLineas, vtn, total, selectMedidor, vtnDevice, masterPermission, devices, alarms, activeTabDevice, selectTab, selectMedidorSensor }) => {

    return (
        <Grid container className='lineas-mabt mt-5'>
            <Grid item className="mr-5 ml-3 mb-3" xs={12}>
                <Grid container>
                    {['R', 'S', 'T'].map((itemFila) => {
                        let sumaFila = calcularSumaFila(datosLineas, itemFila === 'R' ? 'dato1' : itemFila === 'S' ? 'dato2' : 'dato3')
                        return (
                            <Grid item xs={12} className='mb-4 mt-4 fila-linea' key={itemFila + 'MabtFilas'}>
                                <Grid container columns={22}>
                                    {/* fila */}
                                    <Grid item xs={2} className='position-relative'>
                                        <h3 className='flex position-absolute fila-texto'>
                                            {itemFila}
                                            <img alt="mabtlineadatos2" src={sumaFila === 0 ? graywArrow : sumaFila > 0 ? blueArrow : greenArrow} className='fila-gif' />
                                        </h3>
                                        {activeTabDevice !== 2 &&
                                            <div className='position-absolute fila-circulo'>
                                                <p className='text-center fila-texto-circulo'>
                                                    {loading ? <BeatLoader color="#ea5e0b" size={5} /> : calcularPorcentajeFila(datosLineas, itemFila === 'R' ? 'dato1' : itemFila === 'S' ? 'dato2' : 'dato3', total) + '%'}
                                                </p>
                                            </div>
                                        }
                                    </Grid>
                                    {/* columnas */}
                                    {itemFila === 'R' && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((itemColumna) => {
                                        let dato = datosLineas?.find(medidor => medidor?.position === itemColumna)
                                        let device = devices?.childrenDevices?.find(ele => Number(ele?.numserie) === Number(dato?.numserie))
                                        let numAlarm = alarms?.filter((ele) => Number(ele?.numserie) === Number(device?.numserie))
                                        let alarmComunication = numAlarm?.find(ele => ele?.alarm_id === alarmas.comunicacion)
                                        let alarmConfigurated = confAlarms?.find(ele => ele?.alarm_id === alarmas.comunicacion && ele?.numserie === device?.numserie)
                                        return (
                                            <Grid item xs={2} key={itemColumna + 'MabtColumnas'} >
                                                <Grid container justifyContent="flex-end" alignItems="flex-start" className='position-relative columna-inicio'>
                                                    <div className={`columna-box d-flex justify-content-between ${!dato ? 'mabt-line-no-used' : ''}`}>
                                                        {/* header */}
                                                        <div className={`position-absolute ${dato?.nick ? 'header-box' : 'header-box-sin-contenido'}`}>
                                                            <p className='text-start header-box-texto'>
                                                                <Grid container className='flex-center justify-center header-line-data-mabt'>
                                                                    <Grid item xs={1} md={1} lg={1} xl={1} className='dropDownOptions'>
                                                                        {dato &&
                                                                            <DropDownDevices
                                                                                device={device}
                                                                                masterPermissionNew={masterPermission}
                                                                            />
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={9} md={9} lg={9} xl={9}>
                                                                        {dato &&
                                                                            <HtmlTooltip title={<span>{convertDatetime(dato?.datetime)}</span>}>
                                                                                <a className='p-0 flex-center justify-center' rel="noopener noreferrer" onClick={() => selectMedidor(dato)}>
                                                                                    {dato?.nick ? truncString(dato?.nick, 25) : 'Sin nombre ' + itemColumna}
                                                                                </a>
                                                                            </HtmlTooltip>
                                                                        }
                                                                    </Grid>
                                                                    {dato &&
                                                                        <Grid item xs={2} md={2} lg={2} xl={2}>
                                                                            {!alarmComunication && alarmConfigurated && 
                                                                                <HtmlTooltip
                                                                                    title={
                                                                                        <span>
                                                                                            Comunicando
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <IconOnline width={15} height={15} />
                                                                                </HtmlTooltip>
                                                                            }
                                                                            {alarmComunication && alarmConfigurated &&
                                                                                <HtmlTooltip
                                                                                    title={
                                                                                        <span>
                                                                                            Sin comunicación
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <IconOffline width={15} height={15} />
                                                                                </HtmlTooltip>
                                                                            }
                                                                            {numAlarm?.length > 0 &&
                                                                                <HtmlTooltip 
                                                                                    title={
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                Alarmas activas: {numAlarm.length}
                                                                                            </Grid>
                                                                                            <ul>
                                                                                                {numAlarm.map(element => {
                                                                                                    return (
                                                                                                        <li>
                                                                                                            <Grid item xs={12}>
                                                                                                                <span>
                                                                                                                    {showDate(element?.datetime_active)}
                                                                                                                </span>
                                                                                                                <span>
                                                                                                                    {' ' + nombreAlarma(element?.alarm_id)}
                                                                                                                </span>
                                                                                                            </Grid>
                                                                                                        </li>
                                                                                                    )
                                                                                                })}
                                                                                            </ul>
                                                                                        </Grid>
                                                                                    }
                                                                                >
                                                                                    <span className='cursor-pointer' onClick={() => selectTab(1)}>
                                                                                        <IconAlarmWith color='black'/>
                                                                                    </span>
                                                                                </HtmlTooltip>
                                                                            }
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                                <hr className={`${dato?.nick ? 'header-divisor' : 'header-divisor-sin-contenido'}`} />
                                                            </p>
                                                        </div>
                                                        {dato ?
                                                            <>
                                                                {/* columna */}
                                                                <p className='text-center header-box-numero'>
                                                                    {itemColumna}
                                                                </p>
                                                                {/* fila 1 */}
                                                                <div className="position-absolute box-linea box-linea-1 flex-center justify-center">
                                                                    <p className={`text-center box-texto ${dato?.dato1 !== null && dato?.dato1 === 0 ? 'number-cero' : ''} ${dato?.dato1 !== null && dato?.dato1 > 0 ? 'number-positivo' : ''} ${dato?.dato1 !== null && dato?.dato1 < 0 ? 'number-negativo' : ''}`}> {loading ? <BeatLoader color="#ea5e0b" size={5} /> : datoNull(dato?.dato1)} </p>
                                                                </div>
                                                                {/* fila 2 */}
                                                                <div className="position-absolute box-linea box-linea-2 flex-center justify-center">
                                                                    <p className={`text-center box-texto ${dato?.dato2 !== null && dato?.dato2 === 0 ? 'number-cero' : ''} ${dato?.dato2 !== null && dato?.dato2 > 0 ? 'number-positivo' : ''} ${dato?.dato2 !== null && dato?.dato2 < 0 ? 'number-negativo' : ''}`}> {loading ? <BeatLoader color="#ea5e0b" size={5} /> : datoNull(dato?.dato2)} </p>
                                                                </div>
                                                                {/* fila 3 */}
                                                                <div className="position-absolute box-linea box-linea-3 flex-center justify-center">
                                                                    <p className={`text-center box-texto ${dato?.dato3 !== null && dato?.dato3 === 0 ? 'number-cero' : ''} ${dato?.dato3 !== null && dato?.dato3 > 0 ? 'number-positivo' : ''} ${dato?.dato3 !== null && dato?.dato3 < 0 ? 'number-negativo' : ''}`}> {loading ? <BeatLoader color="#ea5e0b" size={5} /> : datoNull(dato?.dato3)} </p>
                                                                </div>
                                                            </>
                                                        :
                                                            <>
                                                                {/* columna */}
                                                                <p className='text-center header-box-numero'>
                                                                    {itemColumna}
                                                                </p>
                                                            </>
                                                        }
                                                        <div className={`box-circulo position-absolute ${!dato ? 'mabt-line-no-used-circle' : ''}`}>
                                                            <p className='text-center box-circulo-texto'>
                                                                {dato && activeTabDevice !== 2 && <> {loading ? <BeatLoader color="#ea5e0b" size={5} /> : calcularPorcentajeColumna(dato, total) + '%'}</>}
                                                            </p>
                                                        </div>
                                                        {device?.sensor?.length > 0 &&
                                                            <a className='position-sensor position-absolute p-0 texto-vtn-enlace' rel="noopener noreferrer" onClick={() => selectMedidorSensor(device)}>
                                                                <HtmlTooltip 
                                                                    title={
                                                                        <span>
                                                                            {device?.sensor?.map((sen, index) => (
                                                                                <div key={index}>{sen}</div> 
                                                                            ))}
                                                                        </span>
                                                                    }
                                                                >
                                                                    <SpeedIcon/>
                                                                </HtmlTooltip>
                                                            </a>
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        )
                    })}
                    <Grid item xs={12} className='mt-3 position-relative'>
                        <h3 className='flex-baseline texto-vtn'>
                            <IconVtnT />
                            {vtnDevice ?
                                <a className='ml-1 p-0 texto-vtn-enlace' rel="noopener noreferrer" onClick={() => selectMedidor(vtnDevice)}>
                                    VTN:
                                </a>
                            :
                                <>VTN:</>
                            }
                            <span className='ml-2 text-center box-texto'> {loading ? <BeatLoader color="#ea5e0b" size={5} /> : datoNull(vtn) + (datoNull(vtn) !== '-' ? ' V' : '')} </span>
                        </h3>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}