import { Pie } from 'react-chartjs-2'

const ScadaPieChart = ({ totalUsageMB, totalUploadMB, totalDownloadMB, className }) => {

    const data = {
      labels: ['Total', 'Subida', 'Bajada'],
      datasets: [{
        label: 'Datos',
        data: [totalUsageMB, totalUploadMB, totalDownloadMB], 
        backgroundColor: [
          '#C3C5C6', 
          '#FF8A1F', 
          '#F8B133', 
        ],
        borderColor: [
          '#C3C5C6',
          '#FF8A1F',
          '#F8B133',
        ],
        borderWidth: 1
      }]
    }
  
  const options = {
    plugins: {
      legend: {
        display: false
      }
    }
  }

  return (
    <div className={className}>
      <Pie data={data} options={options} />
    </div>
  )
}

export default ScadaPieChart