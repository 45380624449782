import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { ModalRespuesta } from "views/ModalResponse/ModalRespuesta"
import { SwitchStyled } from "components/Switch/SwitchStyled"
import InputStyled from "components/Input/InputStyled"
import { Modal } from "components/Modal/Modal"
import { headersCentroControl } from "services/headers"
import { validatorEmail } from "utils/ValidatorForm"
import { EnerSim } from "utils/icon-centrocontrol"
import { urls } from "utils/constant"

export const ModalCedeSim = ({ open, setOpen, enerSimData }) => {
  const isLastEnersim = sessionStorage.isLastEnersim === "true"
  const token = sessionStorage?.token
  const navigate = useNavigate()
  const [error, setError] = useState(false)
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [isSwitchActive, setIsSwitchActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState()
  const [isValid, setIsValid] = useState(true)
  const [disabled, setDisabled] = useState(true)

  const cedeEnersim = () => {
    if (email && email.length > 0 && isValid) {
      setLoading(true)
  
      fetch(
        urls.ccontrol + `validateAction/enersim`,
        {
          method: 'PATCH',
          redirect: 'follow',
          headers: headersCentroControl(token),
          body: JSON.stringify({
            "numserie": enerSimData?.numserie_sim,
            "address": email,
            "permission": "admin",
            "nick": enerSimData?.installationName,
            "action": "cedeSim",
            "acepted": 1,
            "eraser": isLastEnersim && isSwitchActive ? 1 : 0
          })
        }
      ).then(res => {
        if (res.status === 200) {
          setEmail("")
          setSuccess(true)
          return res.json()
        } else if (res.status === 400) {
          setError(true)
          return res.json()
        } else {
          setError(true)
          throw new Error(`API request failed with status ${res.status}`)
        }
      }).then(data => {
        setMessage(data.message)
      }).catch(err => {
        console.error("ERROR. API Centro de Control", err)
        setError(true)
        setMessage("Error en la solicitud a la API")
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  const handleCloseMensaje = () => {
    setSuccess(false)
    setError(false)
    setOpen(current => !current)
    if(isLastEnersim && isSwitchActive) {
      navigate('/projects/inicio')
    } 
  }

  const validateEmail = () => {
    setIsValid(validatorEmail(email))
  }

  const handleBlur = () => {
    if (email.length > 0) {
      validateEmail()
    }
  }

  useEffect(() => {
    if (isValid === true && email.length > 0) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [isValid, email])

  useEffect(() => {
    setEmail("")
    setIsValid(true)
  }, [open])

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        title={`Ceder enerSim`}
        buttonAction="Ceder"
        iconTitle={<EnerSim />}
        functSave={cedeEnersim}
        disabled={disabled}
        loading={loading}
      >
        <Grid container className="pl-5 pr-5 mt-2">
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <div className='pb-2'>
              <small className="color-black">
                Email de usuario:
              </small>
            </div>
            <InputStyled 
              fullWidth  
              value={email}
              onChange={(e) => setEmail(e)}
              onBlur={handleBlur}
              error={!isValid}
              errorMessage={'Email inválido'}
              maxLength={50}
            />
          </Grid>
          {isLastEnersim && 
            <Grid item xs={12} md={12} lg={12} xl={12} className="pt-2 flex space-between items-center">
              <small className="color-black pr-2">
                Es la última tarjeta EnerSim, el proyecto quedará vacío. ¿Desea eliminar el proyecto?
              </small>
              <SwitchStyled className='switch-small' checked={isSwitchActive} onClick={() => setIsSwitchActive(!isSwitchActive)} />
            </Grid>
          }
        </Grid>
      </Modal>
      <ModalRespuesta
        success={success}
        error={error}
        onClose={handleCloseMensaje}
        mensajeSuccess={message}
        mensajeError={message}
      />
    </>
  )
}
