import React from 'react'
import { Droppable } from '@hello-pangea/dnd'
import DraggableItem from './DraggableItem'
import { getClassNameItem } from 'utils/Utils'
import "./LayOut.scss"
 
const DropContainer = ({ columnId, column, direction, isActive, disabled }) => {
  return (
    <Droppable droppableId={columnId} key={columnId} direction={direction}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided?.innerRef}
          className={columnId === "Devices" ? "devices-container" : `list-style ${snapshot.isDraggingOver ? 'list-style-dragging-over' : ''}`}
        >
          {column?.items?.map((item, index) => {
            const itemClassName = getClassNameItem(item?.icon_type)
            return (
              <div key={item?.id}>
                <DraggableItem item={item} index={index} columnId={columnId} isActive={isActive} disabled={disabled} itemClassName={itemClassName} />
              </div>
            )
          })}
          {provided?.placeholder}
        </div>
      )}
    </Droppable>
  )
}
 
export default DropContainer