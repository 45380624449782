import React from "react"
import ReactDOM from "react-dom/client"
import './index.css'
import './assets/bamburgh/base.scss'
import 'semantic-ui-css/semantic.min.css'
import "./assets/scss/argon-dashboard-react.scss"
import './views/Principal/StylePrincipal.scss'
import { App } from "./App"
import ErrorBoundary from "views/PageError/NotFound"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
)