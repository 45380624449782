import React from "react"
import { Grid, FormControl } from '@mui/material'
import { SelectMultiple } from "components/SelectMultiple/SelectMultiple"
import { SwitchStyled } from "components/Switch/SwitchStyled"
import { validatorInputNumber } from "utils/ValidatorForm"
import InputStyled from "components/Input/InputStyled"
import { nombreDispositivo } from "utils/Utils"
import { nombreAlarma } from "utils/Utils"
import { dispositivos } from "utils/constant"
import { alarmas } from "utils/constant"

export const FormEditar = ({ tipoAlarma, tipoDispositivo, todosDispositivos, setDispositivoNoSeleccionado, dispositivoSeleccionado, setDispositivoSeleccionado, tiempoAlarma, setTiempoAlarma, tiempoMinimo, tiempoMaximo, habilitado, setHabilitado }) => {
    const handleAutocompleteChange = (event, newValue) => {
        setDispositivoSeleccionado([...newValue])
        const deselectedOptions = todosDispositivos.filter(option => !newValue.includes(option))
        setDispositivoNoSeleccionado(deselectedOptions)
    }

    const handleTiempoAlarma = (valor) => {
        if (validatorInputNumber(valor)) {
            setTiempoAlarma(valor)
        } else if (valor === '' || valor === undefined || valor === null) {
            setTiempoAlarma(valor)
        }
    }

    return (
        <Grid container>
            <Grid item xs={1} md={1} lg={1} xl={1}/> 
            <Grid item xs={4} md={4} lg={4} xl={4}> 
                <FormControl className='width-100' size="small">
                    <small className="font-weight-lighter">
                        Tipo de dispositivo*:
                    </small>
                    <InputStyled value={nombreDispositivo(tipoDispositivo)} onChange={() => {}} disabled={true}/>
                </FormControl>
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2}/> 
            <Grid item xs={4} md={4} lg={4} xl={4}> 
                <FormControl className='width-100' size="small">
                    <small className="font-weight-lighter">
                    Tipo de alarma*:
                    </small>
                    <InputStyled value={nombreAlarma(tipoAlarma)} onChange={() => {}} disabled={true}/>
                </FormControl>
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}/>
            <Grid item xs={1} md={1} lg={1} xl={1}/>
            <Grid item xs={10} md={10} lg={10} xl={10} className="mt-2"> 
                <small className="font-weight-lighter">
                    Seleccione los dispositivos de la instalación <em className="font-bold">{todosDispositivos[0].nickMaster}</em>*:
                </small>
                <SelectMultiple 
                    onChange={handleAutocompleteChange}
                    options={todosDispositivos} 
                    optionLabelFunction={
                        (option) => `${option['nick'] ? 
                            option['nick'] 
                        : 
                            tipoDispositivo === dispositivos.ccmabtv ? 
                                'VTN' 
                            : 
                                'Sin nombre' + (option['posicion'] ?
                                    ' ' + option['posicion'] 
                                : 
                                    ''
                                )}` 
                        }
                    value={dispositivoSeleccionado} 
                    optionLabel='nick' 
                    id='EditarDispositivosAlarma'
                />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}/>
            
            {tipoAlarma !== alarmas.tramo && tipoAlarma !== undefined &&
                <>
                    <Grid item xs={1} md={1} lg={1} xl={1}/>
                    <Grid item xs={6} md={6} lg={6} xl={6} className="mt-2"> 
                        <small className="font-weight-lighter">
                            Introduzca el tiempo de observación para la alarma:
                        </small>
                    </Grid>
                    <Grid item xs={5} md={5} lg={5} xl={5}/>
                    <Grid item xs={1} md={1} lg={1} xl={1}/>
                    <Grid item xs={6} md={6} lg={6} xl={6} className="mt-2"> 
                        <InputStyled value={tiempoAlarma} onChange={handleTiempoAlarma}/>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4} xl={4} className="mt-2 edit-form-alarm-switch"> 
                        <SwitchStyled id="habilitado" className="switch-small" checked={habilitado} onClick={() => setHabilitado(!habilitado)}/>
                        <label className="ml-2 mb-0" htmlFor='habilitado'> Activar alarma </label>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1} xl={1}/>
                    <Grid item xs={1} md={1} lg={1} xl={1}/>
                    <Grid item xs={6} md={6} lg={6} xl={6}> 
                        <FormControl className='width-100 color-red' size="small">  
                            <small className="font-weight-lighter">
                                {tiempoMinimo > 0 && tiempoMaximo > 0 && `El valor debe estar entre ${tiempoMinimo} - ${tiempoMaximo} minutos`}
                                {tiempoMinimo > 0 && tiempoMaximo === 0 && `El valor debe ser superior a ${tiempoMinimo} minutos`}
                                {tiempoMinimo === 0 && tiempoMaximo === 0 && `El valor debe ser superior a 0 minutos`}
                                {tiempoMinimo === 0 && tiempoMaximo > 0 && `El valor debe estar entre 0 - ${tiempoMaximo} minutos`}
                            </small>
                        </FormControl>
                    </Grid>
                </>
            }
            <Grid item xs={5} md={5} lg={5} xl={5}/>
        </Grid>
    )
}