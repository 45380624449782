import React from "react"
import { ButtonMenu } from "components/Buttons/ButtonMenu"

export const MenuGeneralConsumo = ({ id_tipo_dispositivo, activeTab, seleccionarTab }) => {

    return (
        <>
            <ButtonMenu tab={activeTab === 0} onClick={() => seleccionarTab(0)}>
                Potencia
            </ButtonMenu>
            <ButtonMenu tab={activeTab === 1} onClick={() => seleccionarTab(1)}>
                Intensidad
            </ButtonMenu>
            <ButtonMenu tab={activeTab === 2} onClick={() => seleccionarTab(2)}>
                Tensiones
            </ButtonMenu>
        </>
    )
}