import { useEffect, useState, useRef } from "react"
import GoogleMapsApiLoader from "google-maps-api-loader"
import { apiMap } from "utils/constant"

const eventsMapping = {
  onCenterChanged: ["center_changed", map => map.getCenter()],
  onBoundsChangerd: ["bounds_changed", map => map.getBounds()]
}

const useGoogleMap = ({ zoom, center, defaultOptions, events, mapTypeId }) => {
  const [mapState, setMapState] = useState({ loading: true })
  const mapRef = useRef()
  const apiKey = `${apiMap}`
  
  useEffect(() => {
    try {
      GoogleMapsApiLoader({ apiKey, libraries: ["places"] }).then(google => {
        const map = new google.maps.Map(mapRef.current, { zoom, center, defaultOptions, mapTypeId })
        Object.keys(events).forEach(eventName =>
          map.addListener(eventsMapping[eventName][0], () =>
            events[eventName](eventsMapping[eventName][1](map))
          )
        )
        setMapState({ maps: google.maps, map, loading: false })
      })
    } catch (error) {
      console.error("Error loading Google Maps API:", error);
      setMapState({ loading: false, error: true });
    }
  }, [])

  return { mapRef, ...mapState }
}
export default useGoogleMap
