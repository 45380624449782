import React, { useState } from "react"
import { useNavigate } from 'react-router-dom'
import { Grid } from "@mui/material"
import { Modal } from "components/Modal/Modal"
import { cerrarSesion } from "views/User/Functions/Sesion"
import { headersUsers } from "services/headers"
import { urls } from "utils/constant"
import { useDispatch } from "react-redux"
import { createToast } from "reduxSlice/toastSlice"

export const ModalOutAllSessions = ({ open, setOpen }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = sessionStorage?.token
    const [loading, setLoading] = useState(false)

    const logOutAllUser = () => {
        setLoading(true)
        let status
        fetch(urls.users + `user/allsessions`, {
            method: 'DELETE', headers: headersUsers('bearer ' + token),
        }).then(res => {
            setLoading(false)
            status = res.status
            return res.json()
        })
        .then((data) => {
            dispatch(createToast({ status: status, message: data.message, reload: true }))
            sessionStorage.clear()
            navigate("/auth/login")
            cerrarSesion(token)
        })
        .catch(err => console.error('No se ha podido conectar con la API...users', err))
    }

    return (
        <>
            <Modal open={open} onClick={(e) => e.stopPropagation()} setOpen={setOpen} buttonAction="Cerrar" title="Cerrar sesiones abiertas" functSave={logOutAllUser} loading={loading}>
                <Grid container className="text-center mt-2">
                    <h4 className="font-weight-lighter" style={{ fontSize: '1.2rem' }}>
                        ¿Deseas cerrar todas las sesiones abiertas?
                    </h4>
                </Grid>
                <hr className='m-0' />
            </Modal >
        </>
    )
}