import { createSlice } from '@reduxjs/toolkit'

export const treeDevicesSlice = createSlice({
    name: "tree",
    initialState: {
        tree: {}
    },
    reducers: {
        addElementTree: (state, action) => {
            const treeState = JSON.parse(sessionStorage.getItem('treeState'))
            if (treeState?.[action.payload?.element] !== undefined && !action.payload?.error) {
                state.tree[action.payload?.element] = treeState?.[action.payload?.element]
            } else {
                state.tree[action.payload?.element] = action.payload?.check
            }
            sessionStorage.setItem('treeState', JSON.stringify(state.tree))
        },
        updateDeviceTree: (state, action) => {
            if (action.payload?.value) {
                function buscarClaveCompleta(fragmento) {
                    const treeState = JSON.parse(sessionStorage.getItem('treeState'))
                    for (let clave in treeState) {
                        if (clave.includes(fragmento)) {
                            return String(clave)
                        }
                    }
                    return null
                }
                const claveCompleta = buscarClaveCompleta(action.payload?.device)
                state.tree[claveCompleta] = false
            } else {
                state.tree[action.payload?.device] = !state.tree[action.payload?.device]
            }
            sessionStorage.setItem('treeState', JSON.stringify(state.tree))
        },
        updateGroupTree: (state, action) => {
            const check = !state.tree[action.payload?.group]
            let newTree = {...state.tree}
            Object.keys(newTree).forEach(key => {
                const lastPart = key.split('-').pop()
                if (key.startsWith(action.payload?.group) && !action.payload?.disabled?.includes(Number(lastPart))) {
                    newTree[key] = check
                }
            })
            state.tree = newTree
            sessionStorage.setItem('treeState', JSON.stringify(newTree))
        },
        updateSubGroupTree: (state, action) => {
            const check = !state.tree[action.payload?.subGroup]
            let newTree = {...state.tree}
            Object.keys(newTree).forEach(key => {
                const lastPart = key.split('-').pop()
                if (key.startsWith(action.payload?.subGroup) && !action.payload?.disabled?.includes(Number(lastPart))) {
                    newTree[key] = check
                }
            })
            state.tree = newTree
            sessionStorage.setItem('treeState', JSON.stringify(newTree))
        },
        selectOnlyDeviceTree: (state, action) => {
            let newTree = {...state.tree}
            Object.keys(newTree).forEach(key => {
                if (key?.includes(action.payload?.device) || !key?.includes('D')) {
                    newTree[key] = true
                } else {
                    newTree[key] = false
                }
            })
            state.tree = newTree
            sessionStorage.setItem('treeState', JSON.stringify(newTree))
        },
        clearTree: (state, action) => {
            return  { tree: {} }
        }
    },
})

export const { addElementTree, updateDeviceTree, updateGroupTree, updateSubGroupTree, selectOnlyDeviceTree, clearTree } = treeDevicesSlice.actions;
export default treeDevicesSlice.reducer