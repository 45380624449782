import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Grid from '@mui/material/Grid'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { headersScada } from 'services/headers'
import { nombreAlarma } from 'utils/Utils'
import { urls } from "utils/constant.jsx"

/**
 * @param {{ token: string, setActualizarAlarma: Function, configuracion: {}, modalEliminar: Boolean, setModalEliminar: Function }} 
 * @param token Token necesario de la llamada
 * @param setActualizarAlarma Funcion apara cambiar el estado de actualizar las alarmas
 * @param configuracion Configuracion de la alarma que se desea eliminar
 * @param modalEliminar Estado que indica si el modal esta abierto o cerrado
 * @param setModalEliminar Funcion apara cambiar el estado del modal
 * @returns devuelve la tabla con las configuraciones de las alarmas
 */
export const EliminarAlarma = ({ setActualizarAlarma, token, configuracion, modalEliminar, setModalEliminar }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    /** 
     * @param {{}} configuracion Configuracion seleccionada
     * @returns Elimina la configuracion seleccionada
     */
    const fetchEliminarAlarma = async (configuracion) => {
        setLoading(true)
        let status
        let obj = {}
        let body 
        if (configuracion.alarm_id === 3) {
            obj[configuracion?.numInstalacion] = {id_alarms: configuracion?.id_alarm}
            body = {
                devices: obj
            }
        } else {
            obj[configuracion?.numInstalacion] = configuracion?.numserie
            body = {
                id_alarm: configuracion?.alarm_id,
                id_type_device: configuracion?.device_type,
                devices: obj
            }
        }
        let url = configuracion?.alarm_id === 3 ? 'alarms/delSectionAlarm' : 'alarms/deleteAlarms'
        let method = configuracion?.alarm_id === 3 ? 'POST' : 'DELETE'
        try {
            const res = await fetch(urls.scada + url, { method: method, headers: headersScada(token), body: JSON.stringify(body) })
            status = res.status
            if (res.status === 200) {
                setLoading(false)
                dispatch(createToast({ status: status, message: "Alarma eliminada correctamente", reload: false }))
                setActualizarAlarma(current => !current)
                setModalEliminar(false)
                return true
            } else {
                setLoading(false)
                dispatch(createToast({ status: status, message: 'Error al eliminar la alarma', reload: false }))
                setActualizarAlarma(current => !current)
                setModalEliminar(false)
                return false
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    return (
        <Modal fullWidth={true} open={modalEliminar} setOpen={setModalEliminar} title="Eliminar alarma" loading={loading} buttonAction="Aceptar" functSave={() => fetchEliminarAlarma(configuracion)}>
            <Grid container className="text-center mt-2">
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <h4 className="font-weight-lighter" style={{ fontSize: '1.2rem' }}>
                        ¿Deseas eliminar la alarma "{nombreAlarma(configuracion?.alarm_id)}" de los siguientes dispositivos?
                    </h4>
                    {configuracion?.nick?.map(nick => {
                        return <p><span style={{ fontWeight: 'bold' }}>{nick}</span></p>
                    })}
                </Grid>
            </Grid>
            <hr className='m-0' />
        </Modal>
    )
}