export default function ChartInlineEnersim({ total = 30, used = 29.5 }) {
  const barStyle = 'min-w-min shrink-0 whitespace-nowrap px-2 py-2 text-center'
  let available
  let percentage
  
  if (used !== "-") {
      available = total - parseFloat(used)
      percentage = (parseFloat(used) / total) * 100
  }

  return (
      <div className={`w-full ${used === "-" ? 'py-2' : ''}`}>
          <p className='text-sm dark:text-gray-400'>
              {used !== "-" ? (
                  <>Quedan <span className='font-semibold'>{available.toFixed(2)}</span> MB de {total} MB</>
              ) : (
                  "No hay datos"
              )}
          </p>
          <div className='my-2 flex w-full min-w-[180px] overflow-hidden rounded-sm font-medium text-white'>
              {used !== "-" && (
                  <div
                      className={`${barStyle} bg-[#73bf45] dark:bg-green-500`}
                      style={{ width: percentage + '%' }}
                  ></div>
              )}
              <div
                  className={`${barStyle} flex-1 bg-[#d9d9d9] dark:bg-gray-300`}
              ></div>
          </div>
          {used !== "-" && (
              <p className='text-right text-sm dark:text-gray-400'>
                  Has usado {parseFloat(used).toFixed(2)} MB
              </p>
          )}
      </div>
  )
}
