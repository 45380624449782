import React from 'react'
import { Grid } from '@mui/material'
import { GraphsBar } from 'components/Graph/GraphsBar'
import { toKiloW } from 'utils/functionsforGraphs'
import { TotalIntensity } from '../Consumption/GraphConsumption/ScadaConsumptionIntensity/TotalIntensity'
import { TotalActivepower } from '../Consumption/GraphConsumption/ScadaConsumptionPower/TotalActivePower'
import { TensionGraph } from '../Consumption/GraphConsumption/ScadaConsumptionTension/TensionGraph'
import "./StyleConsumption.scss"

export const PrincipalGraphsMonophasic = ({ eacons, eagen, labels, noConect, currentDevice, today }) => {

    return (
        <>
            <Grid item xs={13} md={6} lg={2} xl={2} className="container-active-power">
                <h3
                    className="text-enerclic text-2xl font-bold mb-4">
                    Energía activa
                </h3>
                <GraphsBar eacons={eacons} eagen={eagen} labels={labels} />
            </Grid>
            <Grid item xs={13} md={6} lg={10} xl={4} >
                <TotalActivepower
                    sinConectar={noConect}
                    data={currentDevice?.currentData}
                />
            </Grid>
            <Grid item xs={13} md={7} lg={7} xl={4} className="container-active-power">
                <TotalIntensity
                    sinConectar={noConect}
                    data={currentDevice?.currentData}
                />
            </Grid>
            <Grid item xs={13} md={6} lg={6} xl={3} className="container-active-power container-tension-mono">
                <TensionGraph value={today && currentDevice?.currentData?.v1 ? currentDevice?.currentData?.v1 : today && currentDevice?.currentData?.vac ? currentDevice?.currentData?.vac : currentDevice?.currentData.v ? currentDevice?.currentData.v : 0} />
                <Grid item md={12} className="container-voltaje-graph-mono">
                    <h3 className="voltaje-tension-graph-mono" >
                        220 V
                    </h3>
                    <h3 className="voltaje-tension-graph-mono">
                        250 V
                    </h3>
                </Grid>
                <Grid item md={12} className="scada-monophasic-tension-valor">
                    <h2 className={`${(currentDevice?.currentData?.v1 === 0 || currentDevice?.currentData?.vac === 0) && 'color-red'} mt-2`} style={{ width: "100%" }}>
                        {today && currentDevice?.currentData?.v1 ? toKiloW(currentDevice?.currentData?.v1) + "V" : today && currentDevice?.currentData?.vac ? toKiloW(currentDevice?.currentData?.vac) + "V" : " - "}
                    </h2>
                </Grid>
            </Grid>
        </>
    )
}
