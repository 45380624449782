import React, { useEffect, useState } from "react"
import { FormControl, Grid, InputLabel } from "@mui/material"
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import "../EditInstall/EditInstall.scss"

export const FiltroConfiguracionInstalaciones = ({ data, setFilterMaster }) => {
    const [filterFirmware, setFilterFirmware] = useState([])
    const [status, setStatus] = useState([])
    const [statusSeleccionado, setStatusSeleccionado] = useState(null)
    const [versionSeleccionada, setVersionSeleccionada] = useState(null)

    const filterFirmVersion = () => {
        const arrayConDuplicados = data?.map(dev => dev.version)
        const arraySinDuplicados = arrayConDuplicados?.filter((value, index, self) => {
            return self.indexOf(value) === index
        })
        setFilterFirmware(arraySinDuplicados)
    }

    const filterState = () => {
        const arrayConDuplicados = data?.map(dev => dev.status)
        const arraySinDuplicados = arrayConDuplicados?.filter((value, index, self) => {
            return self.indexOf(value) === index
        })
        setStatus(arraySinDuplicados)
    }

    useEffect(() => {
        filterFirmVersion()
        filterState()
    }, [data])

    const handleStatusChange = (event) => {
        setStatusSeleccionado(event.target.value === "Todos" ? null : event.target.value)
    }

    const handleVersionChange = (event) => {
        setVersionSeleccionada(event.target.value === "Todos" ? null : event.target.value)
    }

    const dispositivosFiltrados = data?.filter((dispositivo) => {
        if (statusSeleccionado && dispositivo?.status !== statusSeleccionado) {
            return false
        }
        if (versionSeleccionada && dispositivo?.version !== versionSeleccionada) {
            return false
        }
        return true
    })

    useEffect(() => {
        setFilterMaster(dispositivosFiltrados)
    }, [statusSeleccionado, versionSeleccionada, data])

    return (
        <Grid item xs={12} className="select-filtro-config-inst">
            <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="select-tipo-dispositivo-input" style={{ color: "#8c8c8c" }}>Versión Firmware</InputLabel>
                <Select
                    className="style-textfield-text tamano-select"
                    labelId="select-tipo-dispositivo-label"
                    id="select-tipo-dispositivo"
                    value={versionSeleccionada == null ? "Todos" : versionSeleccionada}
                    label="Versión Firmware"
                    onChange={(event) => handleVersionChange(event)}>
                    <MenuItem value="Todos" selected > Todos</MenuItem>
                    {filterFirmware?.map((ver) => {
                        return <MenuItem value={ver}>{ver}</MenuItem>
                    }
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="select-tipo-dispositivo-input" style={{ color: "#8c8c8c" }}>Status</InputLabel>
                <Select
                    className="style-textfield-text tamano-select"
                    labelId="select-tipo-dispositivo-label"
                    id="select-tipo-dispositivo"
                    value={statusSeleccionado == null ? "Todos" : statusSeleccionado}
                    label="Status"
                    onChange={(event) => handleStatusChange(event)}>
                    <MenuItem value={"Todos"} selected >Todos</MenuItem>
                    {status.map((sta) => {
                        return <MenuItem value={sta}>{sta}</MenuItem>
                    }
                    )}
                </Select>
            </FormControl>
        </Grid>
    )
}