import React, { useState, useEffect } from 'react'
import { Grid, TextField } from '@mui/material'
import { validatorInput } from 'utils/ValidatorForm'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'

/**
 * @param {{token: string, setNuevo: Function, device: Number, id_alarm: Number}}
 * @param token Token para realizar la llamada
 * @param setNuevo Funcion para actualizar los comentarios
 * @param device Numero de serie del dispositivo
 * @param id_alarm Id de la alarma
 * @returns Devuelve el comentario privado de la alarma
 */
export const ComentarioNuevo = ({ token, device, id_alarm, setNuevo }) => {
    const [comentario, setComentario] = useState('')
    const [error, setError] = useState()
    const [keyInputComentarioAlarm, setKeyInputComentarioAlarm] = useState(Date.now() + 'comentarioAlarmaPublico')
    const [loading, setLoading] = useState(false)
    const [invalidComment, setInvalidComment] = useState(false)

    /**
     * @returns Crea un nuevo comentario publico de la alarma
     */
    const fetchCrearComentario = async () => {
        setLoading(true)
        try {
            const res = await fetch(urls.scada + 'alarms/createCommentAlarm', { method: 'POST', headers: headersScada(token), body: JSON.stringify({ device: device, id_alarm: id_alarm, public: 1, comment: comentario }) })
            if (res.status === 200) {
                setNuevo(current => !current)
                setComentario('')
                setKeyInputComentarioAlarm(Date.now() + 'comentarioAlarmaNuevo')
            } else {
            }
            setLoading(false)
            return true
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const handleChangeComment = (e) => {
        setComentario(e)
        setInvalidComment(validatorInput(e))
    }

    useEffect(() => {
        if (comentario.length > 300) {
            setError('No se puede crear un mensaje con más de 300 caracteres')
        }
        if (comentario.length <= 300 && error) {
            setError()
        }
    }, [comentario])

    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <div className='pb-1'>
                    <small className="color-black">
                        Comentario público:
                    </small>
                </div>
                <TextField
                    fullWidth
                    minRows={1}
                    className="style-textfield-text"
                    key={keyInputComentarioAlarm}
                    error={invalidComment}
                    helperText={invalidComment ? 'Caracteres no permitidos' : ''}
                    value={comentario}
                    onChange={(e) => handleChangeComment(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                {error && <small className="color-red-error">{error}</small>}
            </Grid>
            <Grid item xs={4} className='pb-5'>
                <ButtonStyled className='boton-historico mt-2' loading={loading} disabled={comentario.length > 300 || !comentario || invalidComment } onClick={() => { fetchCrearComentario() }}>
                    Guardar
                </ButtonStyled>
            </Grid>
        </Grid>
    )
}