import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        name: false,
    },
    reducers: {
        updateLoading: (state, action) => {
            state.name = !state.name
        }
    },
})

export const { updateLoading } = loadingSlice.actions

export default loadingSlice.reducer