import React from "react"
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

export const SelectorTime = ({ groupData, setgroupData }) => {
    
    return (  
        <FormControl sx={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} size="small">
            <Select
                className='tamano-select'
                style={{ height: '2.4em',  maxWidth: '10em' }}
                id="select-agrupacion-fecha"
                value={groupData}
                onChange={(e) => { setgroupData(e.target.value) }}
            >
                <MenuItem value="1">1 minuto</MenuItem>
                <MenuItem value="5">5 minutos</MenuItem>
                <MenuItem value="15">15 minutos</MenuItem>
                <MenuItem value="30">30 minutos</MenuItem>
                <MenuItem value="60">1 hora</MenuItem>
            </Select>
        </FormControl>
    )
}