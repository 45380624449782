import React from "react"
import { ButtonMenu } from "components/Buttons/ButtonMenu"
import { esHoy } from "utils/Utils"

export const MenuDescripcionMabt = ({ activeTab, selectTab, start, grafica, visible, medidores }) => {

    return (
        <>
            <ButtonMenu className='mb-2'disabled={grafica && !visible} tab={activeTab === 0} onClick={() => selectTab(0)} >
                P. Activa (kW)
            </ButtonMenu>
            <ButtonMenu className='mb-2' disabled={grafica && (!esHoy(start) || !visible)} tab={activeTab === 3} onClick={() => selectTab(3)} >
                P. Reactiva (kW)
            </ButtonMenu>
            <ButtonMenu className='mb-2' disabled={grafica && !visible} tab={activeTab === 1} onClick={() => selectTab(1)} >
                Corriente (A)
            </ButtonMenu>
            <ButtonMenu className='mb-2' disabled={grafica && !visible} tab={activeTab === 2} onClick={() => selectTab(2)}>
                Tensión (V)
            </ButtonMenu>
            {(grafica && medidores === 1) &&
                <>
                    <ButtonMenu className='mb-2' tab={activeTab === 5} onClick={() => selectTab(5)} >
                        Energía Exportada (kWh)
                    </ButtonMenu>
                    <ButtonMenu className='mb-2' tab={activeTab === 6} onClick={() => selectTab(6)} >
                        Energía Importada (kWh)
                    </ButtonMenu>
                </>
            }
            {(grafica && !medidores) &&
                <ButtonMenu className='mb-2' tab={activeTab === 4} onClick={() => selectTab(4)} >
                    Energía (kWh)
                </ButtonMenu>
            }
        </>
    )
}