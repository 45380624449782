import React from "react"
import ModalDeleteUser from "views/User/UnShareUser/ModalDeleteUser"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import { typePermissions } from "utils/Utils"
import "views/Admin/styleUsersAdministration.scss"

/** 
 * @param {listUser[{ }],project {}, grup {}, type: String, master {}, subgroup {}} Recibimos Recibimos una lista de todos los usuarios que tienen algo compartidotodo,
 * y todos los datos del proyecto, grupo, .... ,además del tipo donde están
 * @returns retornamos un listado con todos los usuarios a los que tenemos compartida esta instalación, 
 * detallando el permiso que tiene y posibilitando quitarle el compartido
 */
export const ShareUser = ({ project, grup, type, master, subgroup, listUser }) => {

    return (
        <>
            {type === "project" && listUser.map(shared => {
                return (
                    <span className={`card-ShareUser ${shared.acepted === 0 && "card-shareUser-pending"} `} title={shared?.acepted === 0 && "Pendiente de aceptar"}>
                        <p className="card-shareUser-mail mr-1">{shared?.email}</p>
                        <span className={`type-permission width-height-20px ${shared?.permission === "admin" ? "admin" : shared?.permission === "instal" ? "instal" : "user"}`} >
                            <HtmlTooltip title={<span>{typePermissions(shared?.permission)}</span>}>
                                {shared?.permission?.charAt(0).toUpperCase()}
                            </HtmlTooltip>
                        </span>
                        <ModalDeleteUser project={project} nick={project.nick} email={shared.email} type={type} idUser={shared.idUser} acepted={shared.acepted} />
                    </span>
                )
            })}
            {type === "group" && listUser.map(shared => {
                return (
                    <span className={`card-ShareUser ${shared.acepted === 0 && "card-shareUser-pending"} `}>
                        <p className="card-shareUser-mail mr-1">{shared?.email}</p>
                        <span className={`type-permission width-height-20px ${shared?.permission === "admin" ? "admin" : shared?.permission === "instal" ? "instal" : "user"}`} >
                            <HtmlTooltip title={<span>{typePermissions(shared?.permission)}</span>}>
                                {shared?.permission?.charAt(0).toUpperCase()}
                            </HtmlTooltip>
                        </span>
                        <ModalDeleteUser group={grup} nick={grup.nick} email={shared.email} type={type} idUser={shared.idUser} acepted={shared.acepted} />
                    </span>
                )
            })}
            {type === "subgroup" && listUser.map(shared => {
                return (
                    <span className={`card-ShareUser ${shared.acepted === 0 && "card-shareUser-pending"} `}>
                        <p className="card-shareUser-mail mr-1">{shared?.email}</p>
                        <span className={`type-permission width-height-20px ${shared?.permission === "admin" ? "admin" : shared?.permission === "instal" ? "instal" : "user"}`} >
                            <HtmlTooltip title={<span>{typePermissions(shared?.permission)}</span>}>
                                {shared?.permission?.charAt(0).toUpperCase()}
                            </HtmlTooltip>
                        </span>
                        <ModalDeleteUser subgroup={subgroup} nick={subgroup?.nick} email={shared?.email} type={type} idUser={shared.idUser} acepted={shared.acepted} />
                    </span>
                )
            })}
            {type === "master" && listUser.map(shared => {
                return (
                    <span className={`card-ShareUser ${shared.acepted === 0 && "card-shareUser-pending"} `}>
                        <p className="card-shareUser-mail mr-1">{shared?.email}</p>
                        <span className={`type-permission width-height-20px ${shared?.permission === "admin" ? "admin" : shared?.permission === "instal" ? "instal" : "user"}`} >
                            <HtmlTooltip title={<span>{typePermissions(shared?.permission)}</span>}>
                                {shared?.permission?.charAt(0).toUpperCase()}
                            </HtmlTooltip>
                        </span>
                        <ModalDeleteUser master={master} nick={master.numserie} email={shared.email} type={type} idUser={shared.idUser} acepted={shared.acepted} />
                    </span>
                )
            })}
        </>
    )
}