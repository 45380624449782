import React, { useState, useEffect } from 'react'
import { Card, Grid } from '@mui/material'
import { urls } from 'utils/constant'

import InputStyled from 'components/Input/InputStyled'
import { headersCentroControl } from 'services/headers'
import { Modal } from 'components/Modal/Modal'
import { validatorInput } from 'utils/ValidatorForm'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'

export const ModalNewNameGroup = ({ projects, info, type, open, setOpen, toggle, title }) => {
  const token = sessionStorage?.token
  const [newName, setNewName] = useState()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [validNameGroup, setValidNameGroup] = useState(false)
  const dispatch = useDispatch()
  //variable para la validacion del nombre (validateChangeNick) y que no haya dos nombres de grupos iguales
  const actualNick = info?.nick?.toLowerCase()?.trim()

  const validateGroup = () => newName && newName !== info?.nick && !visible && !validNameGroup

  let url

  if (type === 'id_group') {
    url = `group`
  } else {
    url = `subgroup`
  }

  const handleEdit = () => {
    setLoading(true)
    let status
    if (newName.length > 0 && projects?.id_project !== null && projects?.id_project) {
      fetch(urls.ccontrol + url, {
        method: 'PATCH', headers: headersCentroControl(token),
        body: JSON.stringify({ "nick": newName, [type]: info.id_group ? info.id_group : info.id_subgroup, 'id_project': Number(projects?.id_project) })
      }).then(res => {
        setLoading(false)
        status = res.status
        return res.json()
      }).then(data => {
        dispatch(createToast({ status: status, message: data.message, reload: true }))
        toggle()
      })
        .catch(err => {
          console.error("ERROR. API Centro de Control", err)
        })
    }
  }


  const handleChangeNick = (e) => {
    setNewName(e)
    setValidNameGroup(validatorInput(e))
  }

  const validateChangeNick = () => {
    if (projects && projects.groups.length > 0 && newName.toLowerCase().trim() !== actualNick) {
      const normalizedNick = newName.toLowerCase().trim()
      const foundProject = projects.groups.find(prj => prj.nick?.toLowerCase().trim() === normalizedNick)
      return foundProject
    }
    return false
  }

  useEffect(() => {
    setNewName(info?.nick)
    setVisible(false)
    setValidNameGroup(false)
  }, [open])

  return (
    <>
      <Modal open={open} setOpen={setOpen} functSave={handleEdit} loading={loading} disabled={!validateGroup()} onClick={(e) => e.stopPropagation()} title={title}>
        <Grid container className="pr-5 pl-5">
          <Grid item xs={12} className='pb-3'>
            <small className="font-weight-lighter">
              Nombre:
            </small>
            <InputStyled value={newName} onChange={handleChangeNick} fullWidth={true} disabled={false}
              error={validNameGroup} onBlur={() => { setVisible(validateChangeNick()) }} maxLength={64} />
          </Grid>
          <Grid item xs={12}>
            {visible &&
              <Card color='danger' style={{ padding: '5px', textAlign: 'center', backgroundColor: '#f75676', color: '#fff' }}>Este nombre ya está asignado.</Card>
            }
          </Grid>
        </Grid>
      </Modal >
    </>
  )
}