import React, { useContext, useEffect, useState } from 'react'
import { Card, Grid } from '@mui/material'
import { urls } from "utils/constant.jsx"
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { headersCentroControl } from 'services/headers'
import { validatorInput } from 'utils/ValidatorForm'
import { GlobalContext } from 'context/GlobalContext'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'

export const NewGroup = ({ open1, project, setOpen1, toggle, ...props }) => {
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const { actualProject } = useContext(GlobalContext)
    const [nick, setNick] = useState("")
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [validNameGroup, setValidNameGroup] = useState(false)

    const addNewGroup = () => {
        let status
        setLoading(true)
        fetch(urls.ccontrol + `group`, {
            method: 'POST', redirect: 'follow', headers: headersCentroControl(token),
            body: JSON.stringify({
                "nick": nick,
                "id_project": parseInt(props?.idProject ? props?.idProject : project?.id_project),
            })
        })
            .then(res => {
                status = res.status

                return res.json()
            })
            .then(data => {
                setLoading(false)
                dispatch(createToast({ status: status, message: data.message, reload: true }))
                toggle(data)

            })
            .catch(err => console.error("ERROR. API Centro de Control", err))
    }

    const handleChangeGroup = (e) => {
        setNick(e)
        setValidNameGroup(validatorInput(e))
    }


    //Si no ha escrito nada o el nombre ya existe, se desactiva el boton    
    const validateNick = () => nick && nick.length && !visible && !validNameGroup

    //Valida si el nombre del proyecto ya existe en la base de datos y avisa al usuario
    const validateNickGroup = () => {
        if (actualProject?.id_project) {
            const normalizedNick = nick?.toLowerCase()?.trim()
            const foundProject = actualProject?.groups?.find(prj => prj?.nick?.toLowerCase()?.trim() === normalizedNick)
            return foundProject
        }
        return false
    }

    useEffect(() => {
        setNick("")
        setValidNameGroup(false)
    }, [open1, setOpen1])

    return (
        <>
            <Modal onClick={(e) => e.stopPropagation()} open={open1} setOpen={setOpen1} title="Nuevo grupo" functSave={addNewGroup} disabled={!validateNick()} buttonAction="Crear" loading={loading} >
                <Grid container className="pr-5 pl-5">
                    <Grid item xs={12} md={12} lg={12} xl={12} className='pb-3'>
                        <div className='pb-1'>
                            <small className="color-black">
                                Nombre del grupo:
                            </small>
                        </div>
                        <InputStyled
                            fullWidth
                            value={nick}
                            maxLength={95}
                            onChange={handleChangeGroup}
                            onBlur={() => { setVisible((validateNickGroup())) }}
                            error={validNameGroup}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        {visible &&
                            <Card color='danger' style={{ padding: '5px', textAlign: 'center', backgroundColor: '#f75676', color: '#fff' }}>Este nombre ya está asignado</Card>}
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}