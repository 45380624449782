import { ModalAddCcMasterGuided } from "views/Master/CreateMaster/ModalAddCcMasterGuided"
import { GlobalContext } from "context/GlobalContext"
import React, { useContext, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShareAlt } from "@fortawesome/free-solid-svg-icons"
import { ImageDevice } from "utils/ImageDevice/ImageDevice"
import { Loader } from "components/Loader/Loader"
import { urls } from "utils/constant"
import { headersScada } from "services/headers"
import { headersCentroControl } from "services/headers"
import { DropdownMaster } from "views/Master/MenuMaster/DropdownMaster"
import { OpenToScada } from "utils/Utils"
import { TypeProject } from "utils/MiddleWareProjects"

export const ProjectListFree = ({
  isMobile,
  numserie,
  setNumserie,
  inHover,
  setHover
}) => {
  const { projectsCompleto, loadingApp } = useContext(GlobalContext)
  const token = sessionStorage?.token
  const [master, setMaster] = useState([])
  const [open, setOpen] = useState(false)
  const licenApp = JSON.parse(localStorage.getItem(token))
  let licence = licenApp?.licence

  const toggle = () => {
    setOpen(!open)
  }

  async function fetchData(item) {
    const result1 = await fetch(urls.ccontrol + `type/${item}`, {
      method: "GET",
      headers: headersCentroControl(token),
    }).catch((err) => {
      console.error("Error", err)
    })
    const tipo = await result1.json()
    let url = `getccm/${item}`
    if (tipo === 0) {
      url += `?idCCMaster=${item}`
    }
    const result2 = await fetch(urls.scada + url, {
      method: "GET",
      headers: headersScada(token),
    })
    let data2 = await result2.json()
    if (result2.status === 200) {
      return data2
    }
  }

  useEffect(() => {
    if (projectsCompleto.length > 0) {
      setMaster([]);
      projectsCompleto?.forEach((prj) => {
        prj?.groups?.forEach((grp) => {
          if (grp?.devices?.length > 0) {
            grp?.devices?.forEach((dev) => {
              fetchData(dev?.numserie).then((data) => {
                setMaster((prev) => [...prev, { ...data, id_project: prj.id_project, shared: dev.shared, permissions: dev.permissions }])
              })
            })
          }
          grp?.subgroups?.forEach((dev) => {
            if (dev.devices.length > 0) {
              dev?.devices?.forEach(disp => {
                fetchData(disp?.numserie).then((data) => {
                  setMaster((prev) => [...prev, { ...data, id_project: prj.id_project, shared: disp.shared, permissions: disp.permissions }])
                })
              })
            }
          })
        })
      })
    }
  }, [])

  const handleScada = (device) => {
    let url = OpenToScada(TypeProject(device.inst_type), device.id_project, device.numserie)
    window.open(url)
  }

  return (
    <>
      <ModalAddCcMasterGuided
        where="id_group"
        toggle={toggle}
        open={open}
        setOpen={setOpen}
        freeUser={true}
        id_group={projectsCompleto?.[0]?.groups?.[0]?.id_group}
        id_project={projectsCompleto?.[0]?.id_project}
      />
      <div
        className={
          isMobile
            ? `justify-content-center projectList `
            : `d-flex justify-content-center flex-wrap projectList`
        }
        style={{ display: isMobile ? "grid" : "" }}
      />
      <div
        className={
          isMobile
            ? `justify-content-center projectList `
            : `d-flex justify-content-center flex-wrap projectList`
        }
        style={{ display: isMobile ? "grid" : "" }}
      >
        {master &&
          master?.map((device) => {
            let masterPermission = { [device?.numserie]: device };
            return loadingApp === true ? (
              <Loader />
            ) : (
              <div
                style={{
                  cursor: "pointer",
                  margin: "10px",
                  width: "200px",
                  position: "relative",
                }}
                onMouseEnter={() => {
                  setNumserie(device.numserie);
                  setHover(true);
                }}
                onMouseLeave={() => setHover(false)}
                className={
                  inHover && device.numserie === numserie
                    ? "active btn-input-select  mr-4 d-flex"
                    : "  btn-input-select mr-4 d-flex"
                }
              >
                <div
                  className="mt-2"
                  style={{
                    width: "90%",
                    display: "flex",
                    justifyContent: "flex-start",
                    position: "absolute",
                    left: "10px",
                    zIndex: "999",
                    cursor: "pointer",
                    top: " 5px",
                  }}
                >
                  <span style={{ cursor: "pointer" }}>
                    <DropdownMaster
                      token={token}
                      master={device}
                      device={device}
                      project={projectsCompleto}
                      permissions={master?.permissions}
                      masterPermission={masterPermission}
                      licence={licence}
                    />
                  </span>
                </div>
                {device.shared === 1 && (
                  <div
                    className="mt-2"
                    style={{
                      position: "absolute",
                      margin: "10px",
                      right: "15px",
                      zIndex: "999",
                      cursor: "pointer",
                      top: " 0px"
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faShareAlt}
                      style={{
                        color: "#525f7f",
                        fontSize: "12px",
                        marginLeft: "100%"
                      }}
                    />
                  </div>
                )}
                <a
                  onClick={() => handleScada(device)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="align-self-center"
                  style={{ padding: "4em" }}
                >
                  <div className="columContainer">
                    <div className="d-100 text-white d-flex align-items-center justify-content-center">
                      <ImageDevice
                        id_type_device={device.deviceType ? device.deviceType : device.id_tipo_dispositivo}
                        name={device.nick}
                        width="50px"
                        projectList={true}
                      />
                    </div>
                    <div
                      className={`${inHover &&
                        device.numserie === numserie &&
                        "font-hover-card-project"
                        } font-weight-bold mt-2 transform`}
                    >
                      {device.nick}
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
      </div>
    </>
  )
}