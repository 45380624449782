import React, { useContext, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"
import { Grid, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { StyledTableCell, StyledTableCellOrdenado, StyledTableRow, StyledTableCellHead } from "components/Table/TableStyle"
import { ButtonStyledIcon } from "components/Buttons/ButtonStyledIcon"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import { ordenarDatos } from "utils/Utils"
import { IconData, IconEditPencil, IconWarning } from "utils/icon-centrocontrol"
import { FirmwareHistory } from "../TableHistorical/FirmwareHistory"
import { GlobalContext } from "context/GlobalContext"
import { ModalEditInstall } from "../EditInstall/ModalEditInstall"
import { ProgressBar } from "react-bootstrap"
import './TableConfigInstallation.scss'

export const TableConfigInstallation = ({ modalEditar, setModalEditar, data, filterMaster, keySwitch, instalacioneSeleccionadas }) => {
  const [filas, setFilas] = useState(25)
  const [modalHistory, setModalHistory] = useState(false)
  const [hasMore, setHasMore] = useState(filterMaster?.length <= filas ? false : true)
  const [configuracion, setConfiguracion] = useState()
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null })
  const [tableHeight, setTableHeight] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const tableRef = useRef(null)
  const divRef = useRef(null)
  const { actualProject } = useContext(GlobalContext)

  const handleopenEdit = (configuracion) => {
    setConfiguracion(configuracion)
    setModalEditar(true)
  }

  const handleopenHistorical = (configuracion) => {
    setConfiguracion(configuracion)
    setModalHistory(true)
  }

  const alarma = useSelector((state) => state.alarma);
  const alarms = (device) => {
    const objetoEncontrado = alarma?.history.find(
      (objeto) => objeto.numserie === Number(device.numserie)
    )
    return objetoEncontrado?.alarmCount
  }

  const timeoutHandler = () => {
    if (isVisible) {
      setIsVisible(false)
      if (filas + 10 < filterMaster?.length) {
        setFilas(filas + 10)
      } else if (filas <= filterMaster?.length) {
        setFilas(filterMaster?.length)
        setHasMore(false)
      }
    }
  }

  const handleScroll = () => {
    const element = divRef?.current?.getBoundingClientRect()?.bottom - divRef?.current?.getBoundingClientRect()?.top + 30
    const currentPosition = document?.documentElement?.scrollTop + window?.innerHeight
    if (currentPosition >= element && hasMore) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(tableRef.current.offsetHeight)
    }
  }, [filas])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <div className="d-flex table-config-install">
        <TableContainer>
          {data?.length === 0 &&
            <h2 style={{ width: "100%", textAlign: "center", marginTop: "2%" }}>
              No se encontraron dispositivos tipo CcMaster.
            </h2>}
          {data?.length > 0 &&
            <InfiniteScroll
              style={{ minHeight: tableHeight + 30 }}
              dataLength={filas}
              next={timeoutHandler()}
              hasMore={hasMore}
              loader={!isVisible && filterMaster?.length > 0 && <h4 className="text-align-center">Cargando más instalaciones...</h4>}>
              <Table ref={tableRef} sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCellOrdenado
                      className=""
                      align="start"
                      celda={"nick"}
                      style={{ margin: "0px", padding: "0px" }}
                      funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "nick") }}
                      sortConfig={sortConfig}>
                      Instalación
                    </StyledTableCellOrdenado>
                    <StyledTableCell align="center" celda={"error"}
                      style={{ margin: "0px", padding: "0px", borderBottom: "1px solid rgba(224, 224, 224, 1)" }}></StyledTableCell>
                    <StyledTableCellOrdenado align="center" celda={"version"}
                      style={{ margin: "0px", padding: "0px" }}
                      funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "version") }}
                      sortConfig={sortConfig}>
                      Versión
                    </StyledTableCellOrdenado>
                    <StyledTableCellOrdenado
                      align="center"
                      celda={"tipo"}
                      funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "tipo") }}
                      sortConfig={sortConfig}>
                      Tipo
                    </StyledTableCellOrdenado>
                    <StyledTableCellOrdenado align="center" celda={"status"} funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "status"); }} sortConfig={sortConfig}>
                      Estado
                    </StyledTableCellOrdenado>
                    <StyledTableCellOrdenado align="center" celda={"ult_act"} funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "ult_act"); }} sortConfig={sortConfig}>
                      Progreso Actualización                    </StyledTableCellOrdenado>
                    <StyledTableCellOrdenado align="center" celda={"ult_act"} funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "ult_act"); }} sortConfig={sortConfig}>
                      Última sincronización
                    </StyledTableCellOrdenado>
                    <StyledTableCellOrdenado align="center" celda={"enabled"} funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "enabled"); }} sortConfig={sortConfig}>
                      Alarmas
                    </StyledTableCellOrdenado>
                    <StyledTableCellHead align="center" celda={"numserie"}>
                      Acciones
                    </StyledTableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data?.length > 0 && data?.slice(0, filas).map((configuracion, i) => {
                    return (
                      (["admin", "instal"]?.includes(configuracion?.permissions) || ["admin", "instal"]?.includes(configuracion?.permissions?.permissions)) &&
                      <StyledTableRow key={i}>
                        <StyledTableCell align="center" >
                          {configuracion?.nick === "" || configuracion?.nick === null ? "-" : configuracion?.nick}
                        </StyledTableCell>
                        <StyledTableCell align="center" >
                          {configuracion.error !== 0 &&
                            <HtmlTooltip title={<span>Revise su configuración.</span>}>
                              <IconWarning width={15} height={15} />
                            </HtmlTooltip>
                          }
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {configuracion?.version}
                        </StyledTableCell>
                        <StyledTableCell align="left" >
                          {configuracion?.tipo}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <span key={keySwitch}>   {configuracion?.status} </span>
                        </StyledTableCell>
                        <StyledTableCell align="center" className="cell-with-center">
                          {configuracion.status === "Pendiente de actualización" ?
                            <ProgressBar className="progressBar progressbar-table-device" style={{ border: "2px solid #35A151" }} >
                              <ProgressBar className='lineProgress' style={{ backgroundColor: "#35A151", fontSize: '10px', }} now={configuracion.progress} />
                              <p className="progressbar-table-percent">{configuracion.progress}%</p>
                            </ProgressBar> : " - "
                          }
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <span key={keySwitch}> {configuracion?.ult_act} </span>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {alarms(configuracion)}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Grid container columns={15} className="table-config-cell-button">
                            <Grid item xs={12} md={12} lg={6} xl={5}>
                              <ButtonStyledIcon style={{ height: "30px", width: "30px" }} className="boton-edit-alarm" title="Configuración" onClick={() => handleopenEdit(configuracion)} disabled={(configuracion.permissions === undefined || configuracion.permissions === "user") ? true : false} >
                                <IconEditPencil className="boton-edit-hijo" />
                              </ButtonStyledIcon>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} xl={5}>
                              <ButtonStyledIcon
                                className="boton-delete-alarm"
                                title="Histórico"
                                onClick={() => handleopenHistorical(configuracion)}
                                disabled={configuracion.permissions === "user" ? true : false}
                                style={{ height: "30px", width: "30px" }}
                              >
                                <IconData className="boton-delete-hijo" />
                              </ButtonStyledIcon>
                            </Grid>
                          </Grid>
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  })
                  }
                </TableBody>
              </Table>
            </InfiniteScroll>
          }
        </TableContainer>
        <div ref={divRef}></div>
      </div>
      {
        modalHistory && (
          <FirmwareHistory
            actualProject={actualProject}
            configuracion={configuracion}
            modalHistory={modalHistory}
            setModalHistory={setModalHistory}
          />
        )
      }
      {
        modalEditar && (
          <ModalEditInstall
            instalacioneSeleccionadas={instalacioneSeleccionadas}
            data={configuracion}
            modalEditar={modalEditar}
            setModalEditar={setModalEditar}
          />
        )
      }
    </>
  )
}