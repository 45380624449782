export const headersCentroControl = (token) => {
    return {
        'Accept': 'application/json',
        'Auth': process.env.REACT_APP_CC_AUTH,
        'Content-Type': 'application/json',
        'Authorization': token
    }
}

export const headersCentroControlImagen = (token) => {
    return {
        'Accept': 'application/json',
        'Auth': process.env.REACT_APP_CCIMAGE_AUTH,
        'Authorization': token
    }
}

export const headersScada = (token) => {
    return {
        'Accept': 'application/json',
        'Auth': process.env.REACT_APP_SCADA_AUTH,
        'Content-type': 'application/json',
        'Authorization': token
    }
}

export const headersUsers = (token) => {
    return {
        'Accept': 'application/json',
        'Auth': process.env.REACT_APP_USER_AUTH,
        'Content-type': 'application/json',
        'Authorization': token
    }
}

export const headersUsersLogin = {
    'Accept': 'application/json',
    'Auth': process.env.REACT_APP_USERLOGIN_AUTH,
    'Content-type': 'application/json',
}

export const headersCentroControlSharedCede = {
    'Accept': 'application/json',
    'Auth': process.env.REACT_APP_CCSHARECEDE_AUTH,
    'Content-type': 'application/json',
}

export const RecaptchaPublic = process.env.REACT_APP_KEY_RECAPTCHA