import React, { useEffect, useState } from "react"
import { ModalSwitchOffSim } from "views/Modals/ModalSim/ModalSwitchOffSim"
import { ModalAddNewSim } from "views/Modals/ModalSim/ModalAddNewSim"
import { Loader } from "components/Loader/Loader"
import { ButtonStyled } from "components/Buttons/ButtonStyled"
import { IconNewObject, EnerSim } from "utils/icon-centrocontrol"
import { urls } from "utils/constant"
import { EnerSimCard } from "./EnerSimCard"
import { headersCentroControl } from "services/headers"
import '../StylePrincipal.scss'
import "./principalSim.scss"

export const PrincipalSim = () => {
    const token = sessionStorage?.token
    const [enerSimData, setEnerSimData] = useState([])
    const [isLastEnerSim, setIsLastEnerSim] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openSwitchOffModal, setopenSwitchOffModal] = useState(false)
    const [openAddModal, setopenAddModal] = useState(false)

    const fetchEnerSim = async () => {
        setLoading(true)
        try {
            const res = await fetch(urls.ccontrol + 'siminfo', {
                method: 'POST',
                headers: headersCentroControl('bearer ' + token),
                body: JSON.stringify({ "numserie_sim": "999888777", "getAll": true })
            })

            if (res.ok) {
                const enerSimData = await res.json()
                if (enerSimData) {
                    setEnerSimData([...enerSimData ])
                }
            }
        } catch (err) {
            console.error("ERROR. API Centro Control", err)
        } finally {
            setLoading(false)
        }
    }
 
    useEffect(() => {
        fetchEnerSim()
    }, [])

    useEffect(() => {
        setIsLastEnerSim(enerSimData.length === 1)
    }, [enerSimData])

    return (
        <>
           {loading ? <Loader message={`Cargando el proyecto ...`}  />  : <>
            <div className="header-projectList mt-4 px-2">
                <div className='header-title'>
                    <EnerSim />
                    <h2 className="header-text">Mis tarjetas enerSim</h2>
                </div>
                <div className='header-button-projectList'>
                    <ButtonStyled className='button-with-text' onClick={() => setopenAddModal(!openAddModal)}>
                        <IconNewObject className='icon-hover-with-text' />
                        <p className='ml-2'>Activar nueva Sim</p>
                    </ButtonStyled>
                </div>
            </div>
            <div className="enersim-main-container">
                <div className={`enersim-container-${enerSimData.length === 0 ? 'center' : 'start'}`}>
                    {enerSimData.length === 0 && !loading ? (
                    <p className="text-no-cards">No tiene tarjetas</p>
                    ) : (
                    enerSimData.map((item, index) => (
                        <EnerSimCard key={index} 
                            data={item} 
                            cardName={item.installationName}
                            imei={item.numserie_sim}
                            connectionStatus={item.status} 
                            datetime={item.datetime}
                            isLastEnerSim={isLastEnerSim} 
                        />
                        ))
                    )}
                </div>
            </div>
            </>}
            <ModalAddNewSim open={openAddModal} setOpen={setopenAddModal} />
            <ModalSwitchOffSim open={openSwitchOffModal} setOpen={setopenSwitchOffModal} />
        </>
    )
}
