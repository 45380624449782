import React, { useEffect, useState } from 'react'
import solarPanel from 'assets/img/icons/consumption/solarPanel.png'
import { Loader } from "components/Loader/Loader"
import { GraphConstructor } from 'views/Scada/SelfConsumption/GraphsUtils/GraphConstructor'
import 'views/Scada/SelfConsumption/GraphsUtils/miniCircle.scss'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'

export const PanelGraphs = ({ data, loading, daily }) => {
    const [constructor, setConstructor] = useState(<GraphConstructor/>)

    useEffect(() => {
        const dataInfo =[{
            data: {
                categories: [],
                data: [{
                    solar: data?.produccion?.pact !== undefined ? data?.produccion?.pact : 0,
                    total: ((data?.produccion?.pact !== undefined && data?.produccion?.pact !== 0 && data?.produccion?.pact !== null) || (data?.red?.pact !== undefined && data?.red?.pact !== 0 && data?.red?.pact !== null)) ? data?.produccion?.pact + data?.red?.pact : 100
                }]
            }
        }]
        setConstructor(<GraphConstructor className='doughnut-style' daily={daily} nombre={['solar']} props={dataInfo} graphStyle={'doughnut'} />)
    }, [data])

    return (
        <>
            {loading && data ?
                <Loader/>
            :
                <div className="flex items-center relative">
                    <div className='miniCircle'>{constructor}</div>
                    <img className="img-mini-style" alt="solarPanelImg" src={solarPanel} />
                </div>
            }
        </>
    )
}