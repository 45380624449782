// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiAutocomplete-input[class*=MuiOutlinedInput-root] {
  padding: 2px 9px !important;
}`, "",{"version":3,"sources":["webpack://./src/views/Principal/Seeker/seeker.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ","sourcesContent":[".MuiAutocomplete-input[class*=\"MuiOutlinedInput-root\"] {\n    padding: 2px 9px !important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
