import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { urls } from 'utils/constant'
import InputStyled from 'components/Input/InputStyled'
import { validatorEmail } from 'utils/ValidatorForm'
import { Modal } from 'components/Modal/Modal'
import { headersCentroControl } from 'services/headers'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'

export const ModalCedeProject = ({ project, open1, setOpen1 }) => {
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const [email, setEmail] = useState('')
    const [disabled, setDisabled] = useState(true)

    const [loading, setLoading] = useState(false)
    const [isValid, setIsValid] = useState(true)

    //Fetch para ceder un dispositivo
    const giveProject = () => {
        setLoading(true)
        let status
        if (email && email.length > 0 && isValid === true) {
            fetch(urls.ccontrol + `validateAction/project`, {
                method: 'PATCH', redirect: 'follow', headers: headersCentroControl(token),
                body: JSON.stringify({
                    "id_project": project.id_project,
                    "address": email,
                    "nick": project?.nick,
                    "action": "cede"
                })
            }).then(res => {
                setLoading(false)
                status = res.status
                return res.json()
            }).then(data => {
                dispatch(createToast({ status: status, message: data.message, reload: true }))
            }).catch(err => console.error("ERROR. API Centro de Control", err))
        }
        setDisabled(true)
        setLoading(false)
        setOpen1(false)
    }

    const validateEmail = () => {
        setIsValid(validatorEmail(email))
    }

    const handleBlur = () => {
        if (email?.length > 0) {
            validateEmail()
        } if (!email) {
            setIsValid(true)
        }
    }

    useEffect(() => {
        if (isValid === true && email?.length > 0) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [isValid, email])

    useEffect(() => {
        setEmail("")
        setIsValid(true)
    }, [open1, setOpen1])

    return (
        <>
            <Modal onClick={(e) => e.stopPropagation()} open={open1} setOpen={setOpen1} title={`Ceder proyecto "${project?.nick}"`} buttonAction="Ceder" functSave={giveProject} loading={loading} disabled={disabled}>
                <Grid container className="pr-5 pl-5">
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <div className='pb-1'>
                            <small className="color-black">
                                Email de usuario:
                            </small>
                        </div>
                        <InputStyled className="style-textfield-text" fullWidth value={email} variant="outlined" autoFocus margin="dense" label="Email de usuario" name="email" type="email"
                            onChange={(e) => setEmail(e)}
                            onBlur={handleBlur}
                            error={!isValid}
                            errorMessage={'Email inválido'}
                            maxLength={50} />
                    </Grid>
                </Grid>
            </Modal>
        </>

    )
}