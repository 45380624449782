import React, { useEffect, useState, useContext } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import moment from "moment"
import { Box, FormControl, Grid, MenuItem, Step, StepLabel, Stepper } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { GlobalContext } from "context/GlobalContext"
import { ButtonStyledIcon } from "components/Buttons/ButtonStyledIcon"
import { SelectStyled } from "components/Select/SelectStyled"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import InputStyled from "components/Input/InputStyled"
import { Modal } from "components/Modal/Modal"
import Map from "components/Mapa/Map"
import { createToast } from "reduxSlice/toastSlice"
import { headersCentroControl } from "services/headers"
import { IconDevice, IconNewObject, IconCT } from "utils/icon-centrocontrol"
import { validatorInputNumber, validatorInput } from "utils/ValidatorForm"
import { urls } from "utils/constant"
import { ResumeAddCcMaster } from "./ResumeAddCcMaster"

/**
 * @param {{ open: boolean, setOpen{}, idProject: Number, id_group:Number, id_subgroup: Number,  master{}, freeUser: string, iconPlus: boolean }} 
 * @param open Abre el modal
 * @param idProject Nos dice en el proyecto que estamos
 * @param master No da los datos del master
 * @returns Permite agregar un dispositivo
 */
export const ModalAddCcMasterGuided = ({ open, setOpen, id_project, master, freeUser, id_group, id_subgroup, iconPlus }) => {
  const location = useLocation()
  const token = sessionStorage?.token
  const idProject = id_project ? id_project : location.pathname.split("/")[3]
  const { actualProject } = useContext(GlobalContext)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [activeTab, setActiveTab] = useState(0)
  let now = new Date()
  let startt = moment(
    new Date(now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(), 0
    ))
  let fecha_sync = startt.format('YYYY-MM-DD HH:mm:ss')
  const [nick, setNick] = useState()
  const [numserie, setNumserie] = useState()
  const [address, setAddress] = useState(master?.address)
  const [lat, setLat] = useState(master?.latitude ? Number(master?.latitude) : null)
  const [lng, setLng] = useState(master?.longitude ? Number(master?.longitude) : null)
  const [group, setGroup] = useState(id_group)
  const [subgroup, setSubgroup] = useState(id_subgroup)
  const [sub, setSub] = useState([])
  const [captcha, setCaptcha] = useState()
  const [isValidNick, setIsValidNick] = useState(false)
  const [isValidNumserie, setIsValidNumserie] = useState(true)
  const [correctValidator, setCorrectValidator] = useState(false)
  const [visibleErroNs, setVisibleErroNs] = useState(false)
  const [disable, setDisable] = useState(true)
  const [isHover, setIsHover] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const validateNumserie = () => {
    if (numserie && isValidNumserie)
      fetch(urls.ccontrol + 'canInstall', {
        method: 'POST', headers: headersCentroControl(token),
        body: JSON.stringify({ "numserie": numserie?.toString() })
      }).then(res => {
        if (res?.ok) {
          setCorrectValidator(true)
        } else {
          setCorrectValidator(false)
        }
        return res.json()
      }).then(data => {
        setVisibleErroNs(data?.message)
      })
  }

  const handleAddNewMaster = async () => {
    addMasterNew()
  }

  const addMasterNew = () => {
    setLoading(true)
    let status
    let body = {
      "nick": nick,
      "numserie": parseInt(numserie),
      "latitud": lat,
      "longitud": lng,
      "address": address,
      "fecha_sync": fecha_sync,
      "id_project": idProject,
      'g_recaptcha_response': captcha,
      "id_group": group
    }
    if (subgroup) {
      body.id_subgroup = subgroup
    }
    fetch(urls.ccontrol + `device`, {
      method: "POST", headers: headersCentroControl(token),
      body: JSON.stringify(body),
    }).then(res => {
      if (res.status === 200) {
        setOpen(false)
        dispatch(createToast({ status: status, message: "Su dispositivo se ha añadido correctamente", reload: true }))
      }
      status = res.status
      return res.json()
    }).catch(err => {
      dispatch(createToast({ status: 400, message: "Ha habido un fallo, por favor espere y vuélvalo a intentar", reload: true }))
      console.error("ERROR. API Centro de control", err)
    }).finally(() => {
      setLoading(false)
    })
  }

  const confirmCaptcha = async () => {
    if (!executeRecaptcha) {
      return null
    }
    await executeRecaptcha()
      .then(token => setCaptcha(token))
      .catch(err => console.error('No ha funcionado el reCaptcha de Google', err))
  }

  ////Steps label
  const steps = [
    'Nombre',
    'Número de serie',
    'Localización',
    'Grupo/Subgrupo',
    'Confirmar',
    'Añadir'
  ]

  ////// Funtion Pasar de Tab
  const advanceTab = () => {
    setActiveTab(activeTab + 1)
    if (activeTab === 0 || activeTab === 1) {
      setDisable(!disable)
    } else {
      setDisable(false)
    }
  }

  const regressTab = () => { setActiveTab(activeTab - 1) }

  //////Capturar las coordenadas del mapa
  const onSelectMarker = (lat, lng, adres) => {
    setLat(lat)
    setLng(lng)
    setAddress(adres)
  }

  ////////Seleccionar grupo/subgrupo
  const handleGrupo = (e) => {
    setGroup(e.target.value)
  }

  const changeSubgroup = () => {
    let selectGroup = actualProject?.groups?.filter((groups) => groups?.id_group === group)
    if (selectGroup?.[0]?.subgroups?.length > 0) {
      setSub(selectGroup?.[0]?.subgroups)
    }
  }
  useEffect(() => {
    setSub("")
    setSubgroup(id_subgroup)
    changeSubgroup()
  }, [group])

  const handleSubGrupo = (e) => {
    setSubgroup(e.target.value)
  }

  const handleChangeNick = (e) => {
    setNick(e)
    setIsValidNick(validatorInput(e))
  }

  const handleChangeNumserie = (e) => {
    setNumserie(e)
    setIsValidNumserie(validatorInputNumber(e))
  }

  const disableButtonAvg = () => {
    if (activeTab === 0 && nick?.length > 0 && !isValidNick) {
      setDisable(false)
    } else if (activeTab === 1 && numserie && isValidNumserie && correctValidator) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }

  const onBlurNumserie = () => {
    validateNumserie()
  }

  const AdvanceTabResume = () => {
    advanceTab()
    confirmCaptcha()

  }

  const openCancel = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (activeTab === 1)
      disableButtonAvg()
  }, [correctValidator, isValidNumserie, numserie])

  useEffect(() => {
    if (!open) {
      setNick()
      setNumserie()
      setLat(null)
      setLng(null)
      setAddress()
      setCorrectValidator(false)
      setVisibleErroNs(false)
      setGroup(id_group)
      setActiveTab(0)
    }
  }, [open])

  return (
    <>
      {/* Botones para añadir  */}
      {freeUser !== undefined ?
        <div div className="d-flex justify-content-center">
          <span onClick={() => { setOpen(!open) }} className="m-3 btn-input-select" style={{ cursor: 'pointer', width: "150px" }}>
            <div className="d-100 d-flex align-items-center justify-content-center  ">
              <FontAwesomeIcon icon={faPlus} style={{ fontSize: "30px" }} />
            </div>
            <div className="font-weight-bold text-secon opacity-6 mt-2">
              Nueva instalación
            </div>
          </span>
        </div > 
      :
        iconPlus === "dropdown" ?
          null 
        :
          <Grid item xs={12} className="d-flex justify-content-end align-items-center" style={{ marginRight: "0.05rem" }} >
            <span className="button-add-ccmaster-guider" onMouseOver={() => setIsHover(true)}
              onMouseOut={() => setIsHover(false)} >
              <HtmlTooltip title={<span>Añadir instalación</span>}>
                <ButtonStyledIcon variant='contained' className='' padding="6px !important" type="submit" onClick={() => { setOpen(!open) }} >
                  <IconNewObject width="20" height="20" fill={isHover ? "#fff" : "#FF8A1F"} fillX={isHover === true ? "#FF8A1F" : "#fff"} />
                </ButtonStyledIcon>
              </HtmlTooltip>
            </span>
          </Grid>
      }
      <Modal onClick={(e) => e.stopPropagation()} funtCancelButton={openCancel}
        iconTitle={actualProject?.category !== 'mabt' ? <IconDevice width={23} height={23} fill="#FF8A1F" /> : <IconCT color="#FF8A1F" />}
        title={`Nueva instalación`} open={open} disabled={(activeTab === 0 || activeTab === 1) ? disable : activeTab === 5 ? loading : false} setOpen={setOpen} buttonAction={activeTab === 4 ? "Confirmar" : activeTab === 5 ? "Añadir" : "Siguiente"}
        buttonCancel={activeTab !== 0 && "Atrás"} functSave={activeTab === 4 ? AdvanceTabResume : activeTab === 5 ? handleAddNewMaster : advanceTab} loading={loading} functCancel={activeTab !== 0 && regressTab}>
        <Grid container className="pr-5 pl-5 pt-3 ">
          <Grid item xs={12} md={12} lg={12} xl={12}>
            {/* Diferentes pasos para la instalación  */}
            {activeTab === 0 &&
              <>
                <div className='pb-1'>
                  <small className="color-black">
                    Nombre de la instalación:
                  </small>
                </div>
                <InputStyled
                  error={isValidNick}
                  maxLength={100}
                  fullWidth
                  value={nick}
                  onBlur={disableButtonAvg}
                  onChange={handleChangeNick}
                />
              </>
            }
            {activeTab === 1 &&
              <>
                <div className='pb-1'>
                  <small className="color-black">
                    Número de serie:
                  </small>
                </div>
                <InputStyled
                  error={!isValidNumserie}
                  helperText={!isValidNumserie}
                  fullWidth
                  value={numserie}
                  onBlur={onBlurNumserie}
                  onChange={(e) => handleChangeNumserie(e)}
                />
                <Grid item xs={12} sm={12} lg={12} xl={12} className='d-flex justify-content-center align-items-center'>
                  <Grid item xs={12} sm={12} lg={12} xl={12} className='d-flex justify-content-flex-start align-items-center'>
                    {!correctValidator && visibleErroNs &&
                      <p className="information-text ml-0">
                        {visibleErroNs}
                      </p>
                    }
                  </Grid>
                </Grid>
              </>
            }
            {activeTab === 2 &&
              <>
                <div className='pb-1'>
                  <small className="color-black">
                    Localización:
                  </small>
                </div>
                <Map center={{ lat: lat, lng: lng }} onSelectMarker={onSelectMarker} zoom={4} address={address} utmShow={false} />
              </>
            }
            {activeTab === 3 &&
              <>
                <div className='pb-1'>
                  <small className="color-black">
                    Selecciona un grupo:
                  </small>
                </div>
                <FormControl className='width-100 pb-2' size="small">
                  <SelectStyled value={group} onChange={handleGrupo}>
                    {actualProject?.groups?.map((grp) => {
                      return (
                        <MenuItem key={grp.id_group} value={grp.id_group}>|-{grp.nick}</MenuItem>
                      )
                    })}
                  </SelectStyled>
                </FormControl>
                {sub?.length > 0 &&
                  <>
                    <div className='pb-1'>
                      <small className="color-black">
                        Selecciona un subgrupo:
                      </small>
                    </div>
                    <FormControl className='width-100' size="small">
                      <SelectStyled value={subgroup} onChange={handleSubGrupo}>
                        {sub?.map((sgrp) => {
                          return (
                            <MenuItem key={sgrp.id_subgroup} value={sgrp.id_subgroup}>|-{sgrp.nick}</MenuItem>
                          )
                        })}
                      </SelectStyled>
                    </FormControl>
                  </>
                }
              </>
            }
            {activeTab === 4 &&
              <ResumeAddCcMaster project={actualProject} nick={nick} numserie={numserie} activeTab={activeTab} address={address} group={group} subgroup={subgroup} />
            }
          </Grid>
        </Grid>
        <Grid container className="container-shared-cede-modal">
          <Box sx={{ width: '100%', marginTop: "15px", marginBottom: "15px" }} className="container-modal-stepper">
            <Stepper activeStep={activeTab} alternativeLabel
              sx={{
                ".MuiStepConnector-lineHorizontal": {
                  borderTopWidth: "2px"
                },
                ".Mui-active>.MuiStepConnector-lineHorizontal": {
                  borderColor: "#FF8A1F"
                },
                ".Mui-completed>.MuiStepConnector-lineHorizontal": {
                  borderColor: "green !important"
                },
                ".Mui-active": {
                  fontWeight: "600 !important",
                },
                ".Mui-completed": {
                  color: "green !important"
                },
                ".MuiStepLabel-labelContainer > .Mui-completed": {
                  fontWeight: 600,
                },
                ".MuiStepLabel-label": {
                  color: "#333",
                  fontWeight: 600,
                },
                ".MuiStepLabel-label>.Mui-completed": {
                  color: "#333"
                },
                ".MuiStepIcon-text": {
                  fontSize: "12px",
                  fontWeight: 600
                },
                ".MuiStepIcon-root.Mui-active": {
                  color: "#FF8A1F"
                }
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel className="stepLabel-color" >{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Grid>
      </Modal>
    </>
  )
}