import React, { useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Avatar, FormControl, Grid, Typography } from '@mui/material'
import { GlobalContext } from 'context/GlobalContext'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { DropZone } from 'components/DropZone/DropZone'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { headersCentroControl, headersCentroControlImagen } from 'services/headers'
import { IconNewObjectBig, IconDeleteAlarm, IconEditPencil, IconArrowAccordion } from 'utils/icon-centrocontrol'
import { CategoryType, TypeProject } from 'utils/MiddleWareProjects'
import { validatorInput } from 'utils/ValidatorForm'
import { positions, categories } from 'utils/Utils'
import { urls } from "utils/constant"
import { updateRefresh } from 'reduxSlice/refreshNavFooterSlice'
import { createToast } from 'reduxSlice/toastSlice'

export const EditProject = (props) => {
  const token = sessionStorage?.token
  const { actualProject, projectsCompleto } = useContext(GlobalContext)
  const [nick, setNick] = useState()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [validProyectName, setValidProyectName] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState()
  const [isDisabled, setIsDisabled] = useState(false)
  const [isChangeLogo, setIsChangeLogo] = useState(false)
  const [logos, setLogos] = useState({})
  const [listLogos, setListLogos] = useState([])
  const [listFiles, setListFiles] = useState([])
  const [position, setPosition] = useState([])
  const [keyLogos, setKeyLogos] = useState('logos')
  const dispatch = useDispatch()
  const [showDropZone, setShowDropZone] = useState([true, true, true])

  let nonSelectedCategoryCount = 0

  categories.sort((a, b) => a.nick.localeCompare(b.nick))

  const fetchGetLogo = async () => {
    setLoading(true)
    try {
      const res = await fetch(urls.ccontrol + 'logo/' + props?.project?.id_project, { method: 'GET', headers: headersCentroControl(token) })
      if (res.status === 200) {
        let json = await res.json()
        setLogos(json)
      }
      setLoading(false)
    } catch (err) {
      console.error("ERROR. API Centro de Control", err)
    }
  }

  const fetchEditLogo = async (sucess) => {
    const formDataEdit = new FormData()
    const formDataCreate = new FormData()
    let edit = false
    let create = false
    let index = 0
    Object?.entries(listFiles)?.forEach(([clave, logo]) => {
      // edit
      let value = index + 1
      if (((typeof (logo[0]) !== 'string' && position[index] !== -1) || (position[index] !== -1 && position[index] !== logos['logo_' + value][0])) && index < Object?.keys(logos)?.length) {
        edit = true
        if ((typeof (logo[0]) !== 'string' && position[index] !== -1)) {
          formDataEdit.append('logos' + value, logo[0])
        }
        formDataEdit.append(clave, position[index])
      }
      // create
      if (typeof (logo[0]) !== 'string' && position[index] !== -1 && index >= Object?.keys(logos)?.length) {
        create = true
        formDataCreate.append('logos', logo[0])
        formDataCreate.append(clave, position[index])
      }
      index++
    })
    if (edit) {
      formDataEdit.append('id_project', props?.project?.id_project)
      try {
        const res = await fetch(urls.ccontrol + 'logo', { method: 'PATCH', headers: headersCentroControlImagen(token), body: formDataEdit })
        if (selectedCategory === 8) {
          dispatch(updateRefresh())
          dispatch(
            createToast({
              status: res.status,
              message: 'Proyecto actualizado correctamente',
              reload: true,
            })
          )}
      } catch (err) {
        console.error("ERROR. API Centro de Control", err)
      }
    }
    if (create) {
      formDataCreate.append('id_project', props?.project?.id_project)
      try {
        const res = await fetch(urls.ccontrol + 'logo', { method: 'POST', headers: headersCentroControlImagen(token), body: formDataCreate })
        if (selectedCategory === 8) {
          dispatch(updateRefresh())
          dispatch(
            createToast({
              status: res.status,
              message: 'Proyecto actualizado correctamente',
              reload: true,
            })
          )}
      } catch (err) {
        console.error("ERROR. API Centro de Control", err)
      }
    }
    if (Object?.keys(logos)?.length > Object?.keys(listFiles)?.length) {
      const deletePromises = Object.keys(logos).map(async (logo, index) => {
        if (index + 1 > Object.keys(listFiles).length) {
          try {
            const res = await fetch(urls.ccontrol + 'logo', {
              method: 'DELETE',
              headers: headersCentroControl(token),
              body: JSON.stringify({ delete: logo, id_project: props?.project?.id_project })
            })
            if (res.status !== 200) {
              return false
            }
            if (selectedCategory === 8) {
            dispatch(updateRefresh())
            dispatch(
              createToast({
                status: res.status,
                message: 'Proyecto actualizado correctamente',
                reload: true,
              })
            )}
            return true
          } catch (err) {
            console.error("ERROR. API Centro de Control", err)
            return false
          }
        }
      })
      await Promise.all(deletePromises)
    }
    setLoading(false)
    props.setOpen1(current => !current)
  }

  const editProject = () => {
    setLoading(true)
    let status
    fetch(
      urls.ccontrol + `project`, {
      method: 'PATCH',
      headers: headersCentroControl(token),
      body: JSON.stringify({ "id_project": props?.project?.id_project ? props?.project?.id_project : actualProject?.id_project, "nick": nick, "type": parseInt(selectedCategory) })
    }).then(response => {
      setLoading(false)
      status = response.status
      if (response.ok) {
        fetchEditLogo(true)
      } else {
        fetchEditLogo(false)
      }
      return response.json()
    }).then(data => {
      dispatch(updateRefresh())
      dispatch(createToast({ status: status, message: data.message, reload: true }))

    }).catch(err => {
      console.error("ERROR. API Centro de Control", err)
    })
  }

  const editEnerSim = () => {
    fetchEditLogo(true)
    setLoading(true)
  }

  const handleShowDropZone = (ind) => {
    const newShowDropZone = [...showDropZone]
    newShowDropZone[ind] = !newShowDropZone[ind]
    setShowDropZone(newShowDropZone)
  }

  const handleEditProject = () => {
    if (selectedCategory === 8) {
      editEnerSim()
    } else {
      editProject()
    }
  }

  const handleChangeNick = (e) => {
    setNick(e)
    setValidProyectName(validatorInput(e))
  }

  const handleChangeCategory = (categoryValue) => {
    if (selectedCategory === 8) {
      return
    }
    setSelectedCategory(categoryValue)
  }

  const handleFiles = (logo, file) => {
    if (file?.length !== 0) {
      setKeyLogos(file)
      setListFiles(prevEstado => ({ ...prevEstado, [logo]: file }))
      setIsChangeLogo(true)
    }
  }

  const handlePosition = (ind, pos) => {
    const positionArray = [...position]
    positionArray[ind] = pos
    setPosition(positionArray)
    setIsChangeLogo(true)
  }

  const addListLogo = () => {
    let index = listLogos.length + 1
    const positionArray = [...position]
    positionArray[listLogos.length] = -1
    setPosition(positionArray)
    setListLogos(prev => [...prev, 'logo' + index])
  }

  const deleteListLogo = (logo, index) => {
    setIsChangeLogo(true)
    if (listLogos?.length === 1) {
      setListLogos(['logo1'])
      setListFiles({})
      setPosition([-1])
      setShowDropZone([true, true, true])
    } else {
      const logosArray = []
      const positionArray = [...position]
      const objFiles = Object.assign({}, listFiles)
      let numLogo = logo.split("logo")[1]
      let numLogos = Object?.keys(objFiles)?.length
      if (numLogos === 1) {
        logosArray.push('logo1')
      } else {
        for (let i = 1; i < numLogos; i++) {
          logosArray.push('logo' + i)
        }
      }
      for (let i = Number(numLogo); i < numLogos; i++) {
        let value = i + 1
        if (objFiles['logo' + value]) {
          objFiles['logo' + i] = objFiles['logo' + value]
        }
      }
      if (numLogos > 1) {
        delete objFiles['logo' + numLogos]
      }
      positionArray.splice(index, 1)
      setPosition(positionArray)
      setListLogos(logosArray)
      setListFiles(objFiles)
      const newShowDropZone = [...showDropZone]
      newShowDropZone[index] = true
      setShowDropZone(newShowDropZone)
    }
  }



  const actualNick = (props?.project?.nick || props?.actualProject?.nick || '').toLowerCase().trim()

  const validateNickProject = () => {
    if (projectsCompleto && projectsCompleto.length > 0 && nick.toLowerCase().trim() !== actualNick) {
      const normalizedNick = nick.toLowerCase().trim()
      const foundProject = projectsCompleto?.find(prj => prj.nick.toLowerCase().trim() === normalizedNick)
      return foundProject
    }
    return false
  }

  useEffect(() => {
    if (listLogos?.length === 0) {
      addListLogo()
    }
  }, [listLogos])

  useEffect(() => {
    fetchGetLogo()
    setIsChangeLogo(false)
    setNick(props?.project?.nick !== undefined ? props?.project?.nick : props?.actualProject?.nick ? props?.actualProject?.nick : props?.actualProject?.nick)
    setSelectedCategory(props?.project?.category !== undefined ? TypeProject(props?.project?.category) : TypeProject(actualProject?.category))
    setVisible(false)
    setValidProyectName(false)
    setListLogos(['logo1'])
    setListFiles({})
    setPosition([-1])
  }, [props?.project, props.open1])

  useEffect(() => {
    if (
      (((props?.project?.nick && props?.project?.nick !== nick) || (props?.actualProject?.nick && props?.actualProject?.nick !== nick)) && !visible && !validProyectName && nick) || isChangeLogo ||
      (((props?.project?.category !== undefined &&
        TypeProject(props?.project?.category) !== selectedCategory) ||
        (actualProject?.category !== undefined && TypeProject(actualProject?.category) !== selectedCategory)) && !visible && !validProyectName && nick)
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [nick, validProyectName, visible, selectedCategory, isChangeLogo])

  useEffect(() => {
    if (Object?.keys(logos)?.length !== 0) {
      let arrayLogo = []
      let objLogo = {}
      let arrayPosition = []
      let arrayShowDropZone = [true, true, true]
      Object?.entries(logos)?.forEach((logo, ind) => {
        let nameEdit = logo[0]?.split('_')
        arrayPosition.push(logo[1][0])
        arrayLogo.push(nameEdit[0] + nameEdit[1])
        objLogo[nameEdit[0] + nameEdit[1]] = `data:${logo[1][1]};base64,${logo[1][2]}`
        arrayShowDropZone[ind] = false
      })
      setListLogos(arrayLogo)
      setListFiles(objLogo)
      setPosition(arrayPosition)
      setShowDropZone(arrayShowDropZone)
    }
  }, [logos])

  return (
    <>
      <Modal open={props?.open1} setOpen={props?.setOpen1} onClick={(e) => e.stopPropagation()} functSave={handleEditProject} disabled={isDisabled} buttonActive="añadir" title="Editar proyecto" loading={loading}>
        <Grid container className="container-shared-cede-modal-padding">
          <Grid item xs={12} className='pb-3'>
            <div className='pb-1'>
              <small className="color-black">
                Nombre del proyecto:
              </small>
            </div>
            <InputStyled value={nick} onChange={handleChangeNick} fullWidth={true} disabled={props?.project?.category === "enerSIM" ? true : false} errorMessage={visible ? "Este nombre ya está asignado" : "Caracteres no permitidos"}
              error={validProyectName || visible} onBlur={() => { setVisible(validateNickProject()) }} maxLength={64} />
          </Grid>
          <Grid item xs={12} className='pb-3'>
            <FormControl fullWidth variant="outlined" size="small" onBlur={validateNickProject}>
              <div className="pb-1">
                <small className="color-black">
                  Selecciona una categoría:
                </small>
              </div>
              <Grid item xs={12} className="pb-1">
                {categories.map((category) => {
                  if ((selectedCategory === 8 && category.value === 8) || (selectedCategory !== 8 && category.value !== 8)) {
                    const currentIndex = nonSelectedCategoryCount++

                    return (
                      <Grid item key={category.value} className={`pt-2 pb-2 ${currentIndex % 2 === 0 ? 'shaded' : 'not-shaded'}`}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                          <Grid item xs={1} className="pl-2">
                            {category.emoticon}
                          </Grid>
                          <Grid item xs={10}>
                            <Typography className='font-size-12'>{category.nick}</Typography>
                          </Grid>
                          <Grid item xs={1} style={{ stroke: selectedCategory === category.value ? '#FF8A1F' : '#fff' }}>
                            <SwitchStyled
                              className="switch-small"
                              checked={selectedCategory === category.value}
                              onChange={() => handleChangeCategory(category.value)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  } else {
                    return null
                  }
                })}
              </Grid>
              <div>
                <small className="color-black">
                  {`Categoría actual: ${CategoryType(props?.project?.category ? props?.project?.category : actualProject?.category)}`}
                </small>
              </div>
            </FormControl>
          </Grid>
          {listLogos?.map((logo, ind) => {
            return (
              <Grid key={ind} item xs={12} md={12} lg={12} xl={12} className='pb-3'>
                <FormControl fullWidth variant="outlined" size="small" className='pb-2'>
                  <Grid container>
                    <Grid item xs={6} md={6} lg={6} xl={6} className='pb-1'>
                      <small className="color-black">
                        Selecciona la posición ({`logo ${ind + 1}`}):
                      </small>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6} className='pb-1 flex-center-end'>
                      {Object?.entries(listFiles)?.length > 0 &&
                        <>
                          {showDropZone[ind] === true ?
                            <span className={`button-accordion-project rotate-button-acordion mr-1 border-1 box-shadow-none`} onClick={() => handleShowDropZone(ind)}>
                              <IconArrowAccordion className="accordion-icon" stroke="#FF8A1F" />
                            </span>
                            :
                            <ButtonStyledIcon padding={'0 !important'} className='boton-edit-alarm mr-1' title='Editar' onClick={() => handleShowDropZone(ind)}>
                              <IconEditPencil className='boton-edit-hijo' />
                            </ButtonStyledIcon>
                          }
                          <ButtonStyledIcon padding={'0 !important'} className='boton-delete-alarm' title='Eliminar imagen' onClick={() => deleteListLogo(logo, ind)}>
                            <IconDeleteAlarm className='boton-delete-hijo' />
                          </ButtonStyledIcon>
                        </>
                      }
                    </Grid>
                    {positions.map((pos, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={5} className={`flex-center justify-start pt-2 pb-2 shaded`}>
                          <Typography className='font-size-12 pl-2'>{pos.position}</Typography>
                        </Grid>
                        <Grid item xs={1} className={`flex-center justify-start pt-2 pb-2 shaded`}>
                          <SwitchStyled
                            className="switch-small"
                            checked={position[ind] === pos.value}
                            onChange={(e) => handlePosition(ind, pos.value)}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </FormControl>
                {showDropZone[ind] &&
                  <>
                    <div className='pb-1'>
                      <small className="color-black">
                        Subir logo:
                      </small>
                    </div>
                    {!loading && logos &&
                      <DropZone key={logo + ind} onFileUpload={(file) => handleFiles(logo, file)} acceptedExtensions={['image/jpeg', 'image/jpg', 'image/png']} maxFilesAcept={1} messageZone='Arrastra y suelta archivos .jpg, .jpeg o .png aquí, o haz clic para seleccionar archivos (El archivo no puede superar los 100kb)' maxSize={100} titleFiles='Nombre del fichero:' />
                    }
                  </>
                }
              </Grid>
            )
          })}
          {Object?.entries(listFiles)?.length > 0 &&
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div className='pb-1'>
                <h4 className="color-black">
                  Previsualización logo:
                </h4>
              </div>
              <div className='flex-space-between' key={keyLogos}>
                {Object?.entries(listFiles)?.map(([clave, logo]) => {
                  return (
                    <div>
                      <h5 className="color-black text-align-center m-0">
                        Logo {clave.split('logo')[1]}:
                      </h5>
                      {logo[0] &&
                        <Avatar sx={{ bgcolor: 'white', maxWidth: '10em', width: 'auto', height: '5em', border: "1px solid #cbcbcb", borderRadius: '0' }}>
                          <img className="pt-2 pb-2 pr-3 pl-3" style={{ maxWidth: '10em', height: '5em' }} src={typeof (logo) === 'string' ? logo : URL.createObjectURL(logo[0])} alt='logo' />
                        </Avatar>
                      }
                    </div>
                  )
                })}
              </div>
            </Grid>
          }
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <div className="avatar-container">
            {listLogos?.length < 3 &&
              <div title="Subir imagen" className={`${listLogos?.length > Object?.keys(listFiles)?.length || position[listLogos?.length - 1] === -1 ? 'button-add-disabled' : 'button-add'} mb-3 mt-2`} onClick={() => listLogos?.length > Object?.keys(listFiles)?.length || position[listLogos?.length - 1] === -1 ? {} : addListLogo()}>
                <IconNewObjectBig className={`${listLogos?.length > Object?.keys(listFiles)?.length || position[listLogos?.length - 1] === -1 ? 'icon-color-enerclic-disabled' : ''} icon-hover-with-text`} />
              </div>
            }
          </div>
        </Grid>
      </Modal>
    </>
  )
}