import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { urls } from 'utils/constant'
import InputStyled from 'components/Input/InputStyled'
import { headersCentroControl } from 'services/headers'
import { validatorEmail } from 'utils/ValidatorForm'
import { Modal } from 'components/Modal/Modal'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'

/**
 * 
 * @param {master {}} Recibimos datos del master 
 * @param Hacemos una llamada a validateAction con la acción que nos dice a quien le cedemos
 * @returns Retornamos un modal que nos permite introducir el mail de la persona a ceder 
 */
export const ModalCedeDevice = ({ master, menuDrop, modal, setModal, toggle }) => {
    const token = sessionStorage?.token;
    const dispatch = useDispatch()
    const [email, setEmail] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [isValid, setIsValid] = useState(true)

    //Fetch para compartir dispositivo
    const cedeDevice = () => {
        setLoading(true)
        let status
        if (email && email.length > 0 && isValid === true) {
            fetch(
                urls.ccontrol + `validateAction/device`, {
                method: 'PATCH', redirect: 'follow', headers: headersCentroControl(token),
                body: JSON.stringify({
                    "numserie": master?.numserie,
                    "address": email,
                    "nick": master?.nick,
                    "action": "cede",
                    "idccmaster": master?.numserie,
                    "permission": "admin",
                })
            }).then(res => {
                setLoading(false)
                status = res.status
                return res.json()
            }).then(data => {
                dispatch(createToast({ status: status, message: data.message, reload: true }))
                setModal(current => !current)
            }).catch(err => console.error("ERROR. API Centro de Control", err))
        }
        setDisabled(true)
        setLoading(false)
        setModal(false)
    }

    const validateEmail = () => {
        setIsValid(validatorEmail(email))
    }

    const handleBlur = () => {
        if (email.length > 0) {
            validateEmail()
        }
    }

    useEffect(() => {
        if (isValid === true && email.length > 0) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [isValid, email])

    useEffect(() => {
        setEmail("")
        setIsValid(true)
    }, [modal, setModal])

    return (
        <>
            <Modal onClick={(e) => e.stopPropagation()} open={modal} setOpen={setModal} title={`Ceder instalación "${master?.nick}"`} buttonAction="Ceder" disabled={disabled} functSave={cedeDevice} loading={loading}>
                <Grid container className="pr-5 pl-5">
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <div className='pb-1'>
                            <small className="color-black">
                                Email de usuario:
                            </small>
                        </div>
                        <InputStyled
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e)}
                            onBlur={handleBlur}
                            error={!isValid}
                            errorMessage={'Email inválido'}
                            maxLength={50}
                        />
                    </Grid>
                </Grid >
            </Modal >
        </>
    )
}

