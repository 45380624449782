import React, { useState } from "react"
import { Button, Menu } from "@mui/material"
import { PermissionsForMenuDevice } from "views/Menu/PermissionsforMenu"
import { IconMenu } from "utils/icon-centrocontrol"

export const DropDownDevices = ({
  end,
  start,
  scada,
  loose,
  setDevices,
  setRefresh,
  refresh,
  updateCcM,
  deviceSelected,
  instalacion,
  device,
  devices,
  masterPermission,
  masterPermissionNew,
  color,
  icon
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
    e.stopPropagation(e)
  }

  const handleClose = (e) => {
    setAnchorEl(null)
    e.stopPropagation(e)
  }
  
  return (
    <>
      {(instalacion || device?.mabt_serial) && (masterPermission?.[instalacion?.master_serial ? instalacion?.master_serial : device?.mabt_serial ? device?.mabt_serial : device?.id_padre ? device?.id_padre : device?.numseriePadre ? device?.numseriePadre : instalacion?.numserie ]?.permissions !== "user" || masterPermissionNew?.permissions !== "user") ?
        <>
          <Button className="p-0 m-0 " onClick={handleClick}>
            <span className="btn-wrapper--icon ">
              <IconMenu width='12' height='19' color={color} />
            </span>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={(e) => handleClose(e)}
          >
            <PermissionsForMenuDevice
              icon={icon}
              device={device}
              devices={devices ? devices : device}
              permissions={
                masterPermissionNew ? masterPermissionNew :
                  masterPermission?.[
                  instalacion?.master_serial
                    ? instalacion?.master_serial
                    : device?.mabt_serial
                      ? device?.mabt_serial
                      : device?.master_serial
                        ? device?.master_serial
                        : device?.id_padre
                          ? device?.id_padre
                          : device?.numseriePadre
                            ? device?.numseriePadre
                            : instalacion?.numserie
                  ]
              }
              updateCcM={updateCcM}
              instalacion={instalacion ? instalacion : deviceSelected}
              setDevices={setDevices}
              loose={loose}
              setRefresh={setRefresh}
              refresh={refresh}
              end={end}
              start={start}
              dataSliced={device}
              handleClose={handleClose}
              scada={scada}
            />
          </Menu>
        </>
      : 
        <>
          <Button className="p-0 m-0 " onClick={handleClick}>
            <span className="btn-wrapper--icon ">
              <IconMenu color={color} />
            </span>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={(e) => handleClose(e)}
          >
            <PermissionsForMenuDevice
              icon={icon}
              device={device}
              devices={devices ? devices : device}
              permissions={masterPermissionNew ? masterPermissionNew :
                masterPermission?.[
                instalacion?.master_serial
                  ? instalacion?.master_serial
                  : device?.mabt_serial
                    ? device?.mabt_serial
                    : device?.master_serial
                      ? device?.master_serial
                      : device?.id_padre
                        ? device?.id_padre
                        : device?.numseriePadre
                          ? device?.numseriePadre
                          : instalacion?.numserie
                ]
              }
              updateCcM={updateCcM}
              instalacion={instalacion ? instalacion : deviceSelected}
              setDevices={setDevices}
              loose={loose}
              setRefresh={setRefresh}
              refresh={refresh}
              end={end}
              start={start}
              dataSliced={device}
              handleClose={handleClose}
              scada={scada}
            />
          </Menu>
        </>
      }
    </>
  )
}