import { createSlice } from '@reduxjs/toolkit'

export const filterAlarmSlice = createSlice({
  name: 'filterAlarm',
  initialState: {},
  reducers: {
    addFilterAlarm: (state, action) => {
      state.numserie = action.payload.numserie
    },
    clearFilterAlarm: (state, action) => {
      state.numserie = undefined
    }
  },
})

export const { addFilterAlarm, clearFilterAlarm } = filterAlarmSlice.actions

export default filterAlarmSlice.reducer