import { createSlice } from '@reduxjs/toolkit'

export const refreshNavFooterSlice = createSlice({
    name: 'refreshNavFooter',
    initialState: {
        name: false,
    },
    reducers: {
        updateRefresh: (state, action) => {
            state.name = !state.name
        }
    },
})

export const { updateRefresh } = refreshNavFooterSlice.actions

export default refreshNavFooterSlice.reducer