import React, { useState } from 'react'
import { styled } from '@mui/system'
import { Drawer, Grid, List } from '@mui/material'
import { ArrowBackIos } from "@mui/icons-material"
import arrowExpanseScada from 'assets/img/icons/arrow-expanse-scada.png'

const StyledDrawer = styled(Drawer)({
  width: '75%',
  flexShrink: 0
})

const StyledDrawerPaper = styled('div')({
  width: '75%'
})

const MenuMobile = ({ children }) => {
  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Grid container className={"menu-mobile-item"}>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <span onClick={handleDrawerOpen} className="button-expanse-menu" style={{ position: 'fixed', zIndex: 999 }}>
          <img alt="menumovilearrowexpansescada" src={arrowExpanseScada} style={{ width: "18px", transform: "rotate(270deg)" }} />
        </span>
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <StyledDrawer 
          className="menu-mobile-width"
          variant="persistent"
          anchor="left"
          open={open}
          classes={{ paper: StyledDrawerPaper, root: {width: '75%'}  }}
        >
          <List>
            {children}
          </List>
        </StyledDrawer>
        {open &&
          <span
            onClick={handleDrawerClose}
            className="button-expanse-menu"
            style={{
              position: 'fixed',
              zIndex: 999,
              left: '73%'
            }}
          >
            <ArrowBackIos />
          </span>
        }
      </Grid>
    </Grid>
  )
}

export default MenuMobile
