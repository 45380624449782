import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { GraphsBar } from 'components/Graph/GraphsBar'
import { GraphsLine } from 'components/Graph/GraphsLine'
import { Loader } from 'components/Loader/Loader'
import { vertidos, consumidos, dispositivos } from 'utils/constant'
import { formatDateNoSecond, namePhasesRed, esHoy, obtenerNombreMes } from 'utils/Utils'
import { ArrayColorsSelf, callBallGraphs, transparent } from './utilsSelfConsumption'
import { formatLabel } from '../MABT/utils'
import "./styleSelfConsumption.scss"

export const GraphComponetSelfConsumption = ({ data, tabsGraphs, dataGraphs, loading, activeTab, disabledGraphs, setActiveTab,
    devicesSelect, colors, currentDataToday, childrenDevices, daily, typeDevice, setFields, fields, selfConsumption, setHandleTab }) => {
    const [labels, setLabels] = useState([])
    const [dataSets, setDataSets] = useState([])
    const [labelEnergy, setLabelEnergy] = useState([])
    const [dataSetsEnergy, setDataSetsEnergy] = useState([])
    const [graphs, setGraphs] = useState("line")
    const [tabs, setTabs] = useState(tabsGraphs)
    const namePlant = childrenDevices?.filter(dev => dev?.id_tipo_dispositivo === dispositivos?.planta)?.[0]?.nick

    const arrayColors = async (length, data) => {
        let lenghtDataGraphs = length ?? length ?? data?.length
        let generalColors = ArrayColorsSelf(consumidos, lenghtDataGraphs + 1)
        let photovol = ArrayColorsSelf(vertidos, lenghtDataGraphs + 1)
        return {
            generalColors, photovol
        }
    }

    const dataGraphsPower = async () => {
        setLabels([])
        let color = await arrayColors(data.length);
        setDataSets([])
        if (typeDevice === dispositivos?.bateria) {
            dataGraphs && dataGraphs?.forEach((item, i) => {
                item?.data?.forEach((datos) => {
                    if (devicesSelect?.includes(Number(datos.numserie))) {
                        setDataSets(prev => [...prev, {
                            label: datos.nick ? datos.nick : namePlant,
                            data: datos?.data,
                            borderColor: colors?.[activeTab]?.[0],
                            pointBackgroundColor: colors?.[activeTab]?.[1],
                            backgroundColor: colors?.[activeTab]?.[1],
                            id: i + 5,
                            pointRadius: 0,
                            tension: 0.1,
                            numserie: `${datos?.numserie}`,
                            fill: true,
                            unit: callBallGraphs(fields)
                        }])
                    }
                })
            })
        } else {
            dataGraphs && dataGraphs?.forEach((item, i) => {
                item?.data?.forEach((datos) => {
                    if (devicesSelect?.includes(Number(datos?.numserie))) {
                        setDataSets(prev => [...prev, {
                            label: datos?.nick ? datos?.nick : namePlant,
                            data: datos?.data,
                            borderColor: color?.generalColors?.[i],
                            pointBackgroundColor: transparent(color?.generalColors?.[i], 0.7),
                            backgroundColor: transparent(color?.generalColors?.[i], 0.7),
                            id: i + 5,
                            pointRadius: 0,
                            tension: 0.1,
                            numserie: `${datos?.numserie}`,
                            fill: true,
                            unit: callBallGraphs(fields),
                            borderWidth: 3,
                        }])
                    }
                })
            })
        }
        setLabels(dataGraphs && dataGraphs?.[0]?.categories)
    }

    const dataGraphsPowerPhases = async () => {
        let color = await arrayColors(data.length);
        setLabels([])
        setDataSets([])
        dataGraphs && dataGraphs?.forEach((item, i) => {
            item?.data?.forEach((datos) => {
                datos?.data?.forEach((phases) => {
                    if (devicesSelect?.includes(Number(datos.numserie))) {
                        setDataSets(prev => [...prev, {
                            label: `${datos?.nick}  -  ${namePhasesRed(phases?.name)}`,
                            data: phases?.data,
                            borderColor: transparent(color?.generalColors?.[i], 1),
                            pointBackgroundColor: transparent(color?.generalColors?.[i], 0.7),
                            backgroundColor: transparent(color?.generalColors?.[i], 0.7),
                            id: i + 5,
                            pointRadius: 0,
                            tension: 0.1,
                            numserie: `${datos?.numserie}`,
                            fill: true,
                            unit: callBallGraphs(fields),
                            borderWidth: 3,
                        }])
                    }
                })
                setLabels(datos?.categories)
            })
        })
    }

    const EnergyGraphsSelf = async (field) => {
        let color = await arrayColors(data.length);
        setDataSetsEnergy([])
        let fieldActive = field ?? field ?? fields
        childrenDevices && childrenDevices?.forEach((dev, i) => {
            if (devicesSelect?.includes(Number(dev.numserie))) {
                tabsGraphs?.forEach(grp => {
                    if (grp?.graphs === "bar" && grp?.field === fieldActive) {
                        setDataSetsEnergy(prev => [...prev, {
                            Type: "bar",
                            label: dev?.nick ? dev?.nick : "Importada",
                            data: data && data?.filter(disp => (disp?.numserie === dev.numserie)).map(device => device?.[fieldActive]),
                            backgroundColor: grp?.field === "eagendia" ? transparent(color?.photovol?.[i], 0.7) : transparent(color?.generalColors?.[i], 0.7),
                            borderColor: grp?.field === "eagendia" ? color?.photovol?.[i] : color?.generalColors?.[i],
                            pointBackgroundColor: grp?.field === "eagendia" ? transparent(color?.photovol?.[i], 0.7) : transparent(color?.generalColors?.[i], 0.7),
                            borderWidth: 3,
                            fill: true,
                            id: "1",
                            numserie: dev?.numserie,
                            maintainAspectRatio: "true"
                        }])
                    }
                }
                )
            }
        })
    }

    const arrayLabelToday = (date) => {
        return date?.map(dat => {
            if (currentDataToday) {
                if (esHoy(dat)) {
                    return dat
                } else { return null }
            } else {
                return dat;
            }
        }).filter(item => item !== null)
    };

    const ArrayLabelEnergy = () => {
        setLabelEnergy([]);
        setLabelEnergy((prev) => {
            const updatedLabelEnergy = [...prev];
            data?.forEach((dev) => {
                const datetime = dev?.datetime;
                if (datetime && !updatedLabelEnergy?.includes(formatDateNoSecond(datetime))) {
                    updatedLabelEnergy?.push(formatDateNoSecond(datetime));
                }
            });
            let arrayLabel = arrayLabelToday(updatedLabelEnergy)
            let arrayFinal
            if (daily === "months") {
                arrayFinal = arrayLabel?.map(numero => obtenerNombreMes(parseInt(numero)));
            } else {
                arrayFinal = arrayLabel
            }
            return arrayFinal;
        })
    }

    const tabsRed = () => {
        let newTabs = []
        if (selfConsumption === "red") {
            switch (typeDevice) {
                case dispositivos.analizador:
                case dispositivos.ccm4:
                    newTabs = tabsGraphs
                    break
                default:
                    newTabs = tabsGraphs?.filter(dev => dev?.key !== 3)
                    break
            }
        } else if (typeDevice === dispositivos?.bateria && !disabledGraphs) {
            newTabs = []
        } else {
            newTabs = tabsGraphs
        }
        setTabs(newTabs)
    }

    const defaultTabs = () => {
        let tab
        let fieldActive
        let tabsEnable = tabs?.some(item => item?.key === activeTab)
        if (tabsEnable) {
            tab = activeTab
            fieldActive = tabs?.filter(dev => dev?.key === activeTab)?.[0]?.field
        } else {
            tab = 0
            fieldActive = "pact"
            handleTab(tab, fieldActive, "line")
        }
        if (activeTab === 1 || activeTab === 2) {
            EnergyGraphsSelf(fieldActive)
            ArrayLabelEnergy()
        }
        setActiveTab(tab)
        setFields(fieldActive)
    }

    const handleTab = (tab, field, graphs) => {
        setFields(field)
        setHandleTab(tab, field)
        setGraphs(graphs)
        if (field !== "phases" && graphs === "line")
            dataGraphsPower()
        if (field === "phases") {
            dataGraphsPowerPhases()
        }
        EnergyGraphsSelf(field)
        ArrayLabelEnergy()
    }

    useEffect(() => {
        defaultTabs()
    }, [tabs]);

    useEffect(() => {
        EnergyGraphsSelf()
        ArrayLabelEnergy()
    }, [data, activeTab, devicesSelect])

    useEffect(() => {
        tabsRed()
    }, [devicesSelect, disabledGraphs])

    useEffect(() => {
        if (fields !== "phases" && graphs === "line") {
            dataGraphsPower()
        }
        if (fields === "phases") {
            dataGraphsPowerPhases()
        }
    }, [dataGraphs, devicesSelect])

    return (
        <>
            <Grid item xs={12} md={12} className="button-tab-graphs-photovoltaic">
                {tabs &&
                    <>
                        {tabs?.map((tab) => {
                            return (
                                <>
                                    <button className={activeTab === tab?.key ? "button-general-active" : "button-general"}
                                        disabled={!disabledGraphs && (tab?.field !== "eaconsdia" && tab.field !== "eagendia")}
                                        selected={activeTab === tab?.key} onClick={() => { handleTab(tab?.key, tab?.field, tab?.graphs) }}>
                                        {tab?.label}
                                    </button>
                                </>
                            )
                        })
                        }</>}
                {loading && <Loader />}
                {!loading &&
                    <Grid item xs={12} md={12} className="graph-component-selfConsumption">
                        {tabsGraphs?.map(grph => {
                            return (
                                <>
                                    {activeTab === grph?.key && grph?.graphs === "line" && dataGraphs?.length > 0 &&
                                        <GraphsLine data={dataSets} labels={formatLabel(labels)} battery={grph?.field === "soc"} />}
                                    {activeTab === grph?.key && grph?.graphs === "bar" &&
                                        <GraphsBar dataSet={dataSetsEnergy} labels={labelEnergy} />}
                                </>
                            )
                        })}
                    </Grid>
                }
            </Grid >
        </>
    )
}
