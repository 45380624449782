import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormControl, Grid } from '@mui/material'
import './DropZone.scss'

export const DropZone = ({ onFileUpload, acceptedExtensions, maxFilesAcept, disabled, messageZone, maxSize, titleFiles }) => {
    const [isMaxItemRejected, setIsMaxItemRejected] = useState(false)
    const [isSizeRejected, setIsSizeRejected] = useState(false)
    const [isTypeRejected, setIsTypeRejected] = useState(false)

    const onDrop = useCallback((acceptedFiles, rejectFiles) => {
        setIsSizeRejected(false)
        setIsTypeRejected(false)
        setIsMaxItemRejected(false)
        if (acceptedFiles?.length > 0) {
            onFileUpload(acceptedFiles)
        } else {
            onFileUpload([])
        }
        if (rejectFiles.length > 0) {
            rejectFiles[0].errors.forEach(element => {
                if (element.code === 'file-too-large') {
                    setIsSizeRejected(true)
                }
                if (element.code === 'file-invalid-type') {
                    setIsTypeRejected(true)
                }
                if(element.code === 'too-many-files') {
                    setIsMaxItemRejected(true)
                }
            })
        }
    }, [])

    const { getRootProps, getInputProps, acceptedFiles, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: acceptedExtensions,
        maxFiles: maxFilesAcept,
        maxSize: maxSize * 1024,
        disabled: disabled
    })

    const listFiles = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>)

    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <div {...getRootProps()} className={`dropzone ${isDragAccept && 'acceptFiles'} ${isDragReject && 'rejectFiles'}`}>
                    <input {...getInputProps()} />
                    {isDragActive ?
                        <p>Suelta los archivos aquí...</p>
                    :
                        <p className='text-center'>{messageZone}</p>
                    }
                </div>
                {isMaxItemRejected && 
                    <FormControl className='width-100 color-red mt-2' size="small">
                        <small className="font-weight-lighter">
                            El número de archivos seleccionados supera el máximo permitido
                        </small>
                    </FormControl>
                }
                {isSizeRejected && 
                    <FormControl className='width-100 color-red mt-2' size="small">
                        <small className="font-weight-lighter">
                            El archivo supera el límite de tamaño permitido
                        </small>
                    </FormControl>
                }
                {isTypeRejected && 
                    <FormControl className='width-100 color-red mt-2' size="small">
                        <small className="font-weight-lighter">
                            El archivo no es de un tipo permitido
                        </small>
                    </FormControl>
                }
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2'>
                {listFiles && listFiles.length > 0 &&
                    <>
                        <h4  className="color-black">{titleFiles ? titleFiles : 'Archivos'}</h4>
                        <ul className='mb-0'>{listFiles}</ul>
                    </>
                }
            </Grid>
        </Grid>
    )
}