import React, { useState, useEffect } from "react"
import { Grid, TextField } from "@mui/material"
import InputStyled from "components/Input/InputStyled"
import { Modal } from "components/Modal/Modal"
import Map from "components/Mapa/Map"
import { headersCentroControl, headersScada } from "services/headers"
import { validatorInput } from "utils/ValidatorForm"
import { dispositivos, urls } from "utils/constant"
import { convertDatetime } from "utils/Utils"
import { createToast } from "reduxSlice/toastSlice"
import { useDispatch } from "react-redux"

export const ModalViewInfoCCMasters = ({ firstModal, setFirstModal, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [newNick, setNewNick] = useState()
  const [newNotes, setNewNotes] = useState()
  const [address, setAddress] = useState("")
  const [lat, setLat] = useState()
  const [lng, setLng] = useState()
  const [devices, setDevices] = useState()
  const [isDisabled, setIsDisabled] = useState(true)
  const [isValidNick, setIsValidNick] = useState(false)
  const [isValidNote, setIsValidNote] = useState(false)
  const token = sessionStorage?.token
  const dispatch = useDispatch()

  const firstToggle = () => {
    setFirstModal(!firstModal)
  }

  let urlGetCcm = `userInfoDevice/${props.master?.numserie}`

  const getDevices = () => {
    setLoading(true)
    if (props?.master) {
      fetch(urls.ccontrol + urlGetCcm, { method: 'GET', headers: headersCentroControl(token) })
        .then(res => res.json())
        .then(data => {
          setDevices(data)
        })
        .catch(err => console.error("ERROR. API Centro de control", err))
    }
    setLoading(false)
  }

  const patchCcMaster = () => {
    setLoading(true)
    let status
    let url = `updateDeviceInfo/${props?.master?.numserie}`
    if (Number(devices?.id_tipo_dispositivo) === dispositivos.ccmaster || devices?.id_tipo_dispositivo === dispositivos.ccmabt ||
      (props?.master?.inst_type === 1 && [2, 4].includes(devices.id_tipo_dispositivo)) ||
      (props?.scada && [2, 4].includes(devices.id_tipo_dispositivo)) ||
      (props?.data?.[0]?.inst_type === 1 && [2, 4].includes(props?.data?.[0]?.id_tipo_dispositivo))) {
      url += `?idCCMaster=${props?.master?.numserie}`
    }
    let body = {
      "nick": newNick,
      "notes": newNotes,
      "latitud": lat,
      "longitud": lng,
      "address": address
    }
    if ((props?.scada && [2, 4].includes(devices.id_tipo_dispositivo)) || (props?.master?.inst_type === 1 && [2, 4].includes(devices.id_tipo_dispositivo)) || (props?.data?.[0]?.inst_type === 1 && [2, 4].includes(props?.data?.[0]?.id_tipo_dispositivo))) {
      body.kit = true
    }
    fetch(urls.scada + url, {
      method: "PATCH", headers: headersScada(token), body: JSON.stringify(body)
    })
      .then(res => {
        status = res.status
        if (res.ok) {
          firstToggle()
        }
        return res.json()
      }).then(data => {
        dispatch(createToast({ status: status, message: data.message, reload: true }))
      })
      .catch(err => {
        console.error("ERROR. API Scada", err)
      }).finally(() => {
        setLoading(false)
      })
  }

  const onSelectMarker = (lat, lng, adres) => {
    setLat(lat)
    setLng(lng)
    setAddress(adres)
  }

  const handleChangeNick = (e) => {
    setNewNick(e)
    setIsValidNick(validatorInput(e))
  }

  const handleChangeNotes = (e) => {
    setNewNotes(e === "" ? null : e)
    setIsValidNote(validatorInput(e))
  }

  useEffect(() => {
    getDevices()
  }, [props?.master, firstModal])

  useEffect(() => {
    setIsValidNick(false)
    setIsValidNote(false)
    setNewNotes(devices?.notes)
    setAddress(devices?.address)
    setNewNick(devices?.nick)
    setLat(Number(devices?.latitud))
    setLng(Number(devices?.longitud))
  }, [firstModal, devices])

  useEffect(() => {
    if (newNick && !isValidNick && !isValidNote && (
      (newNick !== props?.master?.nick) ||
      (address !== devices?.address) ||
      (newNotes !== undefined && newNotes !== devices?.notes)
    )) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [newNick, newNotes, address, isValidNick, isValidNote])


  return (
    <>
      <Modal open={firstModal} setOpen={setFirstModal} onClick={(e) => e.stopPropagation()} functSave={patchCcMaster} loading={loading} disabled={isDisabled} title="Información">
        <Grid container className="pr-5 pl-5">
          <><Grid className="pr-2 pb-3" item xs={12} md={6} lg={6} xl={6}>
            <div className='pb-1'>
              <small className="color-black">
                Número de serie:
              </small>
            </div>
            <InputStyled
              value={props?.master?.numserie}
              fullWidth={true}
              disabled={true}
              maxLength={64} />
          </Grid><Grid item xs={12} md={6} lg={6} xl={6}>
              <div className='pb-1'>
                <small className="color-black">
                  Nombre del dispositivo:
                </small>
              </div>
              <InputStyled
                error={isValidNick}
                value={newNick}
                fullWidth={true}
                onChange={handleChangeNick}
                maxLength={64} />
            </Grid><Grid item xs={12} md={12} lg={12} xl={12} className="mb-2 text-align-left">
              <div className='pb-1'>
                <small className="color-black">
                  Dirección:
                </small>
              </div>
              {address !== undefined &&
                <Map center={{ lat: lat, lng: lng }} onSelectMarker={onSelectMarker} zoom={4} address={address} utmShow={false} />}
            </Grid><Grid className="pr-2 pb-3" item xs={6} md={6} lg={6} xl={6}>
              <div className='pb-1'>
                <small className="color-black">
                  Fecha de registro:
                </small>
              </div>
              <InputStyled
                value={devices?.fecha_sync ? convertDatetime(devices?.fecha_sync) : props?.data?.[0]?.fecha_sync ? convertDatetime(props?.data?.[0]?.fecha_sync) : ''}
                disabled={true}
                fullWidth={true}
                maxLength={64} />
            </Grid><Grid item xs={6} md={6} lg={6} xl={6}>
              <div className='pb-1'>
                <small className="color-black">
                  Firmware:
                </small>
              </div>
              <InputStyled
                value={devices?.FW_version ? devices?.FW_version : "-"}
                disabled={true}
                fullWidth={true}
                maxLength={64} />
            </Grid><Grid item xs={12} md={12} lg={12} xl={12}>
              <div className='pb-1'>
                <small className="color-black">
                  Notas:
                </small>
              </div>
              <TextField
                fullWidth
                error={isValidNote}
                helperText={isValidNote && "Caracteres no permitidos"}
                className="style-textfield-text" id="outlined-multiline-static"
                onChange={(e) => handleChangeNotes(e.target.value)}
                multiline rows="4"
                value={newNotes}
                variant="outlined" />
            </Grid></>

        </Grid>
      </Modal>
    </>
  )
}