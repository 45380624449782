import React, { useState, useEffect } from 'react'
import { NoIconWeather } from 'utils/icon-centrocontrol'
import { SunnyIcon } from 'utils/icon-centrocontrol'
import { MoonIcon } from 'utils/icon-centrocontrol'
import { CloudyIcon } from 'utils/icon-centrocontrol'
import { CloudySunnyIcon } from 'utils/icon-centrocontrol'
import { RainIcon } from 'utils/icon-centrocontrol'
import { CloudyMoonyIcon } from 'utils/icon-centrocontrol'
import { RainLeftIcon } from 'utils/icon-centrocontrol'
import { RainThunderIcon } from 'utils/icon-centrocontrol'
import { SnowIcon } from 'utils/icon-centrocontrol'
import { FogIcon } from 'utils/icon-centrocontrol'

export const WeatherIcon = ({ weather, size }) => {
    const [weatherIcon, setWeatherIcon] = useState(<NoIconWeather />)

    useEffect(() => {
        switch (weather.icon) {
            case '-':
                setWeatherIcon(<NoIconWeather width={size} height={size} />)
                break;
            case '01d':
                setWeatherIcon(<SunnyIcon width={size} height={size} />)
                break;
            case '01n':
                setWeatherIcon(<MoonIcon width={size} height={size} />)
                break;
            case '02d':
                setWeatherIcon(<CloudySunnyIcon width={size} height={size} />)
                break;
            case '02n':
                setWeatherIcon(<CloudyMoonyIcon width={size} height={size} />)
                break;
            case '03d':
                setWeatherIcon(<CloudySunnyIcon width={size} height={size} />)
                break;
            case '03n':
                setWeatherIcon(<CloudyMoonyIcon width={size} height={size} />)
                break;
            case '04d':
                setWeatherIcon(<CloudyMoonyIcon width={size} height={size} />)
                break;
            case '04n':
                setWeatherIcon(<CloudyIcon width={size} height={size} />)
                break;
            case '09d':
                setWeatherIcon(<RainIcon width={size} height={size} />)
                break;
            case '09n':
                setWeatherIcon(<RainIcon width={size} height={size} />)
                break;
            case '10d':
                setWeatherIcon(<RainLeftIcon width={size} height={size} />)
                break;
            case '10n':
                setWeatherIcon(<RainLeftIcon width={size} height={size} />)
                break;
            case '11d':
                setWeatherIcon(<RainThunderIcon width={size} height={size} />)
                break;
            case '11n':
                setWeatherIcon(<RainThunderIcon width={size} height={size} />)
                break;
            case '13d':
                setWeatherIcon(<SnowIcon width={size} height={size} />)
                break;
            case '13n':
                setWeatherIcon(<SnowIcon width={size} height={size} />)
                break;
            case '50d':
                setWeatherIcon(< FogIcon width={size} height={size} />)
                break;
            case '50n':
                setWeatherIcon(< FogIcon width={size} height={size} />)
                break;
            default:
                break;
        }
    }, [weather.icon])


    return (
        <span width={size}>
            {weatherIcon}
        </span >
    )
}