import React from 'react'
import { Divider, Grid } from '@mui/material'
import { CalendarButton } from './CalendarButton'
import "./CalendarComponent.scss"

export const CalendarRowButtons = ({ selectedButton, handleToday, handleYesterday, handleMonth, handleYear, handleRangeToggle, handleClose }) => {

  const isSelect = (select) => {
    return selectedButton === select;
  }
  return (
    <>
      <Grid container className="justify-content-center pl-2 pr-2 pb-2">
        <CalendarButton title={"Hoy"} onClick={handleToday} className={isSelect("Hoy") ? "background-color-selected" : "background-color-no-selected "} variant={isSelect("Hoy") ? "contained" : "text"} />
        <CalendarButton title={"Ayer"} onClick={handleYesterday} className={isSelect("Ayer") ? "background-color-selected" : "background-color-no-selected "} variant={isSelect("Ayer") ? "contained" : "text"} />
        <CalendarButton title={"Mes"} onClick={handleMonth} className={isSelect("Mes") ? "background-color-selected" : "background-color-no-selected "} variant={isSelect("Mes") ? "contained" : "text"} />
        <CalendarButton title={"Año"} onClick={handleYear} className={isSelect("Año") ? "background-color-selected" : "background-color-no-selected "} variant={isSelect("Año") ? "contained" : "text"} />
        <CalendarButton title={"Rango"} onClick={handleRangeToggle} className={isSelect("Rango") ? "background-color-selected" : "background-color-no-selected "} variant={isSelect("Rango") ? "contained" : "text"} />
      </Grid>
      <Divider className='divider' />
      <Grid container className="justify-content-center pb-1 pt-1">
        <CalendarButton title={"Cerrar"} className="close-button" onClick={handleClose} />
      </Grid>
    </>
  )
}
