import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import { useDispatch } from 'react-redux'
import { Modal } from 'components/Modal/Modal'
import { headersScada } from 'services/headers'
import { updateAlarma } from 'reduxSlice/alarmaSlice'
import { urls } from 'utils/constant'
import { createToast } from 'reduxSlice/toastSlice'

/**
 * @param {{data: {}, setData: Function, alarma: {id: Number, alarm_id: Number, instalacion: string, nick: string, device_type: Number, numserie: Number}, index: Number, token: string, modalVisualizacion: Boolean, setModalVisualizacion: Function}}
 * @param data Estado que guarda la informacion de las alarmas activas o historicas
 * @param setData Funcion apara cambiar el estado de data
 * @param alarma Alarma seleccionada
 * @param index Indice de la alarma
 * @param modalVisualizacion Estado que controla si el modal de mostrar/ocultar alarma
 * @param setModalVisualizacion Comentario publico de la alarma
 * @param token Token para realizar la llamada
 * @returns Devuelve el comentario publico de la alarma
 */
export const VisuzalizacionAlarma = ({ data, alarma, index, modalVisualizacion, setModalVisualizacion, token }) => {
    const [success, setSuccess] = useState(false)
    const hideAlarma = alarma.hide
    const dispatch = useDispatch()

    /**
     * @param {{id: Number, alarm_id: Number, instalacion: string, nick: string, device_type: Number, numserie: Number}} alarma Alarma que se quiere ocultar o desvelar
     * @param {Number} indice Indice en el array de objetos
     * @returns Oculta o Desvela la alarma seleccionada
     */
    const fetchOcultarMostrarAlarms = async (alarma, indice) => {
        let status
        let nuevaData = [...data]
        let hide = hideAlarma === 1 ? 0 : 1
        nuevaData[indice].hide = hide
        let body = { id_alarm: alarma.id, numserie: alarma.numserie, hide: hide }
        try {
            const res = await fetch(urls.scada + 'alarms/hideAlarm', { method: 'PATCH', headers: headersScada(token), body: JSON.stringify(body) })
            if (res.status === 200) {
                setSuccess(true)
                dispatch(updateAlarma({ numserie: alarma?.numserieInstalacion ? alarma?.numserieInstalacion : alarma?.numserie, hide: hide }))
                dispatch(createToast({ status: status, message: "Alarma modificada correctamente", reload: false }))
            } else {
                dispatch(createToast({ status: status, message: "Ha habido un error al modificar la alarma", reload: false }))
            }
            setModalVisualizacion(false)
            return true
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    return (
        <div>
            <Modal fullWidth={true} open={modalVisualizacion} setOpen={setModalVisualizacion} title={`${success ?
                hideAlarma === 1 ? `Ocultar alarma "${alarma?.nick}"` : `Visualizar alarma "${alarma?.nick}"`
                :
                hideAlarma === 0 ? `Ocultar alarma "${alarma?.nick}"` : `Visualizar alarma "${alarma?.nick}"`}`} functSave={() => fetchOcultarMostrarAlarms(alarma, index)} buttonAction="Aceptar">
                <Grid container className="text-center mt-2 pr-5 pl-5">
                    <h4 className="font-weight-lighter" style={{ fontSize: '1.2rem' }}>
                        {hideAlarma === 0 ? '¿Deseas ocultar esta alarma?' : '¿Deseas hacer visible esta alarma?'}
                    </h4>
                </Grid>
                <hr className='m-0' />
            </Modal>
        </div>
    )
}