import React, { useEffect } from "react"
import Grid from "@mui/material/Grid"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import { DigitalOutputs, IconAlarm, IconControl, IconCT, IconDevice, IconWarning } from "utils/icon-centrocontrol"
import { isMobile, nombreInstalacion, iconoInstalacion } from "utils/Utils"
import { instalaciones, dispositivos } from "utils/constant"
import { Ecoresumen } from "views/Scada/SelfConsumption/General/Ecoresumen"
import { DropdownMaster } from "views/Master/MenuMaster/DropdownMaster"
import { UserPermission } from "views/User/Functions/userPermission"

export const MenuScada = ({ activeTab, devices, master, loose, selectTab, handleClick, nombreInst, masterError, activeTabCuadro, setActiveTabCuadro }) => {
  const token = sessionStorage?.token
  let mobile = isMobile(476)
  let EcoMobile = isMobile(850)
  const licenApp = JSON.parse(localStorage.getItem(token))
  let licence = licenApp?.licence
  const nameInstalation = nombreInst?.name ? nombreInst.name : devices?.nick;
  const allowedDeviceTypes = [1, 2, 4, 20]

  useEffect(() => {
    if (devices?.id_tipo_dispositivo === 0 && devices?.childrenDevices.length === 0)
      selectTab(4)
  }, [])

  const handleConfigurationTab = () => {
    selectTab(2)
    if (activeTabCuadro) {
      setActiveTabCuadro(false)
    }
  }

  return (
    <Grid container className="pl-3 pt-2">
      <Grid item xs={5} md={3} lg={3} xl={2} className="containter-title-scada">
        {master &&
          <Grid container columns={20} className="title-detail">
            <Grid item xs={4} className="flex justify-center">
              <div className="pr-2">{iconoInstalacion(devices?.inst_type)}</div>
              <div>
                <DropdownMaster token={token} scada={true} master={master} licence={licence} />
              </div>
            </Grid>
            <Grid item xs={16}>
              {nameInstalation?.length > 20 ?
                <HtmlTooltip title={<span> {nameInstalation} </span>}>
                  <a className="p-0" rel="noopener noreferrer" onClick={() => selectTab(0)}>
                    {nameInstalation?.substr(0, 17) + "..."}
                  </a>
                </HtmlTooltip>
              :
                <a className="p-0" rel="noopener noreferrer" onClick={() => selectTab(0)}>
                  {nameInstalation}
                </a>
              }
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={16} className="subtitle-project-scada mb-2">
              <p className="project-category-name">
                {" "}
                {nombreInstalacion(devices?.inst_type)}
              </p>
              <UserPermission
                project={master}
                className="subtitle-user-permissions"
              />
            </Grid>
          </Grid>
        }
      </Grid>
      <Grid item xs={8} md={5} lg={4} xl={(devices?.inst_type === instalaciones.selfConsumption || devices?.inst_type === instalaciones.photovoltaic) ? 5 : 10} className="d-flex" >
        <div className="flex">
          {((loose === 0 && devices && devices?.childrenDevices?.length > 0) || (allowedDeviceTypes?.includes(devices?.id_tipo_dispositivo))) &&
            <div
              className={Number(activeTab) === 0 ? "list-item-icon active-button pr-4" : "list-item-icon pr-4"}
              onClick={() => {
                devices?.childrenDevices?.length > 0 ?
                  selectTab(0)
                :
                  selectTab(3)
                devices?.inst_type !== 3 && handleClick("general", "0")
              }}
            >
              {devices?.inst_type === 3 ?
                <IconCT color={activeTab === 0 ? "#FF8A1F" : "#545454"} />
              :
                <IconDevice fill={Number(activeTab) === 0 ? "#FF8A1F" : "#545454"} title="inicio" />
              }
              {!mobile && <p>Inicio</p>}
            </div>
          }
          {licence !== "free" && 
            <div
              className={
                activeTab === 1
                  ? "list-item-icon active-button pr-4"
                  : "list-item-icon pr-4"
              }
              onClick={() => selectTab(1)}
              title="Alarmas" >
              <IconAlarm fill={activeTab === 1 ? "#FF8A1F" : "#545454"} />
              {!mobile && <p>Alarmas</p>}
            </div>
          }
          {devices?.inst_type !== 3 && !devices?.cloud && devices?.id_tipo_dispositivo === dispositivos.ccmaster && licence !== "free" &&
              <div
                className={
                  activeTab === 4
                    ? "list-item-icon active-button pr-4"
                    : "list-item-icon pr-4"
                }
                onClick={() => selectTab(4)}
                title="Salidas Digitales" >
                <DigitalOutputs fill={activeTab === 4 ? "#FF8A1F" : "#545454"} />
                {!mobile && <p>Salidas Digitales</p>}
              </div>
          }
          {master?.permissions !== "user" && licence !== "free" &&
              <div
                className={
                  activeTab === 2
                    ? "list-item-icon active-button pr-4"
                    : "list-item-icon pr-4"
                }
                onClick={handleConfigurationTab}
                title="Configuración">
                <IconControl fill={activeTab === 2 ? "#FF8A1F" : "#545454"} />
                {!mobile && <p className="mb-0">Configuración</p>}
                {masterError && masterError?.length > 0 && (
                  <div className="separator-left">
                    <HtmlTooltip title={<span>Revise su configuración.</span>}>
                      <IconWarning width={20} height={20} />
                    </HtmlTooltip>
                  </div>
                )}
              </div>
            }
        </div>
      </Grid>
      {((!EcoMobile && devices?.inst_type === instalaciones.selfConsumption) || devices?.inst_type === instalaciones.photovoltaic) &&
          <Grid item xs={2} md={4} lg={5} xl={5}>
            <Ecoresumen token={token} devices={devices} />
          </Grid>
      }
    </Grid>
  )
}