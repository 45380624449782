import React, { useEffect, useState } from "react"
import { Grid, MenuItem, FormControl } from "@mui/material"
import { SelectStyled } from "components/Select/SelectStyled"
import InputStyled from "components/Input/InputStyled"
import { getNameVariable, getLineVariable } from "utils/Utils"
import { validatorFloatVacio } from "utils/ValidatorForm"

export const FormCrearTramo = ({ setValidateFormTramo, objAlarmTramo, setObjAlarmTramo, tipoAlarma, tipoDispositivo }) => {
    const [nameVariable, setNameVariable] = useState([])
    const [lineVariable, setLineVariable] = useState([])
    const [valueLower, setValueLower] = useState('')
    const [valueUpper, setValueUpper] = useState('')
    const [valueExpected, setValueExpected] = useState('')
    const [selectedVar, setSelectedVar] = useState('')
    const [selectedLine, setSelectedLine] = useState('')
    const error = (valueLower !== '' && valueUpper !== '' && Number(valueLower) > Number(valueUpper))
    
    const handleSelectLine = (event) => {
        setSelectedLine(event.target.value)
        objAlarmTramo.variable_name = selectedVar + (event.target.value !== '-1' ? event.target.value : '')
        setObjAlarmTramo(objAlarmTramo)
    }

    const clearVarName = () => {
        setSelectedLine('')
        objAlarmTramo.variable_name = ''
        setObjAlarmTramo(objAlarmTramo)
    }

    const handleLowerValue = (valor) => {
        if (validatorFloatVacio(valor)) {
            setValueLower(valor)
            objAlarmTramo.lower_value = valor === '' ? undefined : parseFloat(valor)
            setObjAlarmTramo(objAlarmTramo)
        }
    }

    const handleUpperValue = (valor) => {
        if (validatorFloatVacio(valor)) {
            setValueUpper(valor)
            objAlarmTramo.upper_value = valor === '' ? undefined : parseFloat(valor)
            setObjAlarmTramo(objAlarmTramo)
        }
    }

    const handleExpectedValue = (valor) => {
        if (validatorFloatVacio(valor)) {
            setValueExpected(valor)
            objAlarmTramo.expected_value = valor === '' ? undefined : parseFloat(valor)
            setObjAlarmTramo(objAlarmTramo)
        }
    }

    useEffect(() => {
        setNameVariable(getNameVariable(tipoDispositivo))
        if(selectedVar !== '') {
            setSelectedVar('')
            clearVarName()
        }
    }, [tipoDispositivo])

    useEffect(() => {
        setSelectedVar('')
        clearVarName()
    }, [tipoAlarma])

    useEffect(() => {
        if(selectedVar !== '') {
            setLineVariable(getLineVariable(selectedVar, tipoDispositivo))
            clearVarName()
        }
    }, [selectedVar])
    
    useEffect(() => {
        if(selectedVar !== '' && selectedLine !== '' && ((valueLower !== '' && valueUpper === '') || (valueLower === '' && valueUpper !== '')  || (valueLower !== '' && valueUpper !== '' && Number(valueLower) < Number(valueUpper)))) {
            setValidateFormTramo(true)
        } else {
            setValidateFormTramo(false)
        }
    }, [selectedVar, selectedLine, valueLower, valueUpper, valueExpected])

    return (
        <Grid container>
            <Grid item xs={1} md={1} lg={1} xl={1}/>
            <Grid item xs={4} md={4} lg={4} xl={4}  className="mt-2">
                <FormControl className='width-100' size="small">
                    <small className="font-weight-lighter">
                        Seleccione una medida*:
                    </small>
                    <SelectStyled value={selectedVar} onChange={(e) => setSelectedVar(e.target.value)}  id='SelectNameVariable'>
                        {nameVariable.map((ele) => {
                            return <MenuItem value={ele.value}>{ele.name}</MenuItem>
                        })}
                    </SelectStyled>
                </FormControl>
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2}/>
            <Grid item xs={4} md={4} lg={4} xl={4} className="mt-2">
                <FormControl className='width-100' size="small">
                    <small className="font-weight-lighter">
                        Seleccione una linea*:
                    </small>
                    <SelectStyled value={selectedLine} onChange={handleSelectLine} id='SelectLineVariable' disabled={selectedVar === ''}>
                        {lineVariable.map((ele) => {
                            return <MenuItem value={ele.value}>{ele.name}</MenuItem>
                        })}
                    </SelectStyled>
                </FormControl>
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}/>
            <Grid item xs={1} md={1} lg={1} xl={1}/>
            <Grid item xs={4} md={4} lg={4} xl={4} className="mt-2">
                <small className="font-weight-lighter">
                    Introduzca valor mínimo:
                </small>
                <InputStyled value={valueLower} onChange={handleLowerValue} />
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2}/>
            <Grid item xs={4} md={4} lg={4} xl={4} className="mt-2">
                <small className="font-weight-lighter">
                    Introduzca el valor máximo:
                </small>
                <InputStyled value={valueUpper} onChange={handleUpperValue} />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}/>
            {error && 
                <>
                    <Grid item xs={1} md={1} lg={1} xl={1}/>
                    <Grid item xs={10} md={10} lg={10} xl={10} className="mt-2">
                        <FormControl className='width-100 color-red' size="small">
                            <small className="font-weight-lighter">
                                El valor máximo no puede ser superior al valor mínimo
                            </small>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1} xl={1}/>
                </>
            }
            <Grid item xs={1} md={1} lg={1} xl={1}/>
            <Grid item xs={4} md={4} lg={4} xl={4} className="mt-2">
                <small className="font-weight-lighter">
                    Introduzca el valor esperado:
                </small>
                <InputStyled value={valueExpected} onChange={handleExpectedValue} />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}/>
        </Grid>
    )
}