import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { CSVLink } from 'react-csv'
import { faSolarPanel } from '@fortawesome/free-solid-svg-icons'
import { ScadaDeviceHeader } from '../SelfConsumption/ScadaDeviceHeader'
import { SelectorFecha } from 'views/SelectorCalendar/SelectorFecha'
import { FilterScada } from 'components/Filter/FilterScada'
import { isToday } from 'utils/Utils'
import { TablePhotoSelf } from './TablePhotoSelf'
import { Loader } from 'components/Loader/Loader'
import { esHoy } from 'utils/Utils'
import { cambiarCabecera } from 'utils/funcionesCsv'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { IconDownloadFile } from 'utils/icon-centrocontrol'
import CalendarComponent from 'components/Calendar/CalendarComponent'
import { GraphsPhotovoltaic } from './GraphsPhotovoltaic'

export const Generationview = ({ devices, setSelfConsumption, idCcMaster, nickProject }) => {
    const token = sessionStorage?.token
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const [daily, setDaily] = useState("days")
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [devicesSelect, setDevicesSelect] = useState([])
    const [deviceType, setDeviceType] = useState(5)
    const [activeTabDevice, setActiveTabDevice] = useState(0)
    const [selectDaily, setSelectDaily] = useState("day")
    const childrenDevices = devices.childrenDevices && devices?.childrenDevices?.filter(dev => dev.id_tipo_dispositivo !== 7)
    let today = isToday(start)
    let currentData = today && daily === "days"
    const headerTable = today && daily === "days" ? ["Nombre", "Fecha", "Energía Generada (kWh)", "Potencia Activa (kW)"] : ["Nombre", "Fecha", "Energía Generada (kWh)"]

    const fetchData = async (numserie) => {
        let url
        if (today && daily === "days") {
            url = `getCurrentData/${numserie}?idCCMaster=${idCcMaster}`
        } else {
            url = `getHistoricalDataScada/${numserie}?idCCMaster=${idCcMaster}&iniDate=${start?.format('YYYY-MM-DD')}&endDate=${end?.format('YYYY-MM-DD')}&groupDate=${daily}`
        }
        try {
            const res = await fetch(urls?.scada + url, { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                const json = await res.json()
                return json
            } else
                return []
        } catch (error) {
            console.error("ERROR. API Scada", error)
        }
    }

    const arrayTablePhotovoltaic = async () => {
        try {
            setLoading(true);
            setData([]);
            let result = await Promise.all(childrenDevices?.map(device => fetchData(device?.numserie)));
            let arrayNew = result?.flat();
            let dataDevice = []
            if (currentData) {
                dataDevice = arrayNew?.map(dev => dev?.currentData)
            } else {
                dataDevice = arrayNew?.map(dev => {
                    let matchingDevice = devices?.childrenDevices.find(item => item?.numserie === dev?.numserie);
                    if (matchingDevice) {
                        return { ...dev, id_tipo_dispositivo: matchingDevice?.id_tipo_dispositivo }
                    }
                    return dev;
                });
            }
            setData(dataDevice.filter(dev => Number(dev?.id_tipo_dispositivo) === deviceType))
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    };

    const handleButton = () => {
        setSelfConsumption('general')
    }

    useEffect(() => {
        if (start) {
            arrayTablePhotovoltaic()
        }
    }, [start, end, daily])

    return (
        <Grid container >
            <Grid item xs={12} className="container-report-photo">
                <ScadaDeviceHeader handleButton={handleButton} icon={faSolarPanel} type="production"></ScadaDeviceHeader>
                <Grid item xs={12} className="d-flex justify-content-end align-items-center">
                    <Grid item xs={12} lg={4}>
                        <span className="generation-calendar-select">
                            <div>
                                <CalendarComponent
                                    setStart={setStart}
                                    setEnd={setEnd}
                                    maxDate={end}
                                    setDaily={setDaily}
                                    setSelectDaily={setSelectDaily}
                                />
                            </div>
                            <div>
                                <SelectorFecha
                                    daily={daily}
                                    setDaily={setDaily}
                                    tipo={daily}
                                    selectDaily={selectDaily}
                                />
                            </div>
                            {data &&
                                <CSVLink data={data ? cambiarCabecera(data, false, false, false, esHoy(start)) : []} separator={";"} style={{ color: "white", marginTop: "3px" }} filename={`${nickProject + "- Generación"}._${start ? start?.format("DD-MM-YYYY") : " "}_${end ? end?.format("DD-MM-YYYY") : " "}.csv`}>
                                    <HtmlTooltip title={<span>Descargar datos</span>}>
                                        <IconDownloadFile />
                                    </HtmlTooltip>
                                </CSVLink>
                            }
                        </span>
                    </Grid>
                </Grid>
                <span className='listItem-componente'>
                    <ul className="nav nav-tabs" >
                        {data?.filter(device => Number(device?.id_tipo_dispositivo) === 5)?.length > 0 &&
                            <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt  ${activeTabDevice === 0 ? "active-button" : null} `} selected={activeTabDevice === 0} onClick={() => { setActiveTabDevice(0); setDeviceType(5) }}>
                                Inversores
                            </li>
                        }
                        {data?.filter(device => Number(device?.id_tipo_dispositivo) === 2)?.length > 0 &&
                            <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt  ${activeTabDevice === 1 ? "active-button" : null} `} selected={activeTabDevice === 1} onClick={() => { setActiveTabDevice(1); setDeviceType(2) }}>
                                CCM2
                            </li>
                        }
                        {data?.filter(device => Number(device?.id_tipo_dispositivo) === 4)?.length > 0 &&
                            <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt  ${activeTabDevice === 2 ? "active-button" : null} `} selected={activeTabDevice === 2} onClick={() => { setActiveTabDevice(2); setDeviceType(4) }}>
                                CCM4
                            </li>
                        }
                    </ul>
                </span>
                <span>
                    <FilterScada device={childrenDevices} setDevicesSelect={setDevicesSelect} devicesSelect={devicesSelect} master={devices} />
                </span>
                {loading ? <Loader /> :
                    <TablePhotoSelf data={data} headerTable={headerTable} today={today} daily={daily} devicesSelect={devicesSelect} currentData={currentData} />
                }
                <GraphsPhotovoltaic loadingData={loading} devices={childrenDevices.filter(dev => dev.id_tipo_dispositivo === deviceType)} childrenDevices={childrenDevices} currentData={currentData} devicesSelect={devicesSelect} data={data} daily={daily} start={start} end={end} idCcMaster={idCcMaster} />
            </Grid>
        </Grid>
    )
}
