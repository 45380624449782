import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Button, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import moment from 'moment'
import { urls, dispositivos } from 'utils/constant'
import { variableTitles, variableTitlesMabt } from 'utils/Translate/variableTitles'
import { variableUnits } from 'utils/Translate/variableUnits'
import { Loader } from 'components/Loader/Loader'
import { Modal } from 'components/Modal/Modal'
import { StyledTableCell, StyledTableCellHead } from "components/Table/TableStyle"
import { StyledTableRow } from "components/Table/TableStyle"
import { SelectorTime } from 'views/SelectorCalendar/SelectorTime'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { headersScada } from 'services/headers'
import { esHoy } from 'utils/Utils'
import { IconDownloadFile } from 'utils/icon-centrocontrol'
import CalendarComponent from 'components/Calendar/CalendarComponent'

export const ModalAdvanceRegisterData = ({ modal, setModal, ...props }) => {
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [csvHeaders, setCsvHeaders] = useState()
    const [groupData, setgroupData] = useState(30)
    const [loading, setLoading] = useState(false)
    const [noData, setNoData] = useState(false)
    const [start, setStart] = useState(moment())
    const [page, setPage] = useState(0)
    const [data, setData] = useState()
    const [end, setEnd] = useState(moment())
    const variablesT = [dispositivos.ccmabt, dispositivos.ccmabtv, dispositivos.ccmabtm].includes(Number(props?.dataSliced?.id_tipo_dispositivo)) ? variableTitlesMabt : variableTitles
    const token = sessionStorage?.token
    let fields = ['datetime', 'vtn', 'pf1', 'pf2', 'pf3', 'it', 'in', 'i1', 'i2', 'i3', 'v1', 'v2', 'v3', 'pact1', 'pact2', 'pact3', 'pap1', 'pap2', 'pap3', 'preac1', 'preac2',
        'preac3', 'thdv1', 'thdv2', 'thdv3', 'thdi1', 'thdi2', 'thdi3', 'eacons', 'eaconsdia', 'eacons1', 'eacons2', 'eacons3', 'eagen', 'eagen1', 'eagen2', 'eagen3', 'ereac_q1', 'ereac_q2',
        'ereac_q3', 'ereac_q4', 'ereac1_q1', 'ereac1_q2', 'ereac1_q3', 'ereac1_q4', 'ereac2_q1', 'ereac2_q2', 'ereac2_q3', 'ereac2_q4', 'ereac3_q1', 'ereac3_q2', 'ereac3_q3',
        'ereac3_q4', 'freq1', 'freq2', 'freq3', 'vf', 'eaconsdia', 'eagendia', 'if1', 'if2', 'if3', 'vf1', 'vf2', 'vf3', 'temperatura', 'temp1', 'temp2', 'temp3', 'eapa', 'eapa1', 'eapa2', 'eapa3',
        'vdc1', 'vdc2', 'vdc3', 'vdc4', 'vdc5', 'vdc6', 'vdc7', 'vdc8', 'vdc9', 'vdc10', 'vdc11', 'vdc12', 'vdc13', 'vdc14', 'vab', 'vbc', 'vca', 'va', 'vb', 'vc',
        'idc1', 'idc2', 'idc3', 'idc4', 'idc5', 'idc6', 'idc7', 'idc8', 'idc9', 'idc10', 'idc11', 'idc12', 'idc13', 'idc14',
        'pdc1', 'pdc2', 'pdc3', 'pdc4', 'pdc5', 'pdc6', 'pdc7', 'pdc8', 'pdc9', 'pdc10', 'pdc11', 'pdc12', 'pdc13', 'pdc14',

    ]
    let headers = []

    if (data) {
        headers = Object.keys(data?.[0]).filter(value =>
            fields.includes(value)
        )
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value)
        setPage(0)
    }

    const csvMount = (tablaCSV) => {
        const variableTable = tablaCSV.map((string) => ({
            label: string === "datetime" ? variablesT[string] : variablesT[string] + ' ' + variableUnits[string],
            key: string

        }))
        setCsvHeaders(variableTable)
    }

    const fetchData = async () => {
        setLoading(true)
        let url = `getRegisterData/${props?.dataSliced?.numserie}${props?.dataSliced?.master_serial || props?.dataSliced?.mabt_serial ? `?idCCMaster=${props?.dataSliced?.master_serial ? props?.dataSliced?.master_serial : props?.dataSliced?.mabt_serial}&` : props?.dataSliced?.deviceType === dispositivos.ccmaster || props?.dataSliced?.deviceType === dispositivos.ccmabt ? `?idCCMaster=${props?.dataSliced?.numserie}&` : '?'}`
        let urlCalendar = start && end && !esHoy(start) ? `iniDate=${start?.format("YYYY-MM-DD")}&endDate=${end?.format("YYYY-MM-DD")}&groupData=${groupData}` : `groupData=${groupData}`
        try {
            const response = await fetch(
                urls.scada + url + urlCalendar + `&csv=${true}`, {
                method: 'GET',
                headers: headersScada(token)
            }).then()
            if (response.ok && response.status === 200) {
                const json = await response.json()
                setNoData(false)
                setData(json)
                setLoading(false)
            } else {
                setNoData(true)
                setLoading(false)
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const valuesFormat = (value) => {
        return value !== null ? value : ' - '
    }

    useEffect(() => {
        if (props?.dataSliced !== undefined && modal === true) {
            fetchData(props?.dataSliced?.numserie, props?.dataSliced?.master_serial, start)
        }
    }, [groupData, start, end, modal])

    useEffect(() => {
        csvMount(headers)
    }, [data])



    return (
        <Modal onClick={(e) => e.stopPropagation()} maxWidth="xl" open={modal} setOpen={setModal} title={`Datos actuales de "${props?.dataSliced?.nick}"`} footer=" " className="disabled-croll-y">
            <Grid item xs={12} md={12} lg={12} xl={12} className='flex-center justify-content-center align-items-start p-2'>
                <div className="mr-2">
                    <CalendarComponent start={start} maxDate={end} setStart={setStart} setEnd={setEnd} />
                </div>
                <div className="mr-2">
                    <SelectorTime groupData={groupData} setgroupData={setgroupData} />
                </div>
                <Button className="pt-1 pl-0" >
                    <CSVLink data={data ? data : []} headers={csvHeaders} separator={";"} filename={`${props?.dataSliced?.nick}.csv`}>
                        <HtmlTooltip title={<span>Descargar datos</span>}>
                            <IconDownloadFile />
                        </HtmlTooltip>
                    </CSVLink>
                </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                {loading ?
                    <Loader />
                    :
                    <div>
                        {!noData ?
                            <Paper>
                                <TableContainer className='scroll-x scroll-y mt-4 ml-4 mr-4 mb-2 width-height-table-modal'>
                                    <Table>
                                        <TableHead>
                                            <TableRow className='table-head-sticky'>
                                                <StyledTableCellHead>
                                                    Nombre
                                                </StyledTableCellHead>
                                                {headers.map(field => {
                                                    return (
                                                        <StyledTableCellHead>
                                                            {variablesT[field]}{' '}{variableUnits[field]}
                                                        </StyledTableCellHead>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        {!loading &&
                                            <TableBody>
                                                {data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((values) => {
                                                    return (
                                                        <StyledTableRow>
                                                            <StyledTableCell align="center">
                                                                {valuesFormat(values?.nick)}
                                                            </StyledTableCell>
                                                            {headers.map(field => {
                                                                return (
                                                                    <StyledTableCell align="center">
                                                                        {valuesFormat(values[field])}
                                                                    </StyledTableCell>
                                                                )
                                                            })}
                                                        </StyledTableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        }
                                    </Table>
                                </TableContainer>
                                {data &&
                                    <TablePagination
                                        className='flex justify-end table-pagination'
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                        labelRowsPerPage='Filas por página'
                                    />
                                }
                            </Paper>
                            :
                            <h2 className='text-center pb-5'>Sin datos</h2>
                        }
                    </div>
                }
            </Grid>
        </Modal>
    )
}