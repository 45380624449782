import { Button, Grid } from "@mui/material";
import { ArrowBack } from "utils/icon-centrocontrol";
import { translateSelfTitle } from "utils/Utils";
import { iconoTipoScada } from "utils/Utils";

export const ScadaDeviceHeader = ({ type, handleButton }) => {

    return (
        <Grid item sm={12} lg={12} xl={12} className='header-auto'>
            <span className="component-header">
                {iconoTipoScada(type)}
                <h3 className="pl-2">{translateSelfTitle(type)}</h3>
            </span>
            <span className="component-header arrow-back">
                <Button onClick={handleButton} className="button-volver" >
                    <ArrowBack />
                    <p>Volver</p>
                </Button>
            </span>
        </Grid>
    )
}