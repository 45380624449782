
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'

export const ModalConfigInyectionEms = ({ master, open, setOpen, configEms, inyectionEnable, setConfigEms, tempOff, tempRec, energyExport, logicSelf, potExp, relayEmergency, relayConfig, setInyectionEnable, emsEnable, setEmsEnableInitial }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const token = sessionStorage?.token

    const functionSave = () => {
        fetchEdit()
    }

    const fetchEdit = () => {
        setLoading(true)
        let status
        let body = {
            potmax: { enable: inyectionEnable ? false : configEms?.potmax?.enable  },
            battery: { enable: inyectionEnable ? false : configEms?.battery?.enable  },
            self_consumption: {
                enable: inyectionEnable,
                mode: logicSelf,
                enable_power_to_export: energyExport,
                power_to_export: Number(potExp),
                rele_used: relayEmergency,
                rele_conf: Number(relayConfig),
                rele_time: Number(tempOff),
                recon_time: Number(tempRec)
            },
            surplus: { enable: inyectionEnable ? false : configEms?.surplus?.enable }
        }
        fetch(urls.scada + `emsConfig/${master?.numserie}?idCCMaster=${master?.numserie}`, { method: 'PATCH', headers: headersScada(token), body: JSON.stringify(body) })
            .then(res => {
                status = res.status
                if (res.status === 200) {
                    let copyConfig = JSON.parse(JSON.stringify(configEms))
                    if (inyectionEnable) {
                        copyConfig.battery.enable = false 
                        copyConfig.surplus.enable = false
                        copyConfig.potmax.enable = false
                    }
                    copyConfig.self_consumption = {
                        enable: inyectionEnable,
                        mode: logicSelf,
                        enable_power_to_export: energyExport,
                        power_to_export: Number(potExp),
                        rele_used: relayEmergency,
                        rele_conf: Number(relayConfig),
                        rele_time: Number(tempOff),
                        recon_time: Number(tempRec)
                    }
                    setConfigEms(copyConfig)
                    setInyectionEnable(inyectionEnable)
                    dispatch(createToast({ status: status, message: "Configuración actualizada correctamente", reload: false }))
                } else {
                    dispatch(createToast({ status: status, message: "Ha habido un error al actualizar la configuración", reload: false }))
                }
            })
            .catch((error) => {
                console.error("Ha habido un erroe en API Scada", error)
            })
            .finally(() => {
                setLoading(false)
                setOpen(false)
            })
    }

    return (
        <Modal onClick={(e) => e.stopPropagation()} maxWidth="sm" open={open} setOpen={setOpen} disabled={loading} title={`Guardar configuración de ${master?.nick}`} loading={loading} functSave={functionSave} buttonAction="Aceptar" >
            <Grid item xs={12} className="text-center mt-2">
                <span>
                    <h3 style={{ fontSize: '1.2rem' }} className="font-weight-lighter">
                        ¿Estás seguro que deseas {inyectionEnable ? 'deshabilitar' : 'habilitar'} la opción de inyección 0?
                    </h3>
                </span>
            </Grid>
        </Modal>
    )
}