import { variableTitles } from 'utils/Translate/variableTitles'
import { cabecera, cabeceraConstYesterday, cabeceraMabt, cabeceraMabtYesterday } from 'utils/constantDispositivos'

export const cambiarPuntoComa = (element) => {
    for (const property in element) {
        if (typeof element[property] === 'number' && (element[property] % 1 !== 0)) {
            element[property] = element[property].toString()
            element[property] = element[property].replace(".", ",")
        }
    }
}

export const crearHearderCsv = (header, array, aceptados) => {
    Object.keys(array).forEach((elem) => {
        if (aceptados.includes(elem)) {
            header.push({ label: variableTitles[elem], key: elem })
        }
    })
}

export const cargarDatosCsv = (datos, checkedItems) => {
    if (Array.isArray(datos)) {
        let salida = []
        datos.forEach((element) => {
            let objeto = {}
            objeto = Object.assign(objeto, element)
            cambiarPuntoComa(objeto)
            if (checkedItems !== undefined) {
                for (const property in checkedItems) {
                    let variable = property.split('- ')
                    if (!checkedItems[property]) {
                        delete objeto[variable[1]]
                    }
                }
            }
            salida.push(objeto)
        })
        return salida
    }
    return datos
}

export const cambiarCabecera = (data, mabt, medidor, vtn, hoy) => {
    
    const cab = mabt && hoy ? cabeceraMabt : mabt && !hoy ? cabeceraMabtYesterday : !mabt && !hoy ? cabeceraConstYesterday : cabecera
    let salida = []
    data.forEach((element) => {
        let objeto = {}
        let objeto2 = {}
        objeto = Object.assign(objeto, element)
        cambiarPuntoComa(objeto)
        cab.forEach((ele) => {
            if (objeto[ele[0]] !== undefined) {
                if (ele[0] === 'datetime') {
                    objeto2[ele[1]] = objeto['fecha'] ? objeto['fecha'] + ' ' + objeto['datetime'] : objeto['datetime']
                } else if (ele[0] === 'nick' && (medidor || vtn)) {
                    objeto2[ele[1]] = objeto[ele[0]] ? objeto[ele[0]] : (medidor ? 'Sin nombre ' + objeto['mabt_output'] : 'VTN')
                } else {
                    objeto2[ele[1]] = objeto[ele[0]]
                }
            }
        })
        salida.push(objeto2)
    })
    return salida
}