import React from 'react'
import { Grid } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'

export const ModalInfoDevices = ({ modal, setModal, ...props }) => {
  const power = props.devices?.potencia_contratada
  const intensity = props.devices?.intensidad_magneto
  const loading = false
  const alias = props?.devices?.nick
  const isValid = false

  return (
    <Modal onClick={(e) => e.stopPropagation()} open={modal} footer={" "} setOpen={setModal} title={`Información del dispositivo "${props.devices?.nick}"`} loading={loading} disabled={isValid}>
      <Grid container className="pr-5 pl-5 mt-3">
        <Grid className="pr-2 pb-3" item xs={12} md={4} lg={4} xl={4}>
          <div className='pb-1'>
            <small className="color-black">
              Número de serie:
            </small>
          </div>
          <InputStyled
            value={props?.type === "free" ? props?.devices?.device?.numserie : props.devices?.numserie}
            disabled={true}
            fullWidth={true}
          />
        </Grid>
        <Grid className="pr-2" item xs={12} md={4} lg={4} xl={4}>
          <div className='pb-1'>
            <small className="color-black">
              Firmware de CCM:
            </small>
          </div>
          <InputStyled
            value={props?.id_tipo_dispositivo !== 0 ? props?.devices?.CCM_FW : props?.devices?.FW_version}
            disabled={true}
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4} xl={4}>
          <div className='pb-1'>
            <small className="color-black">
              Fecha de registro:
            </small>
          </div>
          <InputStyled
            value={props?.devices.fecha_sync ? props?.devices.fecha_sync : props?.devices?.data?.currentData?.fecha_sync}
            disabled={true}
            fullWidth={true}
          />
        </Grid>
        <Grid className='pr-2' item xs={12} md={4} lg={4} xl={4}>
          <div className='pb-1'>
            <small className="color-black">
              Nombre del dispositivo:
            </small>
          </div>
          <InputStyled
            value={alias}
            disabled={true}
            fullWidth={true}
            maxLength={100}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={8} xl={8}>
          <div className='pb-1'>
            <small className="color-black">
              Intensidad nominal del magneto-térmico (A):
            </small>
          </div>
          <InputStyled
            value={props?.type === "free" ? props?.devices?.device?.intensidad_magneto : intensity}
            disabled={true}
            fullWidth={true}
          />
          <small className="color-black">Verifique en el magneto-térmico la intensidad máxima soportada.</small>
        </Grid>
        <Grid item xs>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <div className='pb-1'>
            <small className="color-black">
              Potencia contratada (w):
            </small>
          </div>
          <InputStyled
            value={props?.type === "free" ? props?.devices?.device?.power : power}
            disabled={true}
            fullWidth={true}
          />
          <div className='pb-1 pb-5'>
            <small className="color-black">La potencia contratada aparece reflejada siempre en la factura de la luz.</small>
          </div>
        </Grid>
      </Grid>
    </Modal>
  )
}