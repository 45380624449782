import React, { useState } from "react"
import InputStyled from "components/Input/InputStyled"
import { validatorInput } from "utils/ValidatorForm"
import "./ConfiguracionPlanta.scss"

export const ConfiguracionDispositivo = ({ index, dispositivo, medidores, setMedidores, vtn }) => {
    const [dispo, setDispo] = useState(dispositivo.nick)
    const [isvalidVTN, setIsValidVTN] = useState(false)

    const handleCancelarDispositivo = () => {
        const nuevoEstado = medidores.filter((objeto) => objeto.numserie !== dispositivo.numserie)
        setMedidores(nuevoEstado)
        setDispo(dispositivo.nick)
        setIsValidVTN(false)
    }

    const handleOnChange = (valor) => {
        setDispo(valor)
        setIsValidVTN(validatorInput(valor))
        let medidor = medidores.find((objeto) => objeto.numserie === dispositivo.numserie)
        if (medidor) {
            if (valor === dispositivo.nick) {
                const nuevoEstado = medidores.filter((objeto) => objeto.numserie !== dispositivo.numserie)
                setMedidores(nuevoEstado)
            } else {
                const nuevoEstado = medidores.filter((objeto) => objeto.numserie !== dispositivo.numserie)
                medidor.nick = valor
                medidor.isDisabled = valor ? validatorInput(valor) : true;
                setMedidores([...nuevoEstado, medidor])
            }
        } else {
            if (vtn) {
                setMedidores((prevEstado) => [...prevEstado, { numserie: dispositivo.numserie, nick: valor, antiguo: dispositivo.nick, vtn: vtn, isDisabled: validatorInput(valor) }])
            } else {
                setMedidores((prevEstado) => [...prevEstado, { numserie: dispositivo.numserie, nick: valor, antiguo: dispositivo.nick, isDisabled: validatorInput(valor) }])
            }
        }
    }

    return (
        <div className="mb-4">
            {!vtn && <h4 style={{ fontWeight: 500 }}>{index ? index : 'Sin identificar'}:</h4>}
            <InputStyled
                classname='inputTextoConfiguracionMabt'
                value={dispo}
                maxLength={32}
                enableRefresh={dispositivo.nick !== dispo}
                onChange={handleOnChange}
                error={isvalidVTN}
                onCancelar={handleCancelarDispositivo}
            />
        </div>
    )
}