import { useEffect } from "react"
import { IconActiveMap, IconInactiveMap } from "utils/icon-centrocontrol"
import useGoogleMapMarker from "./useGoogleMapMarker"

const Marker = ({ position, type, maps, map, events, cluster, active = false, title, idMaster, icon }) => {
  const marker = useGoogleMapMarker({ position, type, maps, map, events, title, idMaster, icon })

  useEffect(() => {
    marker && cluster?.addMarker(marker)
  }, [marker, cluster])

  useEffect(() => {
    marker && active ? marker?.setIcon(IconActiveMap) : marker?.setIcon(IconInactiveMap)
  }, [active])

  return null
}
export default Marker
