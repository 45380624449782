import React, { useEffect, useState } from "react"
import { BeatLoader } from "react-spinners"
import { IconScadaEnerSim, IconEditPencil } from "utils/icon-centrocontrol"
import { convertDatetime } from "utils/Utils"
import { getStatus } from "utils/Utils"
import { urls } from "utils/constant"
import { headersCentroControl } from "services/headers"
import { ModalEditInfoSim } from "views/Modals/ModalSim/ModalEditInfoSim"
import ChartInlineEnersim from "components/Charts/ChartInLineEnersim"
import { ButtonStyled } from "components/Buttons/ButtonStyled"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import "./principalSim.scss"

export const EnerSimCard = ({ data, cardName, imei, connectionStatus, datetime, isLastEnerSim  }) => {
    const token = sessionStorage?.token
    const [openEdit, setOpenEdit] = useState(false)
    const [selectedSim, setSelectedSim] = useState(null)
    const [isCardLoading, setIsCardLoading] = useState(true)
    const [measuringData, setMeasuringData] = useState(null)
    const [lastMonth, setLastMonth] = useState(null)
    const { colorClass, status, icon } = getStatus(connectionStatus)

    const fetchMeasuringData = async () => {
        setIsCardLoading(true)
        try {
            const res = await fetch(urls.ccontrol + 'measuring', {
                method: 'POST',
                headers: headersCentroControl("bearer " + token),
                body: JSON.stringify({
                    "numserie_sim": imei,
                    "start_date": "2024-01-18T11:01:16.737Z",
                    "end_date": "2024-01-18T11:01:16.737Z"
                })
            })
            if (res.ok) {
                const measuringData = await res.json()
                if (measuringData) {
                    setMeasuringData(measuringData)
                    setLastMonth(measuringData?.measuringMonths[measuringData?.measuringMonths?.length - 1])
                }
            }
        } catch (error) {
            console.error("Error al obtener los datos", error)
        } finally {
            setIsCardLoading(false)
        }
    }

    const handleScada = (id) => {
        sessionStorage.setItem("isLastEnersim", isLastEnerSim)
        window.open(`/view/scada/${id}`, "_blank")
      }

    const handleEditClick = (sim) => {
        setSelectedSim(sim)
        setOpenEdit(true)
    }

    useEffect(() => {
        fetchMeasuringData()
    }, [])

    const totalUsageMB = lastMonth?.total_usage_mb

    return (
        <>
            <div className="sim-card-container">
                <div className="sim-card">
                    <div className="sim-header-container">
                        <div className="sim-header">
                                {icon}
                            <div className="sim-status-container">
                                <span className={`status-text ${colorClass}`}>{status}</span>
                                <span className={`status-text ${colorClass}`}>{convertDatetime(datetime)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="sim-details-container">
                        <div className="sim-details">
                             <div className="details-text flex">Nombre: 
                                <div className="pl-1">
                                    {cardName?.length > 18 ? 
                                        <HtmlTooltip title={cardName}>
                                            {cardName?.substr(0, 17) + '...'}
                                        </HtmlTooltip>
                                    : 
                                        cardName ? cardName : "-"
                                    }
                                </div>
                            </div>
                            <span onClick={() => handleEditClick(data)}>
                                <IconEditPencil className="boton-edit-hijo cursor-pointer" />
                            </span>
                        </div>
                        <span className="text-small">IMEI: {imei}</span>
                    </div>
                    <div className="chart-inline-container py-2.5">
                    {isCardLoading ?  
                        <div className="loader-container">
                            <BeatLoader  color="#ea5e0b" size={5} /> 
                        </div>
                        :
                        <ChartInlineEnersim used={totalUsageMB ? totalUsageMB : "-"} total={measuringData?.tariff} />}
                    </div>
                    <div className="sim-button-container">
                        <ButtonStyled onClick={() => handleScada(imei)} className="button-with-text" border="1px solid #FF8A1F" >
                            <IconScadaEnerSim className="icon-hover-with-text" />
                            <p className="sim-button-text">Ver detalle</p>
                        </ButtonStyled>
                    </div>
                </div>
            </div>
            <ModalEditInfoSim open={openEdit} setOpen={setOpenEdit} simData={selectedSim} />
        </>
    )
}
