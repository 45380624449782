import React, { useEffect, useState, useContext } from 'react'
import moment from "moment"
import { GlobalContext } from 'context/GlobalContext'
import { urls } from 'utils/constant'
import { CsvDownloader } from 'views/Scada/SelfConsumption/CsvDownloader'
import { headersScada } from 'services/headers'

import { CurrentDataTable } from './CurrentDataTable'
import { RegisterDataTable } from './RegisterDataTable'
import { dispositivos } from 'utils/constant'
import CalendarComponent from 'components/Calendar/CalendarComponent'

export const Tables = ({ result3, result, masterPermission, orderBy, setOrderBy, activeTab, instalacioneSeleccionadas }) => {
    const token = sessionStorage?.token
    const { actualProject } = useContext(GlobalContext)
    const [orderByValue, setOrderByValue] = useState('nick_master')
    const [data, setData] = useState([])
    const [noRepeatData, setNoRepeatData] = useState([])
    const [mabt, setMabt] = useState(false)
    let now = new Date()
    let startt = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
    let ends = moment(startt).add(1, "days").subtract(1, "seconds")
    let [start, setstart] = useState(startt)
    let [end, setend] = useState(undefined)
    const [csvData, setCsvData] = useState([])
    const [csvHeaders, setCsvHeaders] = useState()
    const [modalAlarms, setModalAlarms] = useState(false)
    const [openModalAlarms, setOpenModalAlarms] = useState({})
    const [alarms, setAlarms] = useState([])
    const [loadAlarms, setLoadAlarms] = useState(false)

    const compareNames = (orderBy, values, arrData) => {
        if (values === 'nick' || values === 'nick_master') {
            if (orderBy === 'asc') {
                arrData.sort((a, b) => {
                    if (a[values] > b[values]) {
                        return 1
                    }
                    if (a[values] < b[values]) {
                        return -1
                    }
                    return 0
                })
            } else if (orderBy === 'des') {
                arrData.sort((a, b) => {
                    if (a[values] > b[values]) {
                        return -1
                    }
                    if (a[values] < b[values]) {
                        return 1
                    }
                    return 0
                })
            }
        } else {
            if (values === 'datetime' || values === 'eaconsdia' || values === 'eagendia' || values === 'pact' || values === 'pact1' || values === 'pact2'
                || values === 'pact3' || values === 'ereac_q1' || values === 'ereac_q2' || values === 'ereac_q3' || values === 'ereac_q3' || values === 'ereact1'
                || values === 'ereact2' || values === 'ereact3' || values === 'ereac1_q1' || values === 'ereac1_q2' || values === 'ereac1_q3') {
                if (orderBy === 'asc') {
                    arrData.sort((a, b) => {
                        if (a?.data?.[0][values] > b?.data?.[0][values]) {
                            return 1
                        }
                        if (a?.data?.[0][values] < b?.data?.[0][values]) {
                            return -1
                        }
                        return 0
                    })
                } else if (orderBy === 'des') {
                    arrData.sort((a, b) => {
                        if (a?.data?.[0][values] < b?.data?.[0][values]) {
                            return 1
                        }
                        if (a?.data?.[0][values] > b?.data?.[0][values]) {
                            return -1
                        }
                        return 0
                    })
                }
            }
        }
    }


    useEffect(() => {
        setNoRepeatData([])
    }, [activeTab])

    const getAlarms = () => {
        setLoadAlarms(false)
        fetch(urls.scada + 'alarms/getConfiguredAlarms', { method: 'POST', headers: headersScada(token), body: JSON.stringify({ 'devices': instalacioneSeleccionadas }) })
            .then((res) => {
                return res.json()
            }).then(data => {
                setAlarms(data)
                setLoadAlarms(true)
            }).catch(err => console.error("ERROR. API Scada", err))
    }

    useEffect(() => {
        let fetch = result3?.filter(device => device?.id_tipo_dispositivo === dispositivos.ccmabt)
        if (fetch?.length > 0) {
            getAlarms()
        } else {
            setLoadAlarms(true)
        }
    }, [])

    return (
        <>
            {activeTab === 2 &&
                <>
                    <div className="pl-0 pr-0 pt-3 pb-2"  >
                        <span spacing={2}>
                            <span md={12} xl={12}>
                                <div className="d-flex justify-end">
                                    {/* *************CALENDARIO FILTRO******************** */}
                                    {(mabt !== true && result3.length > 0) &&
                                        <div className="grid-calendar">

                                            <CalendarComponent setStart={setstart} setEnd={setend} maxDate={end} />

                                            {csvData !== undefined &&
                                                <CsvDownloader start={start} historicalData={csvData} style={{ width: "150px" }} headers={csvHeaders} separator={";"} view="cc" name={actualProject?.nick}
                                                    filename={`${actualProject?.nick ? actualProject?.nick : " Instalaciones Genérica"}_${start}_${end}.csv`}
                                                />
                                            }
                                        </div>
                                    }
                                </div>
                            </span>
                        </span>
                    </div>
                    <div className="mb-3">
                        {
                            moment(startt.format('YYYY-MM-DD')).isAfter(start.format('YYYY-MM-DD')) ?
                                <RegisterDataTable
                                    actualProject={actualProject}
                                    compareNames={compareNames}
                                    installations={result3}
                                    objSelectedInstallations={result}
                                    masterPermission={masterPermission}
                                    start={start} end={end}
                                    startt={startt}
                                    now={now}
                                    setData={setData}
                                    data={data}
                                    setOpenModalAlarms={setOpenModalAlarms} Tables
                                    openModalAlarms={openModalAlarms}
                                    setModalAlarms={setModalAlarms}
                                    modalAlarms={modalAlarms}
                                    setstart={setstart}
                                    setend={setend}
                                    ends={ends}
                                    orderBy={orderBy}
                                    setOrderBy={setOrderBy}
                                    orderByValue={orderByValue}
                                    setOrderByValue={setOrderByValue}
                                    csvData={csvData}
                                    setCsvData={setCsvData}
                                    csvHeaders={csvHeaders} setCsvHeaders={setCsvHeaders} />
                                :
                                < CurrentDataTable
                                    loadAlarms={loadAlarms}
                                    alarms={alarms}
                                    instalacioneSeleccionadas={instalacioneSeleccionadas}
                                    mabt={mabt}
                                    setMabt={setMabt}
                                    actualProject={actualProject}
                                    noRepeatData={noRepeatData}
                                    setNoRepeatData={setNoRepeatData}
                                    compareNames={compareNames}
                                    installations={result3}
                                    objSelectedInstallations={result}
                                    masterPermission={masterPermission}
                                    start={start} end={end}
                                    startt={startt}
                                    now={now}
                                    setData={setData}
                                    data={data}
                                    setOpenModalAlarms={setOpenModalAlarms}
                                    openModalAlarms={openModalAlarms}
                                    setModalAlarms={setModalAlarms}
                                    modalAlarms={modalAlarms}
                                    setstart={setstart}
                                    setend={setend}
                                    ends={ends}
                                    orderBy={orderBy}
                                    setOrderBy={setOrderBy}
                                    orderByValue={orderByValue}
                                    setOrderByValue={setOrderByValue}
                                    activeTab={activeTab}
                                    csvData={csvData}
                                    setCsvData={setCsvData}
                                    csvHeaders={csvHeaders} setCsvHeaders={setCsvHeaders}
                                />
                        }
                    </div>
                </>}
        </>
    )
}