import React from 'react'
import { Grid } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { Modal } from 'components/Modal/Modal'
import { CSVLink } from 'react-csv'
import "./principalIpMeter.scss"

export const ModalTableDeviceDownload = ({ open, setOpen, data, loading, columns }) => {

    return (
        <>
            <Modal onClick={(e) => e.stopPropagation()} title={`Descargar tabla`} open={open} setOpen={setOpen} buttonAction="Compartir" disabled={loading} functSave={" "} footer={" "} loading={!loading}>
                <Grid container className=" text-center pr-5 pl-5">
                    <Grid className='pb-1' item xs={12} md={12} lg={12} xl={12}>
                        ¿Estas seguro que quieres descargar esta tabla de dispositivos?
                    </Grid>
                    <Grid item xs={12} className="button-table-download-device text-center">
                        <CSVLink data={data ? data : []} headers={columns} separator={";"} filename={`${"Dispositivos"}.csv`} >
                            <ButtonStyled className='button-with-text' disabled={loading}>
                                Descargar
                            </ButtonStyled>
                        </CSVLink>
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}
