export const urls = {
    energy: process.env.REACT_APP_URL_ENERGY,
    alarms: process.env.REACT_APP_URL_ALARMS,
    onoff: process.env.REACT_APP_URL_ONOFF,
    ccontrol: process.env.REACT_APP_URL_CCONTROL,
    scada: process.env.REACT_APP_URL_SCADA,
    users: process.env.REACT_APP_URL_USERS
}

export const dispositivos = {
    ccmaster: Number(process.env.REACT_APP_CCMASTER),
    ccm1: Number(process.env.REACT_APP_CCM1),
    ccm2: Number(process.env.REACT_APP_CCM2),
    ccm3: Number(process.env.REACT_APP_CCM3),
    ccm4: Number(process.env.REACT_APP_CCM4),
    inversor: Number(process.env.REACT_APP_INVERSOR),
    analizador: Number(process.env.REACT_APP_ANALIZADOR),
    planta: Number(process.env.REACT_APP_PLANTA),
    dataLogger: Number(process.env.REACT_APP_DATALOGGER),
    bateria: Number(process.env.REACT_APP_BATERIA),
    ups: Number(process.env.REACT_APP_UPS),
    bombilla: Number(process.env.REACT_APP_BOMBILLA),
    enchufe: Number(process.env.REACT_APP_ENCHUFE),
    sensor: Number(process.env.REACT_APP_SENSOR),
    ventilacion: Number(process.env.REACT_APP_VENTILACION),
    supervisor: Number(process.env.REACT_APP_SUPERVISOR),
    maquinaTermica: Number(process.env.REACT_APP_MAQUINA_TERMICA),
    termostato: Number(process.env.REACT_APP_TERMOSTATO),
    cargadorVehiculos: Number(process.env.REACT_APP_CARGADOR_VEHICULOS),
    fototermia: Number(process.env.REACT_APP_FOTOTERMIA),
    ccmabt: Number(process.env.REACT_APP_CCMABT),
    ccmabtm: Number(process.env.REACT_APP_CCMABT_M),
    persiana: Number(process.env.REACT_APP_PERSIANA),
    ccmabtv: Number(process.env.REACT_APP_CCMABT_V),
    ccmabtsl: Number(process.env.REACT_APP_CCMABT_SENSOR_LINEA)
}

export const instalaciones = {
    consumption: Number(process.env.REACT_APP_CONSUMPTION),
    selfConsumption: Number(process.env.REACT_APP_SELF_CONSUMPTION),
    photovoltaic: Number(process.env.REACT_APP_PHOTOVOLTAIC),
    mabt: Number(process.env.REACT_APP_MABT),
    lighting: Number(process.env.REACT_APP_LIGHTING)
}

export const alarmas = {
    conexionDataLogger: Number(process.env.REACT_APP_CONEXION_DATALOGGER),
    comunicacion: Number(process.env.REACT_APP_COMUNICACION),
    tramo: Number(process.env.REACT_APP_TRAMO),
    proteccion: Number(process.env.REACT_APP_PROTECCION),
    manual: Number(process.env.REACT_APP_MANUAL)
}

export const alarmasConfig = {
    conexionDataLogger: Number(process.env.REACT_APP_CONEXION_DATALOGGER),
    comunicacion: Number(process.env.REACT_APP_COMUNICACION),
    tramo: Number(process.env.REACT_APP_TRAMO)
}

export const devicesCentroControlConsumption = [
    Number(process.env.REACT_APP_CCM1),
    Number(process.env.REACT_APP_CCM2),
    Number(process.env.REACT_APP_CCM3),
    Number(process.env.REACT_APP_CCM4),
    Number(process.env.REACT_APP_INVERSOR),
    Number(process.env.REACT_APP_ANALIZADOR),
    Number(process.env.REACT_APP_ENCHUFE)
]

export const devicesAllowedTablePlant = [dispositivos.ccm1, dispositivos.ccm2, dispositivos.ccm3, dispositivos.ccm4, dispositivos.analizador, dispositivos.inversor, dispositivos.enchufe]

export const devicesAllowedPF = [dispositivos.ccm2, dispositivos.ccm4, dispositivos.analizador]

export const devicesAllowedProd = [dispositivos.ccm2, dispositivos.ccm4, dispositivos.analizador, dispositivos.inversor]

export const devicesAllowedConsumption = [dispositivos.ccm1, dispositivos.ccm2, dispositivos.ccm3, dispositivos.ccm4, dispositivos.analizador, dispositivos.enchufe]

export const monofasic = [
    Number(process.env.REACT_APP_CCM1),
    Number(process.env.REACT_APP_CCM2),
    Number(process.env.REACT_APP_ENCHUFE)
]

export const devicesMinervaConsumption = [
    Number(process.env.REACT_APP_CCMASTER),
    Number(process.env.REACT_APP_CCM1),
    Number(process.env.REACT_APP_CCM2),
    Number(process.env.REACT_APP_CCM3),
    Number(process.env.REACT_APP_CCM4),
    Number(process.env.REACT_APP_INVERSOR),
    Number(process.env.REACT_APP_ANALIZADOR),
    Number(process.env.REACT_APP_CCMABT),
    Number(process.env.REACT_APP_ENCHUFE)
]

export const devicesMinervaSelfConsumption = [
    Number(process.env.REACT_APP_CCMASTER),
    Number(process.env.REACT_APP_CCM1),
    Number(process.env.REACT_APP_CCM2),
    Number(process.env.REACT_APP_CCM3),
    Number(process.env.REACT_APP_CCM4),
    Number(process.env.REACT_APP_INVERSOR),
    Number(process.env.REACT_APP_ANALIZADOR),
    Number(process.env.REACT_APP_PLANTA),
    Number(process.env.REACT_APP_BATERIA),
    Number(process.env.REACT_APP_CCMABT),
    Number(process.env.REACT_APP_ENCHUFE)
]

export const apiMap = process.env.REACT_APP_GOOGLE_MAP

export const VERSION = '1.10.1'

export const firmware = ["0.3.17", "1.0.1", "1.0.2", "1.0.3", "1.0.4", "1.0.5", "1.0.6", "1.0.7", "1.1.0", "1.1.4", "1.1.5", "1.1.6", "1.1.7", "1.1.8", "1.1.9"]

export const valueMax = 1844674407370955

export const vertidos = 'rgb(58, 135, 29)'

export const vertidosPhoto = [
    ['rgb(58, 135, 29)'],
    ['rgb(130, 154, 30)'],
    ['rgb(135, 179, 68)'],
    ['rgb(153, 205, 133)'],
    ['rgb(196, 228, 119)'],
    ['rgb(55, 89, 29)'],
    ['rgb(58, 135, 29)'],
    ['rgb(130, 154, 30)'],
    ['rgb(135, 179, 68)'],
    ['rgb(153, 205, 133)'],
    ['rgb(196, 228, 119)'],
];

export const consumidos = 'rgb(20, 20, 255)'

export const powerColor = 'rgb(20, 36, 255)'

export const bateria = [
    ['rgb(250,129,18)', 'rgb(250,129,18, 0.2)'],
    ['rgb(250,129,38)', 'rgb(250,129,38, 0.2)'],
    ['rgb(93, 193, 185)', 'rgb(93, 193, 185, 0.2)'],
    ['rgb(190,129,08)', 'rgb(190,129,08, 0.2)'],
];

export const generalGraphs = [
    ['rgb(20, 20, 255)'], // consumida
    ['rgb(58, 135, 29)'], // generada
    ['rgb(60, 160, 255)'], // importada
    ['rgb(138,207,156)'], // vertida
];

export const generalGraphsBackground = [
    ['rgb(20, 20, 255, 0.3)'],
    ['rgb(58, 135, 29, 0.3)'],
    ['rgb(60, 160, 255, 0.3)'],
    ['rgb(138,207,156, 0.3)'],
]

export const optionsMode = [
    { label: 'Encender', value: 100 },
    { label: 'Apagar', value: 103 }
]

export const optionsRelay = [
    { label: 'Todos', value: [1, 2] },
    { label: 'Relay 1', value: [1] },
    { label: 'Relay 2', value: [2] }
]

export const SelfConsumptionOptions = {
    production: [
        {
            headers: [
                { label: "Nombre", key: "nick" },
                { label: "Fecha", key: "datetime" },
                { label: "Energía Generada (kWh)", key: "eagendia" },
                { label: "Potencia Activa (kW)", key: "pact" }
            ]
        },
        { colors: vertidos },
        { type: [{ key: dispositivos.ccm2, name: "CCM2" }, { key: dispositivos.ccm4, name: "CCM4" }, { key: dispositivos.inversor, name: "Inversor" }] },
        { tabsGraphs: [{ label: "Potencia (kW)", key: 0, field: "pact", graphs: "line" }, { label: "Energia generada(kWh)", key: 1, field: "eagendia", graphs: "bar" }] }
    ], red: [{
        headers: [
            { label: "Nombre", key: "nick" },
            { label: "Fecha", key: "datetime" },
            { label: "Energía Generada (kWh)", key: "eagendia" },
            { label: "Energía Consumida (kWh)", key: "eaconsdia" },
            { label: "Potencia Activa (kW)", key: "pact" }
        ]
    }, { colors: { vertidos: vertidos, consumidos: consumidos } },
    { type: [{ key: dispositivos.ccm2, name: "CCM2" }, { key: dispositivos.ccm4, name: "CCM4" }, { key: dispositivos.analizador, name: "Analizador" }] },
    {
        tabsGraphs: [{ label: "Potencia (kW)", key: 0, field: "pact", graphs: "line" },
        { label: "Potencia por fases (kW)", key: 3, field: "phases", graphs: "line" },
        { label: "Energía vertida (kWh)", key: 1, field: "eagendia", graphs: "bar" },
        { label: "Energía consumida (kWh)", key: 2, field: "eaconsdia", graphs: "bar" }]
    }
    ], consumption: [{
        headers: [
            { label: "Nombre", key: "nick" },
            { label: "Fecha", key: "datetime" },
            { label: "Energía Consumida (kWh)", key: "eaconsdia" },
            { label: "Potencia Activa (kW)", key: "pact" }
        ]
    },
    { colors: [consumidos] },
    { type: [{ key: dispositivos.ccm2, name: "CCM2" }, { key: dispositivos.ccm4, name: "CCM4" }, { key: dispositivos.planta, name: "Planta" }, { key: dispositivos.enchufe, name: "Enchufes" }] },
    { tabsGraphs: [{ label: "Potencia (kW)", key: 0, field: "pact", graphs: "line" }, { label: "Energia consumida (kWh)", key: 1, field: "eaconsdia", graphs: "bar" }] }],
    batery: [
        {
            headers: [
                { label: "Nombre", key: "nick" },
                { label: "Fecha", key: "datetime" },
                { label: "Intensidad (A)", key: "i" },
                { label: "Potencia  (kW)", key: "p" },
                { label: "Voltaje (V)", key: "v" },
                { label: "Carga (%)", key: "soc" },
                { label: "Carga Máxima (%)", key: "maxsoc" },
                { label: "Carga Mínima (%)", key: "minsoc" },
            ]
        },
        { colors: bateria },
        { type: [{ key: dispositivos.bateria, name: "Baterías" }] },
        { tabsGraphs: [{ label: "Potencia (kW)", key: 0, field: "p", graphs: "line" }, { label: "Tensión (V)", key: 1, field: "v", graphs: "line" }, { label: "Carga Batería (%)", key: 2, field: "soc", graphs: "line" }, { label: "Corriente (A)", key: 3, field: "i", graphs: "line" }] }]
};

export const configPlantOption = [
    { id: instalaciones.consumption, name: "Consumo" },
    { id: instalaciones.selfConsumption, name: "Autoconsumo" },
    { id: instalaciones.photovoltaic, name: "Fotovoltaico" },
    { id: instalaciones.lighting, name: "Iluminación Pública" },
]
