import { formatDate } from "utils/Utils";

export const vertidos = [
    ['rgb(58, 135, 29)', 'rgb(58, 135, 29, 0.5)'],
    ['rgb(130, 154, 30)', 'rgb(130, 154, 30, 0.5)'],
    ['rgb(135, 179, 68)', 'rgb(0, 175, 75, 0.5)'],
    ['rgb(153, 205, 133)', 'rgb(153, 205, 133, 0.5)'],
    ['rgb(196, 228, 119)', 'rgb(196, 228, 119, 0.5)'],
    ['rgb(55, 89, 29)', 'rgb(55, 89, 29, 0.2)'],
    ['rgb(58, 135, 29)', 'rgb(58, 135, 29, 0.5)'],
    ['rgb(130, 154, 30)', 'rgb(130, 154, 30, 0.5)'],
    ['rgb(135, 179, 68)', 'rgb(0, 175, 75, 0.5)'],
    ['rgb(153, 205, 133)', 'rgb(153, 205, 133, 0.5)'],
    ['rgb(196, 228, 119)', 'rgb(196, 228, 119, 0.5)'],
];

export const consumidos = [
    ['rgb(20, 20, 255)', 'rgb(20, 20, 255, 0.2)'],
    ['rgb(50, 20, 255)', 'rgb(50, 20, 255, 0.2)'],
    ['rgb(60, 60, 255)', 'rgb(60, 60, 255, 0.2)'],
    ['rgb(60, 80, 255)', 'rgb(60, 80, 255, 0.2)'],
    ['rgb(60, 100, 255)', 'rgb(60, 100, 255, 0.2)'],
    ['rgb(60, 120, 255)', 'rgb(60, 120, 255, 0.2)'],
    ['rgb(60, 160, 255)', 'rgb(60, 160, 255, 0.2)'],
    ['rgb(60, 190, 255)', 'rgb(60, 190, 255, 0.2)'],
    ['rgb(60, 210, 255)', 'rgb(60, 210, 255, 0.2)'],
    ['rgb(60, 230, 255)', 'rgb(60, 230, 255, 0.2)'],
    ['rgb(60, 250, 255)', 'rgb(60, 250, 255, 0.2)'],
    ['rgb(70, 20, 255)', 'rgb(70, 20, 255, 0.2)'],
    ['rgb(90, 50, 255)', 'rgb(90, 50, 255, 0.2)'],
    ['rgb(90, 80, 255)', 'rgb(90, 80, 255, 0.2)'],
    ['rgb(90, 110, 255)', 'rgb(90, 110, 255, 0.2)'],
    ['rgb(90, 130, 255)', 'rgb(90, 130, 255, 0.2)'],
    ['rgb(90, 160, 255)', 'rgb(90, 160, 255, 0.2)'],
    ['rgb(90, 190, 255)', 'rgb(90, 190, 255, 0.2)'],/////////////////////////////
];
/////////////////////////////////gráfica general de 4 magnitudes.
export const general = [
    ['rgb(20, 20, 255)'], // consumida
    ['rgb(58, 135, 29)'], // generada
    ['rgb(60, 160, 255)'], // importada
    ['rgb(138,207,156)'], // vertida
];

export const batería = [
    ['rgb(93, 193, 185)', 'rgb(93, 193, 185, 0.2)'],
    ['rgb(250,129,38)', 'rgb(250,129,38, 0.2)'],
    ['rgb(250,129,18)', 'rgb(250,129,18, 0.2)'],
    ['rgb(250,129,08)', 'rgb(250,129,08, 0.2)'],
];

export const cargaBatería = [
    ['rgb(93, 193, 185)', 'rgb(93, 193, 185, 0.2)'],
    ['rgb(230,129,38)', 'rgb(230,129,38, 0.2)'],
    ['rgb(210,129,18)', 'rgb(210,129,18, 0.2)'],
    ['rgb(190,129,08)', 'rgb(190,129,08, 0.2)'],
];

export const tension = [
    ['rgb(93, 193, 185)', 'rgb(93, 193, 185, 0.2)'],
    ['rgb(230,129,38)', 'rgb(230,129,38, 0.2)'],
    ['rgb(210,129,18)', 'rgb(210,129,18, 0.2)'],
    ['rgb(190,129,08)', 'rgb(190,129,08, 0.2)'],
];

export const corriente = [
    ['rgb(93, 193, 185)', 'rgb(93, 193, 185, 0.2)'],
    ['rgb(230,129,38)', 'rgb(230,129,38, 0.2)'],
    ['rgb(210,129,18)', 'rgb(210,129,18, 0.2)'],
    ['rgb(190,129,08)', 'rgb(190,129,08, 0.2)'],
];

//////////ASIGNA LOS COLORES EN FUNCIÓN DEL PARÁMETRO.
const chartComposer = (serie, obj, name) => {

    if (obj !== undefined) { ////////////////////////////////////////////////////////FILTRA QUE NO VENGA VACIÓ PARA EVITAR BLOQUEOS EN LA APP.

        if (name?.name?.[0]?.includes('Potencia')) { /////////////////////////////////////////////////////IDENTIFICA SI ES POTENCIA

            if (name?.name?.length === 3) { ////////////////FILTRA SI ES GRÁFICA RED DE TRES MAGNITUDES

                /////Vertida vendrá siempre primero
                /////y Consumida después
                /////por lo que vertidas serán las 'i' 0 Y pares,
                /////y consumidas las impares.

                for (let i = 0; i < obj?.datasets?.length; i++) {

                    if (i % 2 === 0) {

                        obj.datasets[i].borderColor = vertidos[i][0];
                        obj.datasets[i].backgroundColor = vertidos[i][1];
                        obj.datasets[i].pointBackgroundColor = vertidos[i][0];
                        obj.datasets[i].id = i;

                    } else {

                        obj.datasets[i].borderColor = consumidos[i][0];
                        obj.datasets[i].backgroundColor = consumidos[i][1];
                        obj.datasets[i].pointBackgroundColor = consumidos[i][0];
                        obj.datasets[i].id = i;
                    }
                }

            } else if (name?.name?.length === 2) { ////FILTRA SI ES GRÁFICA RED DE DOS MAGNITUDES. 

                //POTENCIA GENERADA/VERTIDA
                if (name?.name?.[0]?.includes('Vertida') || name?.name?.[0]?.includes('Generada')) {

                    for (let i = 0; i < obj?.datasets?.length; i++) {
                        obj.datasets[i].borderColor = vertidos[i][0];
                        obj.datasets[i].backgroundColor = vertidos[i][1];
                        obj.datasets[i].pointBackgroundColor = vertidos[i][0];
                        obj.datasets[i].id = i;
                    }
                    //POTENCIA CONSUMIDA
                } else if (name?.name?.[0]?.includes('Consumida')) {

                    for (let i = 0; i < obj?.datasets?.length; i++) {
                        obj.datasets[i].borderColor = consumidos[i][0];
                        obj.datasets[i].backgroundColor = consumidos[i][1];
                        obj.datasets[i].pointBackgroundColor = consumidos[i][0];
                        obj.datasets[i].id = i;

                    }
                    //SI ES GRÁFICA POTENCIA DE PANTALLA GENERAL.
                } else if (name?.name?.[1]?.includes('General')
                    && serie?.[0]?.data?.data?.[0]?.consumida
                    && serie?.[0]?.data?.data?.[0]?.vertida
                    && serie?.[0]?.data?.data?.[0]?.cargaBatería
                    && serie?.[0]?.data?.data?.[0]?.potenciaBatería) {


                    for (let i = 0; i < obj?.datasets?.length; i++) {

                        let consumida = 0;
                        let vertida = 0;
                        let p = 0;
                        let soc = 0;

                        switch (obj?.datasets?.[i]?.label) {

                            case "Producción FV (kW)":
                                obj.datasets[i].borderColor = vertidos[vertida][0];
                                obj.datasets[i].backgroundColor = vertidos[vertida][1];
                                vertida = vertida + 1;
                                break;

                            case "Consumida (kW)":
                                obj.datasets[i].borderColor = consumidos[consumida][0];
                                obj.datasets[i].backgroundColor = consumidos[consumida][1];
                                consumida = consumida + 1;
                                break;

                            case "Batería (kW)":
                                obj.datasets[i].borderColor = batería[p][0];
                                obj.datasets[i].backgroundColor = batería[p][1];
                                p = p + 1;
                                break;

                            case "Carga batería (%)":
                                obj.datasets[i].borderColor = cargaBatería[soc][0];
                                obj.datasets[i].backgroundColor = cargaBatería[soc][0];
                                obj.datasets[i].fill = false;
                                soc = soc + 1;
                                break;
                            default:
                                break;
                        };
                    }

                } else if (name?.name?.[1]?.includes('General')) {

                    if (serie?.[0]?.data?.data?.[0]?.consumida !== undefined
                        && serie?.[0]?.data?.data?.[0]?.vertida !== undefined) {////////////////////SI ES POTENCIA DE DOS MAGNITUDES

                        for (let i = 0; i < obj?.datasets?.length; i++) {

                            if (i % 2 === 0) {
                                obj.datasets[i].borderColor = vertidos[i][0];
                                obj.datasets[i].backgroundColor = vertidos[i][1];
                                obj.datasets[i].pointBackgroundColor = vertidos[i][0];
                                obj.datasets[i].id = i;
                            } else {
                                obj.datasets[i].borderColor = consumidos[i][0];
                                obj.datasets[i].backgroundColor = consumidos[i][1];
                                obj.datasets[i].pointBackgroundColor = consumidos[i][0];
                                obj.datasets[i].id = i;
                            }
                        }

                    } else if (serie?.[0]?.data?.data?.[0]?.consumida !== undefined
                        && serie?.[0]?.data?.data?.[0]?.vertida === undefined) {////////SI ES POTENCIA DE DOS MAGNITUDES - CONSUMIDA

                        for (let i = 0; i < obj?.datasets?.length; i++) {
                            obj.datasets[i].borderColor = consumidos[i][0];
                            obj.datasets[i].backgroundColor = consumidos[i][1];
                            obj.datasets[i].pointBackgroundColor = consumidos[i][0];
                            obj.datasets[i].id = i;
                        }

                    } else if (serie?.[0]?.data?.data?.[0]?.consumida === undefined
                        && serie?.[0]?.data?.data?.[0]?.vertida !== undefined) {//SI ES POTENCIA DE DOS MAGNITUDES -VERTIDA/GENERADA

                        for (let i = 0; i < obj?.datasets?.length; i++) {
                            obj.datasets[i].borderColor = vertidos[i][0];
                            obj.datasets[i].backgroundColor = vertidos[i][1];
                            obj.datasets[i].pointBackgroundColor = vertidos[i][0];
                            obj.datasets[i].id = i;
                        }
                    }
                }
            }

        } else if (serie?.[0]?.data?.data?.[0]?.consumida ////////////////////////////////////IDENTIFICA GRÁFICA GENERAL DE 4 MAGNITUDES
            && serie?.[0]?.data?.data?.[0]?.generada
            && serie?.[0]?.data?.data?.[0]?.importada
            && serie?.[0]?.data?.data?.[0]?.vertida) {

            for (let i = 0; i < obj?.datasets?.length; i++) {//VA ASGINANDO LOS COLORES A LAS MAGNITUDES ENTRANTES.

                if (obj?.datasets?.[i]?.label === 'consumida') {
                    obj.datasets[i].borderColor = general[0];
                    obj.datasets[i].backgroundColor = general[0];
                    obj.datasets[i].id = i;
                } else if (obj?.datasets?.[i]?.label === 'generada') {
                    obj.datasets[i].borderColor = general[1];
                    obj.datasets[i].backgroundColor = general[1];
                    obj.datasets[i].id = i;
                } else if (obj?.datasets?.[i]?.label === 'importada') {
                    obj.datasets[i].borderColor = general[2];
                    obj.datasets[i].backgroundColor = general[2];
                    obj.datasets[i].id = i;
                } else if (obj?.datasets?.[i]?.label === 'vertida') {
                    obj.datasets[i].borderColor = general[3];
                    obj.datasets[i].backgroundColor = general[3];
                    obj.datasets[i].id = i;
                }
            }

        } else if (name?.name?.[0]?.includes('Energía')) { ///////////////////////////////////////IDENTIFICA SI ES GRÁFICA DE ENERGÍA

            if (name?.name?.[0]?.includes('Vertida')
                || name?.name?.[0]?.includes('Generada')) {//GRÁFICA ENERGÍA VERTIDA/GENERADA

                for (let i = 0; i < obj?.datasets?.length; i++) {

                    obj.datasets[i].borderColor = vertidos[i][0];
                    obj.datasets[i].backgroundColor = vertidos[i][0];
                    obj.datasets[i].id = i;

                }

            } else if (name?.name?.[0]?.includes('Consumida')) { //GRÁFICA ENERGÍA CONSUMIDA

                for (let i = 0; i < obj?.datasets?.length; i++) {

                    obj.datasets[i].borderColor = consumidos[i][0];
                    obj.datasets[i].backgroundColor = consumidos[i][0];
                    obj.datasets[i].id = i;

                }
            }

        } else if (name?.name?.[0].includes('solar')) { /////////////////////////////////////////////IDENTIFICA SI ES RADIAL - SOLAR

            obj.datasets[0].backgroundColor = ['#35A151', 'grey'];
            obj.datasets[0].id = 1;
            obj.datasets[0].labels = ['solar', 'total'];

        } else if (name?.name?.[0].includes('consumoRadial')) { /////////////////////////////////IDENTIFICA SI ES RADIAL - SOLAR/RED

            obj.datasets[0].backgroundColor = ['#3B9FD1', '#35A151', 'grey'];
            obj.datasets[0].id = 2;
            obj.datasets[0].labels = ['red', 'solar'];

        } else if (name?.name?.[0].includes('redRadial')) { ///////////////////////////////////////////IDENTIFICA SI ES RADIAL - RED

            obj.datasets[0].backgroundColor = ['#3B9FD1', 'grey'];
            obj.datasets[0].id = 3;
            obj.datasets[0].labels = ['red', 'total'];

        } else if (name?.name?.[name?.name?.length - 1].includes('Battery')
            && obj.datasets.length > 0) {

            if (name?.name?.[0].includes('Carga batería')) {
                obj.datasets[0].borderColor = cargaBatería[0][0];
                obj.datasets[0].backgroundColor = cargaBatería[0][1];
                obj.datasets[0].id = 1;
                obj.datasets[0].fill = false
            } else if (name?.name?.[0].includes('Tensión')) {
                obj.datasets[0].borderColor = tension[0][0];
                obj.datasets[0].backgroundColor = tension[0][1];
                obj.datasets[0].id = 1;
            } else if (name?.name?.[0].includes('Batería')) {
                obj.datasets[0].borderColor = batería[0][0];
                obj.datasets[0].backgroundColor = batería[0][1];
                obj.datasets[0].id = 1;
            } else if (name?.name?.[0].includes('Corriente')) {
                obj.datasets[0].borderColor = corriente[0][0];
                obj.datasets[0].backgroundColor = corriente[0][1];
                obj.datasets[0].id = 1;
            }
        }
    }

    return obj;
}

///////EXTRAE EL LABEL, QUE PUEDEN SER HORA/S, DÍA/S, AÑO/S, MES/ES.

const labelExtractor = (serie, name) => {

    let res;
    let aux;

    if (name?.name?.[0].includes('Potencia')) { //////////////////////////////////////////////////////////IDENTIFICA SI ES POTENCIA

        if (serie?.categories !== undefined) {///////IDENTIFICA SI MODELO ENTRANTE SEGÚN CONSULTA

            res = [serie?.categories?.[0]?.slice(11)];
            aux = serie?.categories?.[0]?.slice(11);

            for (let i = 1; i < serie?.categories?.length; i++) {

                if (serie?.categories?.[i]?.slice(11) === aux) {

                    i = serie.length;

                } else {

                    res.push(serie?.categories?.[i]?.slice(11));
                }
            }

        } else if (serie?.[0]?.data?.categories) {

            res = [serie?.[0]?.data?.categories?.[0]?.slice(11)];
            aux = serie?.[0]?.data?.categories?.[0]?.slice(11);

            for (let i = 1; i < serie?.[0]?.data?.categories?.length; i++) {

                if (serie?.[0]?.data?.categories?.[i]?.slice(11) === aux) {

                    i = serie.length;

                } else {
                    res?.push(serie?.[0]?.data?.categories?.[i]?.slice(11));
                }
            }

        } else if (serie?.[0]?.categories) {/////////IDENTIFICA SI MODELO ENTRANTE SEGÚN CONSULTA

            res = [serie?.[0]?.categories?.[0]?.slice(11)];
            aux = serie?.[0]?.categories?.[0]?.slice(11);

            for (let i = 1; i < serie?.[0]?.categories?.length; i++) {

                if (serie?.[0]?.categories?.[i]?.slice(11) === aux) {

                    i = serie.length;

                } else {

                    res.push(serie?.[0]?.categories?.[i]?.slice(11));
                }
            }
        }

    } else if (serie?.[0]?.data?.categories) { ///////////////////////////////////////IDENTIFICA SI NO ES POTENCIA, QUÉ MODELO 
        res = [serie?.[0]?.data?.categories?.[0]];
        aux = serie?.[0]?.data?.categories?.[0];


        for (let i = 1; i < serie?.[0]?.data?.categories.length; i++) {

            if (serie?.[0]?.categories?.[i] === aux) {

                i = serie?.[0]?.data?.categories.length;

            } else {

                res.push(serie?.[0]?.data.categories[i]);

            }
        }

    } else { ///////////////////////////////////////////////////////////////////////IDENTIFICA SI NO ES POTENCIA, QUÉ MODELO 
        res = [formatDate(serie?.[0]?.data?.datetime)];
        aux = formatDate(serie?.[0]?.data?.datetime);
        for (let i = 1; i < serie?.length; i++) {
            if (formatDate(serie?.[i]?.data?.datetime) === aux) {
                i = serie.length;
            } else {
                res.push(serie?.[i]?.data.datetime);
            }
        }
    }
    return res;
}

///////EXTRAE LOS NICKS CON LOS QUE SE NOMBRARAN LAS DISTINTAS CONSTANTES 
///////MEDIDAS Y SU COLOR CORRESPONDIENTE A PIE DE GRÁFICAS DE ENERGÍA.

const nicksExtractor = (serie) => {

    let res = [];

    for (let i = 0; i < serie?.length; i++) {
        res.push(serie?.[i])
    }
    return res;
}

///////EXTRAE LOS NICKS CON LOS QUE SE NOMBRARAN LAS DISTINTAS CONSTANTES 
///////MEDIDAS Y SU COLOR CORRESPONDIENTE A PIE DE GRÁFICAS DE POTENCIA.

const nicksExtractorToPower = (serie) => {
    let res = [];

    for (let i = 0; i < serie?.length; i++) {

        res.push(serie?.[i] + ' Vertida')
        res.push(serie?.[i] + ' Consumida')

    }

    return res;
}

///////IDENTIFICA CUANTOS DISPOSITIVOS VAN A SER MEDIDoS Y LES ASGINA SU 
///////PROPIA CONFIGURACIÓN.

const devices = (labels, type, name, list) => {

    let res = [];

    if (name?.name?.length === 3) { //////////////////////////////////////////////IDENTIFICA SI ES POTENCIA DE RED
        for (let i = 0; i < labels.length; i++) {
            res.push({
                Type: type,
                data: [],
                label: labels[i],
                backgroundColor: '',
                borderColor: '',
                pointBackgroundColor: '',
                fill: true,
                id: '',
                pointRadius: 1,
                tension: 0.1,
                numserie: name.nSeries[i % 2 === 0 ? i / 2 : (i - 1) / 2],
                yAxisID: labels[i] === 'Carga batería (%)' && name?.name?.[name?.name?.length - 1] !== 'Battery' ? 'y1' : 'y',
            })
        }
    } else { /////////////////////////////////////////////////////////////////IDENTIFICA SI NO ES POTENCIA DE RED
        for (let i = 0; i < labels.length; i++) {
            res.push({
                Type: type,
                data: [],
                label: labels[i],
                backgroundColor: '',
                borderColor: '',
                fill: true,
                id: '',
                pointRadius: 1,
                tension: 0.1,
                numserie: name.nSeries[i],
                yAxisID: labels[i] === 'Carga batería (%)' && name?.name?.[name?.name?.length - 1] !== 'Battery' ? 'y1' : 'y',
            })
        }
    }
    return res;
}

//////DETECTA SI EN UN ARRAY HAY ALGÚN VALOR NO NULL, PARA DESCARTAR MAGNITUDES INEXISTENTES.

const valorDetector = (serie) => {
    let res = false;

    for (let i = 0; i < serie?.length; i++) {

        if (serie?.[i] !== null) {
            res = true;
        }
    }

    return res;
}

/////SACA EL NÚMERO DE DISPOSITIVOS QUE ESTÁN ENVIANDO LECTURAS.

const nDeviceExtractor = (serie, name, graphStyle, list) => {

    let res;
    let labels = [];

    if (name?.name?.[name?.name?.length - 1] === 'Producción') { ////////IDENTIFICA SI PANTALLA PRODUCCIÓN
        labels = nicksExtractor(name?.nicks);
        res = devices(labels, graphStyle, name, list);
    } else if (name?.name?.[name?.name?.length - 1] === 'Red') { ///////////////IDENTIFICA SI PANTALLA RED
        if (name?.name?.[0]?.includes('Potencia')) { ///////IDENTIFICA RED - POTENCIA
            labels = nicksExtractorToPower(name?.nicks);
            res = devices(labels, graphStyle, name, list);
        } else if (name?.name?.[0]?.includes('Energía')) {///IDENTIFICA RED - ENERGÍA
            labels = nicksExtractor(name?.nicks);
            res = devices(labels, graphStyle, name);
        }
    } else if (name?.name?.[name?.name?.length - 1] === 'Consumo') { ///////IDENTIFICA SI PANTALLA CONSUMO
        labels = nicksExtractor(name?.nicks);
        res = devices(labels, graphStyle, name, list);
    } else if (name?.name?.[name?.name?.length - 1] === 'General') { ///////IDENTIFICA SI PANTALLA GENERAL
        if (name?.name?.[0]?.includes('Potencia')) { ////////////////IDENTIFICA GENERAL - POTENCIA
            if (serie?.[0]?.data?.data?.[0]?.consumida === undefined
                && serie?.[0]?.data?.data?.[0]?.vertida !== undefined) {//TIPO POTENCIA
                labels = ['Producción FV (kWh)'];
            } else if (serie?.[0]?.data?.data?.[0]?.consumida !== undefined
                && serie?.[0]?.data?.data?.[0]?.vertida === undefined) {//TIPO POTENCIA
                labels = ['Consumida'];
            } else if (serie?.[0]?.data?.data?.[0]?.consumida !== undefined
                && serie?.[0]?.data?.data?.[0]?.vertida !== undefined
                && serie?.[0]?.data?.data?.[0]?.cargaBatería !== undefined
                && serie?.[0]?.data?.data?.[0]?.potenciaBatería !== undefined) {//TIPO POTENCIA
                labels = ['Producción FV (kW)', 'Consumida (kW)', 'Batería (kW)', 'Carga batería (%)'];
            } else {//////////////////////////////////////////////////////TIPO POTENCIA
                labels = ['Producción FV (kW)', 'Consumida (kW)'];
            }
            res = devices(labels, graphStyle, name, list);
        } else if (name?.name?.[0]?.includes('Energy')) { /////////////IDENTIFICA GENERAL - ENERGÍA
            let consumida = valorDetector(serie?.[0]?.data?.data?.[0]?.consumida)
            let generada = valorDetector(serie?.[0]?.data?.data?.[0]?.generada)
            let importada = valorDetector(serie?.[0]?.data?.data?.[0]?.importada)
            let vertida = valorDetector(serie?.[0]?.data?.data?.[0]?.vertida)
            if (consumida === true) {
                labels.push('consumida');//SI CONSUMIDA
            }
            if (generada === true) {
                labels.push('generada');////SI GENERADA
            }
            if (importada === true) {
                labels.push('importada');//SI IMPORTADA
            }
            if (vertida === true) {
                labels.push('vertida');//////SI VERTIDA
            }

            res = devices(labels, graphStyle, name, list);
        }

    } else if (name?.name?.[name?.name?.length - 1] === 'Battery') {

        switch (serie?.[0]?.data?.data?.[0]?.name) {
            case 'soc':
                labels.push('Carga batería (%)');
                break;
            default:
                break;
        };
        switch (serie?.[0]?.data?.data?.[0]?.name) {
            case 'p':
                labels.push('Batería (kW)');
                break;
            default:
                break;
        };
        switch (serie?.[0]?.data?.data?.[0]?.name) {
            case 'i':
                labels.push('Corriente (A)');
                break;
            default:
                break;
        };
        switch (serie?.[0]?.data?.data?.[0]?.name) {
            case 'v':
                labels.push('Tensión (V)');
                break;
            default:
                break;
        };

        res = devices(labels, graphStyle, name, list);

    } else if (name?.name?.[0] === 'solar'
        || name?.name?.[0] === 'consumoRadial'
        || name?.name?.[0] === 'redRadial') {//////////////////IDENTIFICA SI GRÁFICAS RADIALES

        res = [{
            Type: graphStyle,
            data: [],
            label: '',
            backgroundColor: '',
            fill: true,
            id: '',
            pointRadius: 0,
            tension: 0.1,
            numserie: '',
            cutout: '65',
            radius: '90%',
            borderWidth: 10,
            hoverOffset: 6,
            responsive: true,
            borderAlign: 'center',

        }];
    }

    return res
}

//HACE COPIA DE LOS ARRAYS ENTRANTES PARA PODER IR EXTRAYENDO 
//LOS DATOS SIN MODIFICARLOS, YA QUE SE BUSCAN REPETICIONES PARA
//IDENTIFICAR LA CANTIDAD DE DISPOSITIVOS.

const copyHistorical = (serie) => {

    let res = [];

    for (let i = 0; i < serie?.length; i++) {
        res[i] = serie?.[i]
    }
    return res;
}

//BUSCA LA POSICIÓN DE CIERTA MAGNITUD EN UN ARRAY.

const positionSeeker = (serie, tag) => {

    let res = 0;

    for (let i = 0; i < serie.length; i++) {
        if (serie?.[i]?.label === tag) {
            res = i;
        }
    }

    return res;
}

//EXTRAE LAS LECTURAS DE CADA MAGNITUD Y DISPOSITIVO, Y LAS INSERTA
//EN EL OBJETO FINAL QUE SE MANDA A LA GRÁFICA.

const datasetExtractor = (serie, obj, name, list) => {

    let copy;
    let copy2;
    let aux = obj?.labels?.length;

    if (name?.name?.[0]?.includes('Potencia')) { ////////////////////////////////////////////SI ES POTENCIA

        if (serie?.categories !== undefined) { //////////IDENTIFICA MODELO ENTRANTE SEGÚN CONSULTA

            if (serie?.data?.length > 1) {

                for (let l = 0; l < serie?.data?.length; l++) {

                    copy = copyHistorical(serie?.data?.[l]?.data)

                    let aux2 = obj?.datasets?.[l]?.data;

                    for (let k = 0; k < obj?.labels?.length; k++) {

                        let aux3 = copy?.[k];

                        aux2?.push(aux3)
                    }

                    copy?.splice(0, aux);
                }

            } else {

                copy = copyHistorical(serie?.data?.[0]?.data)

                for (let i = 0; i < obj?.datasets?.length; i++) {

                    let aux2 = obj?.datasets?.[i]?.data;

                    for (let k = 0; k < obj?.labels?.length; k++) {

                        let aux3 = copy?.[k];

                        aux2?.push(aux3)
                    }

                    copy?.splice(0, aux);
                }
            }

        } else if (serie?.[0]?.data?.[0]) { /////////////IDENTIFICA MODELO ENTRANTE SEGÚN CONSULTA
            let aux2 = null;
            for (let i = 0; i < obj?.datasets?.length; i++) {

                copy = copyHistorical(serie?.[i]?.data?.[0].data)

                for (let k = 0; k < obj?.datasets?.length; k++) {
                    if (obj?.datasets?.[k]?.numserie.toString() === serie?.[i]?.data?.[0]?.numserie) {
                        aux2 = obj?.datasets?.[k]?.data;
                    };
                };

                if (aux2 !== null) {
                    for (let k = 0; k < obj?.labels?.length; k++) {
                        let aux3 = copy?.[k];
                        aux2?.push(aux3)
                    };
                };

                copy?.splice(0, aux);
            }

        } else if (serie?.[0]?.data?.data?.[0]?.consumida
            && serie?.[0]?.data?.data?.[0]?.vertida
            && serie?.[0]?.data?.data?.[0]?.cargaBatería
            && serie?.[0]?.data?.data?.[0]?.potenciaBatería) { //IDENTIFICA MODELO ENTRANTE SEGÚN CONSULTA

            copy = copyHistorical(serie?.[0]?.data?.data?.[0]?.consumida);
            copy2 = copyHistorical(serie?.[0]?.data?.data?.[0]?.vertida);
            let copy3 = copyHistorical(serie?.[0]?.data?.data?.[0]?.potenciaBatería);
            let copy4 = copyHistorical(serie?.[0]?.data?.data?.[0]?.cargaBatería);

            for (let i = 0; i < obj?.datasets?.length;) {

                let aux2 = obj?.datasets?.[i]?.data;
                let aux3 = obj?.datasets?.[i + 1]?.data;
                let aux4 = obj?.datasets?.[i + 2]?.data;
                let aux5 = obj?.datasets?.[i + 3]?.data;

                for (let k = 0; k < obj?.labels?.length; k++) {

                    ///se invierte el orden DE COPY Y COPY2 
                    //para que producción aparezca delante de consumo.

                    let aux6 = copy2?.[k];
                    let aux7 = copy?.[k];
                    let aux8 = copy3?.[k];
                    let aux9 = copy4?.[k];
                    aux2?.push(aux6)
                    aux3?.push(aux7)
                    aux4?.push(aux8)
                    aux5?.push(aux9)
                }

                copy?.splice(0, aux);
                copy2?.splice(0, aux);
                copy3?.splice(0, aux);
                copy4?.splice(0, aux);

                i = i + 4;
            }

        } else if (serie?.[0]?.data?.data?.[0]?.consumida
            && serie?.[0]?.data?.data?.[0]?.vertida) { //IDENTIFICA MODELO ENTRANTE SEGÚN CONSULTA

            for (let i = 0; i < obj?.datasets?.length;) {

                if (serie?.[0].data?.data?.[0].nick !== undefined) {
                    let position = obj?.datasets?.[i].label.lastIndexOf(' ');
                    let name = obj?.datasets?.[i].label.slice(0, position);
                    let dispo = serie.filter(device => device.data.data[0].nick === name);
                    if (dispo.length > 0) {
                        copy = copyHistorical(dispo?.[0].data?.data?.[0]?.consumida);
                        copy2 = copyHistorical(dispo?.[0]?.data?.data?.[0]?.vertida);
                    }
                } else if (serie?.[0].data?.data?.[0].nick === undefined) {
                    copy = copyHistorical(serie?.[0].data?.data?.[0]?.consumida);
                    copy2 = copyHistorical(serie?.[0]?.data?.data?.[0]?.vertida);
                };

                let aux2 = obj?.datasets?.[i]?.data;
                let aux3 = obj?.datasets?.[i + 1]?.data;

                for (let k = 0; k < obj?.labels?.length; k++) {

                    ///se invierte el orden DE COPY Y COPY2 
                    //para que producción aparezca delante de consumo.

                    let aux4 = copy2?.[k];
                    let aux5 = copy?.[k];
                    aux2?.push(aux4)
                    aux3?.push(aux5)
                }

                copy?.splice(0, aux);
                copy2?.splice(0, aux);

                i = i + 2;
            }

        } else if (serie?.[0]?.data?.data?.[0]?.data) {

            copy = copyHistorical(serie?.[0]?.data?.data?.[0]?.data)

            for (let i = 0; i < obj?.datasets?.length; i++) {

                let aux2 = obj?.datasets?.[i]?.data;

                for (let k = 0; k < obj?.labels?.length; k++) {

                    let aux3 = copy?.[k];

                    aux2?.push(aux3)
                }

                copy?.splice(0, aux);
            }


        } else if (serie?.[0]) { ////////////////////////IDENTIFICA MODELO ENTRANTE SEGÚN CONSULTA

            //POTENCIAS DE UNA SOLA MAGNITUD - consumida
            if (serie?.[0]?.data?.data?.[0]?.consumida) {

                copy = copyHistorical(serie?.[0]?.data?.data?.[0]?.consumida);
                //POTENCIAS DE UNA SOLA MAGNITUD - vertida
            } else if (serie?.[0]?.data?.data?.[0]?.vertida) {
                copy = copyHistorical(serie?.[0]?.data?.data?.[0]?.vertida);

            }

            for (let i = 0; i < obj?.datasets?.length; i++) {

                let aux2 = obj?.datasets?.[i]?.data;

                for (let k = 0; k < obj?.labels?.length; k++) {

                    let aux3 = copy?.[k];

                    aux2?.push(aux3)
                }

                copy?.splice(0, aux);
            }
        }

    } else if (serie?.[0]?.data?.data?.[0]?.consumida /////////////////////////SI ES GRÁFICA GENERAL DE 4 MAGNITUDES
        && serie?.[0]?.data?.data?.[0]?.generada
        && serie?.[0]?.data?.data?.[0]?.importada
        && serie?.[0]?.data?.data?.[0]?.vertida) {

        // SE VERIFICA QUE VENGAN TODAS LAS MAGNITUDES PARA ADAPTARSE
        // A LAS POSIBLES VARIANTES COMO LA DE GENERALINVERTER 
        // QUE SOLO MUESTRA GENERADA
        // ASÍ QUEDA DINÁMICA DE CARA AL FUTURO.

        let counter = 0;
        let categories = [];
        let consumida = valorDetector(serie?.[0]?.data?.data?.[0]?.consumida)
        let generada = valorDetector(serie?.[0]?.data?.data?.[0]?.generada)
        let importada = valorDetector(serie?.[0]?.data?.data?.[0]?.importada)
        let vertida = valorDetector(serie?.[0]?.data?.data?.[0]?.vertida)

        if (consumida === true) {
            categories.push('consumida')
        }
        if (generada === true) {
            categories.push('generada')
        }
        if (importada === true) {
            categories.push('importada')
        }
        if (vertida === true) {
            categories.push('vertida')
        }

        //////////////////////////////////////////////////consumida
        if (consumida === true) {

            let aux0 = obj?.datasets?.[positionSeeker(obj?.datasets, 'consumida')]?.data;

            for (let k = 0; k < serie?.[0]?.data?.data?.[0]?.consumida?.length; k++) {

                let aux5 = serie?.[0]?.data?.data?.[0]?.consumida;

                aux0.push(aux5[k]);

            }

            obj.datasets[counter].label = 'consumida';
            counter = counter + 1;
        }

        //////////////////////////////////////////////////generada
        if (generada === true) {

            let aux0 = obj?.datasets?.[positionSeeker(obj?.datasets, 'generada')]?.data;

            for (let k = 0; k < serie?.[0]?.data?.data?.[0]?.generada?.length; k++) {

                let aux5 = serie?.[0]?.data?.data?.[0]?.generada;

                aux0.push(aux5[k]);

            }

            obj.datasets[counter].label = 'generada';
            counter = counter + 1;
        }

        //////////////////////////////////////////////////importada
        if (importada === true) {

            let aux0 = obj?.datasets?.[positionSeeker(obj?.datasets, 'importada')]?.data;

            for (let k = 0; k < serie?.[0]?.data?.data?.[0]?.importada?.length; k++) {

                let aux5 = serie?.[0]?.data?.data?.[0]?.importada;

                aux0.push(aux5[k]);

            }

            obj.datasets[counter].label = 'importada';
            counter = counter + 1;
        }

        ///////////////////////////////////////////////////vertida
        if (vertida === true) {

            let aux0 = obj?.datasets?.[positionSeeker(obj?.datasets, 'vertida')]?.data;

            for (let k = 0; k < serie?.[0]?.data?.data?.[0]?.vertida?.length; k++) {

                let aux5 = serie?.[0]?.data?.data?.[0]?.vertida;

                aux0.push(aux5[k]);

            }

            obj.datasets[counter].label = 'vertida';
        }

    } else if (name?.name?.[0]?.includes('Generada')
        || name?.name?.[0]?.includes('Vertida')) {//////////////////////////////////////////////////SOLO VERTIDA

        copy = copyHistorical(serie);

        for (let i = 0; i < obj?.datasets?.length; i++) {

            let aux2 = obj?.datasets?.[i]?.data;

            for (let k = 0; k < obj?.labels?.length; k++) {

                let aux3 = copy?.[k]?.data?.eagendia;

                aux2?.push(aux3)
            }

            copy?.splice(0, aux);
        }

    } else if (name?.name?.[0]?.includes('Consumida')) {///////////////////////////////////////////////////SOLO CONSUMIDA

        copy = copyHistorical(serie);

        for (let i = 0; i < obj?.datasets?.length; i++) {

            let aux2 = obj?.datasets?.[i]?.data;

            for (let k = 0; k < obj?.labels?.length; k++) {

                let aux3 = copy?.[k]?.data?.eaconsdia;

                aux2?.push(aux3)
            }

            copy?.splice(0, aux);
        }
    } else if (name?.name?.[0]?.includes('solar')) {////////////////////////////////////////////GRÁFICAS RADIALES - SOLAR
        let aux2 = obj?.datasets?.[0]?.data;
        let aux3 = serie?.[0]?.data?.data?.[0]?.solar

        aux2.push(aux3);

        aux3 = serie?.[0]?.data?.data?.[0]?.total;

        aux2.push(aux3)

    } else if (name?.name?.[0]?.includes('consumoRadial')) {/////////////////////////////GRÁFICAS RADIALES - consumoRadial

        let aux2 = obj?.datasets?.[0]?.data;
        let aux3 = serie?.[0]?.data?.data?.[0]?.autoconsumo?.[1];
        if (aux3 < 0) { aux3 = 0 }
        aux2.push(aux3);

        aux3 = serie?.[0]?.data?.data?.[0]?.autoconsumo?.[0];
        if (aux3 < 0) { aux3 = 0 }
        aux2.push(aux3)

        if (serie?.[0]?.data?.data?.[0]?.autoconsumo?.[2] !== 0) { aux2.push(serie?.[0]?.data?.data?.[0]?.autoconsumo?.[2]) }
    } else if (name?.name?.[0]?.includes('redRadial')) {/////////////////////////////////////GRÁFICAS RADIALES - redRadial

        let aux2 = obj?.datasets?.[0]?.data;
        let aux3 = serie?.[0]?.data?.data?.[0]?.red?.[1];
        if (aux3 < 0) { aux3 = 0 }

        aux2.push(aux3);

        aux3 = serie?.[0]?.data?.data?.[0]?.red?.[0];

        if (aux3 < 0) { aux3 = 0 }

        aux2.push(aux3)
    } else if (serie?.[0]?.data?.data?.[0]?.name === 'soc'
        || serie?.[0]?.data?.data?.[0]?.name === 'p'
        || serie?.[0]?.data?.data?.[0]?.name === 'i'
        || serie?.[0]?.data?.data?.[0]?.name === 'v') {

        copy = copyHistorical(serie?.[0]?.data?.data?.[0]?.data);

        for (let i = 0; i < copy.length; i++) {
            obj.datasets[0].data.push(copy[i]);
        }
    }

    return obj;
}

const cleaner = (serie, num) => {

    let res = false;

    for (let i = 0; i < serie.length; i++) {

        if (serie?.[i]?.data?.[0]?.numserie === num) {

            res = true;
        }
    }

    return res;
};

const cleanSerie = (serie) => {

    let res = [];

    for (let i = 0; i < serie?.length; i++) {

        if (cleaner(res, serie?.[i]?.data?.[0]?.numserie) === false) {

            res.push(serie?.[i]);
        }
    }

    return res;
};
////////FUNCIÓN INICIAL QUE VA ENVIANDO LOS DATOS A LAS DEMÁS FUNCIONES
////////Y DANDO LOS PASOS EN ORDEN.

export const dataFormat = (serie, name, graphStyle, list) => {


    if (serie?.[0]?.data?.[0]?.numserie !== undefined) {
        serie = cleanSerie(serie);
    }

    let aux = {
        labels: '',
        datasets: [],
    };

    if (serie !== undefined && name?.name !== undefined) { //////////////FILTRA QUE NO LLEGUEN VACIÓS PARA EVITAR BLOQUEOS EN LA APP
        aux = {
            labels: labelExtractor(serie, name),
            datasets: '',
        };
        if (name?.name?.[0]?.includes('Potencia')) {//////////////////////////////////////IDENTIFICA SI ES POTENCIA
            if (serie?.data?.[0]?.data) {//////////////////////////BUSCA MODELO SEGÚN CONSULTA
                aux.datasets = nDeviceExtractor(serie?.data?.[0]?.data, name, graphStyle, list);
            } else if (serie?.[0]?.data?.[0]?.data//////////////////BUSCA MODELO SEGÚN CONSULTA
                || serie?.[0]?.data?.eaconsdia
                || serie?.[0]?.data?.eagendia) {
                aux.datasets = nDeviceExtractor(serie, name, graphStyle, list);
            } else if (serie?.[0]?.data?.data?.[0]?.vertida
                && serie?.[0]?.data?.data?.[0]?.consumida
                && serie?.[0]?.data?.data?.[0]?.cargaBatería
                && serie?.[0]?.data?.data?.[0]?.potenciaBatería) {
                aux.datasets = nDeviceExtractor(serie, name, graphStyle, list);
            } else if (serie?.[0]?.data?.data?.[0]//////////////////BUSCA MODELO SEGÚN CONSULTA
                && (serie?.[0]?.data?.data?.[0]?.consumida
                    || serie?.[0]?.data?.data?.[0]?.vertida)) {

                aux.datasets = nDeviceExtractor(serie, name, graphStyle, list);
            } else if (serie?.[0]?.data?.data?.[0]?.data) {
                aux.datasets = nDeviceExtractor(serie, name, graphStyle, list);
            }
        } else if (name?.name?.[0]?.includes('solar')
            || name?.name?.[0]?.includes('consumoRadial')
            || name?.name?.[0]?.includes('redRadial')) {
            /////BUSCA MODELO SEGÚN CONSULTA
            aux.labels = [];
            aux.datasets = nDeviceExtractor(serie, name, graphStyle, list);
        } else {/////////////////////////////////////////////////////BUSCA MODELO SEGÚN CONSULTA
            aux.datasets = nDeviceExtractor(serie, name, graphStyle, list);
        }
        aux = datasetExtractor(serie, aux, name, list);
        aux = chartComposer(serie, aux, name)
    }
    return aux;
}

/////SACA COPIA DEL OBJETO FINAL ORIGINAL PARA 
/////PODER OPERAR CON ÉL SIN MANIPULARLO EN EL FILTRO.
const copyObject = (obj) => {

    let res = {
        datasets: [],
        labels: obj?.labels,
    }

    return res;
}

/////MODIFICA UNA COPIA DEL OBJETO FINAL PARA  
/////CUMPLIR CON LOS REQUISITOS DEL FILTRO.
export const filterList = (serie, list) => {

    let res = copyObject(serie);

    if (serie?.datasets?.length > 0 && list !== undefined) {
        for (let i = 0; i < serie?.datasets?.length; i++) {

            let aux = serie?.datasets?.[i]?.numserie;
            let aux1 = list.filter(num => num === aux);



            if (aux === aux1[0]) {
                res?.datasets?.push(serie?.datasets?.[i]);
            };
        };
        return res;
    } else {
        return serie;
    };
}


