import React, { useEffect, useState } from 'react'
import { BeatLoader } from "react-spinners"
import { Grid } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { Loader } from 'components/Loader/Loader'
import { IconWarning } from 'utils/icon-centrocontrol'

import "../CcMaster.scss"
import { ModalSwitchOnOff } from 'views/Configuracion/Calendar/Modal/ModalSwitchOnOff'

export const ManualControl = ({ setTableManual, manualControl, data, index, checkedInternal, centroControl, loading, loadingAlias, setLoadingOn, loadingOn, error, setKeySwitch, pulse, control, idCcMaster }) => {
    const [pulso, setPulso] = useState(checkedInternal(data?.[index]?.internal_mode !== 101))
    const [modal, setModal] = useState(false)
    const [salidaId, setSalidaId] = useState()

    const handleButton = () => {
        if (checkedInternal(data?.[index]?.internal_mode === 101)) {
            setPulso(false)
        } else {
            setPulso(true)
        }
        if (checkedInternal(data?.[index]?.internal_mode === 101)) {
            setTimeout(() => {
                setPulso(true)
                setKeySwitch(Date.now())
            }, 1000)
        } else if (checkedInternal(data?.[index]?.internal_mode === 102)) {
            setTimeout(() => {
                setPulso(false)
                setKeySwitch(Date.now())
            }, 1000)
        }
    }

    const handleOnOffModal = (salidaId) => {
        setSalidaId(salidaId)
        setModal(true)
    }

    const selectedSalida = data.find(item => item.ID_entry === salidaId)

    useEffect(() => {
        if (pulse) {
            handleButton()
        }
    }, [pulse])


    return (
        <>
            {data && !loading ?
                data?.map((control, i) => {
                    return (
                        <div className="layout-calendar-manual-control card-calendar-manual-control" key={i}>
                            <span className="content-textfield-manual-control">
                                {loadingAlias ?
                                    <BeatLoader color="#ea5e0b" size={10} /> :
                                    <h3>{control.prevName ? control.prevName : control.name}</h3>
                                }
                            </span>
                            <span value={control.enable} >
                                <span
                                    onClick={() => { handleOnOffModal(i + 1) }}
                                    className={
                                        `${(control?.internal_mode !== 101 && control?.internal_mode !== 102) ?
                                            checkedInternal(control?.internal_mode) ? "button-on-off-active" : "button-on-off-inactive"
                                            :
                                            control?.internal_mode === 101 ?
                                                !pulso && (control?.ID_entry === index + 1) ? "button-on-off-active" : control?.ID_entry === index + 1 ? "button-on-off-inactive" : checkedInternal(control?.internal_mode) ? "button-on-off-active" : "button-on-off-inactive"
                                                :
                                                pulso && (control?.ID_entry === index + 1) ? "button-on-off-active" : control?.ID_entry === index + 1 ? "button-on-off-inactive" : checkedInternal(control?.internal_mode) ? "button-on-off-active" : "button-on-off-inactive"
                                        } 
                                        button-on-off`}
                                >
                                    <FontAwesomeIcon icon={faPowerOff} size="xl" />
                                </span>
                            </span>
                            <span className="content-icon-clock-error-calendar">
                                {loadingOn && (control?.ID_entry === index + 1) && error && <FontAwesomeIcon icon={faClock} size="xl" className="icon-clock-on-off" />}
                                <HtmlTooltip title={<span>Ha ocurrido un error con el dispositivo</span>} className="icon-clock-on-off" >
                                    {(control?.ID_entry === index + 1) && error && <IconWarning width={20} height={20} />}
                                </HtmlTooltip>
                            </span>
                        </div >
                    )
                })
                :
                !control ?
                    <Grid item xs={12}>
                        <h2>El Master no está encendido</h2>
                    </Grid>
                    :
                    data?.lenght === 0 ?
                        <Grid item xs={12}>
                            <h2>No hay temporizadores configurados</h2>
                        </Grid>
                        :
                        <Loader />
            }
            {modal &&
                <ModalSwitchOnOff loadingOn={loadingOn} centroControl={centroControl} setTableManual={setTableManual} manualControl={manualControl} modal={modal} setModal={setModal} data={selectedSalida} setLoadingOn={setLoadingOn} idCcMaster={idCcMaster} />}
        </>

    )
}