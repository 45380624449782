import React, { useEffect, useState } from 'react'
import { Grid, Table, TableBody, TableContainer, TableHead, TableRow, Paper, FormControl } from '@mui/material'
import { StyledTableCell, StyledTableRow, StyledTableCellHead } from 'components/Table/TableStyle'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { Loader } from "components/Loader/Loader"
import { headersScada } from 'services/headers'
import { IconShowAlarms, IconCheckMail, IconCreateAlarms, IconTelegram } from 'utils/icon-centrocontrol'
import { PermissionType } from 'utils/MiddleWareProjects'
import { jwtDecode } from 'utils/JwtUtils'
import { urls } from "utils/constant.jsx"

export const ConfiguracionInformes = ({ seleccionarPermisions, tipoAlarma, token, todosDispositivos }) => {
    const idUser = jwtDecode(token)
    const [informAlarms, setInformAlarms] = useState()
    const [loading, setLoading] = useState(true)
    const [permiso, setPermiso] = useState()
    const [filaState, setFilaState] = useState()
    const [permisosAdicionales, setPermisosAdicionales] = useState(false)

    let dispositivosSelec = []
    let estadoSelec = {}
    todosDispositivos.forEach(ele => {
        if (ele.instalacion !== undefined && !dispositivosSelec.includes(ele.instalacion)) {
            dispositivosSelec.push(ele.instalacion)
        } else if (ele.numseriePadre !== undefined && !dispositivosSelec.includes(ele.numseriePadre)) {
            dispositivosSelec.push(ele.numseriePadre)
        } else if (ele.instalacion === undefined && (ele.numseriePadre === undefined)) {
            dispositivosSelec.push(ele.numserie)
        }
    })

    const estadoMultiple = {
        admin: {
            view_alarm: true,
            email_active: false,
            email_solved: false,
            telegram_active: false,
            telegram_solved: false,
            report: 0
        },
        install: {
            view_alarm: false,
            email_active: false,
            email_solved: false,
            telegram_active: false,
            telegram_solved: false,
            report: 0
        },
        user: {
            view_alarm: false,
            email_active: false,
            email_solved: false,
            telegram_active: false,
            telegram_solved: false,
            report: 0
        }
    }

    function compararPermisos(permiso1, permiso2) {
        const nivelesDePermiso = ["user", "instal", "admin"]
        const nivel1 = nivelesDePermiso.indexOf(permiso1)
        const nivel2 = nivelesDePermiso.indexOf(permiso2)
        return nivel1 >= nivel2
    }

    const handleSwitchChange = (rol, columna, permisoFila) => {
        if (compararPermisos(permiso, permisoFila)) {
            setFilaState(prevState => ({
                ...prevState,
                [rol]: {
                    ...prevState[rol],
                    [columna]: !prevState[rol][columna],
                },
            }))
        }
    }

    function checkAttributesStartWith(data) {
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                if (!key.startsWith(idUser)) {
                    return true;
                }
            }
        }
        return false;
    }

    const fetchGetSharedAlarms = async () => {
        setLoading(true)
        let objNumSerie = {}
        todosDispositivos.forEach(ele => {
            if (ele.numseriePadre === undefined && ele.instalacion === undefined) {
                objNumSerie[ele.numserie] = [ele.numserie]
            } else if (objNumSerie[ele.numseriePadre]) {
                objNumSerie[ele.numseriePadre].push(ele.numserie)
            } else if (objNumSerie[ele.instalacion]) {
                objNumSerie[ele.instalacion].push(ele.numserie)
            } else if (ele.numseriePadre !== undefined) {
                objNumSerie[ele.numseriePadre] = [ele.numserie]
            } else {
                objNumSerie[ele.instalacion] = [ele.numserie]
            }
        })
        let obj = { numserie: objNumSerie, id_alarm: tipoAlarma }
        try {
            const res = await fetch(urls.scada + 'alarms/getSharedAlarms',
                { method: 'POST', headers: headersScada(token), body: JSON.stringify(obj) })
            if (res.status === 200) {
                let json = await res.json()
                const resultObject = {}
                for (const key in json) {
                    const entries = json[key]
                    entries.forEach(entry => {
                        const uniqueKey = `${entry.id_user}_${entry.permission}`
                        if (!resultObject[uniqueKey]) {
                            resultObject[uniqueKey] = [entry]
                        }
                    })
                }
                setInformAlarms(resultObject)
                setPermisosAdicionales(checkAttributesStartWith(resultObject))
                Object.keys(json).forEach(key => {
                    json[key].forEach((fila) => {
                        if (Number(fila.id_user) === Number(idUser)) {
                            setPermiso(fila.permission)
                        }
                        estadoSelec[fila.id_user] = {
                            view_alarm: fila.view_alarm ? fila.view_alarm : fila.permission === 'admin' ? true : false,
                            email_active: fila.email_alarm_active ? fila.email_alarm_active : false,
                            email_solved: fila.email_alarm_solved ? fila.email_alarm_solved : false,
                            telegram_active: fila.telegram_alarm_active ? fila.telegram_alarm_active : false,
                            telegram_solved: fila.telegram_alarm_solved ? fila.telegram_alarm_solved : false,
                            report: 0
                        }
                    })
                })
                setFilaState(dispositivosSelec.length === 1 ? estadoSelec : estadoMultiple)
            } else {
                setLoading(false)
                return false
            }
            setLoading(false)
            return true
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    useEffect(() => {
        seleccionarPermisions(filaState)
    }, [filaState])

    useEffect(() => {
        fetchGetSharedAlarms()
    }, [todosDispositivos])

    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <h2 className='font-weight-lighter mb-2 title-in-tabs'>Permisos</h2>
                {loading &&
                    <Loader/>
                }
                {informAlarms && filaState && !loading && dispositivosSelec.length === 1 &&
                    <TableContainer component={Paper} className='w-100 mb-4'>
                        <Table aria-label="customized table">
                            <TableHead className='table-head-2em table-head'>
                                <TableRow>
                                    {dispositivosSelec.length === 1 &&
                                        <StyledTableCellHead className='table-cell-10em'>
                                            Mis Permisos
                                        </StyledTableCellHead>
                                    }
                                    <StyledTableCellHead>
                                        Rol
                                    </StyledTableCellHead>
                                    <StyledTableCellHead>
                                        <div className="table-cell-center">
                                            <HtmlTooltip title={<span>Visualización de alarma</span>}>
                                                <IconShowAlarms />
                                            </HtmlTooltip>
                                        </div>
                                    </StyledTableCellHead>
                                    <StyledTableCellHead>
                                        <div className="table-cell-center">
                                            <HtmlTooltip title={<span>Notificación de alarmas instantaneas</span>}>
                                                <IconCreateAlarms />
                                            </HtmlTooltip>
                                        </div>
                                    </StyledTableCellHead>
                                    <StyledTableCellHead>
                                        <div className="table-cell-center">
                                            <HtmlTooltip title={<span>Notificación de alarmas finalizadas</span>}>
                                                <IconCheckMail />
                                            </HtmlTooltip>
                                        </div>
                                    </StyledTableCellHead>
                                    <StyledTableCellHead>
                                         <div className="table-cell-center">
                                            <HtmlTooltip title={<span>Notificación por telegram de alarmas instantaneas</span>}>
                                                <IconTelegram />
                                            </HtmlTooltip>
                                        </div>
                                    </StyledTableCellHead>
                                    <StyledTableCellHead className="">
                                         <div className="table-cell-center">
                                            <HtmlTooltip title={<span>Notificación por telegram de alarmas finalizadas</span>}>
                                                <IconTelegram />
                                            </HtmlTooltip>
                                        </div>
                                    </StyledTableCellHead>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(informAlarms).map(key => {
                                    return (
                                        key.startsWith(idUser) ?
                                            informAlarms[key].map((fila, i) => {
                                                return (
                                                    <StyledTableRow key={i} className='table-row-2-5em table-row-personal-permissions-background'>
                                                        <StyledTableCell className='table-cell-personal-permissions-color'>
                                                            {fila.email}
                                                        </StyledTableCell>
                                                        <StyledTableCell className="table-cell-center">
                                                            <HtmlTooltip title={<span>{PermissionType(fila.permission)}</span>}>
                                                                <span className={`type-permission width-height-20px ${fila.permission}`} >
                                                                    {fila.permission.charAt(0).toUpperCase()}
                                                                </span>
                                                            </HtmlTooltip>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <SwitchStyled disabled={fila.permission === 'admin' || !compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila.id_user]?.view_alarm} onClick={() => fila.permission === 'admin' ? null : handleSwitchChange(fila.id_user, 'view_alarm', fila.permission)} />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <SwitchStyled disabled={!compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila.id_user]?.email_active} onClick={() => handleSwitchChange(fila.id_user, 'email_active', fila.permission)} />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <SwitchStyled disabled={!compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila.id_user]?.email_solved} onClick={() => handleSwitchChange(fila.id_user, 'email_solved', fila.permission)} />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <SwitchStyled disabled={!compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila.id_user]?.telegram_active} onClick={() => handleSwitchChange(fila.id_user, 'telegram_active', fila.permission)} />
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <SwitchStyled disabled={!compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila.id_user]?.telegram_solved} onClick={() => handleSwitchChange(fila.id_user, 'telegram_solved', fila.permission)} />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })
                                            :
                                            null
                                    )
                                })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                {informAlarms && filaState && (Object.keys(filaState).length === 3 || Object.keys(informAlarms).length > 1) && (Object.keys(filaState).length === 3 || permisosAdicionales) &&
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead className='table-head-2em table-head'>
                                <TableRow>
                                    {dispositivosSelec.length === 1 &&
                                        <StyledTableCellHead className='table-cell-10em'>
                                            Email
                                        </StyledTableCellHead>
                                    }
                                    <StyledTableCellHead>
                                        Rol
                                    </StyledTableCellHead>
                                    <StyledTableCellHead>
                                        <div className="table-cell-center">
                                            <HtmlTooltip title={<span>Visualización de alarma</span>}>
                                                <IconShowAlarms />
                                            </HtmlTooltip>
                                        </div>
                                    </StyledTableCellHead>
                                    <StyledTableCellHead>
                                        <div className="table-cell-center">
                                            <HtmlTooltip title={<span>Notificación de alarmas instantaneas</span>}>
                                                <IconCreateAlarms />
                                            </HtmlTooltip>
                                        </div>
                                    </StyledTableCellHead>
                                    <StyledTableCellHead>
                                        <div className="table-cell-center">
                                            <HtmlTooltip title={<span>Notificación de alarmas finalizadas</span>}>
                                                <IconCheckMail />
                                            </HtmlTooltip>
                                        </div>
                                    </StyledTableCellHead>
                                    <StyledTableCellHead>
                                        <div className="table-cell-center">
                                            <HtmlTooltip title={<span>Notificación por telegram de alarmas instantaneas</span>}>
                                                <IconTelegram />
                                            </HtmlTooltip>
                                        </div>
                                    </StyledTableCellHead>
                                    <StyledTableCellHead>
                                        <div className="table-cell-center">
                                            <HtmlTooltip title={<span>Notificación por telegram de alarmas finalizadas</span>}>
                                                <IconTelegram />
                                            </HtmlTooltip>
                                        </div>
                                    </StyledTableCellHead>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dispositivosSelec.length === 1 ?
                                    Object.keys(informAlarms).map(key => {
                                        return (
                                            !key.startsWith(idUser) ?
                                                informAlarms[key].map((fila, i) => {
                                                    return (
                                                        <StyledTableRow key={i} className='table-row-2-5em'>
                                                            <StyledTableCell>
                                                                {fila.email}
                                                            </StyledTableCell>
                                                            <StyledTableCell className="table-cell-center">
                                                                <HtmlTooltip title={<span>{PermissionType(fila.permission)}</span>}>
                                                                    <span className={`type-permission width-height-20px ${fila.permission}`} >
                                                                        {fila.permission.charAt(0).toUpperCase()}
                                                                    </span>
                                                                </HtmlTooltip>
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <SwitchStyled disabled={fila.permission === 'admin' || !compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila.id_user]?.view_alarm} onClick={() => fila.permission === 'admin' ? null : handleSwitchChange(fila.id_user, 'view_alarm', fila.permission)} />
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <SwitchStyled disabled={!compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila.id_user]?.email_active} onClick={() => handleSwitchChange(fila.id_user, 'email_active', fila.permission)} />
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <SwitchStyled disabled={!compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila.id_user]?.email_solved} onClick={() => handleSwitchChange(fila.id_user, 'email_solved', fila.permission)} />
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <SwitchStyled disabled={!compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila.id_user]?.telegram_active} onClick={() => handleSwitchChange(fila.id_user, 'telegram_active', fila.permission)} />
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <SwitchStyled disabled={!compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila.id_user]?.telegram_solved} onClick={() => handleSwitchChange(fila.id_user, 'telegram_solved', fila.permission)} />
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                })
                                                :
                                                null
                                        )
                                    })
                                    :
                                    ['admin', 'install', 'user'].map((fila, i) => {
                                        return (
                                            <StyledTableRow key={i} className='table-row-2-5em'>
                                                <StyledTableCell className="table-cell-center">
                                                    <HtmlTooltip title={<span>{PermissionType(fila)}</span>}>
                                                        <span className={`type-permission width-height-20px ${fila}`} >
                                                            {fila.charAt(0).toUpperCase()}
                                                        </span>
                                                    </HtmlTooltip>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <SwitchStyled disabled={fila.permission === 'admin' || !compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila]?.view_alarm} onClick={() => fila === 'admin' ? null : handleSwitchChange(fila, 'view_alarm', fila)} />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <SwitchStyled disabled={!compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila]?.email_active} onClick={() => handleSwitchChange(fila, 'email_active', fila)} />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <SwitchStyled disabled={!compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila]?.email_solved} onClick={() => handleSwitchChange(fila, 'email_solved', fila)} />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <SwitchStyled disabled={!compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila.id_user]?.telegram_active} onClick={() => handleSwitchChange(fila, 'telegram_active', fila)} />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <SwitchStyled disabled={!compararPermisos(permiso, fila.permission)} className='switch-small' checked={filaState[fila.id_user]?.telegram_solved} onClick={() => handleSwitchChange(fila, 'telegram_solved', fila)} />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2'>
                <FormControl className='width-100 color-red' size="small">
                    <small className="font-weight-lighter">
                        Advertencia: para las notificaciones por telegram sera necesario que el usuario añada el id de telegram en su perfil
                    </small>
                </FormControl>
            </Grid>
        </Grid>
    )
}