import React, { useState, useEffect } from "react"
import { FormControl, Grid, MenuItem } from "@mui/material"
import { SelectStyled } from "components/Select/SelectStyled"
import { Modal } from "components/Modal/Modal"
 
export const ModalMoveDeviceRelay = ({ open, setOpen, configuration, nameDevices, setNameDevices, deviceType, dispositivo, setClonedDevice, setColumns, clonedDevice, moves, setMoves }) => {
  const [selectedDevice, setSelectedDevice] = useState(null)
 
  const handleCancel = () => {
    if (clonedDevice) {
      const updatedMoves = { ...moves }
      const itemType = clonedDevice?.device_type
      const itemId = clonedDevice?.id
      setColumns((prevColumns) => {
        const updatedColumns = {}
        Object?.keys(prevColumns)?.forEach((key) => {
          const column = prevColumns[key]
          const updatedSubColumns = column?.columns?.map((col) => {
            return {
              ...col,
              // Filtrar los items para eliminar el dispositivo clonado si se cancela
              items: col?.items?.filter((item) => item?.id !== itemId),
            }
          })
          updatedColumns[key] = {
            ...column,
            columns: updatedSubColumns,
          }
        })
        return {
          ...prevColumns,
          ...updatedColumns,
        }
      })
      if (itemType !== 25 && itemType !== 26) {
        updatedMoves[itemType] = updatedMoves?.[itemType] ? updatedMoves?.[itemType] + 1 : 1
      }
      setClonedDevice(null)
      setMoves(updatedMoves)
    }
    setOpen(!open)
  }
 
  const handleDeviceChange = (event) => {
    setSelectedDevice(event?.target?.value)
  }
 
  const handleSave = () => {
    const selectedDeviceInfo = nameDevices?.find(device => device?.numserie === selectedDevice)
    if (selectedDeviceInfo) {
      const updatedClonedDevice = { ...clonedDevice, name: selectedDeviceInfo?.nick }
      setClonedDevice(updatedClonedDevice)
      // Actualizar las columnas
      setColumns(prevColumns => {
        const updatedColumns = { ...prevColumns }
        Object?.keys(updatedColumns)?.forEach(columnKey => {
          const column = updatedColumns?.[columnKey]
          if (column?.items) {
            column.items = column?.items?.map(item =>
              item?.id === updatedClonedDevice?.id
                ? { ...item, name: selectedDeviceInfo?.nick, numserie: selectedDeviceInfo?.numserie, isMoved: true }
                : item
            )
          }
          if (column?.columns) {
            column.columns = column?.columns?.map(subColumn => ({
              ...subColumn,
              items: subColumn?.items?.map(item =>
                item?.id === updatedClonedDevice?.id
                  ? { ...item, name: selectedDeviceInfo?.nick, numserie: selectedDeviceInfo?.numserie, isMoved: true }
                  : item
              )
            }))
          }
        })
        return updatedColumns
      })
      // Eliminar el dispositivo seleccionado de la lista de dispositivos
      setNameDevices(prevNameDevices =>
        prevNameDevices?.filter(device => device?.numserie !== selectedDevice)
      )
    }
    setOpen(!open)
  }
 
  useEffect(() => {
    const nameDevicesTemp = []
    const devices = dispositivo?.[0]?.childrenDevices
  
    const configuredNumseries = []
    configuration?.forEach(relay => {
      relay?.forEach(column => {
        column?.forEach(device => {
          if (device && device?.numserie && device?.icon_type !== 25 && device?.icon_type !== 26) {
            configuredNumseries?.push(device.numserie)
          }
        })
      })
    })
    devices?.forEach(device => {
      const typeId = device?.id_tipo_dispositivo
      if (typeId === deviceType && !configuredNumseries?.includes(device.numserie)) {
        nameDevicesTemp?.push({ nick: device?.nick, numserie: device?.numserie })
      }
      if (device?.childrenDevices) {
        device?.childrenDevices?.forEach(child => {
          const childTypeId = child?.id_tipo_dispositivo
          if (childTypeId === deviceType && !configuredNumseries?.includes(child?.numserie)) {
            nameDevicesTemp?.push({ nick: child?.nick, numserie: child?.numserie })
          }
        })
      }
    })
    if (
      dispositivo?.[0]?.id_tipo_dispositivo !== null &&
      dispositivo?.[0]?.id_tipo_dispositivo !== undefined
    ) {
      const typeId = dispositivo?.[0]?.id_tipo_dispositivo
      if (typeId === deviceType && !configuredNumseries?.includes(dispositivo[0]?.numserie)) {
        nameDevicesTemp?.push({
          nick: dispositivo?.[0]?.nick,
          numserie: dispositivo?.[0]?.numserie,
        })
      }
    }
    setNameDevices(nameDevicesTemp)
  }, [deviceType, dispositivo, configuration]) 
 
  useEffect(() => {
    if (nameDevices?.length > 0) {
      setSelectedDevice(nameDevices[0]?.numserie)
    }
  }, [nameDevices])
 
  return (
    <Modal open={open} setOpen={setOpen} buttonAction="Aceptar" title="Mover dispositivo" functSave={handleSave} functCancel={handleCancel}>
      <Grid container className="text-center mt-2 pr-5 pl-5">
        <h4 className="font-weight-lighter modal-font">
          ¿Qué dispositivo deseas mover?
        </h4>
        <FormControl className="width-100 text-start" size="small">
          <SelectStyled value={selectedDevice} onChange={handleDeviceChange}>
            {nameDevices?.map((device, index) => (
              <MenuItem value={device?.numserie} key={index}>
                {device?.nick}
              </MenuItem>
            ))}
          </SelectStyled>
        </FormControl>
      </Grid>
    </Modal>
  )
}