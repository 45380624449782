import React, { useState } from 'react'
import SpeedIcon from '@mui/icons-material/Speed'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { apiCentroControl } from 'services/api'
import { VerificationCodeMaster } from 'views/Master/VerificationCodeMaster/VerificationCodeMaster'
import { deleteSensor } from '../UtilsSensor'

export const ModalDeleteSensor = ({ open, setOpen, sensor, fetchGetAllSensors, dispositivo }) => {
    const [loading, setLoading] = useState(false)
    const [secondModal, setSecondModal] = useState(false)
    const [errorModal, setErrorModal] = useState(false)
    const toggle = () => setOpen(false)
    const toggle2 = () => setSecondModal(!secondModal)
    
    const handleResult = (status) => {
        if(status === 200) {
            toggle()
            deleteSensor(sensor?.numserie, dispositivo)
            fetchGetAllSensors()
        }
    }

    const sendCode = async () => {
        setLoading(true)
        try {
            const body = {
                numserie: sensor?.numserie,
                address: sessionStorage?.getItem('email'),
                nick: sensor?.nick,
                action: 'delete',
                permission: 'admin'
            }
            const result = await apiCentroControl.patch(`validateAction/device`, body)
            if (result.status === 200) {
                setLoading(false)
                setErrorModal(false)
                toggle2()
            }
        } catch (err) {
            setErrorModal(true)
            console.error("ERROR. API Centro de Control", err)
        }
        setLoading(false)
    }

    return (
        <>
            <Modal 
                fullWidth={true}
                open={open}
                setOpen={setOpen}
                title={`Eliminar Sensor ${sensor?.nick ?? 'Sin nombre'}`}
                loading={loading}
                buttonAction="Aceptar"
                iconTitle={<SpeedIcon/>} 
                functSave={sendCode}
            >
                <Grid container className="text-center mt-2">
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <h4 className="font-weight-lighter" style={{ fontSize: '1.2rem' }}>
                            ¿Estas seguro de que deseas eliminar el sensor {sensor?.nick ?? 'Sin nombre'} asociado al medidor {sensor?.nick_medidor_asociado ?? 'Sin nombre' }?
                        </h4>
                        {errorModal &&
                            <p className='color-red-error'>Ha ocurrido un error, intentelo de nuevo más tarde </p>
                        }
                    </Grid>
                </Grid>
                <hr className='m-0' />
            </Modal>
            {secondModal &&
                <VerificationCodeMaster
                    toggle={toggle}
                    toggle2={toggle2}
                    secondModal={secondModal}
                    numserie={sensor?.numserie}
                    setSecondModal={setSecondModal}
                    method='DELETE'
                    order='delete'
                    reload={false}
                    handleResult={handleResult}
                />
            }
        </>
    )
}