import axios from 'axios'
import { headersCentroControl, headersCentroControlSharedCede, headersScada, headersUsers, headersUsersLogin } from './headers'

const token = sessionStorage.getItem('token')

export const apiCentroControl = axios.create({
  baseURL: process.env.REACT_APP_URL_CCONTROL,
  headers: headersCentroControl(token)
})

export const apiCentroControlNoAutenticated = axios.create({
  baseURL: process.env.REACT_APP_URL_CCONTROL,
  headers: headersCentroControlSharedCede
})

export const apiScada = axios.create({
  baseURL: process.env.REACT_APP_URL_SCADA,
  headers: headersScada(token)
})

export const apiUserNoAutenticated = axios.create ({
  baseURL: process.env.REACT_APP_URL_USERS,
  headers:  headersUsersLogin
})

export const apiUserAutenticated = axios.create ({
  baseURL: process.env.REACT_APP_URL_USERS,
  headers:  headersUsers(token)
})