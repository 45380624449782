import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Loader } from "components/Loader/Loader"
import { Grafica } from 'components/Graph/Grafica'
import { nombreAlarma } from 'utils/Utils'

/**
 * @param {{ data: [], start: moment, end: moment }} 
 * @param data Array con los datos de las alarmas
 * @param start Fecha de inicio de la gráfica
 * @param end Fecha de fin de la gráfica
 * @returns Devuelve la gráfica del funcionamiento del dispositivo entre las fechas seleccionadas
 */
export const GraficaAlarma = ({ data, start, end }) => {
    const [labels, setLabels] = useState()
    const [datosError, setDatosError] = useState()
    const [datosCorrectos, setDatosCorrectos] = useState()
    const [puntosInflexion, setPuntosInflexion] = useState()

    /**
     * @param {[]} array Array que se desea ordenar
     * @description Ordena el array de menor a mayor, almacenando los valores null del array al final de este
     */
    function ordenarArray (array) {
        array.sort((a, b) => {
            if (a === null && b === null) {
                return 0;
            }
            if (a === null) {
                return 1;
            }
            if (b === null) {
                return -1;
            }
            return a - b;
        });
    }

    /**
     * @param {string} value Fecha en formato string
     * @returns Devuelve la fecha sin segundos o null en caso de no pertenecer a un punto de inflexión
     */
    function funcionTickX (value) {
        if (puntosInflexion.includes(labels[value])) {
            const fechaConSegundos = labels[value]
            const [fecha, hora] = fechaConSegundos.split(" ")
            const [horas, minutos, segundos] = hora.split(":")
            const fechaSinSegundos = `${fecha} ${horas}:${minutos} ${segundos}`
            return fechaSinSegundos
        } else {
            return null
        }
    }

    /**
     * @param {moment[]} activaciones Array con los momentos en los que se han activado las alarmas
     * @param {moment[]} soluciones Array con los momentos en los que se han solucionado las alarmas
     * @description Setea en un estado las activaciones seguidas de las soluciones formateadas con los segundos a 0
     */
    const crearPuntosInflexion = (activaciones, soluciones) => {
        const puntosActivacion = activaciones.map(activacion => activacion.set({'second': 0}).format('DD-MM-YYYY HH:mm:ss'))
        const puntosSolucion = soluciones.map(soluciones => soluciones !== null ? soluciones.set({'second': 0}).format('DD-MM-YYYY HH:mm:ss') : null )
        setPuntosInflexion(puntosActivacion.concat(puntosSolucion))
    }

    /**
     * @param {moment} momentInicio Fecha start del calendario seleccionado
     * @param {moment} momentFin Fechas end del calendario seleccionado
     * @description Calcula el tiempo entre los puntos de la grafica en funcion del tiempo seleccionado y llama a las funciones necesarias para cargar la grafica
     */
    const getIntervaloDatos = (momentInicio, momentFin) => {
        const diffDays = momentFin.diff(momentInicio, 'days')
        let intervaloEnMinutos = diffDays === 0 ? 1 : 2*(diffDays+1)
        let activaciones = data.map(objeto => moment(objeto.datetime_active, 'DD-MM-YYYY HH:mm:ss'))
        let soluciones = data.map(objeto => objeto.datetime_solved ? moment(objeto.datetime_solved, 'DD-MM-YYYY HH:mm:ss') : null)
        ordenarArray(activaciones)
        ordenarArray(soluciones)
        crearPuntosInflexion(activaciones, soluciones)
        cargarLabelDatos(momentInicio, momentFin, intervaloEnMinutos, activaciones, soluciones)
    }

    /**
     * @param {moment} momentoActual Fecha start del calendario seleccionado
     * @param {moment} momentFin Fechas end del calendario seleccionado
     * @param {number} intervaloEnMinutos Intervalo de tiempo entre los puntos de la gráfica
     * @param {moment[]} activaciones Array con los momentos en los que se han activado las alarmas
     * @param {moment[]} soluciones Array con los momentos en los que se han solucionado las alarmas
     * @description Setea en los estados correspondientes los labels, los datos para la gráfica de funcionamiento normal y los datos para la gráfica de la activacion de la alarma
     */
    const cargarLabelDatos = (momentoActual, momentFin, intervaloEnMinutos, activaciones, soluciones) => {
        const [arrayLabels, arrayDatosCorrectos, arrayDatosError] = [[],[],[]]
        while (momentoActual.isSameOrBefore(momentFin)) {
            const diffMinActivacion = activaciones.length > 0 ? activaciones[0].diff(moment(momentoActual), 'minutes') : -1
            const diffMinSolucion = soluciones.length > 0  && soluciones[0] !== null ? soluciones[0].diff(moment(momentoActual), 'minutes') : -1
            arrayLabels.push(moment(momentoActual).format('DD-MM-YYYY HH:mm:ss'))
            if(soluciones[0] !== null && moment(momentoActual).unix() > soluciones[0].set({'second': 0}).unix() && activaciones.length > 1 && soluciones.length === activaciones.length) {
                activaciones.shift()
                soluciones.shift()
            }
            if (activaciones.length > 0 && activaciones[0].set({'second': 0}).unix() <= moment(momentoActual).unix()) {
                if(soluciones[0] === null ) {
                    arrayDatosCorrectos.push(activaciones[0].set({'second': 0}).unix() === moment(momentoActual).unix() ? 1 : null)
                    arrayDatosError.push(0.5)
                } else if (soluciones[0].set({'second': 0}).unix() >= moment(momentoActual).unix()) {
                    arrayDatosCorrectos.push(soluciones[0].set({'second': 0}).unix() === moment(momentoActual).unix() || activaciones[0].set({'second': 0}).unix() === moment(momentoActual).unix() ? 1 : null)
                    arrayDatosError.push(0.5)
                } else {
                    arrayDatosCorrectos.push(1)
                    arrayDatosError.push(null)
                }
            } else {
                arrayDatosCorrectos.push(1)
                arrayDatosError.push(null)
            }
            if (diffMinActivacion < intervaloEnMinutos && diffMinActivacion > 0) {
                momentoActual = momentoActual.add(diffMinActivacion, 'minutes')
            } else if (diffMinSolucion > 0 && diffMinSolucion < intervaloEnMinutos) {
                momentoActual = momentoActual.add(diffMinSolucion, 'minutes')
            } else {
                momentoActual = momentoActual.add(intervaloEnMinutos, 'minutes')
            }
        }
        setLabels(arrayLabels)
        setDatosError(arrayDatosError)
        setDatosCorrectos(arrayDatosCorrectos)
    }
    
    useEffect(()=>{
        getIntervaloDatos(moment(start._d).set({'hour': 0, 'minute': 0, 'second': 0}), moment(end._d) > moment() ? moment().set({'hour': 23, 'minute': 59, 'second': 59}) : moment(end._d).set({'hour': 23, 'minute': 59, 'second': 59}))
    },[])

    const datos = [
        {
            label: 'Alarma Activada',
            data: datosError,
            backgroundColor: 'red',
            pointRadius: 0,
            fill: 'origin'
        },
        {
            label: 'Funcionamiento Normal',
            data: datosCorrectos,
            backgroundColor: 'green',
            pointRadius: 0,
            fill: 'origin'
        }
    ]
    
    return (
        <>
            {labels && datosError && datosCorrectos ?
                <div style={{ height: '13em', width: '98%' }} className='mt-4 mr-1'>
                    <Grafica 
                        tipo='line' labels={labels} datos={datos} titulo={`Alarma de ${nombreAlarma(data[0].alarm_id)}`} maintainAspectRatio={false} 
                        posicion="bottom" scalesX={true} rotationX={90} fontSizeX={9}  displayX={true} scalesY={true} minY={0} maxY={1} displayY={false}
                        funcionTickX={funcionTickX} zoom={true}
                    />
                </div>
            :
                <div className="justify-content-center">
                    <Loader/>
                </div>
            }
        </>
    )
}