import React, { useState } from 'react'
import { Grid } from '@mui/material'
import AuthCode from 'react-auth-code-input'
import { urls } from 'utils/constant'
import { Modal } from 'components/Modal/Modal'
import { headersCentroControl } from 'services/headers'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'

export const VerificationCodeDevice = ({ open, setOpen, numserie, devices, token }) => {
    const [code, setCode] = useState()
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const deleteDevice = () => {
        setLoading(true)
        let status
        fetch(urls.ccontrol + `device`, {
            method: 'DELETE', redirect: 'follow', headers: headersCentroControl(token),
            body: JSON.stringify({
                code: Number(code),
                numserie: Number(numserie),
                action: 'DELETE',
            })
        })
        .then(res => {
            setLoading(false)
            status = res.status
            return res.json()
        }).then(data => {
            dispatch(createToast({ status: status, message: data.message, reload: true }))
        })
        .catch(err => {
            console.error("ERROR. API Centro de control", err)
        })
    }

    return (
        <Modal open={open} setOpen={setOpen} onClick={e => e.stopPropagation()} title={`Eliminar "${devices.nick}"`} functSave={deleteDevice} loading={loading} buttonAction="Eliminar" disabled={code < 99999}>
            <Grid container>
                <Grid item xs={12} md={12} lg={12} xl={12} id="verificationCode" className="mb-4 d-flex justify-center items-center">
                    <AuthCode characters={6} onChange={(e) => setCode(parseInt(e))} inputClassName='ios-verified' />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} id="verificationCode">
                    <p className='text-center'>Comprueba tu bandeja de entrada y pega el código aquí</p>
                </Grid>
            </Grid>
        </Modal>
    )
}