import React, { useEffect, useState } from 'react'
import { Loader } from "components/Loader/Loader"
import { IconRed } from 'utils/icon-centrocontrol'
import { GraphConstructor } from 'views/Scada/SelfConsumption/GraphsUtils/GraphConstructor'
import 'views/Scada/SelfConsumption/GraphsUtils/circle.scss'

export const RedGraphs = ({ data, loading, daily }) => {
    const [constructor, setConstructor] = useState(<GraphConstructor/>)

    useEffect(() => {
        let red = []        
        if((data?.produccion?.pact === 0 || data?.produccion?.pact === undefined || data?.produccion?.pact === null) && (data?.red?.pact === 0 || data?.red?.pact === undefined || data?.red?.pact === null)) {
            red.push(100)
        } else {
            red.push(data?.produccion?.pact)
        }
        if (data?.red?.pact === 0 || data?.red?.pact === undefined || data?.red?.pact === null) {
           red.push(0)
        } else {
            red.push(data?.red?.pact)
        }
        const dataInfo =[{
            data: {
                categories: [],
                data: [{
                    red: red
                }]
            }
        }]
        setConstructor(<GraphConstructor className='doughnut-style' daily={daily} nombre={['redRadial']} props={dataInfo} graphStyle={'doughnut'} />)
    }, [data])

    return (
        <>
            {loading && data ?
                <Loader/>
            :
                <div className="flex items-center relative">
                    {constructor}
                    <IconRed className="img-style-red" />
                </div>
            }
        </>
    )
}