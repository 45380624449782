import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { NotFoundVerifi } from 'views/PageError/NotFound'
import { headersUsersLogin } from 'services/headers'
import { urls } from "utils/constant"

export const VerifyProfile = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const token = location.pathname.split('/')[3]
    const delUrl = location.pathname.split('/')[4]
    const [error, setError] = useState(false)

    const verifyUser = () => {
        let url = delUrl === 'delete' ? 'user/deleteValidate/' : 'user/verify/'
        fetch(
            urls.users + url + token,
            {
                method: delUrl === 'delete' ? 'DELETE' : 'PUT',
                redirect: 'follow',
                headers: headersUsersLogin,
                body: delUrl === 'delete' ?
                    undefined
                :
                    JSON.stringify({
                        'active': 1
                    })
            })
            .then((res) => {
                if (res.ok) {
                    navigate("/auth/login")
                } else {
                    setError(true)
                }
            })
            .catch(err => {
                console.error("ERROR. API Users", err)
            })
    }

    useEffect(() => {
        verifyUser()
    }, [token])

    return (
        <>
            {error &&
                <NotFoundVerifi />
            }
        </>
    )
}