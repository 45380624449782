import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { Grid } from "@mui/material"
import qrBot from 'assets/img/imagen.png'
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import { ButtonModal } from "components/Buttons/ButtonModal"
import InputStyled from "components/Input/InputStyled"
import { validatorInput } from "utils/ValidatorForm"
import { Loader } from "components/Loader/Loader"

export const DataProfile = ({ profile, loadingButton, idTelegram, setIdTelegram, setIsValidTelegram, name, setName, isValidName, setIsValidName, lastName, setLastName, isValidLastName, setIsValidLastName, updateUser, valid, loading }) => {

    const handleChangeName = (e) => {
        setName(e)
        setIsValidName(validatorInput(e))
    }

    const handleChangeLastname = (e) => {
        setLastName(e)
        setIsValidLastName(validatorInput(e))
    }

    const handleChangeTelegram = (e) => {
        setIdTelegram(e)
        setIsValidTelegram(validatorInput(e))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!valid) {
            updateUser()
        }
    }
    return (
        <>{loading ?
            <Grid item xs={12} md={12} lg={12} xl={12} className="input-profile-text">
                <Loader loading={loading} />
            </Grid>
            :
            <>
                <Grid item xs={12} md={12} lg={12} xl={12} className="input-profile-text">
                    <div className='pt-2 pb-1'>
                        <p className="label-profile-bold" >
                            Nombre:
                        </p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <InputStyled
                            autoComplete='off'
                            required={true}
                            error={isValidName}
                            helperText={isValidName && "Caracteres no permitidos"}
                            fullWidth
                            className="style-textfield-text"
                            variant="outlined"
                            margin="dense"
                            type="text"
                            name="nombre"
                            value={name}
                            onChange={(e) => handleChangeName(e)}
                            inputProps={{ maxLength: 30 }} />
                    </form>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className="input-profile-text">
                    <div className='pt-2 pb-1'>
                        <p className="label-profile-bold" >
                            Apellidos:
                        </p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <InputStyled
                            autoComplete='off'
                            required={true}
                            error={isValidLastName}
                            helperText={isValidLastName && "Caracteres no permitidos"}
                            fullWidth
                            className="style-textfield-text"
                            variant="outlined"
                            margin="dense"
                            type="text"
                            name="Apellido"
                            value={lastName}
                            onChange={(e) => handleChangeLastname(e)}
                            inputProps={{ maxLength: 30 }}
                        />
                    </form>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className="input-profile-text">
                    <div className='pt-2 pb-1'>
                        <p className="label-profile-bold" >
                            Id de Telegram:
                        </p>
                    </div>
                    <form onSubmit={handleSubmit}>
                    <InputStyled
                        autoComplete='off'
                        required={true}
                        error={isValidLastName}
                        helperText={isValidLastName && "Caracteres no permitidos"}
                        fullWidth
                        className="style-textfield-text"
                        variant="outlined"
                        margin="dense"
                        type="text"
                        name="Id telegram"
                        value={idTelegram}
                        onChange={(e) => handleChangeTelegram(e)}
                        inputProps={{ 
                            maxLength: 50,
                        }}
                        InputProps={{
                            endAdornment:
                                <HtmlTooltip 
                                    title={
                                        <div>
                                            <div>Instrucciones para activar el ID de Telegram:</div>
                                            <div> - Escanear el siguiente QR</div>
                                            <div className="width-qr"> <img src={qrBot} alt="Qr Enerclic Bot" /></div>
                                            <div> - Mandarle por mensaje la palabra /register</div>
                                            <div> - Copiar la id recibida en este campo</div>
                                        </div>
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        className="font-size-lg pointer info-icon"
                                    />
                                </HtmlTooltip>
                        }}
                    />
                    </form>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className="input-profile-text">
                    <div className='pt-2 pb-1'>
                        <p className="label-profile-bold" >
                            Email:
                        </p>
                    </div>
                    <p>{profile?.email}</p>
                </Grid>
                <Grid item xs={12} className="containter-button-profile justify-content-end">
                    <ButtonModal
                        fullWidth={true}
                        loading={loadingButton}
                        disabled={valid}
                        className="button-ok-modal button-save-profile"
                        onClick={handleSubmit}>Guardar </ButtonModal>
                </Grid>
            </>
        }
        </>
    )



}