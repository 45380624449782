import { dispositivos } from "utils/constant";

export const powerChange = (field, type) => {

    if ((type === dispositivos.ccm1 || type === dispositivos.ccm2) && field === "pact") {
        return "pact1";
    } else if (type === dispositivos.bateria && field === "pact") {
        return "p";
    } else if (type === dispositivos.enchufe && field === "pact") {
        return "pac";
    }
    else {
        return field;
    }
}

export const headerTableSelf = (currentData, type, header) => {
    if (type === dispositivos.bateria) {
        if (currentData) {
            return header?.filter(field =>
                ["nick", "datetime", "i", "soc", "p", "v"].includes(field.key)
            );
        } else {
            return header?.filter(field =>
                ["nick", "datetime", "minsoc", "maxsoc"].includes(field.key)
            );
        }
    } else {
        if (currentData) {
            return header

        } else {
            return header?.filter(head => head.key !== "pact")
        }
    }
}

export const CallBackTableGeneral = (label) => {
    switch (label) {
        case "Carga batería (%)":
            return " %"
        case "Intensidad":
            return " A"
        case "Corriente":
            return " A"
        case "Tensión":
            return " V"
        default:
            return ' kW'
    }
}

export const callBallGraphs = (field) => {
    switch (field) {
        case "soc":
            return " %"
        case "i":
            return " A"
        case "v":
            return " V"
        case "pact":
            return ' kW'
        case "undefined":
            return " kW"
        case "p":
            return " kW"
        default:
            return " "
    }
}

export const ArrayColorsSelf = (colorRGB, long) => {
    colorRGB = colorRGB.trim();
    let longitud = long + 1

    const regex = /^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/;
    const match = colorRGB.match(regex);

    if (!match) {
        throw new Error('El formato del color no es válido. Asegúrate de que esté en formato rgb(x, y, z).');
    }

    const r = parseInt(match[1], 10);
    const g = parseInt(match[2], 10);
    const b = parseInt(match[3], 10);

    if (isNaN(r) || isNaN(g) || isNaN(b)) {
        throw new Error('No se pudieron extraer correctamente los valores RGB.');
    }

    if (longitud === 1) {
        return [`rgb(${r}, ${g}, ${b})`];
    }

    const colores = [];

    for (let i = 0; i < longitud; i++) {
        const factor = i / (longitud - 1);

        const nuevoR = Math.min(255, Math.max(0, Math.round(r * (1 - factor) + 255 * factor)));
        const nuevoG = Math.min(255, Math.max(0, Math.round(g * (1 - factor) + 255 * factor)));
        const nuevoB = Math.min(255, Math.max(0, Math.round(b * (1 - factor) + 255 * factor)));

        // Almacenamos los colores en formato rgb(x, y, z)
        const color = `rgb(${nuevoR}, ${nuevoG}, ${nuevoB})`;

        colores.push(color);
    }

    return colores;
};

export const transparent = (rgba, trans) => {
    let alpha = trans;
    let rgbaColor = rgba?.replace('rgb', 'rgba')?.replace(')', `, ${alpha})`);
    return rgbaColor
}

export const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const findFirstWithCategories = (arrays) => {
    for (let i = 0; i < arrays.length; i++) {
        if (arrays?.[i]?.length > 0) {
            return arrays[i];
        }
    }
    return null;
}
