export const logicalSelfConsumption = [
    { value: 1, name: "Minimo" },
    { value: 0, name: "Medio" }
]

export const configBreaker = [{
    name: "Circuit Breaker in rele 1 - CcMaster",
    value: 0
}, {
    name: "Circuit Breaker in rele 2 - CcMaster",
    value: 1
}, {
    name: "Circuit Breaker in rele 1 and 2 - CcMaster",
    value: 2
}
]