import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Doughnut } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

export const TensionGraph = ({ value, triphasic, mabt }) => {
    const [toolTipData, setToolTipData] = useState()
    const [graphData, setGraphData] = useState(0)

    const percentageCalculator = (num) => {
        let min = 220
        let max = 250
        let dif = 30
        let res = 0
        if (num >= min && num <= max) {
            res = num - min;
            res = (res * 100) / dif;
        } else if (num < min) {
            res = 0
        } else if (num > max) {
            res = 100
        }
        return res.toFixed(2)
    }

    const data = {
        labels: [],
        datasets: [{
            type: 'doughnut',
            label: [],
            data: [20, 60, 20],
            backgroundColor: [value === 0 ? '#d1d2db' : '#E9521E', '#d1d2db', value === 0 ? '#d1d2db' : '#E9521E'],
            needleValue: graphData,
            responsive: true,
            circumference: 180,
            rotation: -90,
            cutout: triphasic ? '35' : '75',
            radius: triphasic ? '75%' : '50%'
        }]
    }

    const config = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                yAlign: 'bottom',
                displayColors: false,
                callbacks: {
                    label:
                        function () {
                            if (value > 0) {
                                return `Tensión : ${toolTipData} V`
                            }
                        }
                }
            }
        },
        maintainAspectRatio: false
    }

    //AGUJA
    const gaugeNeedle = {
        id: 'gaugeNeedle',
        afterDatasetsDraw(chart) {
            const { ctx, data, chartArea: { width } } = chart
            //bloque de inclinación de la aguja
            ctx.save()
            const needleValue = data.datasets[0].needleValue
            const dataTotal = data.datasets[0].data.reduce((a, b) => a + b, 0)
            const angle = Math.PI + (1 / dataTotal * needleValue * Math.PI)
            const cx = width / 2
            const cy = chart._metasets[0].data[0].y
            //aguja
            ctx.translate(cx, cy)
            ctx.rotate(angle)
            ctx.beginPath()
            ctx.moveTo(0, -5)
            ctx.lineTo(triphasic ? 50 : 70, 0)
            ctx.lineTo(0, 5)
            ctx.fillStyle = '#444'
            ctx.fill()
            //punto
            ctx.translate(-cx, -cy)
            ctx.beginPath()
            ctx.arc(cx, cy, 5, 0, 10)
            ctx.fill()
            ctx.restore()
        },
    }

    const [graph, setGraph] = useState(
        <Doughnut
            data={data}
            options={config}
            plugins={[gaugeNeedle]}
            graphStyle={'doughnut'}
        />
    )

    useEffect(() => {
        if (value > 0 || value < 0) {
            setGraphData(percentageCalculator(value))
            setToolTipData(value)
        }
    }, [value])

    useEffect(() => {
        setGraph(
            <Doughnut
                data={data}
                options={config}
                plugins={[gaugeNeedle]}
                graphStyle={'doughnut'}
            />
        )
    }, [graphData])

    return (
        <Grid item xs={12} md={12} lg={12} xl={12} style={{ height: triphasic ? '7em' : '15em' }}>
            {!mabt &&
                <span style={{ textAlign: "center" }}>
                    <h3
                        className="text-enerclic text-2xl font-bold text-center">
                        Tensión
                    </h3>
                </span>
            }
            {graph}
        </Grid>
    )
}