import React from 'react'
import { Box, Step, StepLabel, Stepper } from '@mui/material'

export const StepsForm = ({ step, steps }) => {

    return (
        <Box sx={{ width: '100%', marginTop: "15px", marginBottom: "15px" }} className="container-modal-stepper">
            <Stepper 
                activeStep={step} 
                alternativeLabel
                sx={{
                    ".MuiStepConnector-lineHorizontal": {
                    borderTopWidth: "2px"
                    },
                    ".Mui-active>.MuiStepConnector-lineHorizontal": {
                    borderColor: "#FF8A1F"
                    },
                    ".Mui-completed>.MuiStepConnector-lineHorizontal": {
                    borderColor: "green !important"
                    },
                    ".Mui-active": {
                    fontWeight: "600 !important",
                    },
                    ".Mui-completed": {
                    color: "green !important"
                    },
                    ".MuiStepLabel-labelContainer > .Mui-completed": {
                    fontWeight: 600,
                    },
                    ".MuiStepLabel-label": {
                    color: "#333",
                    fontWeight: 600,
                    },
                    ".MuiStepLabel-label>.Mui-completed": {
                    color: "#333"
                    },
                    ".MuiStepIcon-text": {
                    fontSize: "12px",
                    fontWeight: 600
                    },
                    ".MuiStepIcon-root.Mui-active": {
                    color: "#FF8A1F"
                    }
                }}
            >
            {steps?.map((label) => (
                <Step key={label}>
                    <StepLabel className="stepLabel-color" >{label}</StepLabel>
                </Step>
            ))}
            </Stepper>
        </Box>
    )
}