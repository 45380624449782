import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useLocation } from 'react-router-dom'
import { GlobalContext } from "context/GlobalContext"
import { IconProfile, UserLogo, Home, Exit, IconCloseAllSessions, LogoMinerva, IconSupport } from 'utils/icon-centrocontrol'
import { parseDefaultImg } from 'utils/MiddleWareProjects'
import { jwtDecode } from 'utils/JwtUtils'
import { urls } from 'utils/constant'
import MenuOptions from 'components/MenuOptions/MenuOptions'
import Navbar from 'components/Navbar/Navbar'
import { ModalOutAllSessions } from 'views/User/Logout/ModalOutAllSessions'
import { ModalSoporte } from 'views/User/ImpersonateUser/ModalSoporte'
import { ModalLogOut } from 'views/User/Logout/ModalLogOut'
import { listUsers } from 'views/User/Functions/Sesion'
import { ItemNavCc } from 'views/NavBar/ItemNavCC'
import { headersCentroControl, headersCentroControlImagen } from 'services/headers'
import { headersScada } from 'services/headers'
import { savePermission } from 'reduxSlice/permissionSlice'
import { addLogoUser, addLogoProject, clearLogoProject } from 'reduxSlice/logosSlice'

export const NavBar = ({ selfConsumption, setSelfConsumption, setRefresh, setMenuPack, seeWeb }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { setProjectsCompleto, updateDevice, refreshApp, setLoadingApp, setActualProject, setUpdateDevice } = useContext(GlobalContext)
  const navegation = location?.pathname?.split('/')[1]
  const root = location?.pathname?.split('/')[2]
  const token = sessionStorage?.token
  const idProject = location?.pathname?.split('/')[3]
  const idCcMaster = location?.pathname?.split('/')[4]
  const [anchorInfo, setAnchorInfo] = useState(null)
  const [masterPermission, setMasterPermission] = useState()
  const [openLogOut, setOpenLogOut] = useState(false)
  const [sessionOut, setSessionOut] = useState(false)
  const [loading, setLoading] = useState(false)
  const [verifyToken, setVerifyToken] = useState()
  const licenApp = JSON.parse(localStorage.getItem(token))
  const [open1, setOpen1] = useState(false)
  const logos = useSelector((state) => state.logos)
  const refreshSlice = useSelector((state) => state.refreshNavFooter.name)
  const headerLogos = parseDefaultImg(logos.user, 'Header')
  const projectHeaderLogos = parseDefaultImg(logos.project, 'Header')
  const dispatch = useDispatch()
  const licence = licenApp?.licence
  const mailUser = listUsers(token)

  const handleLogin = () => {
    navigate("/auth/login")
  }

  const profile = () => {
    navigate("/admin/perfil")
  }

  const handleExitScada = () => {
    window.document.title = 'Dashboard - Enerclic'
    navigate("/projects/inicio")
  }

  const OptionsMenu = [
    navegation !== "projects" && {
      title: 'Pantalla principal',
      icon: <Home className='h-4 w-4' />,
      onClick: () => handleExitScada()
    },
    {
      title: 'Mi cuenta',
      icon: <IconProfile className='h-3 w-5' />,
      onClick: profile
    },
    sessionStorage?.rol === 'support' && !sessionStorage?.impersonate && {
      title: 'Cuenta cliente',
      icon: <IconSupport className='h-4 w-5' />,
      onClick: () => setOpen1((prev) => !prev)
    },
    {
      title: 'Cerrar sesión',
      icon: <Exit className='h-4 w-5' />,
      onClick: () => setOpenLogOut(!openLogOut)
    },
    {
      title: 'Cerrar sesiones abiertas',
      icon: <IconCloseAllSessions className='h-4 w-5' />,
      onClick: () => setSessionOut(!sessionOut)
    }
  ].filter(Boolean)

  const handleButton = () => {
    if (selfConsumption === undefined) {
      navigate(`/projects/inicio`)
    } else if (selfConsumption === 'general') {
      window.location.reload()
    } else if ((selfConsumption === 'red') || (selfConsumption === 'consumption') || (selfConsumption === 'production')) {
      setSelfConsumption('general')
      if (seeWeb) { seeWeb("general") }
      if (setRefresh) {
        setRefresh(current => !current)
      } else {
        setUpdateDevice(current => !current)
      }
    } else {
      window.location.reload()
    }
  }

  const fetchGetLogo = async () => {
    try {
      const res = await fetch(urls.ccontrol + 'logo/0', { method: 'GET', headers: headersCentroControlImagen(token) })
      if (res.status === 200) {
        let json = await res.json()
        dispatch(addLogoUser(json))
      }
    } catch (err) {
      console.error("ERROR. API Centro de Control", err)
    }
    if (idProject) {
      try {
        const res = await fetch(urls.ccontrol + 'logo/' + idProject, { method: 'GET', headers: headersCentroControlImagen(token) })
        if (res.status === 200) {
          let json = await res.json()
          dispatch(addLogoProject(json))
        }
      } catch (err) {
        console.error("ERROR. API Centro de Control", err)
      }
    } else {
      dispatch(clearLogoProject())
    }
  }

  const fecthProjects = async () => {
    fetch(urls.ccontrol + `userProjects`, { method: "GET", headers: headersCentroControl(token) })
      .then(res => {
        if (res.ok === true) {
          setVerifyToken(jwtDecode(token))
        } else if (res.status === 400) {
          handleLogin()
        } else {
          return {}
        }
        return res.json()
      })
      .then(data => {
        setProjectsCompleto(data)
      })
      .catch(err => console.error("ERROR. API Centro de Control", err))
  }

  const fecthProjectsOld = async () => {
    fetch(urls.scada + `user`, { method: "GET", headers: headersScada(token) })
      .then(res => {
        if (res.ok === true) {
          setVerifyToken(jwtDecode(token))
        } else if (res.status === 400) {
          handleLogin()
        } else {
          return {}
        }
        return res.json()
      })
      .then(data => {
        setProjectsCompleto(data)
      })
      .catch(err => console.error("ERROR. API Centro de Control", err))
  }

  const fecthProject = async () => {
    fetch(urls.ccontrol + `userInfoAdmin/${idProject}`, { method: "GET", headers: headersCentroControl(token) })
      .then(res => {
        return res.json()
      })
      .then(data => {
        if (licenApp?.licence === 'premium') {
          setActualProject(data)
        } else {
          setProjectsCompleto(data)
        }
      })
      .catch(err => console.error("ERROR. API Centro de Control", err))
  }

  const fecthScada = async () => {
    fetch(urls.ccontrol + `getInfoInstallation/${idCcMaster}/${idProject}`, { method: "GET", headers: headersCentroControl(token) })
      .then(res => {
        return res.json()
      })
      .then(data => {
        setActualProject(data)
        if (licenApp?.licence === 'free') {
          setProjectsCompleto(data)
        }
      })
      .catch(err => console.error("ERROR. API Centro de Control", err))
  }

  const userPermissions = async () => {
    fetch(urls.ccontrol + `listaction`, { method: "GET", headers: headersCentroControl(token), })
      .then(res => {
        if (res.ok === true) {
          return res.json()
        } else {
          return {}
        }
      })
      .then(data => {
        dispatch(savePermission({ permission: data }))
      })
      .catch(err => console.error("Error API", err))
  }

  useEffect(() => {
    if (setMenuPack !== undefined) {
      setMenuPack({
        idCcMaster: idCcMaster,
        anchorInfo: anchorInfo,
        setAnchorInfo: setAnchorInfo,
        masterPermission: masterPermission,
        setMasterPermission: setMasterPermission,
        actualProject: null,
      })
    }
  }, [idCcMaster, anchorInfo])

  useEffect(() => {
    async function fetchData() {
      const licenApp = JSON.parse(localStorage.getItem(token))
      if (navegation === 'projects') {
        setLoading(true)
        setLoadingApp(true)
        setActualProject({})
        if (licenApp?.licence === 'premium') {
          await fecthProjects()
        } else {
          await fecthProjectsOld()
        }
        setLoading(false)
        setLoadingApp(false)
      }
      if (navegation === 'admin' && root === 'inicio') {
        setLoading(true)
        setLoadingApp(true)
        await fecthProjects()
        await fecthProject()
        setLoading(false)
        setLoadingApp(false)
      }
      if (navegation === 'view' || navegation === 'scada') {
        setLoading(true)
        setLoadingApp(true)
        await fecthScada()
        if (licenApp?.licence === 'premium') {
          await fecthProjects()
        }
        setLoading(false)
        setLoadingApp(false)
      }
      await userPermissions()
    }
    fetchData()

  }, [updateDevice, refreshApp, navegation])

  useEffect(() => {
    fetchGetLogo()
  }, [refreshSlice, root])

  return (
    <Navbar companyLogos={[...headerLogos, ...projectHeaderLogos]} logo={<LogoMinerva width="168" height="55" className="cursor-pointer" onClick={() => handleButton()} />}>
      <span className="icon-mail-user">
        {licence !== "free" &&
          <ItemNavCc
            navegation={navegation}
            verify={verifyToken}
          />
        }
        <span>
          <MenuOptions selector={<UserLogo className="logo-user" height="25" width="25" />} options={OptionsMenu} />
          <ModalLogOut open={openLogOut} setOpen={() => setOpenLogOut(!openLogOut)} />
          <ModalOutAllSessions open={sessionOut} setOpen={() => setSessionOut(!sessionOut)} />
          <ModalSoporte open1={open1} setOpen1={() => setOpen1(!open1)} />
        </span>
        {!loading && <h3 className='font-bold pl-3'>{sessionStorage?.name ? `Hola, ${sessionStorage.name}` : mailUser}</h3>}
      </span>
    </Navbar>
  )
}