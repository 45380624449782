import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { headersUsersLogin } from 'services/headers'
import { urls } from 'utils/constant'
import { Card, FormControl, Grid, TextField } from '@mui/material'
import { Loader } from 'components/Loader/Loader'
import { MinervaIcon } from 'utils/icon-centrocontrol'
import { validatorNumber } from 'utils/ValidatorForm'
import { CustomValidation } from 'utils/Utils'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'

export const VerifyToken = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const idUser = location.pathname.split('/')[3]
    const dispatch = useDispatch()
    const [code, setCode] = useState()
    const [password, setPassword] = useState()
    const [configPass, setConfigPass] = useState()
    const [visible, setVisible] = useState(false)

    const [loading, setLoading] = useState(false)
    const [validCode, setValidCode] = useState(false)
    const [customPass, setCustomPass] = useState([])

    const validateForm = () => !validCode && password?.length > 7 && configPass?.length > 7 && customPass?.length < 1 && configPass === password

    const urlParams = new URLSearchParams(window.location.search)
    const emailFromUrl = urlParams.get('email')


    //envia los datos
    const goLogin = () => navigate(`/auth/login`)

    //Hace POST y envía el token 
    const newPassword = () => {
        setLoading(true)
        let status
        fetch(urls.users + 'user/recoveryPass', {
            method: 'PUT', redirect: 'follow', headers: headersUsersLogin,
            //Le paso el correo y contraseña de los inputs
            body: JSON.stringify({ 'code': parseInt(code), "pass": password, "idUser": parseInt(idUser), "email": emailFromUrl })
        }).then(res => {
            status = res.status
            if (res.ok) {
                goLogin()
            }
            return res.json()
        }).then(data => {
            dispatch(createToast({ status: status === 403 ? 400 : status, message: data.message }))
        })
            .catch(err => console.error("ERROR. API Login", err))
            .finally(() => {
                setLoading(false)
            })
    }

    const validatorCode = (e) => {
        setCode(e)
        setValidCode(validatorNumber(e))
    }
    const validatePass = () => {
        setCustomPass(CustomValidation(emailFromUrl, password))
    }
    const handleBlur = () => {
        validatePass()
    }


    return (
        <><Grid item xs={11} md={12} lg={12} xl={12} className="grid-login">
            <MinervaIcon />
            <h2 className="title-login"> ACCESO CLIENTES</h2>
            <p className="subtitle-login">¡Bienvenido! Accede a tu <b>Portal Enerclic</b></p>
            <Card className="card-reset-password">
                <Grid item xs={12} md={12} lg={12} xl={12} className="grid-return-login">
                    <a onClick={goLogin} className="button-return-login">x</a>
                </Grid>
                <h2 className="text-register-user">Cambiar contraseña</h2>
                {loading ?
                    <Loader />
                    :
                    <FormControl style={{ width: "100%" }} autoSave="off" onSubmit={newPassword}>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield new-password-auth">
                            <Grid item xs={11} sm={11} lg={8} xl={8} style={{ marginTop: window.innerWidth < 378 ? '6px' : null, width: '100%' }}>
                                <TextField className="textfied-login" size='small' variant='outlined' placeholder="Código de verificación" style={{ width: '100%' }}
                                    autoComplete="off" value={code} name="code" onChange={(e) => validatorCode(e.target.value)} error={validCode} onClick={() => setVisible(true)}
                                    helperText={validCode ? 'Código no válido' : ''} />
                            </Grid>
                            <Grid item xs={11} sm={11} lg={8} xl={8} style={{ marginTop: window.innerWidth < 378 ? '6px' : null, width: '100%' }}>
                                <TextField onClick={() => setVisible(true)} className="textfied-login" size='small' variant='outlined' placeholder="Nueva contraseña" style={{ width: '100%' }}
                                    type="password" autoComplete="off" value={password} name="password" e
                                    onChange={(e) => setPassword(e.target.value)} onBlur={handleBlur} />
                            </Grid>
                            <Grid item xs={11} sm={11} lg={8} xl={8} style={{ marginTop: window.innerWidth < 378 ? '6px' : null, width: '100%' }}>
                                <TextField onClick={() => setVisible(true)} className="textfied-login" size='small' variant='outlined' placeholder="Confirmar Contraseña" style={{ width: '100%' }}
                                    type="password" autoComplete="off" value={configPass} name="password"
                                    onChange={(e) => setConfigPass(e.target.value)} onBlur={handleBlur} />
                                <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield">
                                </Grid>
                            </Grid>
                            {visible &&
                                <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield">
                                    <Grid item xs={11} sm={11} lg={12} xl={12}>
                                        <ul className="font-size-10px">
                                            <li className={`${password?.length > 0 && customPass?.includes(1) ? "error-checklist-password checklist-password" : (password?.length > 7 && !customPass?.includes(1)) ? "correct-checklist-password checklist-password" : "checklist-password"}`}>Mínimo 8 caracteres</li>
                                            <li className={`${password?.length > 0 && customPass?.includes(3) ? "error-checklist-password checklist-password" : (password?.length > 7 && !customPass?.includes(3)) ? "correct-checklist-password checklist-password" : "checklist-password"}`}>Las contraseñas no pueden coincidir con el email</li>
                                            <li className={`${password?.length > 0 && customPass?.includes(2) ? "error-checklist-password checklist-password" : (password?.length > 7 && !customPass?.includes(2)) ? "correct-checklist-password checklist-password" : "checklist-password"}`}>Debe contener al menos una mayúscula, minúscula, número</li>
                                            {configPass?.length > 0 && <li className={`${configPass?.length > 0 && (password !== configPass) ? "error-checklist-password checklist-password" : password?.length > 0 && configPass?.length > 0 && password === configPass ? "correct-checklist-password checklist-password" : "checklist-password"}`}>Las contraseñas coinciden</li>}
                                        </ul>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield container-button-register-user">
                            <Grid item xs={12} sm={12} lg={6} xl={6}>
                                <button variant='contained' loading={loading} className="button-create-user-login" disabled={!validateForm()} onClick={newPassword}>
                                    Cambiar contraseña
                                </button>
                            </Grid>
                        </Grid>
                    </FormControl>
                }
            </Card>
        </Grid>
        </>
    )
}