import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/system'

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${theme.name}-tooltip`]: {
      backgroundColor: 'white',
      color: 'black',
      maxWidth: 220,
      fontSize: '12rem',
    },
  }));
  
export const HtmlTooltip = ({ title, children, className }) => {
  return (
    <CustomTooltip className={className} title={<span>{title}</span>}>
        <div className={className}>{children}</div>
    </CustomTooltip>
  )
}