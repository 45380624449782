import React from "react"
import { Card, Grid, Table } from "@mui/material"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import { ImageDevice } from "utils/ImageDevice/ImageDevice"
import { DeviceDate } from "utils/DeviceDate/DeviceDate"
import { IconWarning } from "utils/icon-centrocontrol"
import { toKiloW } from "utils/functionsforGraphs"
import { monofasic } from "utils/constant"
import { CardTriphasic, SinglePhasic } from "views/Scada/Consumption/CardTableScada"
import { DropDownDevices } from "views/Devices/MenuDevices/DropDownDevices"
import "./StyledCardScada.scss"

export const CardDevicesScada = ({ devices, activeDevice, masterData, refresh, setRefresh, setActiveTab, loose, currentDevice, setActiveTabDevice, loading, setLoading, setActiveDevice }) => {

    return (
        <Grid constainer className="card-scada-container">
            <Card
                className={`card-box-alt custom-hover card-scada-card ${currentDevice?.currentData?.numserie === activeDevice && "card-scada-selected"}`}
                onClick={() => { setActiveTab(0); setActiveDevice(devices?.numserie); setActiveTabDevice(devices) }}
            >
                <Grid item sm={12} lg={12} xl={12} className="flex-center justify-start height-2-5">
                    <div>
                        <DropDownDevices
                            scada={true}
                            setLoading={setLoading}
                            device={devices}
                            masterPermission={masterData}
                            active={true}
                            setRefresh={setRefresh}
                            refresh={refresh}
                            loose={loose}
                            instalacion={devices}
                            masterPermissionNew={masterData?.permissions}
                        />
                    </div>
                    <div className="card-scada-image">
                        <ImageDevice id_type_device={devices?.id_tipo_dispositivo} />
                    </div>
                    <Grid container alignItems="flex-start">
                        <Grid item xs={12} sm={6} lg={10} xl={10}>
                            {currentDevice && devices?.nick?.length > 15 ? (
                                <HtmlTooltip title={<span>{devices?.nick}</span>}>
                                    <p className="card-scada-nick">{devices?.nick?.substr(0, 14) + '...'}</p>
                                </HtmlTooltip>
                            ) : (
                                <p className="card-scada-nick">{devices?.nick}</p>
                            )}
                        </Grid>
                        {currentDevice && currentDevice?.currentData?.error === 1 && (
                            <Grid item xs={12} sm={6} lg={2} xl={2} className="warning-icon-container">
                                <HtmlTooltip title={<span>Revise su configuración.</span>}>
                                    <IconWarning width={20} height={20} />
                                </HtmlTooltip>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <div className="divider card-scada-divider" />
                <Grid item sm={12} lg={12} xl={12} className="card-scada-device-date">
                    <DeviceDate
                        device={devices}
                        loading={loading}
                        data={currentDevice?.currentData}
                        masterPermission={devices}
                    />
                </Grid>
                <Grid className="card-scada-container-data">
                    <Grid item sm={12} lg={12} xl={12}>
                        <h4>Potencia total : {toKiloW(currentDevice?.currentData?.pact)} kW</h4>
                    </Grid>
                    <Grid item sm={12} lg={12} xl={12}>
                        <h4>E. Importada : {toKiloW(currentDevice?.currentData?.eaconsdia)} kWh</h4>
                    </Grid>
                    <Grid item sm={12} lg={12} xl={12}>
                        <h4>E. Exportada : {toKiloW(currentDevice?.currentData?.eagendia)} kWh </h4>
                    </Grid>
                    <Table className="card-scada-containter-table">
                        <thead className="border-bottom-div" >
                            <tr>
                                <th className="card-scada-containter-table-cell-l">{' '}</th>
                                <th className="card-scada-containter-table-cell">P(kW)</th>
                                <th className="card-scada-containter-table-cell">I(A)</th>
                                <th className="card-scada-containter-table-cell">V(V)</th>
                                <th className="card-scada-containter-table-cell"> PF</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '13px' }}>
                            {monofasic?.includes(devices?.id_tipo_dispositivo) ?
                                <SinglePhasic data={currentDevice} />
                                :
                                <CardTriphasic data={currentDevice} />
                            }
                        </tbody>
                    </Table>
                </Grid >
            </Card >
        </Grid>
    )
}