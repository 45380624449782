import React, { useState } from 'react'
import { Button, Menu } from '@mui/material'
import { IconMenu } from 'utils/icon-centrocontrol'
import { PermissionsForMenuProject } from 'views/Menu/PermissionsforMenu'

export const DropdownProject = ({ project, loading, centroControl, permissions}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <>
      {project?.permissions !== "user" &&
        <>
          <Button className="btn-square" onClick={handleClick} style={{ backgroundColor: "transparent", border: "0px solid transparent", }}>
            <span >
              <IconMenu />
            </span>
          </Button>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted classes={{ list: "p-0" }} open={Boolean(anchorEl)} onClose={handleClose}>
            <PermissionsForMenuProject
              project={project}
              permissions={permissions}
              handleClose={handleClose}
              loading={loading}
              centroControl={centroControl}
            />
          </Menu>
        </>
      }
    </>
  )
}