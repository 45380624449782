import React from "react"
import { Card, CircularProgress } from "@mui/material"

export const Loader = ({ message }) => {

  return (
    <div className="d-flex flex-row text-center align-items-center flex-wrap justify-content-center" style={{ background: "transparent" }} >
      <Card className="rounded-sm shadow-none p-3 m-3" style={{ background: "transparent" }}>
        <div className="d-flex align-items-center justify-content-center" style={{ height: "auto" }} >
          <CircularProgress disableShrink sx={{ color: "#FF8A1F;", marginBottom: "30px" }} size="75px" />
        </div>
        <p className="mb-0 pt-3 text-black-200 text-center ml-1 tracking-[5px] text-2xl	font-bold">{message || "Cargando ..."}</p>
      </Card>
    </div>
  )
}
