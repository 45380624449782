import React, { useState, useEffect, } from 'react'
import { useMediaQuery } from 'react-responsive'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'
import { FormControl, Grid, MenuItem, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { StyledTableCellHead } from 'components/Table/TableStyle'
import { SelectStyled } from 'components/Select/SelectStyled'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { StyledTableCell } from 'components/Table/TableStyle'
import { StyledTableRow } from 'components/Table/TableStyle'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { headersScada } from 'services/headers'
import { getCurrentDate } from 'utils/Utils'
import { urls } from 'utils/constant'
import "./StyleModal.scss"

export const ModalEditGeneralControl = ({ data, idCcMaster, relay, setRelay, centroControl, openEdit, setUpdateCalendar, setOpenEdit, loading, tableData }) => {
    const token = sessionStorage?.token
    const [sunrise, setSunrise] = useState(data?.sunrise ? data?.sunrise : false)
    const [sunset, setSunset] = useState(data?.sunset ? data?.sunset : false)
    const [selectWeekly, setSelectWeekly] = useState(data?.type_date !== 1 ? true : false)
    const [selectDate, setSelectDate] = useState(data?.type_date === 1 ? true : false)
    const [offset, setOffset] = useState(data?.min_offset ? data?.min_offset : 0)
    const [date, setDate] = useState(data?.day)
    const [dateIni, setDateIni] = useState(data?.start_date)
    const [dateFinal, setDateFinal] = useState(data?.end_date)
    const [total, setTotal] = useState(0)
    const [error, setError] = useState(false)
    const [errorFecha, setErrorFecha] = useState(false)
    const [time, setTime] = useState(!sunset && !sunrise && data?.hour?.toString().padStart(2, '0') + ":" + data?.min?.toString().padStart(2, '0'))
    const [idOutPut, setIdOutPut] = useState(data?.ID_output + 1)
    const [internal, setInternal] = useState(data?.internal_mode)
    const [body, setBody] = useState()
    const isMobile = useMediaQuery({ maxWidth: 789 })
    const [enable, setEnable] = useState(data?.enable)
    const [onOff, setOnOff] = useState()
    const [loadingRelay, setLoadingRelay] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)
    const [internalState, setInternalState] = useState(data?.internal_mode)
    const [priority, setPriority] = useState(data?.priority)
    const dispatch = useDispatch()
    const intervalo = Array.from({ length: 119 }, (_, index) => index - 59)
    const validateForm = () => idOutPut > -1 && internal > -1 && (time || sunset || sunrise) && ((date && selectDate) || (total > 0 && selectWeekly))

    const enableChange = () =>
        data && (
            (data?.enable === enable && data?.ID_output + 1 === idOutPut && data?.internal_mode === internalState
                && data?.priority === priority && data?.sunrise === sunrise && data?.sunset === sunset && data?.min_offset === offset
                && data?.start_date === dateIni && data?.end_date === dateFinal && ((selectDate && data?.day === date) || (selectWeekly && data?.week_day === total)) && (data?.hour?.toString().padStart(2, '0') + ":" + data?.min?.toString().padStart(2, '0') === time || (sunset && time === "00:00") || (sunrise && time === "00:00")))
        )

    const internal_calendar = [
        {
            value: 1,
            label: 'Normal'
        },
        {
            value: 2,
            label: 'Pulso'
        }
    ]

    const output = [
        {
            ID_entry: 1,
            name: "Salida 1"
        },
        {
            ID_entry: 2,
            name: "Salida 2"
        }
    ]

    const [dataWeek, setDataWeek] = useState([
        { id: 0, label: 'Lunes', valor: 1, checked: false },
        { id: 1, label: 'Martes', valor: 2, checked: false },
        { id: 2, label: 'Miércoles', valor: 4, checked: false },
        { id: 3, label: 'Jueves', valor: 8, checked: false },
        { id: 4, label: 'Viernes', valor: 16, checked: false },
        { id: 5, label: 'Sábado', valor: 32, checked: false },
        { id: 6, label: 'Domingo', valor: 64, checked: false },
        { id: 7, label: 'Todos', valor: 0, checked: false }
    ])

    const handleDateIni = (value) => {
        setDateIni(value)
        if (value > dateFinal && dateFinal !== '' && value !== '') {
            setErrorFecha(true)
        } else {
            setErrorFecha(false)
        }
    }

    const handleDateFinal = (value) => {
        setDateFinal(value)
        if (value < dateIni && dateIni !== '' && value !== '') {
            setErrorFecha(true)
        } else {
            setErrorFecha(false)
        }
    }

    const handleCheckboxChange = (index) => {
        let newData = [...dataWeek] // Clonar el array para no mutar el estado directamente
        if (index === 7) {
            // Marcar o desmarcar todos los elementos dependiendo del estado del ítem 7
            const isChecked = dataWeek.find((item) => item.id === index)?.checked || false
            newData = newData.map((item) => ({ ...item, checked: !isChecked }))
        } else {

            // Actualizar el estado normalmente
            newData = newData.map((item) =>
                item.id === index ? { ...item, checked: !item.checked } : item
            )

            // Si se desmarca algún ítem del 0 al 6, desmarcar el ítem 7
            const isAnyUnchecked = newData.some((item) => item.id !== 7 && !item.checked)
            newData = newData.map((item) =>
                item.id === 7 ? { ...item, checked: !isAnyUnchecked } : item
            )
        }

        // Actualizar el estado final
        setDataWeek(newData);

        // Calcular el total sumando los valores de los elementos seleccionados
        const newTotal = newData.filter((item) => item.checked)
            .reduce((acc, item) => acc + item.valor, 0)
        setTotal(newTotal)
    }

    const initialMode = () => {
        if (data?.internal_mode === 100) {
            setOnOff(true)
            setInternal(1)
        } else if (data?.internal_mode === 103) {
            setOnOff(false)
            setInternal(1)
        } if (data?.internal_mode === 101) {
            setOnOff(true)
            setInternal(2)
        } if (data?.internal_mode === 102) {
            setOnOff(false)
            setInternal(2)
        }
    }

    const handleModeOnOff = () => {
        if (internal === 1 && onOff) {
            return setInternalState(100)
        } else if (internal === 1 && !onOff) {
            return setInternalState(103)
        } else if (internal === 2 && onOff) {
            return setInternalState(101)
        } else if (internal === 2 && !onOff) {
            return setInternalState(102)
        }
    }

    const handleWeekly = () => {
        setSelectWeekly(!selectWeekly)
        setSelectDate(!selectDate)
    }

    const handleDateMode = () => {
        setSelectDate(!selectDate)
        setSelectWeekly(!selectWeekly)
    }
    const handleSunrise = () => {
        setSunrise(!sunrise)
        if (sunset) {
            setSunset(false)
        }
    }

    const handleSunset = () => {
        setSunset(!sunset)
        if (sunrise) {
            setSunrise(false)
        }
    }
    const handleEnable = () => {
        setEnable(current => !current)
    }

    const asyncCalendar = () => {
        let status
        setLoadingSave(true)
        fetch(urls.scada + `calendar/${idCcMaster}?idCCMaster=${idCcMaster}`, { method: 'PATCH', headers: headersScada(token), body: JSON.stringify([body]) })
            .then(res => {
                status = res.status
                if (status === 200) {
                    dispatch(createToast({ status: status, message: "Calendario editado", reload: false }))
                    setUpdateCalendar(current => !current)
                }
                else {
                    dispatch(createToast({ status: status, message: "Error al editar calendario", reload: false }))
                }

                return res.json()
            }).catch(err => console.error("ERROR. API Scada", err))
            .finally(() => {
                setOpenEdit(false)
                setLoadingSave(false)
            })
    }

    const fetchRelay = (idCcMaster) => {
        setLoadingRelay(true)
        fetch(urls.scada + `calendarRelay/${idCcMaster}?idCCMaster=${idCcMaster}`, { method: 'GET', headers: headersScada(token) })
            .then(res => {
                return res.json()
            }).then(data => {
                if (data.length === 0) {
                    setRelay(output)
                } else {
                    setRelay(data)
                }
            })
            .catch(err => console.error("ERROR. API Scada", err))
            .finally(() => {
                setLoadingRelay(false)
            })
    }

    const modificarNumero = (numero) => {
        switch (numero) {
            case 3:
                return 4
            case 4:
                return 8
            case 5:
                return 16
            case 6:
                return 32
            case 7:
                return 64
            default:
                return numero
        }
    }

    const checkDay = (week, day) => {
        if (selectDate) {
            const dateNew = new Date(date)
            return (week & modificarNumero(dateNew?.getDay())) !== 0
        } else {
            const dateNew = new Date(day)
            return day ? (total & modificarNumero(dateNew?.getDay())) : (week & total) !== 0
        }
    }

    const DuplicatedPriority = (id) => {
        if (data) {
            const hour = time ? parseFloat(time?.split(":")[0]) : null;
            const min = time ? parseFloat(time?.split(":")[1]) : null;
            const snDeviceFound = tableData?.filter(entry => entry?.SN_device?.toString() === id?.toString())
            const duplicated = snDeviceFound
                .find(calendar =>
                    data?.ID_entry !== calendar?.ID_entry
                    && calendar?.ID_output === idOutPut - 1
                    && calendar?.priority === priority
                    && (
                        (calendar?.hour === hour && calendar?.min === min && calendar?.sunrise === false && calendar?.sunset === false) ||
                        (calendar?.hour === 26 && calendar?.sunrise === true && Number(calendar?.min_offset) === Number(offset)) ||
                        (calendar?.hour === 27 && calendar?.sunset === true && Number(calendar?.min_offset) === Number(offset))
                    )
                    && (
                        (calendar?.sunset === false && !sunset && calendar?.sunrise === false && !sunrise && calendar?.min_offset === 0 && offset === 0) ||
                        (calendar?.sunset === true && sunset && calendar?.sunrise === false && !sunrise && (Number(calendar?.min_offset) === Number(offset))) ||
                        (calendar?.sunset === false && !sunset && calendar?.sunrise === true && sunrise && (Number(calendar?.min_offset) === Number(offset)))
                    )
                    && (checkDay(calendar?.week_day, calendar?.day) || (calendar?.day === date && selectDate && date !== '' && date !== undefined))
                )
            if (duplicated) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    useEffect(() => {
        if (data?.week_day !== undefined) {
            const isWeekDay127 = data.week_day === 127
            const newData = dataWeek.map(item => {
                const isChecked = item.id === 7 ? isWeekDay127 : (data.week_day & item.valor) === item.valor
                return { ...item, checked: isChecked }
            })
            setDataWeek(newData)
            const selectedItems = newData.filter(item => item.checked)
            const totalSum = selectedItems.reduce((acc, item) => acc + item.valor, 0)
            setTotal(totalSum)
        }
    }, [data?.week_day])

    useEffect(() => {
        initialMode()
    }, [])

    useEffect(() => {
        if (sunset || sunrise) {
            setTime("00:00")
        }
    }, [sunset, sunrise])

    useEffect(() => {
        setBody({
            "ID_entry": data?.ID_entry,
            "state": 1,
            "enable": enable,
            "SN_device": idCcMaster?.toString(),
            "week_day": selectWeekly ? total : 0,
            "type_date": selectDate ? 1 : 0,
            "start_date": selectWeekly ? dateIni : "",
            "end_date": selectWeekly ? dateFinal : "",
            "day": selectDate ? date : "",
            "min_offset": Number(offset ? offset : 0),
            "hour": time ? parseFloat(time?.split(":")[0]) : null,
            "min": time ? parseFloat(time?.split(":")[1]) : null,
            "internal_mode": internalState,
            "ID_output": idOutPut - 1,
            "sunrise": sunrise,
            "sunset": sunset,
            "priority": priority
        })
        setError(DuplicatedPriority(idCcMaster))
    }, [enable, total, date, selectDate, time, offset, dateFinal, dateIni, internalState, idOutPut, sunrise, sunset, idOutPut, priority])

    useEffect(() => {
        handleModeOnOff()
    }, [onOff, internal])

    useEffect(() => {
        if (data?.SN_device && centroControl)
            fetchRelay(data?.SN_device)
    }, [data])



    return (
        <Modal open={openEdit} setOpen={setOpenEdit} functSave={() => asyncCalendar()} title={`Editar programación de ${data.nick_master && data.nick_master}`} loading={loadingSave} disabled={enableChange() || loadingRelay || errorFecha || !validateForm() || DuplicatedPriority(idCcMaster) || loading} maxWidth="lg"  >
            <div className="modal-create-new-calendar">
                <Grid container items xs={12} className='d-flex justify-content-around align-items-start'>
                    <Grid item xs={6} md={4} lg={1} xl={1} className="d-flex align-items-center justify-content-center flex-column">
                        <p className="h3-switch-add-calendar " style={{ marginButton: "15px !important" }}>Activo</p>
                        <SwitchStyled id="habilitado" className="switch-small" checked={enable} onClick={() => handleEnable()} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={2} xl={2} className="d-flex align-items-center justify-content-center flex-column">
                        <FormControl className='width-100' size="small">
                            <p className="font-weight-lighter mb-0">
                                Seleccione una Salida:
                            </p>
                            <TextField
                                fullWidth
                                size="small"
                                select
                                value={idOutPut}
                                onChange={(e) => setIdOutPut(e.target.value)}
                                variant="outlined" >
                                {relay?.map((option, i) => (
                                    <MenuItem key={i} value={option.ID_entry}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8} md={8} lg={2} xl={2} className="d-flex align-items-center justify-content-center flex-column">
                        <FormControl className='width-100' size="small">
                            <p className="font-weight-lighter mb-0">
                                Seleccione un modo:
                            </p>
                            <TextField
                                fullWidth
                                size="small"
                                select
                                value={internal}
                                onChange={(e) => setInternal(e.target.value)}
                                variant="outlined" >
                                {internal_calendar?.map((option, i) => (
                                    <MenuItem key={i} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Grid>
                    {internal === 1 &&
                        <Grid item xs={6} md={4} lg={2} xl={2} className="d-flex align-items-center justify-content-center flex-column">
                            <p className="h3-switch-add-calendar" style={{ marginButton: "15px !important" }}>Apagado/Encendido</p>
                            <SwitchStyled id="habilitado" className="switch-small" checked={onOff} onClick={() => { setOnOff(!onOff) }} />
                        </Grid>}
                    {internal === 2 &&
                        <Grid item xs={6} md={4} lg={2} xl={2} className="d-flex align-items-center justify-content-center flex-column">
                            <p className="h3-switch-add-calendar" style={{ marginButton: "15px !important" }}>Pulso apagado</p>
                            <SwitchStyled id="habilitado" className="switch-small" checked={!onOff} onClick={() => { setOnOff(!onOff) }} />
                            <p className="h3-switch-add-calendar" style={{ marginButton: "15px !important" }}>Pulso encendido</p>
                            <SwitchStyled id="habilitado" className="switch-small" checked={onOff} onClick={() => { setOnOff(!onOff) }} />
                        </Grid>}
                    <Grid item xs={6} md={4} lg={1} xl={1} >
                        <p className="h3-switch-add-calendar" style={{ marginButton: "15px !important" }}>Semana</p>
                        <SwitchStyled id="habilitado" className="switch-small" checked={selectWeekly} onClick={() => handleWeekly()} />
                    </Grid>
                    <Grid item xs={6} md={4} lg={1} xl={1} >
                        <p className="h3-switch-add-calendar" style={{ marginButton: "15px !important" }}>Fecha</p>
                        <SwitchStyled id="habilitado" className="switch-small" checked={selectDate} onClick={() => handleDateMode()} />
                    </Grid>
                    <Grid item xs={6} md={4} lg={1} xl={1} >
                        <p className="h3-switch-add-calendar" style={{ marginButton: "15px !important" }}>Prioridad</p>
                        <SwitchStyled id="habilitado" className="switch-small" checked={priority} onClick={() => setPriority(!priority)} />
                    </Grid>
                </Grid>
                <Grid container spacing={1} item xs={12} style={{ marginTop: "15px" }}>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                        <p className="font-weight-lighter mb-0">
                            Hora:
                        </p>
                        <InputStyled
                            disabled={sunset || sunrise}
                            fullWidth
                            size="small"
                            type="time"
                            value={time}
                            onChange={(e) => {
                                setTime(e)
                            }
                            }
                        >
                        </InputStyled>
                    </Grid>
                    <Grid item xs={6} md={4} lg={2} xl={2} className="d-flex align-items-center justify-content-center flex-column">
                        <p className="h3-switch-add-calendar" style={{ marginButton: "15px !important" }}>Amanecer</p>
                        <SwitchStyled id="habilitado" className="switch-small" checked={sunrise} onClick={() => handleSunrise()} />
                    </Grid>
                    <Grid item xs={6} md={4} lg={2} xl={2} className="d-flex align-items-center justify-content-center flex-column">
                        <parent className="h3-switch-add-calendar" style={{ marginButton: "15px !important" }}>Atardecer</parent>
                        <SwitchStyled id="habilitado" className="switch-small" checked={sunset} onClick={() => handleSunset()} />
                    </Grid>
                    <Grid item xs={6} md={4} lg={4} xl={4} className="d-flex align-items-center justify-content-center ">
                        <p className="h3-switch-add-calendar" style={{ marginButton: "15px !important" }}>Minutos de desfase </p>
                        <FormControl className='width-100' size="small">
                            <SelectStyled
                                select
                                disabled={!sunset && !sunrise}
                                fullWidth
                                size="small"
                                value={offset}
                                onChange={(e) => setOffset(e.target.value)}
                            >{intervalo.map((numero) => (
                                <MenuItem key={numero} value={numero}>
                                    {numero}
                                </MenuItem>))}
                            </SelectStyled>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ marginTop: "15px " }}>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        {!isMobile && selectWeekly &&
                            <Grid container>
                                <Grid item xs={5}>
                                    <p className="h3-switch-add-calendar" style={{ marginButton: "0px !important" }}>Fecha Inicio</p>
                                    <TextField
                                        className="modal-add-calendar-number-input"
                                        size='small'
                                        placeholder='Hora'
                                        fullWidth
                                        type="date"
                                        value={dateIni}
                                        format={"DD-MM-YYYY"}
                                        onChange={(e) => handleDateIni(e.target.value)}
                                        inputProps={{
                                            min: getCurrentDate(),
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item xs={5}>
                                    <p className="h3-switch-add-calendar" style={{ marginButton: "0px !important" }}>Fecha Fin</p>
                                    <TextField
                                        className="modal-add-calendar-number-input"
                                        size='small'
                                        placeholder='Hora'
                                        fullWidth
                                        type="date"
                                        value={dateFinal}
                                        format={"DD-MM-YYYY"}
                                        onChange={(e) => handleDateFinal(e.target.value)}
                                        inputProps={{
                                            min: getCurrentDate(),
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <TableContainer component={Paper} className='mt-3'>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead style={{ borderRadius: '50px' }}>
                                            <TableRow>
                                                <StyledTableCellHead align="center">Lunes</StyledTableCellHead>
                                                <StyledTableCellHead align="center">Martes</StyledTableCellHead>
                                                <StyledTableCellHead align="center">Miércoles</StyledTableCellHead>
                                                <StyledTableCellHead align="center">Jueves</StyledTableCellHead>
                                                <StyledTableCellHead align="center">Viernes</StyledTableCellHead>
                                                <StyledTableCellHead align="center">Sábado</StyledTableCellHead>
                                                <StyledTableCellHead align="center">Domingo</StyledTableCellHead>
                                                <StyledTableCellHead align="center">Todos</StyledTableCellHead>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledTableRow >
                                                {dataWeek.map((checkbox, index) => {
                                                    return (
                                                        <StyledTableCell align="center"><SwitchStyled className="switch-small" checked={checkbox.checked}
                                                            onChange={() => handleCheckboxChange(index)} /></StyledTableCell>
                                                    )
                                                })}
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        }
                        {!isMobile && selectDate &&
                            <Grid Grid item xs={12}>
                                <Grid item xs={8}>
                                    <TextField
                                        className="modal-add-calendar-number-input"
                                        size='small'
                                        placeholder='Hora'
                                        fullWidth
                                        type="date"
                                        value={date}
                                        format={"DD-MM-YYYY"}
                                        onChange={(e) => setDate(e.target.value)}
                                        variant="outlined"
                                        inputProps={{
                                            min: getCurrentDate(),
                                        }}
                                    ></TextField>
                                </Grid>
                            </Grid>
                        }
                        {error &&
                            <Grid Grid item xs={12} className='text-align-center mt-4 color-red'>
                                Ya existe una programación para esta fecha con la misma prioridad
                            </Grid>
                        }
                        {errorFecha &&
                            <Grid Grid item xs={12} className='text-align-center mt-4 color-red'>
                                La fecha de finalización del calendario no puede ser anterior a la incial
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </div>
        </Modal >
    )
}