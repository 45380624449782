import React, { useState, useEffect, useContext } from 'react'
import { Grid } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import CalendarComponentV2 from 'components/Calendar/CalendarComponentV2'
import { FilterScada } from 'components/Filter/FilterScada'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { Loader } from 'components/Loader/Loader'
import { GlobalContext } from 'context/GlobalContext'
import { headersScada } from 'services/headers'
import { urls, SelfConsumptionOptions } from 'utils/constant'
import { IconDownloadFile } from 'utils/icon-centrocontrol'
import { translateSelf, isToday, compararFecha } from 'utils/Utils'
import { GraphComponetSelfConsumption } from './GraphComponetSelfConsumption'
import { ScadaDeviceHeader } from './ScadaDeviceHeader'
import { TableSelfConsumption } from './TableSelfConsumption'
import { headerTableSelf, powerChange } from './utilsSelfConsumption'

export const ComponentSelfConsumption = ({ selfConsumption, setActiveTab, setSelfConsumption, currentData, master, start, setStart,
    end, setEnd, daily, setDaily, devicesPlant, loading, setLoading, deviceChildren }) => {

    const token = sessionStorage?.token
    const location = useLocation()
    const idCcMaster = location.pathname.split('/')[4]
    let tabsGraphs = SelfConsumptionOptions[selfConsumption]?.find(obj => obj.tabsGraphs)?.tabsGraphs || []
    const { actualProject } = useContext(GlobalContext)
    const [data, setData] = useState([])
    const [loadingGraph, setLoadingGraph] = useState(true)
    const [deviceType, setDeviceType] = useState([])
    const [activeDeviceType, setActiveDeviceType] = useState()
    const [activeTabGraph, setActiveTabGraph] = useState(0)
    const [disabledGraphs, setDisabledGraphs] = useState(true)
    const [dataGraphs, setDataGraphs] = useState([])
    const [devicesSelect, setDevicesSelect] = useState([])
    const [matchingDeviceTypes, setMatchingDeviceTypes] = useState([])
    const [filterDeviceTab, setFilterDeviceTab] = useState([])
    const [fields, setFields] = useState(tabsGraphs?.[0]?.field)
    const maxDate = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    let currentDataToday = isToday(start) && daily === "days"
    let devices = devicesPlant
    let headers = SelfConsumptionOptions[selfConsumption]?.find(obj => obj.headers)?.headers || []
    let types = SelfConsumptionOptions[selfConsumption]?.find(obj => obj.type)?.type || []
    let colors = SelfConsumptionOptions[selfConsumption]?.find(obj => obj.colors)?.colors || []
    const filteredHeaders = headerTableSelf(currentDataToday, activeDeviceType, headers)

    const arrayTabsMenu = () => {
        setMatchingDeviceTypes([])
        const uniqueKeys = new Set();
        deviceChildren?.forEach(device => {
            const matchingType = types?.find(type => type?.key === device?.id_tipo_dispositivo);
            if (matchingType && !uniqueKeys?.has(matchingType.key)) {
                uniqueKeys?.add(matchingType?.key);
                setMatchingDeviceTypes(prev => [...prev, { key: matchingType?.key, name: matchingType?.name }]);
            }
        });
    }

    const ArrayDevicesType = async (type) => {
        let devSelect = [];
        let devicesIni = devices?.filter(dev => dev?.deviceType === type)
        if (devicesIni) {
            for (const dev of devicesIni) {
                devSelect = devSelect?.concat(dev?.devices);
            }
        }
        return devSelect;
    }

    const fetchData = async (numserie, date) => {
        let url
        let istoday = await isToday(date?.start)
        if (istoday && date?.daily === "days") {
            url = `getCurrentData/${numserie}?idCCMaster=${idCcMaster}`
        } else {
            url = `getHistoricalDataScada/${numserie}?idCCMaster=${idCcMaster}&iniDate=${date?.start?.format('YYYY-MM-DD')}&endDate=${date?.end?.format('YYYY-MM-DD')}&groupDate=${date?.daily}`
        }
        try {
            const res = await fetch(urls.scada + url, { method: 'GET', headers: headersScada(token) })
            if (res?.status === 200) {
                const json = await res?.json()
                return json
            } else
                return []
        } catch (error) {
            console.error("ERROR. API Scada", error)
        } finally {
        }
    }

    const todayArrayDevice = (datos) => {
        const today = new Date().toISOString().split('T')[0];
        return datos?.map(dev => {
            const deviceDate = new Date(dev?.datetime)?.toISOString().split('T')[0];
            if (deviceDate !== today) {
                return {
                    ...dev,
                    pact: null,
                    eaconsdia: null,
                    eagendia: null,
                };
            }
            return dev;
        });
    };

    const fetchGraphsPowerSelf = async (numserie, type, date, field) => {
        if (numserie && field !== "eaconsdia" && field !== "eagendia") {
            try {
                const res = await fetch(urls.scada + `chart/getDataChartsCurves/${numserie}?idCCMaster=${idCcMaster}`, {
                    method: 'POST', redirect: 'follow', headers: headersScada(token),
                    body: JSON.stringify(
                        {
                            "iniDate": date?.start?.format("YYYY-MM-DD"),
                            "endDate": date?.end?.format("YYYY-MM-DD"),
                            "iniTime": `00:00`,
                            "endTime": moment().format("YYYY-MM-DD") === date?.end?.format("YYYY-MM-DD") ? moment().format("HH:mm") : `23:59`,
                            "interval": "5",
                            "axis": [{
                                "idDeviceType": type,
                                "fields": [powerChange(field, type)],
                                "numseries": [`${numserie}`],
                                "unit": "kWh"
                            }]
                        }
                    )
                })
                if (res.status === 200) {
                    return res.json()
                } else {
                    return []
                }
            }
            catch (err) {
                console.error("ERROR. API Scada", err);
            }
        }
    }

    const fetchDataPhasesRed = async (numserie, date) => {
        try {
            const res = await fetch(urls.scada + `getPhases/${numserie}?idCCMaster=${idCcMaster}&date=${date.start.format('YYYY-MM-DD')}`, { method: 'GET', headers: headersScada(token) })
            if (res?.status === 200) {
                const json = await res?.json()
                return json;
            } else {
                return []
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const arrayTableComponentSelf = async (date) => {
        let type = await ArrayDevicesType(date.key)
        setDeviceType(type)
        try {
            let result = await Promise.all(type?.map(device => fetchData(device, date)));
            let arrayNew = result.flat();
            let dataDevice = [];
            if (isToday(date?.start)) {
                dataDevice = arrayNew?.map(dev => {
                    if (dev?.consumo) {
                        return dev?.consumo;
                    } else {
                        return dev?.currentData;
                    }
                });
            } else {
                dataDevice = arrayNew?.map(dev => {
                    let matchingDevice = devices?.find(item => item === dev?.numserie);
                    if (matchingDevice) {
                        return { ...dev, id_tipo_dispositivo: matchingDevice?.id_tipo_dispositivo };
                    }
                    return dev;
                });
            }
            if (isToday(date?.start)) {
                setData(todayArrayDevice(dataDevice));
            }
            else { setData(dataDevice) }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
        }
    };

    const arrayGraphsPowerSelf = async (objDate) => {
        setDataGraphs([])
        let result = await Promise.all(objDate?.filter?.map(device => fetchGraphsPowerSelf(device?.numserie, device?.id_tipo_dispositivo, objDate, objDate?.field)))
        setDataGraphs(result)
    }

    const arrayGraphsPhasesRed = async (objDate) => {
        setDataGraphs([])
        let result = await Promise.all(objDate.filter?.map(device => fetchDataPhasesRed(device.numserie, objDate)))
        setDataGraphs(result)
    }

    const handleDate = async (ele, filter, tab, field, key) => {
        setDataGraphs([])
        setLoadingGraph(true)
        const objDate = {
            start: ele?.start ?? start, end: ele?.end ?? end, daily: ele?.daily ?? daily ?? 'days',
            filter: filter ?? filterDeviceTab, tab: tab ?? activeTabGraph, field: field ?? field ?? fields, key: key ?? key ?? activeDeviceType
        }
        const visible = compararFecha(objDate?.start, objDate?.end)
        setDisabledGraphs(visible)
        if (ele) {
            setStart(ele?.start)
            setEnd(ele?.end)
            setDaily(ele?.daily)
        }
        await arrayTableComponentSelf(objDate)
        if (visible) {
            if (objDate.field !== "phases") {
                await arrayGraphsPowerSelf(objDate)
            }
            else if (objDate.field === "phases") {
                await arrayGraphsPhasesRed(objDate)
            }
        }
        setLoadingGraph(false)
    }

    const handleButton = () => {
        setSelfConsumption("general")
        setActiveTab(0)
    }

    const typeFilter = async (types) => {
        let type = await deviceChildren?.filter(dev => dev?.id_tipo_dispositivo === (types ? types : devicesPlant?.[0]?.deviceType))
        return type
    }

    const handleKey = async (key) => {
        setActiveDeviceType(key)
        typeFilter(key)
        let filter = await typeFilter(key)
        setFilterDeviceTab(filter)
        let fieldActive = await tabsGraphs?.find(item => item?.key === activeTabGraph)?.field
        await handleDate(false, filter, 0, fieldActive, key)
    }

    const handleTab = (tab, field) => {
        setActiveTabGraph(tab)
        handleDate(false, filterDeviceTab, tab, field)
    }

    useEffect(() => {
        setLoading(true)
        handleKey(devicesPlant?.[0]?.deviceType)
        setLoading(false)
        setDataGraphs([])
    }, [selfConsumption])

    useEffect(() => {
        const visible = compararFecha(start, end)
        if (!visible) {
            if (selfConsumption === "red") {
                if (activeTabGraph === 3 || activeTabGraph === 0) {
                    setActiveTabGraph(1)
                }
                setFields(tabsGraphs?.[2]?.field)
            } else {
                setActiveTabGraph(1)
                setFields(tabsGraphs?.[1]?.field)
            }
        }
    }, [start, end, selfConsumption])

    useEffect(() => {
        arrayTabsMenu()
    }, [deviceType])

    return (
        <> {loading && <Loader />}
            {!loading && devicesPlant?.length > 0 &&
                <Grid className="container-scada-component" item sm={12} lg={12} xl={12}>
                    <span><ScadaDeviceHeader handleButton={handleButton} type={selfConsumption} currentData={currentData}></ScadaDeviceHeader>
                        <Grid item xs={12} className="calendar-component-scada-self">
                            <span className="filter-component-calendar">
                                <CalendarComponentV2 start={start} end={end} maxDate={maxDate} handleDate={handleDate} select={true} />
                                <div className="ml-3 d-flex align-items-center">
                                    {data &&
                                        <CSVLink data={data} headers={filteredHeaders} separator={";"} style={{ color: "white", marginTop: "3px" }} filename={`${actualProject.nick} - ${translateSelf(selfConsumption)}}._${start ? start?.format("DD-MM-YYYY") : " "}_${end ? end?.format("DD-MM-YYYY") : " "}.csv`}>
                                            <HtmlTooltip title={<span>Descargar datos</span>}>
                                                <IconDownloadFile />
                                            </HtmlTooltip>
                                        </CSVLink>}
                                </div>
                            </span>
                        </Grid>
                    </span>
                    {devices?.length > 0 &&
                        <><span className='listItem-componente'>
                            <ul className="nav nav-tabs">
                                {matchingDeviceTypes?.map(dev => {
                                    return (
                                        <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt ${activeDeviceType === dev?.key && "active-button"} `} onClick={() => handleKey(dev?.key)}>
                                            {dev?.name}
                                        </li>
                                    )
                                })}
                            </ul>
                        </span>
                            {filterDeviceTab?.length > 0 &&
                                <span>
                                    <FilterScada device={filterDeviceTab} selfConsumption={selfConsumption} setDevicesSelect={setDevicesSelect} devicesSelect={devicesSelect} master={master} />
                                </span>
                            }</>
                    }
                    <>
                        <span className="container-component-table-self-scada">
                            <TableSelfConsumption start={start} data={data} devicesSelect={devicesSelect} filteredHeaders={filteredHeaders} loading={loading} />
                        </span>
                        <span className="container-component-chart-self-scada">
                            < GraphComponetSelfConsumption setFields={setFields} fields={fields} dataGraphs={dataGraphs} disabledGraphs={disabledGraphs}
                                activeTabGraph={activeTabGraph} loading={loadingGraph} setActiveTab={setActiveTabGraph} currentDataToday={currentDataToday}
                                data={data} selfConsumption={selfConsumption}
                                tabsGraphs={tabsGraphs} typeDevice={activeDeviceType} colors={colors} deviceType={deviceType} devicesSelect={devicesSelect}
                                childrenDevices={filterDeviceTab} daily={daily} start={start} end={end} activeTab={activeTabGraph} setHandleTab={handleTab} />
                        </span>
                    </>
                </Grid >
            }
        </>
    )
}
