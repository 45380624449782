import React, { useState, useRef, useEffect } from 'react'
import { Grid, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { CSVLink } from 'react-csv'
import { Loader } from "components/Loader/Loader"
import { Modal } from 'components/Modal/Modal'
import { StyledTableCell, StyledTableRow, StyledTableCellHead } from 'components/Table/TableStyle'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { headersCentroControl } from 'services/headers'
import { urls } from "utils/constant.jsx"
import { IconDownloadFile } from 'utils/icon-centrocontrol'
import { stateUpdateInstal } from 'utils/Utils'

export const FirmwareHistory = ({ configuracion, modalHistory, setModalHistory, actualProject }) => {
    const token = sessionStorage?.token
    const [data, setData] = useState([]);
    const [dataFirm, setDataFirm] = useState([])
    const [loading, setLoading] = useState(true)
    const tableRef = useRef(null)

    const headers = [
        { label: 'Nº serie', key: 'numserie' },
        { label: 'Fecha', key: 'fecha' },
        { label: 'FW anterior', key: 'oldFW' },
        { label: 'FW actual', key: 'newFW' },
        { label: 'Estado', key: 'status' },
    ];

    const fetchHistoricalFirmware = (numserie) => {
        setLoading(true)
        fetch(urls.ccontrol + `getHistoricalVersions/${numserie}`, { method: 'GET', headers: headersCentroControl(token) })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    return []
                }
            })
            .then(data => {
                setData(data)
            })
            .catch(err => console.error("ERROR. API Scada", err))
            .finally(() =>
                setLoading(false)
            )
    }

    useEffect(() => {
        fetchHistoricalFirmware(configuracion.numserie)
    }, [configuracion])

    useEffect(() => {
        if (data?.length > 0) {
            setDataFirm(data?.map(dev => ({ ...dev, status: stateUpdateInstal(dev.status) })))
        }
    }, [data])

    return (
        <>
            <Modal maxWidth="lg" open={modalHistory} setOpen={setModalHistory} title={`Histórico de actualizaciones de ${actualProject?.category === "mabt" ? 'MABT de ' : ''} "${configuracion.nick}"`} footer=" ">
                <hr className="m-0" />
                {loading && <Grid items xs={12} md={12} lg={12} xl={12} className="center-loading-error-container"><Loader /></Grid>}
                {!loading && data?.length <= 0 && <Grid items xs={12} md={12} lg={12} xl={12} className="center-loading-error-container">
                    <h2>Ha sido imposible recabar la información</h2>
                </Grid>}
                {!loading && data.length > 0 &&
                    <Grid item lg={12} style={{ padding: '2%' }}>
                        <div>
                            <CSVLink data={dataFirm} headers={headers} separator={";"} style={{ color: "white", marginTop: "3px" }} filename={`${configuracion?.nick} - Histórico de firmware.csv`}>
                                <HtmlTooltip title={<span>Descargar datos</span>}>
                                    <IconDownloadFile />
                                </HtmlTooltip>
                            </CSVLink>
                        </div>
                        <TableContainer style={{ maxHeight: '30em', minHeight: '30em', marginTop: '0px' }}>
                            <Table ref={tableRef} sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead style={{ borderBottom: '2px solid #FF8A1F', borderRadius: '50px' }}>
                                    <TableRow >
                                        {headers && headers?.map(head => {
                                            return (
                                                <StyledTableCellHead>{head.label}</StyledTableCellHead>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataFirm?.map((obj) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell><p>{obj.numserie}</p></StyledTableCell>
                                                <StyledTableCell><p>{obj.fecha}</p></StyledTableCell>
                                                <StyledTableCell><p>{obj.oldFW}</p></StyledTableCell>
                                                <StyledTableCell><p>{obj.newFW}</p></StyledTableCell>
                                                <StyledTableCell><p>{obj.status}</p></StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                }
            </Modal>
        </>
    )
}