import { useState } from "react"
import { IconScadaCalendarEnerSim, IconEditPencil } from "utils/icon-centrocontrol"
import { convertDatetime } from "utils/Utils"
import { getStatus } from "utils/Utils"
import { ModalEditInfoSim } from "views/Modals/ModalSim/ModalEditInfoSim"
import ChartInlineEnersim from "components/Charts/ChartInLineEnersim"

export const ScadaEnerSimForm = ({ enerSimData, measuringData }) => {
    const [openEdit, setOpenEdit] = useState(false)
    const lastMonth = measuringData?.measuringMonths[measuringData?.measuringMonths?.length - 1]
    const totalUsageMB = lastMonth?.total_usage_mb

    const { colorClass, status, icon } = getStatus(enerSimData.status)

    return ( 
        <>
            <div className="form-container">
                <div className="form-title-container">
                    <p className="installation-name">Nombre:</p>
                    <span onClick={() => setOpenEdit(!openEdit)}>
                        <IconEditPencil className='boton-edit-hijo edit-icon'/>
                    </span>
                </div>
                <div className="pt-2">
                    <span>{enerSimData.installationName ? enerSimData.installationName  : "-"}</span> 
                </div>
                <p className="form-title">IMEI:</p>
                <span>{enerSimData.numserie_sim ? enerSimData.numserie_sim : "-"}</span>
                <p className="form-title">Estado:</p>
                <div className="flex items-center">
                    {icon}
                    <span className={`${colorClass} status pl-2`}>{status} {convertDatetime(enerSimData.datetime)}</span> 
                </div>
                <p className="form-title">Puertos:</p>
                   <div className="table-container">
                        <table className="custom-table">
                            <thead class="thead-table">
                                <tr>
                                    <th scope="col" className="p-4"></th> 
                                    <th scope="col" className="pl-3 py-2">
                                        Puerto
                                    </th>
                                    <th scope="col" className="pl-3 py-2">
                                        IP
                                    </th>
                                    <th scope="col" className="pl-3 py-2">
                                        Puerto público
                                    </th>
                                    <th scope="col" className="pl-3 py-2">
                                        Puerto interno
                                    </th>
                                    <th scope="col" className="pl-3 py-2">
                                        Interfaz Ip-Meter
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="tbody-table">
                                    <td className="pl-3 py-4">
                                    </td> 
                                    <td className="pl-3 py-4">
                                    </td>
                                    <td className="pl-3 py-4">
                                    </td>
                                    <td className="pl-3 py-4">
                                    </td>
                                    <td className="pl-3 py-4">
                                    </td>
                                    <td className="pl-3">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                <div className="flex gap-4 py-3">
                    <IconScadaCalendarEnerSim />
                    <div className="flex flex-col">
                        <span className="bold-text">Detalles consumo</span>
                        <span className="small-text">30 días para reiniciar tu consumo</span>
                    </div>
                </div>
                <div className="pt-2">
                    <ChartInlineEnersim used={totalUsageMB ? totalUsageMB : "-"} total={measuringData?.tariff} />
                </div>
                <p className="bonus">Mi bono</p>
            </div>
        <ModalEditInfoSim open={openEdit} setOpen={setOpenEdit} simData={enerSimData} />
    </>
    )
}