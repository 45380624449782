import React from 'react'
import { Droppable } from '@hello-pangea/dnd'
import Item from './Item'

const Column = ({ id, list }) => {
  return (
    <Droppable droppableId={id} key={id}>
      {(provided) => (
        <div className="styled-column">
          <div className="styled-list" {...provided.droppableProps} ref={provided.innerRef}>
            {list?.map((device, index) => (
              <Item key={device.numserie} text={device.nick} index={index} />
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  )
}

export default Column