import React, { useEffect, useState } from 'react'
import { Grid, MenuItem } from '@mui/material'
import moment from 'moment'
import CalendarComponentV2 from 'components/Calendar/CalendarComponentV2'
import { SelectStyled } from 'components/Select/SelectStyled'
import { Loader } from 'components/Loader/Loader'
import { apiScada } from 'services/api'
import { esHoy } from 'utils/Utils'
import { TableSensorScada } from './TablaSensorScada'
import { cargarDatosGrafica } from '../utils'
import { GraficaMabt } from '../GraficaMabt'

export const ScadaSensor = ({ mabt, device, medidores }) => {
    const maxDate = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    const [start, setStart] = useState(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
    const [end, setEnd] = useState(moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }))
    const [daily, setDaily] = useState('days')
    const [dataChartsCurves, setDataChartsCurves] = useState()
    const [dataChartsCurvesDelta, setDataChartsCurvesDelta] = useState()
    const [dataTable, setDataTable] = useState()
    const [loading, setLoading] = useState(false)
    const [selectMed, setSelectMed] = useState(device?.numserie)
    
    const getDataTable = async (url) => {
        try {
            const result = await apiScada.get(url)
            if(result?.status === 200) {
                return result?.data
            }
        } catch (err) {
            console.error("ERROR. API Scada")
            return 'Error'  
        }
    } 

    const getChartCurves = async (url, start, end, med) => {
        const body = {
            iniDate: moment(start).format('YYYY-MM-DD'),
            endDate: moment(end).format('YYYY-MM-DD'),
            iniTime: moment(start).format('HH:mm'),
            endTime: esHoy(end) ? moment().format('HH:mm') : moment(end).format('HH:mm'),
            interval: '1'
        }
        if (med) {
            body.axis= [
                {
                    idDeviceType: 21,
                    fields: [
                        "tension"
                    ],
                    numseries: [
                        "523011112"
                    ],
                    unit: "V"
                }
            ]
        }
        try {
            const result = await apiScada.post(url, body)
            if(result?.status === 200) {
                if (result?.data !== 'Error' && result?.data?.categories?.length > 0) {
                    return cargarDatosGrafica(result?.data)
                } else {
                    return 'Error'
                }
            }
        } catch (err) {
            console.error("ERROR. API Scada")
            return 'Error'  
        }
    } 

    const handleDate = async (ele) => {
        setLoading(true)
        const objDate = {start: ele?.start ?? start, end: ele?.end ?? end, daily: ele?.daily ?? daily }
        if (ele) {
            setStart(ele?.start)
            setEnd(ele?.end)
            setDaily(ele?.daily)
        }
        const sensors = medidores?.find(med => Number(med?.numserie) === Number(selectMed))?.sensor
        if (sensors?.length > 0) {
            const resultsGraph = await Promise.all(sensors?.map(numserie => getChartCurves(`chart/getDataChartsCurves/${numserie}?idCCMaster=${numserie}`, objDate?.start, objDate?.end)))
            const resultsDelta = await Promise.all(sensors?.map(numserie => getChartCurves(`deltaSensor/${numserie}?idCCMaster=${numserie}`, objDate?.start, objDate?.end)))
            const resultsTable = await Promise.all(sensors?.map(numserie => getDataTable(`getHistoricalDataScada/${numserie}?idCCMaster=${numserie}&iniDate=${moment(objDate?.start).format('YYYY-MM-DD')}&endDate=${moment(objDate?.end).format('YYYY-MM-DD')}&groupDate=${objDate?.daily}`)))
            const resultsTableMed = await getDataTable(`getHistoricalDataScada/${selectMed}?idCCMaster=${mabt}&iniDate=${moment(objDate?.start).format('YYYY-MM-DD')}&endDate=${moment(objDate?.end).format('YYYY-MM-DD')}&groupDate=${objDate?.daily}`)
            const resultsGraphMed = await getChartCurves(`chart/getDataChartsCurves/${selectMed}?idCCMaster=${mabt}`, objDate?.start, objDate?.end, true)
            let resultFinal = {categories: [], data: []}
            let resultFinalDelta = {categories: [], data: []}
            let resultFinalTable = []
            resultsGraph?.forEach(res => {
                if (res !== 'Error') {
                    resultFinal.categories = res?.categories
                    resultFinal.data = resultFinal?.data?.concat(res?.data)
                }
            })
            if (resultsGraphMed !== 'Error') {
                resultFinal.categories = resultsGraphMed?.categories
                resultFinal.data = resultFinal?.data?.concat(resultsGraphMed?.data)
            }
            resultsDelta?.forEach(res => {
                if (res !== 'Error') {
                    resultFinalDelta.categories = res?.categories
                    resultFinalDelta.data = resultFinalDelta?.data?.concat(res?.data)
                }
            })
            resultsTable?.forEach(res => {
                if (res !== 'Error') {
                    resultFinalTable = resultFinalTable?.concat(res)
                }
            }) 
            if (resultsTableMed !== 'Error') {
                resultFinalTable = resultFinalTable?.concat(resultsTableMed)
            }
            setDataChartsCurves(resultFinal?.categories?.length === 0 ? 'Error' : resultFinal)
            setDataChartsCurvesDelta(resultFinalDelta?.categories?.length === 0 ? 'Error' : resultFinalDelta)
            setDataTable(resultFinalTable?.length === 0 ? 'Error' : resultFinalTable)
        }
        setLoading(false)
    }

    useEffect(() => {
        handleDate()
    }, [selectMed])

    return (
        <Grid container className='mt-5'>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <h2>Detalle Tensiones</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='flex-center justify-space-between mt-5'>
                <SelectStyled value={selectMed} onChange={(e) => setSelectMed(e.target.value)} id='SelectMedSensor' className='width-15-em'>
                    {medidores?.map(med => {
                        return <MenuItem value={med?.numserie}>{med?.nick}</MenuItem>
                    })}
                </SelectStyled>
                <div className='flex'>
                    <CalendarComponentV2 handleDate={handleDate} maxDate={maxDate} notOpen={true} select={true}/>
                </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-5 pl-5 pr-5'>
                {loading && <Loader/>}                      
                {!loading && dataTable === 'Error' && 
                    <h3 className='texto-error-carga'>
                        Error al cargar los datos de la tabla, contacte con el soporte técnico.
                    </h3>
                } 
                {!loading && dataTable !== 'Error' && <TableSensorScada data={dataTable} hoy={esHoy(start)}/>}
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-5 pl-5 pr-5'>
                {loading && <Loader/>}                
                {!loading && dataChartsCurves === 'Error' && 
                    <h3 className='texto-error-carga'>
                        Error al cargar los datos de la gráfica, contacte con el soporte técnico.
                    </h3>
                }
                {!loading && dataChartsCurves !== 'Error' && <GraficaMabt datosGrafica={dataChartsCurves} escalaTipo={'V'} tipoGrafica={'Tension'} />}
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-5'>
                <h2>Delta de Valores</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 pl-5 pr-5'>
                {loading && <Loader/>}                
                {!loading && dataChartsCurves === 'Error' && 
                    <h3 className='texto-error-carga'>
                        Error al cargar los datos de la gráfica, contacte con el soporte técnico.
                    </h3>
                }
                {!loading && dataChartsCurves !== 'Error' && <GraficaMabt datosGrafica={dataChartsCurvesDelta} escalaTipo={'V'} tipoGrafica={'Tension'} />}
            </Grid>
        </Grid>
    )
}