import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Link from '@mui/material/Link'
import { Grid, TextField } from '@mui/material'
import { validatorInput } from 'utils/ValidatorForm'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { headersScada } from 'services/headers'
import { mostrarFecha } from 'utils/Utils'
import { EliminarComentario } from './EliminarComentario'
import { urls } from 'utils/constant'

/**
 * @param {{comentarioPublico: {comment: string, datetime: string}, token: string, setNuevo: Function}}
 * @param comentarioPublico Comentario publico de la alarma
 * @param token Token para realizar la llamada
 * @param setNuevo Funcion para actualizar los comentarios
 * @returns Devuelve el comentario publico de la alarma
 */
export const ComentarioPublico = ({ token, comentarioPublico, setNuevo }) => {
    const [disabled, setDisabled] = useState(true)
    const [comentario, setComentario] = useState(comentarioPublico.comment)
    const [comentarioPrevio, setComentarioPrevio] = useState()
    const [keyInputComentarioAlarm, setKeyInputComentarioAlarm] = useState(Date.now() + 'comentarioAlarmaPublico')
    const [fecha, setFecha] = useState(comentarioPublico.datetime)
    const [modalEliminar, setModalEliminar] = useState(false)
    const [error, setError] = useState('')
    const [invalidComment, setInvalidComment] = useState(false)

    /**
     * @returns Actualiza el comentario publico de la alarma
     */
    const fetchModificarComentario = async () => {
        let now = moment()
        try {
            const res = await fetch(urls.scada + 'alarms/updateCommentAlarm', { method: 'PATCH', headers: headersScada(token), body: JSON.stringify({ id: comentarioPublico.id, comment: comentario }) })
            if (res.status === 200) {
                setDisabled(true)
                setFecha(now)
            } else {
                setError('Error al actualizar el comentario')
            }
            return true
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const handleChangeComment = (e) => {
        setComentario(e)
        setInvalidComment(validatorInput(e))
    }

    useEffect(() => {
        if (comentario.length > 300) {
            setError('No se puede crear un mensaje con más de 300 caracteres')
        }
        if (comentario.length <= 300 && error) {
            setError()
        }
    }, [comentario])

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <div className='pb-1'>
                        <small className="font-weight-bold">{comentarioPublico.user} <small className="font-weight-lighter">{mostrarFecha(fecha)}</small></small>
                    </div>
                    <TextField
                        fullWidth
                        className="style-textfield-text"
                        minRows={1}
                        key={keyInputComentarioAlarm}
                        value={comentario}
                        disabled={disabled}
                        error={invalidComment}
                        helperText={invalidComment ? 'Caracteres no permitidos' : ''}
                        onChange={(e) => handleChangeComment(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    {error && <small className="color-red-error">{error}</small>}
                </Grid>
                <Grid item xs={12} className='mb-3'>
                    {comentarioPublico.edit === 1 &&
                        <>
                            {disabled ?
                                <>
                                    <Link href="#" variant="body2" className='mr-2' onClick={(event) => { event.preventDefault(); setComentarioPrevio(comentario); setDisabled(!disabled) }}>
                                        <small className="font-weight-bold text-underline" >Editar</small>
                                    </Link>
                                    <Link href="#" variant="body2" className='mr-2' onClick={(event) => { event.preventDefault(); setModalEliminar(true) }}>
                                        <small className="font-weight-bold text-underline" >Eliminar</small>
                                    </Link>
                                </>
                                :
                                <>
                                    <ButtonStyled className='boton-historico mt-2' disabled={comentario.length > 300 || !comentario || invalidComment} onClick={() => { fetchModificarComentario() }}>
                                        Guardar
                                    </ButtonStyled>
                                    {comentarioPublico?.comment !== undefined &&
                                        <ButtonStyled background='white' colorHover='red' color='#212529' backgroundHover="#FFFFFF" border="2px solid red" className="ml-2 mt-2 boton-cancelar-dialog" onClick={() => { setComentario(comentarioPrevio); setKeyInputComentarioAlarm(Date.now() + 'comentarioAlarmaPublico'); setDisabled(!disabled); setInvalidComment(false) }}>Cancelar</ButtonStyled>
                                    }
                                </>
                            }
                        </>
                    }
                </Grid>
            </Grid>
            {modalEliminar && <EliminarComentario token={token} id={comentarioPublico.id} modalEliminar={modalEliminar} setModalEliminar={setModalEliminar} setNuevo={setNuevo} />}
        </>
    )
}