import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { GlobalContext } from 'context/GlobalContext'
import { updateDeviceTree } from 'reduxSlice/treeDevicesSlice'
import { createToast } from 'reduxSlice/toastSlice'
import { headersScada } from 'services/headers'
import { urls, dispositivos } from 'utils/constant'

export const ModalConfigChangeInstall = ({ centroControl, open, setOpen, data, activeTab, idMaster, setDataInitial, dataPotMax, setAnswer,
    setDisabled, setDisabledTable, dataInitialPmax, setDataInitialPmax, masterConfigInstall, dataConfigInstall, master }) => {
    const token = sessionStorage?.token
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { setUpdateDevice, actualProject } = useContext(GlobalContext)

    const arrayBody = async () => {
        let body = []
        if (masterConfigInstall?.length > 0) {
            body = await data?.concat(masterConfigInstall)
        } else {
            body = data
        }
        return body
    }

    const fetchUpdatePlant = async () => {
        setLoading(true)
        let body = await arrayBody()
        let status
        try {
            const res = await fetch(urls.scada + `configPlantInstallation/${idMaster}?idCcMaster=${idMaster}`, { method: 'PATCH', headers: headersScada(token), body: JSON.stringify(body) })
            if (res.status === 200) {
                status = res.status
                setDataInitial(JSON.parse(JSON.stringify(data)))
                dataConfigInstall.error = actualProject.category !== masterConfigInstall[0].config.plant_type ? 1 : 0
                dataConfigInstall.inst_type = masterConfigInstall[0].config.plant_type
                setDisabled(true)
                setDisabledTable(true)
                if (dataConfigInstall?.error === 1) {
                    dispatch(updateDeviceTree({ device: idMaster, value: true }))
                }
                setAnswer([{ status: status, message: "Instalación actualizada con éxito" }])
                dispatch(createToast({ status: status, message: "Configuración actualizada correctamente", reload: false }))
                setUpdateDevice(current => !current)
            } else {
                status = res.status
                setAnswer([{ status: false, message: "Ha habido un error al actualizar la instalación" }])
                dispatch(createToast({ status: status, message: "Ha habido un error al actualizar la configuración", reload: false }))
            }
            return true
        } catch (err) {
            console.error("Ha habido un erroe en API Scada", err)
        } finally {
            setOpen(false)
            setLoading(false)
        }
    }

    const fetchUpdatePotMax = () => {
        let status
        setLoading(true)
        fetch(urls.scada + `updateDevicePlant/${idMaster}?idCCMaster=${idMaster}`, { method: 'PATCH', headers: headersScada(token), body: JSON.stringify(dataPotMax) })
            .then(res => {
                if (res.status === 200) {
                    setDisabled(true)
                    AcceptChangePoxMax()
                    setUpdateDevice(current => !current)
                    dispatch(createToast({ status: status, message: "Configuración actualizada correctamente", reload: false }))
                } else {
                    status = res.status
                    dispatch(createToast({ status: status, message: "Ha habido un error al actualizar la configuración", reload: false }))
                }
                return res.json()
            }).then(data => {
                setAnswer(data)
            }).catch((error) => {
                console.error("Ha habido un erroe en API Scada", error)
            }).finally(() => {
                setOpen(false)
                setLoading(false)
            })
    }

    const fetchUpdatePotMaxLoose = async (numserie, body) => {
        let status
        try {
            const res = fetch(urls.scada + `updateDeviceInfo/${numserie}`, {
                method: 'PATCH', headers: headersScada(token), body: JSON.stringify({
                    "nick": body?.nick,
                    "potencia_contratada": body?.potencia_contratada
                })
            })
            if (res.status === 200) {
                setDisabled(true)
                AcceptChangePoxMax()
                setUpdateDevice(current => !current)
                dispatch(createToast({ status: status, message: "Configuración actualizada correctamente", reload: false }))
                return res.json()
            } else {
                status = res.status
                dispatch(createToast({ status: status, message: "Ha habido un error al actualizar la configuración", reload: false }))
            }
        } catch {
            console.error("Ha habido un erroe en API Scada")
        } finally {
            setOpen(false)
            setLoading(false)
        }
    }

    const AcceptChangePoxMax = () => {
        const arrayNew = dataInitialPmax?.map(devIni => {
            const match = dataPotMax?.find(dev => dev?.numserie === devIni?.numserie)
            if (match) {
                return {
                    ...devIni,
                    potencia_contratada: match?.potencia_contratada,
                    nick: match?.nick
                }
            }
            return devIni
        })
        setDataInitialPmax(arrayNew)
    }

    const bodyPotMax = async () => {
        setLoading(true)
        await Promise.all(dataPotMax?.map(device => fetchUpdatePotMaxLoose(device.numserie, device)))
        setLoading(false)
    }

    const ApplyChange = () => {
        if (centroControl || master?.[0]?.id_tipo_dispositivo === dispositivos.ccmaster) {
            if (activeTab === 0) {
                fetchUpdatePlant()
            } else if (activeTab === 1) {
                fetchUpdatePotMax()
            }
        } else {
            bodyPotMax()
        }
    }

    return (
        <Modal open={open} setOpen={setOpen} title="Configurar instalación" disabled={loading} functSave={() => ApplyChange()} loading={loading}>
            <Grid className="modal-eliminar-alarma" item lg={12}>
                <h4 className="font-weight-lighter text-center mt-2" style={{ fontSize: '1.2rem' }}>
                    ¿Desea aplicar el cambio en la {activeTab === 0 && "configuración de la Planta ?"} {activeTab === 1 && "configuración de los dispositivos?"}
                </h4>
            </Grid>
        </Modal>
    )
}