export const variableUnits = {

    vtn: "(V)",

    p: "(kW)",
    p1: "(kW)",
    p2: "(kW)",
    p3: "(kW)",

    i: '(A)',
    i1: '(A)',
    i2: '(A)',
    i3: '(A)',

    v: '(V)',
    v1: '(V)',
    v2: '(V)',
    v3: '(V)',

    pre: '(var)',
    pre1: '(var)',
    pre2: '(var)',
    pre3: '(var)',

    it: '(A)',
    in: '(A)',

    pac: '(kW)',
    pac1: '(kW)',
    pac2: '(kW)',
    pac3: '(kW)',

    pact: '(kW)',
    pact1: '(kW)',
    pact2: '(kW)',
    pact3: '(kW)',

    pap: '(kVA)',
    pap1: '(kVA)',
    pap2: '(kVA)',
    pap3: '(kVA)',

    freq: "(Hz)",
    freq1: "(Hz)",
    freq2: "(Hz)",
    freq3: "(Hz)",

    preac: '(kVAr)',
    preac1: '(kVAr)',
    preac2: '(kVAr)',
    preac3: '(kVAr)',

    eacons: '(kWh)',
    eacons1: '(kWh)',
    eacons2: '(kWh)',
    eacons3: '(kWh)',

    eagen: '(kWh)',
    eagen1: '(kWh)',
    eagen2: '(kWh)',
    eagen3: '(kWh)',


    idc: "(A)",
    idc1: "(A)",
    idc2: "(A)",
    idc3: "(A)",
    idc4: "(A)",
    idc5: "(A)",
    idc6: "(A)",
    idc7: "(A)",
    idc8: "(A)",
    idc9: "(A)",
    idc10: "(A)",
    idc11: "(A)",
    idc12: "(A)",
    idc13: "(A)",
    idc14: "(A)",

    vdc: "(V)",
    vdc1: "(V)",
    vdc2: "(V)",
    vdc3: "(V)",
    vdc4: "(V)",
    vdc5: "(V)",
    vdc6: "(V)",
    vdc7: "(V)",
    vdc8: "(V)",
    vdc9: "(V)",
    vdc10: "(V)",
    vdc11: "(V)",
    vdc12: "(V)",
    vdc13: "(V)",
    vdc14: "(V)",

    pdc: "(kW)",
    pdc1: "(kW)",
    pdc2: "(kW)",
    pdc3: "(kW)",
    pdc4: "(kW)",
    pdc5: "(kW)",
    pdc6: "(kW)",
    pdc7: "(kW)",
    pdc8: "(kW)",
    pdc9: "(kW)",
    pdc10: "(kW)",
    pdc11: "(kW)",
    pdc12: "(kW)",
    pdc13: "(kW)",
    pdc14: "(kW)",

    v_input: "(V)",
    v_output: "(V)",



    pf: '(%)',
    pf1: '(%)',
    pf2: '(%)',
    pf3: '(%)',


    vf: "(V)",
    vf1: "(V)",
    vf2: "(V)",
    vf3: "(V)",

    temp: '(Cº)',
    temperatura: "(Cº)",
    temp1: "(Cº)",
    temp2: "(Cº)",
    temp3: "(Cº)",

    eaconsdia: '(kWh)',
    eagendia: '(kWh)',

    eapa: "(VAh)",
    eapa1: "(VAh)",
    eapa2: "(VAh)",
    eapa3: "(VAh)",

    thdv: "(%)",
    thdv1: "(%)",
    thdv2: "(%)",
    thdv3: "(%)",

    thdi: "(%)",
    thdi1: "(%)",
    thdi2: "(%)",
    thdi3: "(%)",

    eact: "(Wh)",
    eact1: '(Wh)',
    eact2: '(Wh)',
    eact3: '(Wh)',

    eact_q14: '(Wh)',
    eact1_q14: '(Wh)',
    eact2_q14: '(Wh)',
    eact3_q14: '(Wh)',

    eact_q23: '(Wh)',
    eact1_q23: '(Wh)',
    eact2_q23: '(Wh)',
    eact3_q23: '(Wh)',

    vab: "(V)",
    vbc: "(V)",
    vca: "(V)",

    if: '(A)',
    if1: '(A)',
    if2: '(A)',
    if3: '(A)',


    ereact: '(varh)',
    ereact1: '(varh)',
    ereact2: '(varh)',
    ereact3: '(varh)',

    ereact_q1: '(varh)',
    ereact1_q1: '(varh)',
    ereact2_q1: '(varh)',
    ereact3_q1: '(varh)',

    ereact_q2: '(varh)',
    ereact1_q2: '(varh)',
    ereact2_q2: '(varh)',
    ereact3_q2: '(varh)',

    ereact_q3: '(varh)',
    ereact1_q3: '(varh)',
    ereact2_q3: '(varh)',
    ereact3_q3: '(varh)',

    ereact_q4: '(varh)',
    ereact1_q4: '(varh)',
    ereact2_q4: '(varh)',
    ereact3_q4: '(varh)',

    ereact_q12: '(varh)',
    ereact1_q12: '(varh)',
    ereact2_q12: '(varh)',
    ereact3_q12: '(varh)',

    ereact_q23: '(varh)',
    ereact1_q23: '(varh)',
    ereact2_q23: '(varh)',
    ereact3_q23: '(varh)',

    ereact_q34: '(varh)',
    ereact1_q34: '(varh)',
    ereact2_q34: '(varh)',
    ereact3_q34: '(varh)',

    ereact_q41: '(varh)',
    ereact1_q41: '(varh)',
    ereact2_q41: '(varh)',
    ereact3_q41: '(varh)',

    ereact_q13: '(varh)',
    ereact1_q13: '(varh)',
    ereact2_q13: '(varh)',
    ereact3_q13: '(varh)',

    ereact_q24: '(varh)',
    ereact1_q24: '(varh)',
    ereact2_q24: '(varh)',
    ereact3_q24: '(varh)',

    ereac: '(varh)',
    ereac1: '(varh)',
    ereac2: '(varh)',
    ereac3: '(varh)',

    ereac_q1: '(varh)',
    ereac1_q1: '(varh)',
    ereac2_q1: '(varh)',
    ereac3_q1: '(varh)',

    ereac_q2: '(varh)',
    ereac1_q2: '(varh)',
    ereac2_q2: '(varh)',
    ereac3_q2: '(varh)',

    ereac_q3: '(varh)',
    ereac1_q3: '(varh)',
    ereac2_q3: '(varh)',
    ereac3_q3: '(varh)',
    ereac_q4: '(varh)',
    ereac1_q4: '(varh)',
    ereac2_q4: '(varh)',
    ereac3_q4: '(varh)',

}
