import React, { useState } from "react"
import moment from "moment"
import { Grid, Typography, Menu } from "@mui/material"
import { ArrowRight, ArrowLeft } from "utils/icon-centrocontrol"
import { obtenerNombreMes } from "utils/Utils"
import { SelectorFechaV2 } from "views/SelectorCalendar/SelectorFechaV2"
import { CalendarRowButtons } from "./CalendarRowButtons"
import { CalendarDatePicker } from "./CalendarDatePicker"
import { CalendarButton } from "./CalendarButton"
import "./CalendarComponent.scss"

export const CalendarComponentV2 = ({ handleDate, className, maxDate, select, start, end, notOpen }) => {
    const today = moment()
    const startOfToday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const endOfToday = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    const [anchorEl, setAnchorEl] = useState(null)
    const [date, setDate] = useState(start ?? startOfToday)
    const [endDate, setEndDate] = useState(end ?? endOfToday)
    const [isRange, setIsRange] = useState(false)
    const [dateType, setDateType] = useState("days")
    const [selectedButton, setSelectedButton] = useState("Hoy")

    const handleChange = (obj) => {
        if (obj.start) {
            setDate(obj?.start)
        }
        if (obj?.end) {
            setEndDate(obj?.end)
        }
        if (obj?.selectedButton) {
            setSelectedButton(obj?.selectedButton)
        }
        if (obj?.daily) {
            setDateType(obj?.daily)
        }
        let valueRange = obj?.selectedButton ? (obj?.selectedButton === 'Rango' ? true : false) : isRange
        setIsRange(valueRange)
        if (obj?.close) {
            setAnchorEl(null)
        } else {
            if (anchorEl === null) {
                handleDate(obj)
            }
        }
    }

    // Abre el menú
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    // Cierra el calendario y setea la fecha de hoy
    const handleClose = () => {
        if (!isRange) {
            setAnchorEl(null)
        }
        if (isRange && date <= endDate) {
            setAnchorEl(null)
            const objDate = {
                start: date,
                end: endDate,
                daily: dateType,
            }
            handleDate(objDate)
        }
    }

    // Formatea la fecha
    const getDateFormat = (date) => {
        return moment(date).format("DD-MM-YYYY")
    }

    const handleChangeDate = (newValue) => {
        const newStartDate = moment().set({ year: newValue.getFullYear(), month: newValue.getMonth(), date: newValue.getDate(), hour: 0, minute: 0, second: 0, millisecond: 0 })
        const newEndDate = moment().set({ year: newValue.getFullYear(), month: newValue.getMonth(), date: newValue.getDate(), hour: 23, minute: 59, second: 59, millisecond: 999 })
        let objDate = {
            start: newStartDate,
            daily: "days",
            close: !isRange
        }
        if (!isRange) {
            objDate.end = newEndDate
            objDate.selectedButton = "Hoy"
            handleDate(objDate)
        }
        handleChange(objDate)
    }

    // Ir a la fecha de hoy
    const handleToday = () => {
        const objDate = {
            start: startOfToday,
            end: endOfToday,
            selectedButton: "Hoy",
            daily: "days",
            range: false,
            close: true
        }
        handleDate(objDate)
        handleChange(objDate)
    }

    // Ir a la fecha de ayer
    const handleYesterday = () => {
        const newStartDate = moment(today).subtract(1, "day").set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        const newEndDate = moment(today).subtract(1, "day").set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        const objDate = {
            start: newStartDate,
            end: newEndDate,
            selectedButton: "Ayer",
            daily: "days",
            range: false,
            close: true
        }
        handleDate(objDate)
        handleChange(objDate)
    }

    // Resta un día a la fecha actual
    const subtractOneDay = () => {
        const newStartDate = moment(date).subtract(1, 'days')
        const newEndDate = moment(endDate).subtract(1, 'days')
        const objDate = {
            start: newStartDate,
            end: newEndDate,
            daily: dateType
        }
        handleChange(objDate)
    }

    // Suma un día a la fecha actual
    const addOneDay = () => {
        const newStartDate = moment(date).add(1, 'days')
        const newEndDate = moment(endDate).add(1, 'days')
        const objDate = {
            start: newStartDate,
            end: newEndDate,
            daily: dateType
        }
        handleChange(objDate)
    }

    // Ir al mes actual
    const handleMonth = () => {
        const newStartDate = moment(today).startOf('month')
        const newEndDate = moment(today).endOf('month')
        const objDate = {
            start: newStartDate,
            end: newEndDate,
            selectedButton: "Mes",
            daily: "months",
            range: false,
            close: true
        }
        handleDate(objDate)
        handleChange(objDate)
    }

    // Ir al mes seleccionado
    const handleSelectedMonth = () => {
        const newStartDate = moment(date).startOf('month')
        const newEndDate = moment(date).endOf('month')
        const objDate = {
            start: newStartDate,
            end: newEndDate,
            selectedButton: "Mes",
            daily: "months",
            range: false,
            close: true
        }
        handleDate(objDate)
        handleChange(objDate)
    }

    // Restar un mes
    const subtractOneMonth = () => {
        const newDate = moment(date).subtract(1, 'months')
        const newStartDate = moment(newDate).startOf('month')
        const newEndDate = moment(newDate).endOf('month')
        let objDate = {
            start: newStartDate,
            daily: dateType
        }
        if (!isRange) {
            objDate.end = newEndDate
        }
        handleChange(objDate)
    }

    // Sumar un mes
    const addOneMonth = () => {
        const newDate = moment(date).add(1, 'months')
        const newStartDate = moment(newDate).startOf('month')
        const newEndDate = moment(newDate).endOf('month')
        let objDate = {
            start: newStartDate,
            daily: dateType
        }
        if (!isRange) {
            objDate.end = newEndDate
        }
        handleChange(objDate)
    }

    // Ir al año actual
    const handleYear = () => {
        const newStartDate = moment(today).startOf('year')
        const newEndDate = moment(today).endOf('year')
        const objDate = {
            start: newStartDate,
            end: newEndDate,
            selectedButton: "Año",
            daily: "years",
            range: false,
            close: true
        }
        handleDate(objDate)
        handleChange(objDate)
    }

    // Ir al año seleccionado
    const handleSelectedYear = () => {
        const newStartDate = moment(date).startOf('year')
        const newEndDate = moment(date).endOf('year')
        const objDate = {
            start: newStartDate,
            end: newEndDate,
            selectedButton: "Año",
            daily: "years",
            range: false,
            close: true
        }
        handleDate(objDate)
        handleChange(objDate)
    }

    // Sumar un año a la fecha seleccionada
    const addOneYear = () => {
        const newStartDate = moment(date).add(1, 'year')
        const newEndDate = moment(endDate).add(1, 'year')
        let objDate = {
            start: newStartDate,
            end: newEndDate,
            daily: dateType
        }
        if (!isRange) {
            objDate.end = newEndDate
        }
        handleChange(objDate)
    }

    // Restar un año a la fecha seleccionada
    const subtractOneYear = () => {
        const newStartDate = moment(date).subtract(1, 'year')
        const newEndDate = moment(endDate).subtract(1, 'year')
        let objDate = {
            start: newStartDate,
            end: newEndDate,
            daily: dateType
        }
        if (!isRange) {
            objDate.end = newEndDate
        }
        handleChange(objDate)
    }

    // Alterna la visibilidad del segundo calendario cuando se hace clic en el botón "Rango"
    const handleRangeToggle = () => {
        setIsRange(true)
        setSelectedButton("Rango")
    }

    // Establece la fecha del segundo calendario
    const handleSetRange = (newValue) => {
        const newEndDate = moment().set({ year: newValue.getFullYear(), month: newValue.getMonth(), date: newValue.getDate(), hour: 23, minute: 59, second: 59, millisecond: 999 })
        const objDate = {
            start: date,
            end: newEndDate,
            daily: "days"
        }
        handleChange(objDate)
    }

    //Restar un año al segundo calendario
    const subtractOneYearForRange = () => {
        const newEndDate = moment(endDate).subtract(1, 'year')
        const objDate = {
            end: newEndDate,
            daily: dateType
        }
        handleChange(objDate)
    }

    //Sumar un año al segundo calendario
    const addOneYearForRange = () => {
        const newEndDate = moment(endDate).add(1, 'year')
        const objDate = {
            end: newEndDate,
            daily: dateType
        }
        handleChange(objDate)
    }

    //Restar un mes al segundo calendario
    const subtractOneMonthForRange = () => {
        const newEndDate = moment(endDate).subtract(1, 'months')
        const objDate = {
            end: newEndDate,
            daily: dateType
        }
        handleChange(objDate)
    }

    //Sumar un mes al segundo calendario
    const addOneMonthForRange = () => {
        const newEndDate = moment(endDate).add(1, 'months')
        const objDate = {
            end: newEndDate,
            daily: dateType
        }
        handleChange(objDate)
    }

    const leftArrowCalendar = () => {
        switch (selectedButton) {
            case "Hoy":
                subtractOneDay()
                break
            case "Ayer":
                subtractOneDay()
                break
            case "Mes":
                subtractOneMonth()
                break
            case "Año":
                subtractOneYear()
                break
            default:
                break
        }
    }

    const rightArrowCalendar = () => {
        switch (selectedButton) {
            case "Hoy":
                addOneDay()
                break
            case "Ayer":
                addOneDay()
                break
            case "Mes":
                addOneMonth()
                break
            case "Año":
                addOneYear()
                break
            default:
                break
        }
    }

    const showDateCalendar = () => {
        switch (selectedButton) {
            case "Hoy":
                return getDateFormat(date)
            case "Ayer":
                return getDateFormat(date)
            case "Mes":
                return `${obtenerNombreMes(date.month() + 1)} - ${date.year()}`
            case "Año":
                return date.year()
            case "Rango":
                return `${getDateFormat(date)} - ${getDateFormat(endDate)}`
            default:
                break
        }
    }

    const handleMaxCalendar = () => {
        switch (selectedButton) {
            case "Hoy":
                return date?.day() === today.day() && date?.month() === today.month() && date?.year() === today.year()
            case "Ayer":
                return date?.day() === today.day() && date?.month() === today.month() && date?.year() === today.year()
            case "Mes":
                return date?.month() === today.month() && date?.year() === today.year()
            case "Año":
                return date?.year() === today.year()
            default:
                return false
        }
    }

    return (
        <>
            <div className="margin-right-0_5em">
                <Grid className="flex-grow calendar-filter-card-alarms margin-right-0_5em">
                    <div className={`${className} calendar-filter`}>
                        <CalendarButton onClick={leftArrowCalendar} className="arrow-calendar" disabled={isRange}>
                            <ArrowLeft />
                        </CalendarButton>
                        <CalendarButton>
                            <Typography textAlign="center" onClick={notOpen ? () => {} : handleMenu} className="pr-2 pl-2 typography-main-btn">
                                {showDateCalendar()}
                            </Typography>
                        </CalendarButton>
                        <CalendarButton
                            onClick={rightArrowCalendar}
                            className="arrow-calendar"
                            disabled={maxDate ? handleMaxCalendar() : false}
                        >
                            <ArrowRight />
                        </CalendarButton>
                    </div>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <Grid container justifyContent="center" className="pr-3 pl-3">
                            {/* Primer calendario*/}
                            <CalendarDatePicker
                                isRange={isRange}
                                onClickArrowLeftFirstRow={() => subtractOneYear()}
                                onClickTitleFirstRow={handleSelectedYear}
                                titleFirstRow={date && date.year()}
                                onClickArrowRightFirstRow={() => addOneYear()}
                                onClickArrowLeftSecondRow={() => subtractOneMonth()}
                                titleSecondRow={date && `${obtenerNombreMes(date.month() + 1)}`}
                                onClickTitleSecondRow={handleSelectedMonth}
                                onClickArrowRightSecondRow={() => addOneMonth()}
                                value={date._d}
                                onChange={handleChangeDate}
                                maxDate={maxDate ? today._d : ""}
                                disabledArrowRightFirstRow={maxDate ? date?.year() === today.year() : false}
                                disabledArrowRightSecondRow={maxDate ? date?.month() === today.month() && date?.year() === today.year() : false}
                            />
                            {/* Segundo calendario */}
                            {isRange &&
                                <CalendarDatePicker
                                    isRange={isRange}
                                    onClickArrowLeftFirstRow={() => subtractOneYearForRange()}
                                    titleFirstRow={endDate && endDate.year()}
                                    onClickTitleFirstRow={handleSelectedYear}
                                    onClickArrowRightFirstRow={() => addOneYearForRange()}
                                    onClickArrowLeftSecondRow={() => subtractOneMonthForRange()}
                                    titleSecondRow={endDate && `${obtenerNombreMes(endDate?.month() + 1)}`}
                                    onClickTitleSecondRow={handleSelectedMonth}
                                    onClickArrowRightSecondRow={() => addOneMonthForRange()}
                                    value={endDate._d}
                                    onChange={handleSetRange}
                                    maxDate={maxDate ? today._d : ""}
                                    disabledArrowRightFirstRow={maxDate ? endDate?.year() === today.year() : false}
                                    disabledArrowRightSecondRow={maxDate ? endDate?.month() === today.month() && endDate?.year() === today.year() : false}
                                />
                            }
                        </Grid>
                        {/* Mensaje de error*/}
                        {isRange && date > endDate && <div className="message-error">La fecha inicial no puede ser posterior a la final.</div>}
                        {/* Botones con opciones */}
                        <CalendarRowButtons
                            selectedButton={selectedButton}
                            handleToday={handleToday}
                            handleYesterday={handleYesterday}
                            handleMonth={handleMonth}
                            handleYear={handleYear}
                            handleRangeToggle={handleRangeToggle}
                            handleClose={handleClose}
                        />
                    </Menu>
                </Grid>
            </div>
            {select &&
                <Grid >
                    <SelectorFechaV2 handleDate={handleDate} date={date} endDate={endDate} dateType={dateType} setDateType={setDateType} selectedButton={selectedButton} />
                </Grid>
            }
        </>
    )
}

export default CalendarComponentV2