import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from "@mui/material"
import moment from 'moment'
import { urls, dispositivos } from "utils/constant"
import { Loader } from "components/Loader/Loader"
import { headersScada } from 'services/headers'
import { CurrentDataTableMABTDevice } from './CurrentDataTableMABTDevice'
import { CurrentDataTableDevice } from './CurrentDataTableDevice'
import { CurrentDataTableMABT } from './CurrentDataTableMABT'
import { result } from 'utils/functionsforGraphs'
import { convertDatetime } from 'utils/Utils'
import { CategoryType } from 'utils/MiddleWareProjects'
import { headersCentroControl } from 'services/headers'

export const CurrentDataTable = ({ masterPermission, alarms, actualProject, installations, setData, loadAlarms, data, noRepeatData, setNoRepeatData, csvData, setCsvData, csvHeaders, setCsvHeaders, end, start }) => {
    const token = sessionStorage?.token
    const [loading, setLoading] = useState(true)
    const [deviceType, setDeviceType] = useState()
    const [viewDevice, setViewDevice] = useState(0)
    const [numserieDevice, setNumserieDevice] = useState([])
    const [noRepeatFilter, setNoRepeatFilter] = useState([])
    const [dataFiltered, setDataFiltered] = useState([])
    const now = moment().format('YYYY-MM-DD')
    const allowedTypes = [0, 7, 12, 11]
    let url

    const fetchData = async (master, masterM) => {
        setDeviceType(master?.id_tipo_dispositivo)
        switch (master?.id_tipo_dispositivo) {
            case dispositivos.ccmaster:
                url = `getCurrentData/${master?.numserie}?idCCMaster=${master?.numserie}`
                break
            case dispositivos.ccmabt:
                url = `getHistoricalDataScada/${master?.numserie}?idCCMaster=${master?.numserie}&iniDate=${now}&endDate=${now}&groupDate=days&scadaType=mabt&typeMabt=total`
                break
            case dispositivos.ccmabtm:
                url = `getCurrentData/${master?.numserie}?idCCMaster=${master?.numserie}`
                break
            case dispositivos.ccmabtv:
                url = `getCurrentData/${master?.numserie}?idCCMaster=${master?.numserie}`
                break
            default:
                url = `getCurrentData/${master?.numserie}${master?.cloud ? '' : '?idCCMaster=' + master?.id_padre}`
                break
        }
        if (master && !allowedTypes.includes(master.id_tipo_dispositivo)) {
            try {
                const res = await fetch(urls.scada + url, { method: "GET", headers: headersScada(token) })
                if (res.status === 200) {
                    const json = await res.json()
                    if (master?.id_tipo_dispositivo === dispositivos?.ccmabt) {
                        return {
                            alarmCount: masterPermission?.[master?.numserie].alarmCount,
                            alarms: masterPermission?.[master?.numserie].alarms,
                            numserie: master.numserie,
                            alarmConfig: alarms.filter((alarm) => alarm?.numserie === master?.numserie),
                            data: json[0],
                            nick_master: master?.nick_padre,
                            nick: master?.nick,
                            datetime: json[0]?.fecha,
                            DeviceType: master?.id_tipo_dispositivo,
                            infoDevice: master,
                            fecha: json?.[0]?.fecha,
                            pac1: json?.[0]?.pac1,
                            pac2: json?.[0]?.pac2,
                            pac3: json?.[0]?.pac3,
                            pre1: json?.[0]?.pre1,
                            pre2: json?.[0]?.pre2,
                            pre3: json?.[0]?.pre3,
                            i1: json?.[0]?.i1,
                            i2: json?.[0]?.i2,
                            i3: json?.[0]?.i3,
                            v1: json?.[0]?.v1,
                            v2: json?.[0]?.v2,
                            v3: json?.[0]?.v3,
                        }
                    } else {
                        json.datetime = json?.currentData?.datetime
                        json.currentData.datetime = convertDatetime(json?.currentData?.datetime)
                        return {
                            nick: master?.nick,
                            numserie: master?.numserie,
                            master_serial: master?.master_serial ? master?.master_serial : master.id_padre,
                            nick_master: master?.nick_padre,
                            data: json,
                            infoDevice: master,
                            ...json[0]
                        }
                    }
                }
            } catch (err) {
                console.error("ERROR. API Scada", err)
            }
        }
    }

    const fetchDataChildrens = async (master, numserie) => {
        try {
            const res = await fetch(urls.scada + `getCurrentData/${numserie}?idCCMaster=${master?.numserie}`, { method: "GET", headers: headersScada(token) })
            const json = await res.json()
            if (res.status === 200 && json?.currentData) {
                let info = {
                    activo: 1,
                    datatime: json?.currentData?.datatime,
                    id_padre: master.numserie,
                    id_tipo_dispositivo: json?.currentData?.id_tipo_dispositivo,
                    ins_type: master?.ins_type,
                    nick: json?.currentData?.nick,
                    nick_padre: master?.nick_padre,
                    numserie: numserie,
                    permissions: master?.permissions
                }
                if (master?.id_tipo_dispositivo === dispositivos.ccmabt) {
                    return {
                        alarmCount: masterPermission?.[master?.numserie].alarmCount,
                        alarms: masterPermission?.[master?.numserie].alarms,
                        numserie: numserie,
                        alarmConfig: alarms.filter((alarm) => alarm?.numserie === numserie),
                        data: json[0],
                        nick_master: master?.nick_padre,
                        nick: master?.nick,
                        datetime: json[0]?.fecha,
                        DeviceType: 21,
                        infoDevice: info,
                        fecha: json?.[0]?.fecha,
                        pac1: json?.[0]?.pac1,
                        pac2: json?.[0]?.pac2,
                        pac3: json?.[0]?.pac3,
                        pre1: json?.[0]?.pre1,
                        pre2: json?.[0]?.pre2,
                        pre3: json?.[0]?.pre3,
                        i1: json?.[0]?.i1,
                        i2: json?.[0]?.i2,
                        i3: json?.[0]?.i3,
                        v1: json?.[0]?.v1,
                        v2: json?.[0]?.v2,
                        v3: json?.[0]?.v3,
                    }
                } else {
                    json.datetime = json?.currentData?.datetime
                    json.currentData.datetime = convertDatetime(json?.currentData?.datetime)
                    return {
                        nick: json?.currentData?.nick,
                        numserie: numserie,
                        master_serial: master?.numserie,
                        nick_master: master?.nick,
                        data: json,
                        infoDevice: info,
                        ...json[0]
                    }
                }
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const fetchDevices = async (master) => {
        if (master && master?.id_tipo_dispositivo !== 7 && master?.id_tipo_dispositivo !== 12 && master?.id_tipo_dispositivo !== 11) {
            try {
                const res = await fetch(urls.ccontrol + `userNumChildren/${master?.numserie}`, { method: "GET", headers: headersCentroControl(token) })
                if (res.status === 200) {
                    let json = await res.json()
                    if(master.id_tipo_dispositivo !== 0) {
                        json.push(master.numserie)
                    }
                    let datos = await Promise.all(json?.flatMap(numserie => { return fetchDataChildrens(master, numserie) }))
                    datos = datos.flatMap((dato => dato))
                    return datos
                } else {
                    return []
                }
            } catch (err) {
                console.error("ERROR. API Scada", err)
            }
        }
    }

    const cargarDatosTabla = async () => {
        try {
            let datos

            if (CategoryType(actualProject?.category) === 'Autoconsumo') {
                datos = await Promise.all(
                    installations?.flatMap?.(instal => {
                        return fetchDevices(instal)
                    })
                )

                datos = datos.flatMap(dato => dato).filter(Boolean)
            } else {
                datos = await Promise.all(
                    installations?.flatMap(master => {
                        return fetchData(master)
                    })
                )

                datos = datos.filter(Boolean)
            }
            setData(datos)
        } catch (error) {
            console.error("Error al guardar los datos:", error)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (loadAlarms) {
            cargarDatosTabla()
        }
    }, [alarms, loadAlarms])

    useEffect(() => {
        if (data) {
            const setObj = new Set()
            const uniqs = data?.map(device => result(data, device?.numserie))
            const noRepeat = uniqs.reduce((acc, device) => {
                if (!setObj.has(device?.numserie)) {
                    setObj.add(device?.numserie, device)
                    acc.push(device)
                }
                return acc
            }, [])
            setNoRepeatData(noRepeat)
        }
    }, [data])



    const handleGeneral = () => {
        setViewDevice(0)
    }

    const handleDeviceTable = (numserie) => {
        setViewDevice(1)
        setNumserieDevice(numserie)
    }

    const deleteIndexDevice = (numserie) => {
        setViewDevice(0)
        setNoRepeatFilter(noRepeatFilter?.filter(device => device.numserie !== numserie))
        setDataFiltered([])
    }

    useEffect(() => {
        const repeat = noRepeatFilter.some(device => device.numserie === dataFiltered?.[0]?.numserie)
        if (data && dataFiltered?.[0] !== undefined && !repeat) {
            setNoRepeatFilter(prev => [...prev, dataFiltered?.[0]])
        }
    }, [dataFiltered])

    return (
        <>
            {(loading || !loadAlarms) ?
                <div className="justify-content-center">
                    <Loader />
                </div>
                :
                <>
                    {Number(deviceType) === 23 || Number(deviceType) === 21 || Number(deviceType) === 20 ?
                        <>
                            <Tabs>
                                <span className="d-flex justify-content-start align-items-center mb-3 card-device">
                                    <Tab style={{ textTransform: 'none' }} className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt  ${viewDevice === 0 ? "active-button" : null}`} onClick={handleGeneral} label="Datos">Datos</Tab>
                                    {noRepeatFilter?.map(device => {
                                        return (
                                            <Tab
                                                style={{ textTransform: 'none' }}
                                                className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt  ${viewDevice === 1 && device?.numserie === numserieDevice ? "active-button" : null}`}
                                                title={device?.nick?.toLowerCase()}
                                                label={
                                                    <>
                                                        <span onClick={() => handleDeviceTable(device.numserie)} className='mr-2'>{device?.nick} </span>
                                                        <a className="button-close-device" onClick={() => { deleteIndexDevice(device.numserie) }}> x </a>
                                                    </>
                                                }
                                            />
                                        )
                                    })}
                                </span>
                            </Tabs>
                            <>
                                {viewDevice === 1 ?
                                    <CurrentDataTableMABTDevice
                                        installations={installations}
                                        data={data}
                                        numserieDevice={numserieDevice}
                                        masterPermission={masterPermission}
                                        alarms={alarms}
                                        csvData={csvData}
                                        setCsvData={setCsvData}
                                        csvHeaders={csvHeaders}
                                        setCsvHeaders={setCsvHeaders}
                                    />
                                    :
                                    <CurrentDataTableMABT
                                        noRepeatData={noRepeatData}
                                        viewDevice={viewDevice}
                                        setViewDevice={setViewDevice}
                                        setNumserieDevice={setNumserieDevice}
                                        setDataFiltered={setDataFiltered}
                                        csvData={csvData}
                                        setCsvData={setCsvData}
                                        csvHeaders={csvHeaders}
                                        setCsvHeaders={setCsvHeaders}
                                    />
                                }
                            </>
                        </>
                        :
                        <CurrentDataTableDevice
                            actualProject={actualProject}
                            noRepeatData={noRepeatData}
                            masterPermission={masterPermission}
                            start={start}
                            end={end}
                            data={data}
                            csvData={csvData}
                            setCsvData={setCsvData}
                            csvHeaders={csvHeaders}
                            setCsvHeaders={setCsvHeaders}
                        />
                    }
                </>
            }
        </>
    )
}