import React from 'react'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { headersScada } from 'services/headers'
import { urls } from "utils/constant.jsx"
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'

/**
 * @param {{id: Number, token: string, modalEliminar: Boolean, setModalEliminar: Function, setNuevo: Function}}
 * @param id Comentario publico de la alarma
 * @param token Token para realizar la llamada
 * @param modalEliminar Estado que controla si se muestra el modal de eliminar
 * @param setModalEliminar Funcion para actualizar el estado del modal
 * @param setNuevo Funcion para actualizar los comentarios
 * @returns Devuelve el comentario publico de la alarma
 */
export const EliminarComentario = ({ id, token, modalEliminar, setModalEliminar, setNuevo }) => {
    const dispatch = useDispatch()

    /**
     * @returns Elimina el comentario de la alarma seleccionada
     */
    const fetcEliminarComentario = async () => {
        let status
        try {
            const res = await fetch(urls.scada + 'alarms/deleteCommentAlarm', { method: 'DELETE', headers: headersScada(token), body: JSON.stringify({ id: id }) })
            status = res.status
            if (res.status === 200) {
                setNuevo(current => !current)
                dispatch(createToast({ status: status, message: "Comentario eliminado correctamente", reload: true }))
            } else {
                dispatch(createToast({ status: status, message: "Ha habido un error al eliminar el comentario", reload: true }))
            }
            setModalEliminar(false)
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    return (
        <Modal fullWidth={true} open={modalEliminar} setOpen={() => setModalEliminar(false)} title="Eliminar comentario" buttonAction="Eliminar" functSave={() => fetcEliminarComentario()}>
            <Grid className="modal-eliminar-alarma" item lg={12}>
                <h4 className="font-weight-lighter text-center mt-2" style={{ fontSize: '1.2rem' }}>
                    ¿Deseas eliminar el comentario?
                </h4>
            </Grid>
        </Modal>
    )
}