import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import moment from "moment"
import { apiScada } from 'services/api'
import { compararFecha, isMobile, esHoy } from 'utils/Utils'
import { dispositivos } from 'utils/constant'
import { ConfiguracionList } from 'views/Configuracion/ConfiguracionList'
import { Alarms } from 'views/Alarms/Alarms'
import { cargarDatosGrafica, cargarDatosTabla, convertirDatosMabt, convertirHistoricoEnergiaGrafica, datoNull, diccionarioConversionMabt, diccionarioConversionMabtHistorico } from './utils'
import { ScadaSensor } from './SensorLine/ScadaSensor'
import { OnOff } from '../CcMaster/tabs/OnOff'
import { InformeMabt } from './InformeMabt'
import { GeneralMabt } from './GeneralMabt'
import { ScadaMabt } from './ScadaMabt'
import { MenuScada } from './MenuScada'

export const Mabt = ({ devices, masterPermission, masterError }) => {
    const [activeTab, setActiveTab] = useState(0)
    const [activeTabDeviceMenuGrafica, setActiveTabDeviceMenuGrafica] = useState(0)
    const [activeTabDevice, setActiveTabDevice] = useState(0)
    const [activeTabDeviceGrafica, setActiveTabDeviceGrafica] = useState(0)
    const [medidor, setMedidor] = useState(0)
    const [visible, setVisible] = useState(true)
    const [data, setData] = useState()
    const [tipoGrafica, setTipoGrafica] = useState('pact')
    const [tipo, setTipo] = useState('pact')
    const [menuGrafica, setMenuGrafica] = useState('total')
    const [loadingLineas, setLoadingLineas] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [error, setError] = useState(false)
    const [loadingGrafica, setLoadingGrafica] = useState(false)
    const [loadingTabla, setLoadingTabla] = useState(false)
    const [errorGrafica, setErrorGrafica] = useState(false)
    const [errorTabla, setErrorTabla] = useState(false)
    const [general, setGeneral] = useState()
    const [datosLineas, setDatosLineas] = useState()
    const [datosGrafica, setDatosGrafica] = useState()
    const [datosTabla, setDatosTabla] = useState()
    const [total, setTotal] = useState(0)
    const [vtn, setVtn] = useState()
    const [tipoEscala, setTipoEscala] = useState('kW')
    const [alarmas, setAlarmas] = useState()
    const nombreInstalacion = useSelector((state) => state.instalacion)
    const [start, setStart] = useState(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
    const [end, setEnd] = useState(moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }))
    const [fechaVtn, setFechaVtn] = useState()
    const [confAlarms, setConfAlarms] = useState()
    const [daily, setDaily] = useState('days')
    const vtnDevice = devices?.childrenDevices?.find(ele => ele?.id_tipo_dispositivo === dispositivos.ccmabtv)
    const url = devices?.numserie + "?idCCMaster=" + devices?.numserie
    let mobile = isMobile(1300)

    const selectTab = (tab) => {
        setMedidor()
        setActiveTab(tab)
        if (tab === 0) {
            setStart(moment())
            setEnd(moment())
            setDaily('days')
            if (tipoGrafica !== 'energiaGenerada' && tipoGrafica !== 'energiaConsumida') {
                selectTabMenuGrafica(0)
            } else {
                selectTabMenuGrafica(1)
            }
            selectTabLinea(0)
            selectTabGrafica(0)
        }
    }

    const selectTabLinea = (tab) => {
        setActiveTabDevice(tab)
        switch (tab) {
            case 0:
                setTipo('pact')
                break
            case 1:
                setTipo('corriente')
                break
            case 2:
                setTipo('tension')
                break
            case 3:
                setTipo('preact')
                break
            default:
                break
        }
    }

    const selectTabGrafica = (tab, mg) => {
        setActiveTabDeviceGrafica(tab)
        switch (tab) {
            case 0:
                setTipoEscala('kW')
                setTipoGrafica('pact')
                handleDate(false, 'kW', 'pact', mg)
                break
            case 1:
                setTipoEscala('A')
                setTipoGrafica('corriente')
                handleDate(false, 'A', 'corriente', mg)
                break
            case 2:
                setTipoEscala('V')
                setTipoGrafica('tension')
                handleDate(false, 'V', 'tension', mg)
                break
            case 3:
                setTipoEscala('kW')
                setTipoGrafica('preact')
                handleDate(false, 'kW', 'preact', mg)
                break
            case 4:
                setTipoEscala('kWh')
                setTipoGrafica('energia')
                handleDate(false, 'kWh', 'energia', mg)
                break
            case 5:
                setTipoEscala('kWh')
                setTipoGrafica('energiaGenerada')
                handleDate(false, 'kWh', 'energiaGenerada', mg)
                break
            case 6:
                setTipoEscala('kWh')
                setTipoGrafica('energiaConsumida')
                handleDate(false, 'kWh', 'energiaConsumida', mg)
                break
            default:
                break
        }
    }

    const selectTabMenuGrafica = (tab) => {
        setActiveTabDeviceMenuGrafica(tab)
        switch (tab) {
            case 0:
                setMenuGrafica('total')
                if (activeTabDeviceGrafica > 4) {
                    selectTabGrafica(4, 'total')
                } else {
                    handleDate(false, tipoEscala, tipoGrafica, 'total')
                }
                break
            case 1:
                setMenuGrafica('medidores')
                if (activeTabDeviceGrafica === 4) {
                    selectTabGrafica(5, 'medidores')
                } else {
                    handleDate(false, tipoEscala, tipoGrafica, 'medidores')
                }
                break
            default:
                break
        }
    }

    const selectMedidorSensor = (device) => {
        setActiveTab(5)
        setMedidor(device)
    }

    const selectMedidor = (device) => {
        setActiveTab(3)
        setLoadingData(true)
        if (medidor && device && device?.numserie === medidor?.numserie) {
            getData()
        }
        device['mabt_serial'] = devices?.numserie
        setMedidor(device)
    }

    const getResumenTabla = async () => {
        try {
            const result = await apiScada.get('getCurrentData/resumen/' + url)
            if (result?.status === 200) {
                setGeneral(result?.data)
            }
        } catch (err) {
            setError(true)
            console.error("ERROR. API Scada", err)
        }
    }

    const getLineas = async () => {
        try {
            const result = await apiScada.get('getCurrentData/' + tipo + '/' + url)
            if (result?.status === 200) {
                setVtn(datoNull(result?.data?.vtn))
                setFechaVtn(result?.data?.datetime ? result?.data?.datetime : '')
                setDatosLineas(convertirDatosMabt(result?.data?.data, tipo, setTotal))
            }
        } catch (err) {
            setError(true)
            console.error("ERROR. API Scada", err)
        }
        setLoadingLineas(false)
    }

    const fecthGrafica = async (date) => {
        let obj = {
            iniDate: moment(date?.start).format('YYYY-MM-DD'),
            endDate: moment(date?.end).format('YYYY-MM-DD'),
            iniTime: "00:00",
            endTime: esHoy(date?.end) ? moment().format('HH:mm') : moment(date?.end).format('HH:mm'),
            interval: !esHoy(date?.start) ? "60" : "5",
            axis: [
                {
                    idDeviceType: 20,
                    fields: [date?.tipoGrafica],
                    numseries: [devices?.numserie],
                    plantType: "mabt",
                    unit: date?.tipoEscala
                }
            ]
        }
        try {
            const result = await apiScada.post('chart/getDataChartsCurves/' + date?.menuGrafica + '/' + url, obj)
            if (result?.status === 200) {
                if (result?.data !== false && !result?.data?.message) {
                    setDatosGrafica(cargarDatosGrafica(result?.data))
                } else {
                    setErrorGrafica(true)
                }
            }
        } catch (err) {
            setErrorGrafica(true)
            console.error("ERROR. API Scada", err)
        }
        setLoadingGrafica(false)
    }

    const fecthTabla = async (date) => {
        let extension = ''
        if (date?.menuGrafica === 'total') {
            extension += '&typeMabt=total'
        }
        try {
            const result = await apiScada.get(`getHistoricalDataScada/` + url + `&iniDate=${date?.start?.format('YYYY-MM-DD')}&endDate=${date?.end?.format('YYYY-MM-DD')}&groupDate=${date?.daily}&scadaType=mabt${extension}`)
            if (result?.status === 200) {
                let mapa = result?.data?.flat()?.concat()
                if (date?.tipoGrafica === 'energia' || date?.tipoGrafica === 'energiaConsumida' || date?.tipoGrafica === 'energiaGenerada') {
                    setDatosGrafica(convertirHistoricoEnergiaGrafica(result?.data, date?.tipoGrafica))
                }
                setDatosTabla(cargarDatosTabla(mapa, esHoy(date?.start) ? diccionarioConversionMabt : diccionarioConversionMabtHistorico, esHoy(date?.start), date?.daily, date?.menuGrafica === 'total'))    
            }
        } catch (err) {
            setErrorTabla(true)
            console.error("ERROR. API Scada", err)
        }
        if (date?.tipoGrafica === 'energia' || date?.tipoGrafica === 'energiaConsumida' || date?.tipoGrafica === 'energiaGenerada') {
            setLoadingGrafica(false)
        }
        setLoadingTabla(false)
    }

    /** 
    * @param {boolean} histo 
    * @returns Devuelve las alarmas activas o historicas de las instalaciones seleccionadas
    */
    const getDataAlarms = async () => {
        try {
            const result = await apiScada.post('alarms/getActiveAlarms', { devices: [devices.numserie] })
            if (result?.status === 200) {
                setAlarmas(result?.data)
            }
        } catch (err) {
            setAlarmas([])
            console.error("ERROR. API Scada", err)
        }
    }

    const getData = async () => {
        try {
            const result = await apiScada.get(`getCurrentData/${medidor.numserie}?idCCMaster=${devices.numserie}`)
            if (result?.status === 200) {
                setData(result?.data)
            }
        } catch (err) {
            setData({currentData: {pac1: null, pac2: null, pac3: null, i1: null, i2: null, i3: null}})
            console.error("ERROR. API Scada", err)
        }
        setLoadingData(false)
    }

    const getAlarms = async () => {
        try {
            const result = await apiScada.post(`alarms/getConfiguredAlarms`, { devices: [devices.numserie] })
            if (result?.status === 200) {
                setConfAlarms(result?.data?.message ? [] : result?.data)
            }
        } catch (err) {
            setConfAlarms([])
            console.error("ERROR. API Scada", err)
        }
    }

    const handleDate = (ele, te, tg, mg) => {
        setLoadingGrafica(true)
        setLoadingTabla(true)
        setErrorGrafica(false)
        setErrorTabla(false)
        const objDate = {start: ele?.start ?? start, end: ele?.end ?? end, daily: ele?.daily ?? daily ?? 'days', tipoGrafica: tg ?? tipoGrafica, tipoEscala: te ?? tipoEscala, menuGrafica: mg ?? menuGrafica }
        const visibleGrafica = compararFecha(objDate?.start, objDate?.end)
        switch (true) {
            case !visibleGrafica && !['energia', 'energiaGenerada', 'energiaConsumida']?.includes(objDate?.tipoGrafica):
                setActiveTabDeviceGrafica(objDate?.menuGrafica === 'total' ? 4 : 5)
                setTipoGrafica(objDate?.menuGrafica === 'total' ? 'energia' : 'energiaGenerada')
                setTipoEscala('kWh')
                objDate.tipoGrafica = objDate?.menuGrafica === 'total' ? 'energia' : 'energiaGenerada'
                objDate.tipoEscala = 'kWh'
                break
            case !visibleGrafica && activeTabDeviceGrafica < 4:
                setActiveTabDeviceGrafica(objDate?.menuGrafica === 'total' ? 4 : 5)
                setTipoEscala('kWh')
                setTipoGrafica(objDate?.menuGrafica === 'total' ? 'energia' : 'energiaGenerada')
                objDate.tipoGrafica = objDate?.menuGrafica === 'total' ? 'energia' : 'energiaGenerada'
                objDate.tipoEscala = 'kWh'
                break
            case !esHoy(objDate?.start) && activeTabDeviceGrafica === 3:
                setActiveTabDeviceGrafica(0)
                setTipoEscala('kW')
                setTipoGrafica('pact')
                objDate.tipoGrafica = 'pact'
                objDate.tipoEscala = 'kW'
                break
            default:
                break
        }
        setVisible(visibleGrafica)
        if (ele) {
            setStart(ele?.start)
            setEnd(ele?.end)
            setDaily(ele?.daily)
        }
        if (!['energia', 'energiaGenerada', 'energiaConsumida']?.includes(objDate?.tipoGrafica)) {
            fecthGrafica(objDate)
        } else {
            setLoadingGrafica(false)
        }
        fecthTabla(objDate)
    }

    useEffect(() => {
        if (activeTab === 0) {
            setError(false)
            setLoadingLineas(true)
            getLineas()
            getResumenTabla()
            getDataAlarms()
        }
    }, [tipo, activeTab])

    useEffect(() => {
        if (medidor) {
            getData()
        }
    }, [medidor])

    useEffect(() => {
        getAlarms()
        handleDate()
    }, [])
    
    return (
        <>
            <Grid item xs={12} md={12} lg={12} xl={12} className='scada-navbar-centro-control card-centro-control'>
                <MenuScada activeTab={activeTab} selectTab={selectTab} devices={devices} master={masterPermission} nombreInst={nombreInstalacion} masterError={masterError} />
            </Grid>
            {activeTab === 0 &&
                <Grid container>
                    <GeneralMabt
                        activeTabDevice={activeTabDevice}
                        selectTabLinea={selectTabLinea}
                        start={start}
                        datosLineas={datosLineas}
                        vtn={vtn}
                        general={general}
                        error={error}
                        loadingLineas={loadingLineas}
                        total={total}
                        selectMedidor={selectMedidor}
                        selectMedidorSensor={selectMedidorSensor}
                        vtnDevice={vtnDevice}
                        alarmas={alarmas}
                        devices={devices}
                        masterPermission={masterPermission}
                        selectTab={selectTab}
                        confAlarms={confAlarms}
                    />
                    <InformeMabt
                        activeTabDeviceMenuGrafica={activeTabDeviceMenuGrafica}
                        selectTabMenuGrafica={selectTabMenuGrafica}
                        activeTabDeviceGrafica={activeTabDeviceGrafica}
                        selectTabGrafica={selectTabGrafica}
                        start={start}
                        visible={visible}
                        datosTabla={datosTabla}
                        datosGrafica={datosGrafica}
                        loadingGrafica={loadingGrafica}
                        loadingTabla={loadingTabla}
                        mobile={mobile}
                        errorGrafica={errorGrafica}
                        errorTabla={errorTabla}
                        tipoGrafica={tipoGrafica}
                        master={devices}
                        tipoEscala={tipoEscala}
                        handleDate={handleDate}
                    />
                </Grid>
            }
            {activeTab === 1 &&
                <Grid item xs={12} md={12} lg={12} xl={12} className='pl-3 pr-3'>
                    <Alarms instalacioneSeleccionadas={[devices?.numserie]} dispositivos={[devices]} />
                </Grid>
            }
            {activeTab === 2 &&
                <Grid item xs={12} md={12} lg={12} xl={12} className='pl-3 pr-3'>
                    <ConfiguracionList instalacioneSeleccionadas={[devices?.numserie]} dispositivo={[devices]} scada={true} masterError={masterError} distribution={true} />
                </Grid>
            }
            {activeTab === 3 && medidor !== undefined && data &&
                <ScadaMabt
                    alarmas={alarmas}
                    selectMedidor={selectMedidor}
                    data={data}
                    loadingData={loadingData}
                    medidor={medidor}
                    devices={devices}
                    masterPermission={masterPermission}
                    fechaVtn={fechaVtn}
                    datosLineas={datosLineas}
                    mobile={mobile}
                />
            }
            {activeTab === 4 &&
                <Grid item xs={12} md={12} lg={12} xl={12} className='pl-3 pr-3'>
                    <OnOff activeTab={activeTab} isMobile={isMobile} />
                </Grid>
            }
            {activeTab === 5 && medidor !== undefined &&
                <Grid item xs={12} md={12} lg={12} xl={12} className='pl-3 pr-3'>
                    <ScadaSensor mabt={devices?.numserie} device={medidor} medidores={devices?.childrenDevices?.filter(med => med?.sensor?.length > 0)}/>
                </Grid>
            }
        </>
    )
}