// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-0 {
  position: relative;
  z-index: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Map/styleMap.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;AACJ","sourcesContent":[".index-0 {\n    position: relative;\n    z-index: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
