// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-not-found {
  height: 100vh;
}

.logo-not-found {
  height: auto;
}

.title-not-found {
  font-size: 48px;
}

.content-button-notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.content-button-notFound > * {
  margin: 0px 15px;
}

.text-not-found {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #2A2E3A !important;
}

.button-return-notFound {
  color: White !important;
}
.button-return-notFound:hover {
  color: white !important;
}

@media (max-width: 830px) {
  .text-not-found {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/PageError/styleNotFound.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;AACI;EACI,uBAAA;AACR;;AAGA;EACI;IACI,eAAA;EAAN;AACF","sourcesContent":[".container-not-found {\n    height: 100vh;\n}\n\n.logo-not-found {\n    height: auto;\n}\n\n.title-not-found {\n    font-size: 48px;\n}\n\n.content-button-notFound {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 30px;\n}\n\n.content-button-notFound>* {\n    margin: 0px 15px;\n}\n\n.text-not-found {\n    font-family: Montserrat;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 20px;\n    letter-spacing: 0em;\n    text-align: center;\n    color: #2A2E3A !important;\n}\n\n.button-return-notFound {\n    color: White !important;\n\n    &:hover {\n        color: white !important;\n    }\n}\n\n@media (max-width: 830px) {\n    .text-not-found {\n        font-size: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
