import React from 'react'
import { Grid, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { StyledTableCell, StyledTableRow } from 'components/Table/TableStyle'
import { StyledTableCellHead } from 'components/Table/TableStyle'
import { IconOk, IconXMark } from 'utils/icon-centrocontrol'
import { IconWarning } from 'utils/icon-centrocontrol'
import "./styleEms.scss"
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'

export const ConfiguracionEMS = ({ data, inst_type }) => {

    const headerTableConsumption = ["Master", "Activo", "Pot. Max"]

    const headerTableSelfConsumption = ["Master", "Activo", "Pot. Max", "Inyección 0", "Surplus", "Gestión Baterías"]

    return (
        <>
            {data?.length <= 0 ? <Grid items xs={12} className="calendar-historical-flex">
                <h4 align='center' className='mt-4'>No hay ningún máster Seleccionado</h4>
            </Grid> :
                <TableContainer className="container-table-cc-ems">
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead style={{ borderBottom: '2px solid #FF8A1F', borderRadius: '50px' }}>
                            <TableRow>
                                {inst_type === 0 && headerTableConsumption?.map((head, i) => {
                                    return (
                                        <StyledTableCellHead align="center">{head}</StyledTableCellHead>
                                    )
                                })}
                                {inst_type === 1 && headerTableSelfConsumption?.map((head, i) => {
                                    return (
                                        <StyledTableCellHead align="center">{head}</StyledTableCellHead>
                                    )
                                })}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data?.map((elm, i) => {
                                return (
                                    <StyledTableRow key={i}>
                                        {inst_type === 0 && elm?.logic &&
                                            <><StyledTableCell align="center" className="center-table-ems">{elm?.master?.nick}</StyledTableCell>
                                                <StyledTableCell align="center" className="center-table-ems">{elm?.enable_ems ?
                                                    <IconOk />
                                                    :
                                                    <IconXMark />}
                                                </StyledTableCell>
                                                <StyledTableCell className="center-table-ems" align="center">{elm?.logic?.potmax ?
                                                    <IconOk />
                                                    :
                                                    <IconXMark />}
                                                </StyledTableCell></>
                                        }
                                        {inst_type === 1 && elm?.logic && <>
                                            <StyledTableCell align="center" className="center-table-ems">{elm?.master?.nick}</StyledTableCell>
                                            <StyledTableCell align="center" className="center-table-ems">{elm?.enable_ems ?
                                                <IconOk />
                                                :
                                                <IconXMark />}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" className="center-table-ems">{elm?.logic?.potmax ?
                                                <IconOk />
                                                :
                                                <IconXMark />}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" className="center-table-ems">{elm?.logic?.self_consumption ?
                                                <IconOk />
                                                :
                                                <IconXMark />}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" className="center-table-ems">{elm?.logic?.surplus ?
                                                <IconOk />
                                                :
                                                <IconXMark />}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" className="center-table-ems">{elm?.logic?.battery ?
                                                <IconOk />
                                                :
                                                <IconXMark />}
                                            </StyledTableCell>
                                        </>}
                                        {!elm?.logic &&
                                            <><StyledTableCell align="center" className="center-table-ems icon-warning-table" >
                                                {elm?.master?.nick}
                                                <HtmlTooltip title={<span>No ha sido posible conectar con el máster </span>}>
                                                    <IconWarning width={20} height={20} />
                                                </HtmlTooltip>
                                            </StyledTableCell>
                                                <StyledTableCell align="center" className="center-table-ems">
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="center-table-ems">
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="center-table-ems">
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="center-table-ems">
                                                </StyledTableCell>
                                                <StyledTableCell align="center" className="center-table-ems">
                                                </StyledTableCell></>
                                        }
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>}

        </>
    )
}


