import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { urls } from 'utils/constant'
import { headersCentroControl } from 'services/headers'
import { Modal } from 'components/Modal/Modal'
import { VerificationCodeDevice } from '../VerificationCodeDevice/VerificationCodeDevice'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'

export const ModalDeleteDevice = ({ modal, setModal, ...props }) => {
    const dispatch = useDispatch()
    const [secondModal, setSecondModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const token = sessionStorage?.token
    let message = sessionStorage.getItem("email")

    const toggle2 = () => setSecondModal(!secondModal)

    const sendCode = () => {
        setLoading(true)
        let status
        fetch(urls.ccontrol + `validateAction/device`, {
            method: 'PATCH',
            redirect: 'follow',
            headers: headersCentroControl(token),
            body: JSON.stringify({
                numserie: props?.devices?.numserie,
                address: message,
                nick: props?.devices?.nick,
                action: "delete",
                idccmaster: [0, 20].includes(props?.devices?.deviceType) ? props?.devices?.numserie : props?.devices?.master_serial,
                permission: "admin"
            })
        }).then(res => {
            setLoading(false)
            if (res.ok) {
                status = res.status
                dispatch(createToast({ status: status, message: "Se le ha enviado un código al correo electrónico. Por favor revíselo", reload: true }))
                toggle2()
            } else {
                dispatch(createToast({ status: status, message: "Ha ocurrido un error inesperado", reload: true }))
            }
            return res.json()
        })
            .catch(err => console.error("ERROR. API Centro de Control", err))
    }



    return (
        <>
            <Modal open={modal} setOpen={setModal} onClick={e => e.stopPropagation()} title={`Eliminar "${props.devices.nick}"`} functSave={sendCode} loading={loading} buttonAction="Eliminar">
                <Grid container className="text-center mt-2">
                    <div>
                        <h3>Se perderán todos los históricos de este dispositivo.</h3>
                        <h2>¿Deseas eliminar el dispositivo?</h2>
                    </div>
                </Grid>
            </Modal>
            {secondModal &&
                <VerificationCodeDevice open={secondModal} setOpen={setSecondModal} numserie={props?.devices?.numserie} devices={props?.devices} token={token} />
            }
        </>
    )
}