import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { urls, dispositivos } from 'utils/constant'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { validatorInput } from 'utils/ValidatorForm'
import { headersScada } from 'services/headers'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'

export const ModalInfoPlanta = ({ modal, setModal, ...props }) => {

    const token = sessionStorage?.token
    const toggle = () => setModal(!modal)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [alias, setAlias] = useState()
    const [isValid, setIsValid] = useState(false)
    const [isValidNick, setIsValidNick] = useState(false)

    const patchData = () => {
        setLoading(true)
        let status
        if (props?.devices?.numserie) {
            let url = `updateDeviceInfo/${props.devices?.numserie}`
            if (Number(props?.master?.id_tipo_dispositivo) === dispositivos.ccmaster) {
                url += `?idCCMaster=${props?.master?.numserie}`
            } else if (Number(props?.permissions?.deviceType) === dispositivos.ccmaster) {
                url += `?idCCMaster=${props?.permissions?.numserie}`
            }
            fetch(
                urls.scada + url, {
                method: "PATCH",
                headers: headersScada(token),
                body: JSON.stringify({
                    nick: alias,
                }),
            })
                .then(res => {
                    setLoading(false)
                    status = res.status
                    return res.json()
                }).then(data => {
                    dispatch(createToast({ status: status, message: data.message, reload: true }))
                    toggle()
                }).catch(err => {
                    toggle()
                    console.error("ERROR. API Scada", err)
                })
        }
    }

    const handleUpdate = () => {
        patchData()
    }

    const handleChangeNick = (e) => {
        setAlias(e)
        setIsValidNick(validatorInput(e))
    }

    useEffect(() => {
        if (
            (((props?.devices?.device?.nick && props?.devices?.device?.nick !== alias) || (props?.devices?.nick && props?.devices?.nick !== alias)) && !isValidNick && alias)
        ) {
            setIsValid(false)
        } else {
            setIsValid(true)
        }
    }, [isValidNick, alias])

    useEffect(() => {
        setAlias(props?.type === "free" ? props?.devices?.device?.nick : props?.devices?.nick)
        setIsValidNick(false)
    }, [modal])

    return (
        <>
            <Modal onClick={(e) => e.stopPropagation()} maxWidth="sm" open={modal} setOpen={setModal} title={`Información de la Planta "${props?.devices?.nick}"`} functSave={handleUpdate} loading={loading} disabled={isValid}>
                <Grid container className="pr-5 pl-5">
                    <Grid className="pb-3 pr-2" item xs={12} md={6} >
                        <div className='pb-1'>
                            <small className="color-black">
                                Número de serie:
                            </small>
                        </div>
                        <InputStyled
                            value={props?.type === "free" ? props?.devices?.device?.numserie : props.devices?.numserie}
                            disabled={true}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <div className='pb-1'>
                            <small className="color-black">
                                Firmware de CCM:
                            </small>
                        </div>
                        <InputStyled
                            value={props?.id_tipo_dispositivo === 0 ? props?.devices?.FW_version : props.devices?.CCM_FW}
                            disabled={true}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <div className='pb-1'>
                            <small className="color-black">
                                Nombre de la planta:
                            </small>
                        </div>
                        <InputStyled
                            error={isValidNick}
                            value={alias}
                            fullWidth={true}
                            maxLength={65}
                            onChange={handleChangeNick}
                        />
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}