// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verificationCode {
  background-color: white;
}
.verificationCode input.code-input {
  font-size: 1.5em;
  width: 1em;
  text-align: center;
  flex: 1 0 1em;
}
.verificationCode input[type=submit] {
  margin-left: auto;
  display: block;
  font-size: 1em;
  cursor: pointer;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
}
.verificationCode input {
  border-radius: 2px;
  color: white;
  background: #2c323a;
  border: 0;
  border: 4px solid transparent;
}
.verificationCode input:invalid {
  box-shadow: none;
}
.verificationCode input:focus {
  outline: none;
  border: 4px solid #ebb447;
}`, "",{"version":3,"sources":["webpack://./src/views/Master/VerificationCodeMaster/StyleVerificationCodeMaster.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AAAM;EACE,gBAAA;EACA,UAAA;EACA,kBAAA;EACA,aAAA;AAER;AAAM;EACE,iBAAA;EACA,cAAA;EACA,cAAA;EACA,eAAA;EACA,iDAAA;AAER;AAAM;EACE,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,SAAA;EACA,6BAAA;AAER;AADQ;EACE,gBAAA;AAGV;AADQ;EACE,aAAA;EACA,yBAAA;AAGV","sourcesContent":[".verificationCode {\n  background-color: white;\n      input.code-input {\n        font-size: 1.5em;\n        width: 1em;\n        text-align: center;\n        flex: 1 0 1em;\n      }\n      input[type='submit']{\n        margin-left: auto;\n        display: block;\n        font-size: 1em;\n        cursor: pointer;\n        transition: all cubic-bezier(0.4, 0.0, 0.2, 1) .1s\n      }\n      input{\n        border-radius: 2px;\n        color: white;\n        background: #2c323a;\n        border: 0;\n        border: 4px solid transparent;\n        &:invalid {\n          box-shadow: none;\n        }\n        &:focus{\n          outline: none;\n          border: 4px solid #ebb447;\n        }\n      }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
