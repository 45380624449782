import React from 'react'
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { Loader } from 'components/Loader/Loader'
import { StyledTableCellHead, StyledTableCell, StyledTableRow } from 'components/Table/TableStyle'
import { constFormatDateTable } from 'utils/Utils'

export const TableSelfConsumption = ({ data, devicesSelect, loading, filteredHeaders, start }) => {

    return (
        <>
            {loading && <Loader />}
            {data?.length > 0 && !loading &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead style={{ borderRadius: '50px' }}>
                            <TableRow>
                                {filteredHeaders && filteredHeaders.map((elem) => {
                                    return (
                                        <StyledTableCellHead align="center" >
                                            {elem.label}
                                        </StyledTableCellHead>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.map((elem, key) => {
                                return (
                                    <StyledTableRow key={key}>
                                        {devicesSelect?.includes(elem?.numserie) && <>
                                            {filteredHeaders?.map((column) => (
                                                <StyledTableCell align="center">
                                                    <p>
                                                        {column.key === 'datetime'
                                                            ? constFormatDateTable(elem?.[column.key], start)
                                                            : elem?.[column.key]}
                                                    </p></StyledTableCell>
                                            ))}
                                        </>
                                        }
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }</>
    )
}
