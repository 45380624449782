import { createSlice } from '@reduxjs/toolkit'

export const logosSlice = createSlice({
  name: 'logos',
  initialState: {
    user: {},
    project: {}
  },
  reducers: {
    addLogoUser: (state, action) => {
      state.user = action.payload
    },
    addLogoProject: (state, action) => {
      state.project = action.payload
    },
    clearLogoProject: (state, action) => {
        return  { 
            user: state.user,
            tree: {} 
        }
    }
  },
})

export const { addLogoUser, addLogoProject, clearLogoProject } = logosSlice.actions

export default logosSlice.reducer