import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { urls } from "utils/constant"
import { datoNull } from "views/Scada/MABT/utils"
import { headersScada } from "services/headers"
import { Iconecoresumen, Iconsurtidor, Iconco2, Icontree } from "utils/icon-centrocontrol"
import "./ScadaGeneral.scss"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"

export const Ecoresumen = ({ token, devices }) => {
    const [ecoResumen, setEcoResumen] = useState()

    const fetchEcoResumen = () => {
        fetch(urls.scada + `ecoresumen/${devices.numserie}?idCCMaster=${devices.numserie}`, { method: "POST", headers: headersScada(token) })
        .then(res => {
            if (res.status === 200) {
                return res.json()
            } else {
                return {huellaCo2: null, tree: null, kmEquivalents: null}
            }
        })
        .then(data => {
            setEcoResumen(data)
        })
        .catch(err => {
            console.error("ERROR. API Scada", err)
        })
    }

    useEffect(()=> {
        fetchEcoResumen()
    },[])

    return (
        <Grid container className="flex-end justify-end height-full">
            <span className="flex-center pr-4">
                <Iconecoresumen />
                <h3>Ecoresumen:</h3>
            </span>
            <span className="component-ecocard pr-4">
                <HtmlTooltip title={<span>Huella de carbono</span>}>
                    <Iconco2/>
                </HtmlTooltip>
                <p>{datoNull(ecoResumen?.huellaCo2) !== '-' ? datoNull(ecoResumen?.huellaCo2) + ' T' : '-' }</p>
            </span>
            <span className="component-ecocard pr-4">
                <HtmlTooltip title={<span>Árboles sembrados</span>}>
                    <Icontree/>
                </HtmlTooltip>
                <p>{datoNull(ecoResumen?.tree)}</p>
            </span>
            <span className="component-ecocard pr-3">
                <HtmlTooltip title={<span>Kms equivalentes</span>}>
                    <Iconsurtidor/>
                </HtmlTooltip>
                <p>{datoNull(ecoResumen?.kmEquivalents) !== '-' ? datoNull(ecoResumen?.kmEquivalents) + ' Km' : '-' }</p>
            </span>
        </Grid>
    )
}