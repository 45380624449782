import { Grid } from '@mui/material'
import React from 'react'
import { ProfileLogos } from 'views/User/Profile/Logo/ProfileLogos'

export const PersonalizationConfig = ({ idProject }) => {

    return (
        <Grid container className='pt-3 d-flex justify-content-center align-items-center'>
            <Grid item xs={12} >
                <div className="avatar-container mb-3 mt-3">
                    <h3>Logos</h3>
                </div>
                <ProfileLogos idProject={idProject} />
            </Grid>
        </Grid>
    )
}