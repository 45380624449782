import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { ProjectsListNew } from "views/Project/ProjectsListNew"
import { AuthFooter } from "views/Footer/AuthFooter"
import { NavBar } from "views/NavBar/NavBar"

const Projects = () => {
  const token = sessionStorage?.token

  return (
    <>
      <NavBar/>
      <div className="main-content d-flex flex-column">
        <Routes>
          <Route path='inicio' element={!localStorage.getItem(token) ? <Navigate to="/auth/login" replace /> : <ProjectsListNew />} />
          <Route path="*" element={<Navigate to="/projects" replace />} />
        </Routes>
      </div>
      <AuthFooter />
    </>
  )
}

export default Projects
