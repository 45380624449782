import React, { useEffect, useState } from 'react'
import { Loader } from "components/Loader/Loader"
import { toKilowPercent } from 'utils/functionsforGraphs'
import { ChangeBatery } from 'utils/icon-centrocontrol'
import { changeBattery } from 'utils/Utils'
import { GraphConstructor } from 'views/Scada/SelfConsumption/GraphsUtils/GraphConstructor'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import 'views/Scada/SelfConsumption/GraphsUtils/circle.scss'

export const BatteryGraphs = ({ data, loading, daily }) => {
    const [constructor, setConstructor] = useState(<GraphConstructor />)
    let isMobile = (window.innerWidth <= 480)

    useEffect(() => {
        const dataInfo =[{
            data: {
                categories: [],
                data: [{
                    solar: data?.bateria?.soc !== undefined ? data?.bateria?.soc : 0,
                    total: data?.bateria?.soc !== undefined ? 100 - data?.bateria?.soc : 100
                }]
            }
        }]
        setConstructor(
            <GraphConstructor
                className='doughnut-style' 
                daily={daily}
                nombre={['solar']}
                props={dataInfo}
                graphStyle={'doughnut'}
                bateria={true}
            />
        )
    }, [data])

    return (
        <>
            {loading && data ?
                <Loader/>
            :
                <div className="flex items-center relative">
                    <div className='miniCircle'>{constructor}</div>
                    <ChangeBatery 
                        className="battery-style"
                        change={changeBattery(data?.bateria?.soc > 0 ? data?.bateria?.soc : 0)} 
                        width={isMobile === true ? "20" : "35"} 
                        height="40"
                    />
                    {data?.bateria !== undefined &&
                        <p className="battery-style-p">{toKilowPercent(data?.bateria?.soc) + "%"}</p>
                    }
                </div>
            }
        </>
    )
}