const numbers = (num) => {
  return num !== undefined && num !== '' && /^[0-9]*$/.test(num)
}

export const validatorNum = (obj) => {
  return (
    numbers(obj.numserie) && numbers(obj.id_project) && numbers(obj.id_group)
  )
}

export const validatorEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const specialCharacters = /^[A-Za-z0-9ñÑáÁéÉíÍóÓúÚ\s@.!$%*?_-]+$/

  return regex.test(email) && specialCharacters.test(email)
}

export const validatorNick = (string) => {
  let validator = /^[´ a-zA-ZñÑáéíóúÁÉÍÓÚ0-9-]*$/
  return validator.test(string)
}

export const validatorCoordenates = (string) => {
  let validator = /^([0-9 ,.-])*$/
  return string.match(validator)
}

export const validatorNumber = (e) => {
  const numberRegex = /^\d*\.?\d*$/
  if (!numberRegex.test(e) && e?.length > 0) {
    return true
  } else if (e?.length === 0) {
    return false
  } else {
    return false
  }
}

export const validatorInput = (e) => {
  const regex = /^[A-Za-z0-9ñÑáÁéÉíÍóÓúÚ\s_.-]+$/
  if (!regex.test(e) && e?.length > 0) {
    return true
  } else if (e?.length === 0) {
    return false
  } else {
    return false
  }
}

export const validatorPass = (e) => {
  const regex = /^[A-Za-z0-9ñÑáÁéÉíÍóÓúÚ\s@.!$%*?_-]+$/
  if (!regex.test(e) && e?.length > 0) {
    return true
  } else if (e?.length === 0) {
    return false
  } else {
    return false
  }
}

export const validatorFloatVacio = (string) => {
  const regex = /^(?:[0-9]+(?:\.[0-9]*)?|)$/
  return regex.test(string)
}

export const validatorInputNumber = (string) => {
  const regex = /^[0-9]*$/
  return regex.test(string)
}

export const validatorMac = (mac) => {
  const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/

  if (macRegex.test(mac)) {
    return false
  } else {
    return true
  }
}
