import React from 'react'
import { Avatar, FormControl, Grid, Typography } from '@mui/material'
import { DropZone } from 'components/DropZone/DropZone'
import { Modal } from 'components/Modal/Modal'
import { positions } from 'utils/Utils'
import { SwitchStyled } from 'components/Switch/SwitchStyled'

export const EditLogo = ({open, setOpen, fetchEditLogo, loading, fileEdit, position, setPosition, logos, handleEditFiles, positionOrigin, name}) => {    
    const functCancel = () => {
        setPosition(-1)
        setOpen(!open)
    }

    return (
        <Modal open={open} setOpen={setOpen} functCancel={functCancel} functSave={fetchEditLogo} disabled={fileEdit?.length === 0 && position === positionOrigin} loading={loading} title='Editar logo'>
            <Grid item xs={12} md={12} lg={12} xl={12} className='pr-4 pl-4 pb-3'>
                <FormControl fullWidth variant="outlined" size="small" className='pb-2'>
                    <div className='pb-1'>
                        <small className="color-black">
                            Selecciona la posición:
                        </small>
                    </div>
                    <Grid container>
                        {positions.map((pos, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={5} className={`flex-center justify-start pt-2 pb-2 shaded ${index % 2 === 0 ? 'shaded' : 'not-shaded'}`}>
                                    <Typography className='font-size-12 pl-2'>{pos.position}</Typography>
                                </Grid>
                                <Grid item xs={1} className={`flex-center justify-start pt-2 pb-2 shaded ${index % 2 === 0 ? 'shaded' : 'not-shaded'}`} style={{ stroke: position === pos.value ? '#FF8A1F' : '#fff' }}>
                                    <SwitchStyled
                                        className="switch-small"
                                        checked={position === pos.value}
                                        onChange={(e) => setPosition(pos.value)}
                                    />
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </FormControl>
                <div className='pb-1'>
                    <small className="color-black">
                        Subir logo:
                    </small>
                </div>
                <DropZone onFileUpload={(file) => { handleEditFiles(file) }} acceptedExtensions={['image/jpeg', 'image/jpg', 'image/png']} maxFilesAcept={1} messageZone='Arrastra y suelta archivos .jpg, .jpeg o .png aquí, o has clic para seleccionar archivos (El archivo no puede superar los 100kb)' maxSize={100} titleFiles='Nombre del fichero con el logo actual:'/>
            </Grid>
            <Grid item xs={5} md={5} lg={5} xl={5} className='pl-4'>
                <div className='pb-1 text-align-center'>
                    <h4 className="color-black">
                        Logo actual
                    </h4>
                </div>
                <Avatar sx={{ bgcolor: 'white', width: 'auto', height: '5em', border: "1px solid #cbcbcb", borderRadius: '0' }}>
                    <img className="pt-2 pb-2 pr-3 pl-3" style={{ maxWidth: '10em', height: '5em' }} src={`data:${logos?.[name]?.[1]};base64,${logos?.[name]?.[2]}`} alt='logo' />
                </Avatar>
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2}/>
            <Grid item xs={5} md={5} lg={5} xl={5} className='pr-4'>
                <div className='pb-1 text-align-center'>
                    <h4 className="color-black">
                        Logo nuevo
                    </h4>
                </div>
                <Avatar sx={{ bgcolor: 'white', width: 'auto', height: '5em', border: "1px solid #cbcbcb", borderRadius: '0' }}>
                    {fileEdit?.length === undefined && <img className="pt-2 pb-2 pr-3 pl-3" style={{ maxWidth: '10em', height: '5em' }} src={URL.createObjectURL(fileEdit)} alt='logo' />}
                </Avatar>
            </Grid>
        </Modal>
    )
}