import { createSlice } from '@reduxjs/toolkit'

export const permissionSlice = createSlice({
    name: 'permission',
    initialState: {
        permission: '',
    },
    reducers: {
        savePermission: (state, action) => {
            if(action.payload.permission) {
                state.permission = action.payload.permission
            }
        }
    },
})

export const { savePermission } = permissionSlice.actions

export default permissionSlice.reducer