import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { GraphsBar } from 'components/Graph/GraphsBar'
import { GraphsLine } from 'components/Graph/GraphsLine'
import { Loader } from 'components/Loader/Loader'
import { consumidos, dispositivos, generalGraphs, generalGraphsBackground, vertidos } from 'utils/constant'
import { formatDateNoSecond } from 'utils/Utils'
import { transparent } from './utilsSelfConsumption'
import { formatLabel } from '../MABT/utils'
import "./styleSelfConsumption.scss"
import { obtenerNombreMes } from 'utils/Utils'

export const GeneralGraphsSelfConsumption = ({ devices, data, dataGraphs, loading, activeTab, daily }) => {
    const [labels, setLabels] = useState([])
    const [dataSets, setDataSets] = useState([])
    const [labelEnergy, setLabelEnergy] = useState([])
    const [dataSetsEnergy, setDataSetsEnergy] = useState([])
    const battery = devices?.[0]?.childrenDevices?.filter(dev => dev?.id_tipo_dispositivo === dispositivos.bateria)
    const labelGeneral = battery?.length > 0 ? ['Producción FV (kW)', 'Consumida (kW)', 'Batería (kW)', 'Carga batería (%)'] : ['Producción FV (kW)', 'Consumida (kW)']

    const dataGraphsPower = () => {
        setDataSets([])
        dataGraphs && dataGraphs?.forEach((item, i) => {
            item?.data?.forEach((datos, index) => {
                if (datos?.name !== "soc") {
                    setDataSets(prev => [...prev, {
                        label: labelGeneral?.[index],
                        data: datos?.data,
                        borderColor: generalGraphs?.[index],
                        backgroundColor: transparent(generalGraphs?.[index]?.[0], 0.7),
                        pointBackgroundColor: transparent(generalGraphs?.[index]?.[0], 0.7),
                        fill: true,
                        id: i + 5,
                        pointRadius: 0,
                        tension: 0.1,
                        numserie: `${datos?.numserie}`,
                        unit: " kW"
                    }])
                } else if (datos?.name === "soc") {
                    setDataSets(prev => [...prev, {
                        label: labelGeneral?.[index],
                        data: datos?.data,
                        borderColor: generalGraphsBackground?.[index],
                        backgroundColor: transparent(generalGraphs?.[index]?.[0], 0.7),
                        pointBackgroundColor: transparent(generalGraphs?.[index]?.[0], 0.7),
                        fill: true,
                        id: i + 5,
                        pointRadius: 0,
                        tension: 0.1,
                        numserie: `${datos?.numserie}`,
                        yAxisID: 'y1',
                        unit: "%"
                    }])
                }
            })
        })
        setLabels(dataGraphs && dataGraphs?.[0]?.categories)
    }

    const EnergyProduction = () => {
        setDataSetsEnergy([])
        setDataSetsEnergy([{
            Type: "bar",
            label: "Generada",
            data: data && data?.map(device => device?.eagendia),
            backgroundColor: transparent(vertidos, 0.7),
            borderColor: vertidos,
            pointBackgroundColor: transparent(vertidos, 0.7),
            fill: true,
            id: "1",
            numserie: data?.[0]?.numserie,
            maintainAspectRatio: "true",
            borderWidth: 3,
        }, {
            Type: "bar",
            label: "Consumida",
            data: data && data?.map(device => device?.eaconsdia),
            backgroundColor: transparent(consumidos, 0.7),
            borderColor: consumidos,
            pointBackgroundColor: transparent(consumidos, 0.7),
            fill: true,
            id: "1",
            numserie: data?.[0]?.numserie,
            maintainAspectRatio: "true",
            borderWidth: 3,
        }])
    }

    const ArrayLabelEnergy = () => {
        setLabelEnergy([]);
        setLabelEnergy((prev) => {
            const updatedLabelEnergy = [...prev];
            data?.forEach((dev) => {
                const datetime = dev?.datetime;
                if (datetime && !updatedLabelEnergy?.includes(formatDateNoSecond(datetime))) {
                    updatedLabelEnergy?.push(formatDateNoSecond(datetime));
                }
            });
            let arrayLabel = updatedLabelEnergy
            let arrayFinal
            if (daily === "months") {
                arrayFinal = arrayLabel?.map(numero => obtenerNombreMes(parseInt(numero)));
            } else {
                arrayFinal = arrayLabel
            }
            return arrayFinal;
        })
    }

    useEffect(() => {
        EnergyProduction()
        ArrayLabelEnergy()
    }, [data])

    useEffect(() => {
        dataGraphsPower()
    }, [dataGraphs])

    return (
        <Grid container className="report-scada-self-graph-general">
            <Grid item xs={12} >
                {loading ? <Loader /> : <>
                    {activeTab === 0 &&
                        <GraphsLine data={dataSets} labels={formatLabel(labels)} battery={battery?.length > 0} />
                    }
                    {activeTab === 1 &&
                        <GraphsBar dataSet={dataSetsEnergy} labels={labelEnergy} />
                    }
                </>
                }
            </Grid>
        </Grid>
    )
}