import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { Loader } from "components/Loader/Loader"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import { IconConsum, IconConstRed, IconProd, Iconvert } from "utils/icon-centrocontrol"
import { toKiloW, getPercent } from 'utils/functionsforGraphs'
import { PieChart } from "views/Scada/SelfConsumption/GraphsUtils/GraphConstructor"
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'

export const TableResumeGeneral = ({ general, loading, isMobile }) => {
    const [data, setData] = useState([])

    useEffect(() => {
        setData([
            general?.eagendia, general?.vertida, general?.consumida
        ])
    }, [general])

    let totalEnergy = Number(general?.eagendia + general?.vertida + general?.consumida)

    return (
        <>
            {!isMobile &&
                <div className="resume-Table" >
                    <Grid colums={15} ></Grid>
                    <Grid item sm={15} lg={3} xl={3} >
                        <div className="card-resume-table">
                            <span>
                                <IconConsum />
                                <p ><b>{toKiloW(general?.eaconsdia)} kWh</b></p>
                            </span>
                            <h4 >Consumo</h4>
                        </div>
                    </Grid>
                    <Grid item sm={12} lg={3} xl={3} >
                        <div className="card-resume-table">
                            <span>
                                <IconConstRed />
                                <p ><b>{toKiloW(general?.consumida)} kWh</b></p>
                            </span>
                            <h4 >Energía Consumida Red</h4>
                        </div>
                    </Grid>
                    <Grid item sm={15} lg={3} xl={3} >
                        <div className="card-resume-table">
                            <span>
                                <IconProd />
                                <p ><b>{toKiloW(general?.eagendia)} kWh</b></p>
                            </span>
                            <h4 >Producción FV</h4>
                        </div>
                    </Grid>
                    <Grid item sm={15} lg={3} xl={3} >
                        <div className="card-resume-table" >
                            <span>
                                <Iconvert />
                                <p ><b>{toKiloW(general?.vertida)} kWh</b></p>
                            </span>
                            <h4 >Energía Vertida Red </h4>
                        </div>
                    </Grid>
                    <Grid item sm={1} lg={3} xl={3} >
                        <div className="card-resume-general-graph">
                            <Grid item sm={3} lg={3} xl={3}>
                                <span className="general-graph-icon">
                                    <span>
                                        <span className="icon-legend-graph-general">
                                            <HtmlTooltip title={<span>Energía producida</span>}>
                                                <IconProd />
                                            </HtmlTooltip>
                                            <span className="label-graph-general-production">
                                            </span>
                                        </span>
                                        <p>{getPercent(general?.eagendia, totalEnergy) + "%"}</p>
                                        <span className="percent-graph-general-left">
                                        </span>
                                    </span>
                                    <span>
                                        <span className="icon-legend-graph-general">
                                            <HtmlTooltip title={<span>Energía vertida red</span>}>
                                                <Iconvert />
                                            </HtmlTooltip>
                                            <span className="label-graph-general-vertida">
                                            </span>
                                        </span>
                                        <p>{getPercent(general?.vertida, totalEnergy) + "%"}</p>
                                        <span className="percent-graph-general-left">
                                        </span>
                                    </span>
                                </span>
                            </Grid>
                            <Grid item sm={6} lg={6} xl={6}>
                                {loading ? <Loader /> :
                                    <PieChart datos={data} />
                                }
                            </Grid>
                            <Grid item sm={3} lg={3} xl={3}>
                                <span className="general-graph-icon graph-icon-consumption">
                                    <span className="icon-legend-graph-general">
                                        <span className="label-graph-general-consumida">
                                        </span>
                                        <HtmlTooltip title={<span>Energía consumida red</span>}>
                                            <IconConstRed />
                                        </HtmlTooltip>
                                    </span>
                                    <p>{getPercent(general?.consumida, totalEnergy) + "%"}</p>
                                    <span className="percent-graph-general">
                                    </span>
                                </span>
                            </Grid>
                        </div>
                    </Grid>
                </div>}
            {isMobile && <div className="resume-Table" >
                <Grid item sm={12} lg={3} xl={3} >
                    <div className="card-resume-general-graph">
                        <Grid item sm={3} lg={3} xl={3}>
                            <span className="general-graph-icon">
                                <span>
                                    <span className="icon-legend-graph-general">
                                        <IconProd />
                                        <span className="label-graph-general-production">
                                        </span>
                                    </span>
                                    <p>{getPercent(general?.eagendia, totalEnergy) + "%"}</p>
                                    <span className="percent-graph-general-left">
                                    </span>
                                </span>
                                <span>
                                    <span className="icon-legend-graph-general">
                                        <Iconvert />
                                        <span className="label-graph-general-vertida">
                                        </span>
                                    </span>
                                    <p>{getPercent(general?.vertida, totalEnergy) + "%"}</p>
                                    <span className="percent-graph-general-left">
                                    </span>
                                </span>
                            </span>
                        </Grid>
                        <Grid item sm={6} lg={6} xl={6}>
                            {loading ? <Loader /> :
                                <PieChart datos={data} />
                            }
                        </Grid>
                        <Grid item sm={3} lg={3} xl={3}>
                            <span className="general-graph-icon graph-icon-consumption">
                                <span className="icon-legend-graph-general">
                                    <span className="label-graph-general-consumida">
                                    </span>
                                    <IconConstRed />
                                </span>
                                <p>{getPercent(general?.consumida, totalEnergy) + "%"}</p>
                                <span className="percent-graph-general">
                                </span>
                            </span>
                        </Grid>
                    </div>
                </Grid>
                <Grid item sm={12} lg={3} xl={3} >
                    <div className="card-resume-table">
                        <span>
                            <IconConsum />
                            <h4 >Consumo</h4>
                        </span>
                        <p ><b>{toKiloW(general?.eaconsdia)} kWh</b></p>
                    </div>
                </Grid>
                <Grid item sm={12} lg={3} xl={3} >
                    <div className="card-resume-table">
                        <span>
                            <IconConstRed />
                            <h4 >Energía Consumida Red</h4>
                        </span>
                        <p ><b>{toKiloW(general?.consumida)} kWh</b></p>
                    </div>
                </Grid>
                <Grid item sm={12} lg={3} xl={3} >
                    <div className="card-resume-table">
                        <span>
                            <IconProd />
                            <h4 >Producción FV</h4>
                        </span>
                        <p ><b>{toKiloW(general?.eagendia)} kWh</b></p>
                    </div>
                </Grid>
                <Grid item sm={12} lg={3} xl={3} >
                    <div className="card-resume-table" >
                        <span>
                            <Iconvert />
                            <h4 >Energía Vertida Red </h4>
                        </span>
                        <p ><b>{toKiloW(general?.vertida)} kWh</b></p>
                    </div>
                </Grid>
            </div>
            }
        </>
    )
}