import React from "react"
import { Button } from "@mui/material"
import { capitalize } from "utils/Capitalize"

export const ButtonStyled = ({
  size,
  type,
  onClick,
  children,
  style,
  className,
  fullWidth,
  disabled,
  value,
  border,
  color,
  background,
  colorHover,
  backgroundHover,
  title,
  loading
}) => {

  return (
    <Button
      className={`button-action ${className}`}
      sx={{
        background: background || "#fff",
        textTransform: "none",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        textAlign: "center",
        border: border || "2px solid transparent",
        color: color || "#545454",
        "&:hover": {
          fontWeight: "700",
          fontSize: "14px",
          lineHeight: "17px",
          color: colorHover || "#fff",
          backgroundColor: backgroundHover || "#FF8A1F"
        }
      }}
      type={type}
      size={size}
      onClick={onClick}
      fullWidth={fullWidth}
      title={title}
      style={style}
      disabled={loading ? loading : disabled}
      variant="contained"
      value={value}
    >
      {loading !== true && typeof children === "string" ? (
        capitalize(children)
      ) : loading === true ? (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          <span className="visually-hidden ml-1">Cargando...</span>
        </>
      ) : (
        children
      )}
    </Button>
  )
}

