import React, { useContext, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Grid } from "@mui/material"
import { GlobalContext } from "context/GlobalContext"
import { urls } from "utils/constant.jsx"
import { Modal } from "components/Modal/Modal"
import { headersCentroControl } from "services/headers"
import "assets/css/custom.css"
import { useEffect } from "react"
import { ButtonModal } from "components/Buttons/ButtonModal"
import { useDispatch } from "react-redux"
import { createToast } from "reduxSlice/toastSlice"

export const RemoveProject = ({ project, open1, toggle, setOpen1 }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname.split('/')[1]
  const token = sessionStorage?.token
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [buttonAccept, setButtonAccept] = useState()
  const { projectsCompleto, setProjectsCompleto } = useContext(GlobalContext)

  const deleteProject = () => {
    setLoading(true)
    let status
    fetch(
      urls.ccontrol + `project`, {
      method: 'DELETE',
      redirect: 'follow',
      headers: headersCentroControl(token),
      body: JSON.stringify({ "id_project": project?.id_project })
    }).then(res => {
      setLoading(false)
      status = res.status
      return res.json()
    }).then(data => {
      dispatch(createToast({ status: status, message: data.message }))
      if (status === 200) {
        setProjectsCompleto(projectsCompleto?.filter((item) => item?.id_project !== project?.id_project))
      }
      toggle()
      if (path === "admin") {
        navigate(`/projects/inicio`)
      }
    }).catch(err => {
      console.error("ERROR. API Centro de Control", err)
    })
  }


  useEffect(() => {
    setButtonAccept(
      <Grid container className='mt-4 mb-4'>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <div className='modal-footer'>
            <ButtonModal
              fullWidth={true}
              className="button-ok-modal"
              onClick={() => setOpen1(false)}>
              Aceptar
            </ButtonModal>
          </div>
        </Grid>
      </Grid>)
  }, [])



  return (
    <>
      <Modal onClick={(e) => e.stopPropagation()} open={open1} setOpen={setOpen1} title={`Eliminar proyecto "${project?.nick}"`} footer={projectsCompleto?.length <= 1 && buttonAccept} functSave={projectsCompleto?.length > 1 && deleteProject} buttonAction="Eliminar" loading={loading} disabled={projectsCompleto?.length <= 1}>
        {project && project?.groups?.map((grp) => {
          return (
            <Grid item xs={12} className="text-center mt-2">

              {grp?.devices?.length !== 0 &&
                <h4 className="font-weight-lighter" style={{ fontSize: '1.2rem' }}>
                  El grupo "{grp?.nick}" tiene {grp?.devices?.length} instalaciones.
                </h4>
              }
              {grp?.subgroups?.map((sbgrp) => {
                return (
                  <div key={sbgrp.id_subgroup}>
                    {sbgrp?.devices?.length !== 0 &&
                      <div id="verificationCode">
                        <h4 className="font-weight-lighter" style={{ fontSize: '1.2rem' }}>
                          Este proyecto tiene un subgrupo con {sbgrp?.devices?.length} instalaciones.
                        </h4>
                      </div>
                    }
                  </div>
                )
              })}
            </Grid>
          )
        })}
        <Grid item xs={12} className="text-center mt-2 ">
          {projectsCompleto?.length <= 1 ?
            <p>El último proyecto no se puede eliminar</p> :
            <h4 className="font-weight-lighter" style={{ fontSize: '1.2rem' }}>
              ¿Deseas eliminar el proyecto?
            </h4>}

        </Grid>
        <hr className='m-0' />
      </Modal>

    </>
  )
}
