import React, { useState } from 'react'
import { Grid } from "@mui/material"
import { Modal } from 'components/Modal/Modal'
import { headersCentroControl } from 'services/headers'
import { urls } from 'utils/constant'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'

export const ModalDeleteSubGroup = ({ subgrp, open, setOpen, toggle }) => {
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)


    //Eliminar subgrupo

    const handleRemove = (item) => {
        let status
        setLoading(true)
        const url = urls.ccontrol + `subgroup`
        fetch(url, {
            method: 'DELETE',
            headers: headersCentroControl(token),
            body: JSON.stringify({
                "id_subgroup": Number(item)
            })
        }).then(response => {
            setLoading(false)
            status = response.status
            return response.json()

        }).then(data => {
            dispatch(createToast({ status: status, message: data.message, reload: true }))
            setOpen(false)
        }).catch(err => {
            console.error("ERROR. API Centro de Control", err)
        })
    }



    return (
        <>
            <Modal open={open} loading={loading} onClick={(e) => e.stopPropagation()} setOpen={setOpen} title={`Eliminar subgrupo "${subgrp.nick}"`} buttonAction="Eliminar" functSave={() => handleRemove(subgrp?.id_subgroup)} disabled={subgrp.devices.length > 0 ? true : false}>
                <Grid item xs={12} className="font-weight-lighter text-center mt-2">
                    {subgrp.devices.length > 0 ?
                        <h4 className="font-weight-lighter" style={{ fontSize: '1.2rem' }}>
                            Tienes dispositivos en este subgrupo. Elimínalos antes.
                        </h4>
                        :
                        <h4 className="font-weight-lighter text-center mt-2" style={{ fontSize: '1.2rem' }}>
                            ¿Deseas eliminar este subgrupo?
                        </h4>}
                </Grid>
            </Modal>
        </>
    )
}