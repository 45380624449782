import moment from "moment"

export function addSensor(numSerie, sensorNumber, dispositivo) {
    const device = dispositivo?.[0]?.childrenDevices?.find(device => device?.numserie === numSerie)
    if (device) {
        if (!device?.sensor) {
            device.sensor = [sensorNumber]
        } else if (!device?.sensor?.includes(sensorNumber)) {
            device.sensor.push(sensorNumber)
        }
    }
    return dispositivo
}

export function updateSensor (numMedidorAnterior, numMedidorNuevo, numSerieSensor, dispositivo) {
    dispositivo?.[0]?.childrenDevices?.forEach(device => {
        if (device?.numserie === numMedidorAnterior) {
            device.sensor = device?.sensor?.filter(sensor => sensor !== numSerieSensor)
        }
        if (device?.numserie === numMedidorNuevo) {
            if (!device?.sensor) {
                device.sensor = [numSerieSensor]
            } else if (!device?.sensor?.includes(numSerieSensor)) {
                device.sensor.push(numSerieSensor)
            }
        }
    })
    return dispositivo
}
    
export function deleteSensor(sensorNumber, dispositivo) {
    dispositivo?.[0]?.childrenDevices?.forEach(device => {
        if (device?.sensor && device?.sensor?.includes(sensorNumber)) {
            device.sensor = device?.sensor?.filter(sensor => sensor !== sensorNumber)
        }
    })
    return dispositivo
}

export function changeKey (key) { 
    switch (key) {
        case 'lat':
            return 'latitud'
        case 'lng':
            return 'longitud'
        case 'medidor_asociado':
            return 'numserie_medidor'
        default:
            return key
    }
}

export function haveChanges (sensor, body) {
    const keyCompare = ["nick", "lat", "lng", "address", "position", "medidor_asociado"]
    for (let key of keyCompare) {
        if (sensor[key] !== body[key]) {
        return true
        }
    }
    return false
}

export function generateBodyCreate (body) {
    const bodyCreate = {...body}
    bodyCreate.numserie = Number(bodyCreate?.numserie)
    bodyCreate.numserie_medidor = Number(bodyCreate?.numserie_medidor)
    bodyCreate.latitud = Number(bodyCreate?.latitud)
    bodyCreate.longitud = Number(bodyCreate?.longitud)
    bodyCreate.position = Number(bodyCreate?.position)
    bodyCreate.fecha_sync = moment().format('YYYY-MM-DD HH:mm:ss')
    return bodyCreate
}

export function generateBodyEdit (sensor, body) {
    const bodyEdit = {}
    for (let key in sensor) {
        if (sensor[key] !== body[key] || key === 'numserie') {
            bodyEdit[changeKey(key)] = body[key]
        }
    }
    return bodyEdit
}