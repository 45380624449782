import React from "react"
import { Button } from "@mui/material"
import { capitalize } from "utils/Capitalize"

export const ButtonLogin = ({
    children,
    disabled,
    loading,
    onClick
}) => {

    return (
        <Button
            className={`button-login`}
            type='submit'
            fullWidth={true}
            disabled={loading ? loading : disabled}
            onClick={onClick}
        >
            {!loading ?
                typeof children === "string" ? capitalize(children) : children
                :
                <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
                    <span className="visually-hidden ml-1">Cargando...</span>
                </>
            }
        </Button>
    )
}