import React, { useState, useEffect } from 'react'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { Loader } from 'components/Loader/Loader'
import { Modal } from 'components/Modal/Modal'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import "./StyleModal.scss"

export const ModalSwitchOnOff = ({ setTableManual, manualControl, reloadRelay, data, loadingOn, setLoadingOn, modal, setModal, idCcMaster, centroControl, loading, setLoading }) => {
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const [body2, setBody2] = useState([])
    const [prevMode, setPrevMode] = useState()

    const handleMode = (mode) => {
        setPrevMode(mode)
        switch (Number(mode)) {
            case 103:
                return 100;
            case 100:
                return 103;
            case 102:
                return 103;
            case 101:
                return 100;
            default:
                return mode;
        }
    }

    const asyncCalendar = () => {
        setLoadingOn(true)
        let status
        fetch(urls.scada + `calendarRelay`, { method: 'PATCH', headers: headersScada(token), body: JSON.stringify(body2) })
            .then(res => {
                status = res.status
                if (res.status === 400) {
                    data.internal_mode = prevMode
                } else {
                    data.internal_mode = handleMode(data?.internal_mode)
                }
                return res.json()
            }).then(datos => {
                if (centroControl) {
                    let prueba = manualControl?.find(ele => ele?.numserie === idCcMaster)
                    if (data?.ID_entry === 1) {
                        prueba.out1_mode = handleMode(prueba.out1_mode)
                    } else if (data?.ID_entry === 2) {
                        prueba.out2_mode = handleMode(prueba.out2_mode)
                    }
                    setTableManual(manualControl)
                }
                dispatch(createToast({ status: status, message: datos.message, reload: false }))
            })
            .catch(err => console.error("ERROR. API Scada", err))
            .finally(() => {
                setLoadingOn(false)
                setModal(false)
            })
    }

    useEffect(() => {
        setBody2({
            "numserie": idCcMaster,
            "state": 1,
            "ID_entry": data?.ID_entry,
            "alias": data?.name,
            "int_mode": handleMode(data?.internal_mode)
        })
    }, [data, loadingOn])


    return (
        <Modal functSave={asyncCalendar} disabled={loadingOn} loading={loadingOn} open={modal} buttonAction="Aplicar" setOpen={setModal} title="Confirmación sobre el calendario"
            footer={
                !loading && !data ?
                    <Grid items xs={12} className="d-flex justify-content-center align-items-center flex-column p-4">
                        {!loading &&
                            <ButtonStyled onClick={() => reloadRelay(idCcMaster)}>Intentar Conectar</ButtonStyled>
                        }
                    </Grid>
                    :
                    loading &&
                    <Grid items xs={12} className="d-flex justify-content-center align-items-center flex-column p-4">
                        <Loader />
                    </Grid>
            }
        >
            {!loading && data &&
                <span style={{ margin: "0 auto" }}>
                    <p>¿Quieres {data?.internal_mode === 100 || data?.internal_mode === 102 ? "desconectar" : "conectar"} {data?.prevName ? data.prevName : data?.name}?</p>
                </span>
            }
            {!loading && !data &&
                <Grid items xs={12} className="d-flex justify-content-center align-items-center flex-column p-4">
                    <h2>El máster no está conectado</h2>
                </Grid>
            }
        </Modal>
    )
}