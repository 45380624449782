import React from 'react'
import { Dialog, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ButtonModal } from 'components/Buttons/ButtonModal'

export const Modal = ({ open, size, setOpen, title, iconTitle, PaperProps, funtCancelButton, maxWidth, children, head, footer, functSave, loading, disabled, buttonAction, buttonCancel, onClick, functCancel, className, classNameBody }) => {

    return (
        <Dialog fullWidth={true} open={open} classes={{ paper: `${className} disabled-croll-x shadow-lg rounded`, }} maxWidth={maxWidth} size={size} onClick={onClick} PaperProps={PaperProps}  >
            {head ?
                <Grid container>
                    {head}
                </Grid>
                :
                <Grid container columns={81} className='p-3'>
                    <Grid item xs={80} md={80} lg={80} xl={80}>
                        <div className='modal-head'>
                            {iconTitle}
                            <h2 className={`color-black ${iconTitle ? 'ml-2 pt-1' : ''}`}>
                                {title}
                            </h2>
                        </div>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1} xl={1}>
                        <div className='modal-close'>
                            <IconButton className='p-0 pt-1' aria-label="close" onClick={() => funtCancelButton ? funtCancelButton() : functCancel ? functCancel() : setOpen(!open)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
            }
            <Grid container className={classNameBody}>
                {children}
            </Grid>
            {footer ?
                <Grid container>
                    {footer}
                </Grid>
                :
                <Grid container className='mt-4 mb-4'>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <div className='modal-footer'>
                            <ButtonModal
                                fullWidth={true}
                                loading={loading}
                                className="button-cancel-modal"
                                onClick={() => functCancel ? functCancel() : setOpen(!open)}
                                messageLoading={false}
                            >
                                {buttonCancel ? buttonCancel : "Cancelar"}
                            </ButtonModal>
                            <div style={{ width: '2em' }}></div>
                            <ButtonModal
                                fullWidth={true}
                                disabled={disabled}
                                loading={loading}
                                className="button-ok-modal"
                                onClick={() => functSave()}
                            >
                                {buttonAction ? buttonAction : "Guardar"}
                            </ButtonModal>
                        </div>
                    </Grid>
                </Grid>
            }
        </Dialog>
    )
}