import React, { useEffect, useState } from 'react'
import { Loader } from "components/Loader/Loader"
import { IconPanel } from 'utils/icon-centrocontrol'
import { GraphConstructor } from 'views/Scada/SelfConsumption/GraphsUtils/GraphConstructor'
import 'views/Scada/SelfConsumption/GraphsUtils/circle.scss'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'

export const SolarGraphs = ({ data, daily, loading }) => {
    const [constructor, setConstructor] = useState(<GraphConstructor/>)

    useEffect(() => {
        const dataInfo =[{
            data: {
                categories: [],
                data: [{
                    solar: data?.produccion?.pact !== undefined && data?.produccion?.pact !== null ? data?.produccion?.pact : 0,
                    total: ((data?.produccion?.pact !== undefined && data?.produccion?.pact !== 0  && data?.produccion?.pact !== null) || (data?.red?.pact !== undefined && data?.red?.pact !== 0 && data?.red?.pact !== null)) ? data?.produccion?.pact + data?.red?.pact : 100
                }]
            }
        }]
        setConstructor(<GraphConstructor className='doughnut-style' daily={daily} nombre={['solar']} props={dataInfo} graphStyle={'doughnut'} />)
    }, [data])

    return (
        <>
            {loading && data ?
                <Loader/>
            :
                <div className="flex items-center relative">
                    {constructor}
                    <IconPanel className="img-style" />
                </div>
            }
        </>
    )
}