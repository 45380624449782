import React from 'react'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import "./StyleModal.scss"
import { ActionHistoricalCalendar } from 'utils/Utils'
import { formatDate } from 'utils/Utils'
import { ModelCalendar } from 'utils/Utils'

export const ModalHistoricalInfoCalendar = ({ data, open, setOpen }) => {

    return (
        <Modal onClick={(e) => e.stopPropagation()} maxWidth="sm" open={open} setOpen={setOpen} title="Información del calendario" footer={" "}>
            <Grid container className="pr-5 pl-5">
                <Grid item xs={12} className="modal-historical-calendar">
                    <p> <b className="text-bold">ID_Entry:</b> {data?.ID_entry}</p>
                    <p> <b className="text-bold">Salida:</b> {data?.payload?.ID_output + 1}</p>
                    <p> <b className="text-bold">Modo:</b> {ModelCalendar(data?.payload?.internal_mode)}</p>
                    <p> <b className="text-bold">Acción:</b> {ActionHistoricalCalendar(data?.action_description)}</p>
                    <p> <b className="text-bold">Fecha de acción:</b> {formatDate(data?.datetime)}</p>
                    <p> <b className="text-bold">Usuario:</b> {data.email}</p>
                    <p> <b className="text-bold">Prioridad:</b>  {data?.payload?.priority ? "Si" : "No"}</p>
                    {data.payload.start_date && <p> <b className="text-bold">Fecha de inicio:</b>  {data?.payload?.start_date}</p>}
                    {data.payload.end_date && <p> <b className="text-bold">Fecha de fin:</b>  {data?.payload?.end_date}</p>}
                </Grid>
            </Grid>

        </Modal>)
}
