import React, { useState, useEffect, useContext } from 'react'
import { useLocation, Link } from "react-router-dom"
import { Table, Tooltip } from "@mui/material"
import { ImageDevice } from 'utils/ImageDevice/ImageDevice'
import { DropDownDevices } from "views/Devices/MenuDevices/DropDownDevices.jsx"
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { toKiloW } from 'utils/functionsforGraphs'
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import { dateTableDevices } from 'utils/functionsforGraphs'
import { StyledTableCellOrdenado } from 'components/Table/TableStyle'
import { ordenarDatos } from 'utils/Utils'
import "views/Principal/StylePrincipal.scss"
import { StyledTableCell, StyledTableCellHead, StyledTableRow } from 'components/Table/TableStyle'
import { esHoyWithoutFormat } from 'utils/Utils'
import { GlobalContext } from 'context/GlobalContext'
import { OpenToScada } from 'utils/Utils'

export const CurrentDataTableDevice = (props) => {
    const location = useLocation()
    const idProject = location.pathname.split("/")[3]
    const [reloadFetch, setReloadFetch] = useState(1)
    const [sortConfig, setSortConfig] = useState({ key: "nick", direction: "asc" })
    const typeEnergy = 1
    const [potential, setPotential] = useState(1)
    const { actualProject } = useContext(GlobalContext)

    const variableTableSelfConsumption = [
        { label: "Dispositivo", key: "nick" },
        { label: "Instalacion", key: "nick_master" },
        { label: "Fecha", key: "datetime" },
        { label: "Potencia Total kW", key: "pact" },
        { label: "Potencia Reactiva Total kW", key: "preact" },
        { label: "Energía Consumida (kWh)", key: "eaconsdia" },
        { label: "Energía Exportada (kVAh)", key: "eagendia" },
        { label: "Potencia L1 (kW)", key: "pact1" },
        { label: "Potencia L2 (kW)", key: "pact2" },
        { label: "Potencia L3 (kW)", key: "pact3" },
        { label: "Corriente L1 (A)", key: "i1" },
        { label: "Corriente L2 (A)", key: "i2" },
        { label: "Corriente L3 (A)", key: "i3" },
        { label: "Potencia Reactiva L1 (kWh)", key: "preac1" },
        { label: "Potencia Reactiva L2 (kWh)", key: "preac2" },
        { label: "Potencia Reactiva L3 (kWh)", key: "preac3" },
    ]

    useEffect(() => {
        props?.setCsvHeaders(variableTableSelfConsumption)
    }, [])

    useEffect(() => {
        props?.setCsvData([])
        props?.noRepeatData?.forEach(master => {
            props.setCsvData(prev => [...prev, {
                "Dispositivo": master?.nick,
                "Instalaciones": master?.nick_master,
                "Fecha": master?.data?.currentData?.datetime,
                "Potencia Activa (kW)": toKiloW(master?.data?.currentData?.pact),
                "Potencia Reactiva (kVAr)": toKiloW(master?.data?.currentData?.preact),
                "Energía Consumida (kWh)": toKiloW(master?.data?.currentData?.eaconsdia),
                "Energía Exportada (kWh)": toKiloW(master?.data?.currentData?.eagendia),
                "Potencia L1 (kW)": toKiloW(master?.data?.currentData?.pact1),
                "Potencia L2 (kW)": toKiloW(master?.data?.currentData?.pact2),
                "Potencia L3 (kW)": toKiloW(master?.data?.currentData?.pact3),
                "Corriente i1 (A)": toKiloW(master?.data?.currentData?.i1),
                "Corriente i2 (A)": toKiloW(master?.data?.currentData?.i2),
                "Corriente i3 (A)": toKiloW(master?.data?.currentData?.i3),
                "Potencia ractiva L1 (kWh)": toKiloW(master?.data?.currentData?.preac1),
                "Potencia ractiva L2 (kWh)": toKiloW(master?.data?.currentData?.preac2),
                "Potencia ractiva L3 (kWh)": toKiloW(master?.data?.currentData?.preac3),
            }])
        })
    }, [props?.noRepeatData])

    const StyledTableCellMiniHead = ({ children, className }) => {
        return (
            <TableCell
                className={className}
                sx={{
                    fontFamily: 'Montserrat',
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#262626",
                    borderBottom: "1px solid #fff",
                    boxShadow: "none",
                    align: "center"
                }}
            >
                {children}
            </TableCell>
        )
    }

    const StyledTableCellMini = ({ children, className }) => {
        return (
            <TableCell
                className={className}
                sx={{
                    fontFamily: 'Montserrat',
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    borderBottom: "none !important",
                    color: "#575757",
                    align: "center"
                }}
            >
                {children}
            </TableCell>
        )
    }

    return (
        <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead >
                    <TableRow className="row-table-device-sticky">
                        <StyledTableCellHead align="center" style={{ padding: "5px 0px" }}>
                        </StyledTableCellHead>
                        <StyledTableCellOrdenado align="center" celda={'nick'} funcion={() => { ordenarDatos(props.noRepeatData, sortConfig, setSortConfig, 'nick') }} sortConfig={sortConfig} >
                            Dispositivos
                        </StyledTableCellOrdenado>
                        <StyledTableCellOrdenado align="center" celda={'nick_master'} funcion={() => { ordenarDatos(props.noRepeatData, sortConfig, setSortConfig, 'nick_master') }} sortConfig={sortConfig} >
                            Instalaciones
                        </StyledTableCellOrdenado>
                        <StyledTableCellHead align="center">
                            Fecha
                        </StyledTableCellHead>
                        <StyledTableCellHead align="center">
                            Potencia Activa (kW)
                        </StyledTableCellHead>
                        <StyledTableCellHead align="center">
                            Potencia Reactiva (kVAr)
                        </StyledTableCellHead>
                        <StyledTableCellHead align="center" >
                            <select
                                className="option-select-table"
                                id="demo-simple-select-standard"
                                defaultValue={typeEnergy}
                                onChange={(e) => setPotential(e.target.value)}
                            >
                                <option className="option-select-table" value={1}> Energía Activa (kWh)</option>
                            </select>
                            <div className="d-flex justify-content-around">
                                <StyledTableCellMiniHead  >
                                    Consumida
                                </StyledTableCellMiniHead>
                                <StyledTableCellMiniHead>
                                    Exportada
                                </StyledTableCellMiniHead>
                            </div>
                        </StyledTableCellHead>
                        <StyledTableCellHead align="center">
                            <select
                                className="option-select-table"
                                id="demo-simple-select-standard"
                                defaultValue={typeEnergy}
                                onChange={(e) => setPotential(e.target.value)}>
                                <option className="option-select-table" value={1}> Potencia (kW)</option>
                                <option className="option-select-table" value={2}> Corriente (A)</option>
                                <option className="option-select-table" value={3}> Potencia Reactiva Fases (kWh)</option>
                                <option className="option-select-table" value={4}> Tensión (V)</option>
                            </select>
                            <div className="d-flex justify-content-around">
                                <StyledTableCellMiniHead align="center"  >
                                    L1
                                </StyledTableCellMiniHead>
                                <StyledTableCellMiniHead align="center" style={{ border: "none !important" }}>
                                    L2
                                </StyledTableCellMiniHead>
                                <StyledTableCellMiniHead align="center" style={{ border: "none !important" }}>
                                    L3
                                </StyledTableCellMiniHead>
                            </div>
                        </StyledTableCellHead>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.noRepeatData.map((master, i) => {
                        return (
                            <React.Fragment key={i}>
                                <StyledTableRow>
                                    <StyledTableCell align="center" >
                                        <DropDownDevices
                                            icon={faInfoCircle}
                                            device={master?.infoDevice}
                                            updateCcM={master?.data?.currentData}
                                            masterPermission={props?.masterPermission}
                                            active={master?.infoDevice?.activo}
                                            instalacion={master}
                                            reloadFetch={reloadFetch}
                                            setReloadFetch={setReloadFetch}
                                            modalAdvance={true}
                                            index={i}
                                            start={props?.start}
                                            end={props?.end}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className="ml-2">
                                        <span className="d-flex justify-content-left align-items-center">
                                            <span className="mr-3">
                                                <ImageDevice id_type_device={master?.data?.currentData?.id_tipo_dispositivo} width={20} />
                                            </span>
                                            <Link
                                                to="#"
                                                onMouseDown={(e) => {
                                                    if (e.button === 0 || e.button === 1) {
                                                        e.preventDefault()
                                                        window.open(OpenToScada(actualProject.category, idProject, master?.master_serial ? master?.master_serial : master?.numserie))
                                                    }
                                                }}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <Tooltip style={{ textAlign: "center" }} title={<span>{master?.nick}</span>} arrow classes={{ tooltip: "p-3" }}>
                                                    <span> {master?.nick?.length > 20 ? master?.nick?.substr(0, 19) + '...' : master?.nick}</span>
                                                </Tooltip>
                                            </Link>
                                        </span>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" >
                                        <Link
                                            to="#"
                                            onMouseDown={(e) => {
                                                if (e.button === 0 || e.button === 1) {
                                                    e.preventDefault()
                                                    window.open(OpenToScada(actualProject.category, idProject, master?.master_serial))
                                                }
                                            }}
                                            target="_blank" rel="noopener noreferrer">
                                            <Tooltip style={{ textAlign: "center" }} title={<span>{master?.nick_master}</span>} arrow classes={{ tooltip: "p-3" }}>
                                                <span>{master?.nick_master?.length > 21 ? master?.nick_master?.substr(0, 20) + "..." : master?.nick_master}</span>
                                            </Tooltip>
                                        </Link>
                                    </ StyledTableCell>
                                    {esHoyWithoutFormat(master?.data?.datetime) ?
                                        <>
                                            <StyledTableCell align="center" >
                                                <span className="text-nowrap">
                                                    {dateTableDevices(master?.data?.currentData?.datetime)}
                                                </span>
                                            </StyledTableCell>
                                            <StyledTableCell align="center" >
                                                <span className="text-nowrap">
                                                    {toKiloW(master?.data?.currentData?.pact)}
                                                </span>
                                            </StyledTableCell>
                                            <StyledTableCell align="center" >
                                                <span className="text-nowrap">
                                                    {toKiloW(master?.data?.currentData?.preact)}
                                                </span>
                                            </StyledTableCell>
                                            <StyledTableCell align="center" >
                                                <div className="d-flex justify-content-around">
                                                    <StyledTableCellMini  >
                                                        {toKiloW(master?.data?.currentData?.eaconsdia)}
                                                    </StyledTableCellMini>
                                                    <StyledTableCellMini>
                                                        {toKiloW(master?.data?.currentData?.eagendia)}
                                                    </StyledTableCellMini>
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="center" >
                                                <div className="d-flex justify-content-around">
                                                    {Number(potential) === 1 ?
                                                        <><StyledTableCellMini align="center">{toKiloW(master?.data?.currentData?.pact1)}</StyledTableCellMini>
                                                            <StyledTableCellMini align="center">{toKiloW(master?.data?.currentData?.pact2)}</StyledTableCellMini>
                                                            <StyledTableCellMini align="center">{toKiloW(master?.data?.currentData?.pact3)}</StyledTableCellMini></>
                                                        : Number(potential) === 2 ?
                                                            <><StyledTableCellMini align="center">{toKiloW(master?.data?.currentData?.i1)}</StyledTableCellMini>
                                                                <StyledTableCellMini align="center">{toKiloW(master?.data?.currentData?.i2)}</StyledTableCellMini>
                                                                <StyledTableCellMini align="center">{toKiloW(master?.data?.currentData?.i3)}</StyledTableCellMini></>
                                                            : Number(potential) === 3 ?
                                                                <><StyledTableCellMini align="center">{toKiloW(master?.data?.currentData?.preac1)}</StyledTableCellMini>
                                                                    <StyledTableCellMini align="center">{toKiloW(master?.data?.currentData?.preac2)}</StyledTableCellMini>
                                                                    <StyledTableCellMini align="center">{toKiloW(master?.data?.currentData?.preac3)}</StyledTableCellMini></>
                                                                : Number(potential) === 4 ?
                                                                    <><StyledTableCellMini align="center">{toKiloW(master?.data?.currentData?.v1)}</StyledTableCellMini>
                                                                        <StyledTableCellMini align="center">{toKiloW(master?.data?.currentData?.v2)}</StyledTableCellMini>
                                                                        <StyledTableCellMini align="center">{toKiloW(master?.data?.currentData?.v3)}</StyledTableCellMini></>
                                                                    : null}
                                                </div>
                                            </StyledTableCell>
                                        </>
                                        :
                                        <>
                                            <StyledTableCell align="center">
                                                -
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                -
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                -
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <div className="d-flex justify-content-around">
                                                    <StyledTableCellMini>
                                                        -
                                                    </StyledTableCellMini>
                                                    <StyledTableCellMini>
                                                        -
                                                    </StyledTableCellMini>
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="center" >
                                                <div className="d-flex justify-content-around">
                                                    {potential === 1 ?
                                                        <><StyledTableCellMini align="center">-</StyledTableCellMini>
                                                            <StyledTableCellMini align="center">-</StyledTableCellMini>
                                                            <StyledTableCellMini align="center">-</StyledTableCellMini></>
                                                        : potential === 2 ?
                                                            <><StyledTableCellMini align="center">-</StyledTableCellMini>
                                                                <StyledTableCellMini align="center">-</StyledTableCellMini>
                                                                <StyledTableCellMini align="center">-</StyledTableCellMini></>
                                                            : potential === 3 ?
                                                                <><StyledTableCellMini align="center">-</StyledTableCellMini>
                                                                    <StyledTableCellMini align="center">-</StyledTableCellMini>
                                                                    <StyledTableCellMini align="center">-</StyledTableCellMini></>
                                                                : null}
                                                </div>
                                            </StyledTableCell>
                                        </>
                                    }
                                </StyledTableRow>
                            </React.Fragment>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer >
    )
}