import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { MenuItem } from "@mui/material"
import { dispositivos } from "utils/constant"
import { ModalAdvanceRegisterData } from "views/Modals/ModalAdvanceCurentData/ModalAdvanceRegisterData"
import { ModalAddCcMasterGuided } from "views/Master/CreateMaster/ModalAddCcMasterGuided"
import { ModalViewInfoCCMasters } from "views/Master/EditMaster/ModalViewInfoCCMasters"
import { ModalDeleteSubGroup } from "views/SubGroup/DeleteSubGroup/ModalDeleteSubGroup"
import { ModalDeleteDeviceNew } from "views/Devices/DeleteDevice/ModalDeleteDeviceNew"
import { ModalViewInfoDevices } from "views/Devices/EditDevice/ModalViewInfoDevices"
import { ModalShareSubgroup } from "views/SubGroup/ShareSubGroup/ModalShareSubgroup"
import { ModalMoveAllDevices } from "views/Master/MoveAllMaster/ModalMoveAllDevices"
import { ModalReplaceDevice } from "views/Master/ReplaceMaster/ModalReplaceDevice"
import { ModalCedeSubgroup } from "views/SubGroup/CedeSubGroup/ModalCedeSubgroup"
import { ModalShareProject } from "views/Project/ShareProject/ModalShareProject"
import { ModalDeleteDevice } from "views/Devices/DeleteDevice/ModalDeleteDevice"
import { ModalDeleteMaster } from "views/Master/DeleteMaster/ModalDeleteMaster"
import { ModalCedeProject } from "views/Project/CedeProject/ModalCedeProject"
import { ModalShareDevice } from "views/Devices/ShareDevice/ModalShareDevice"
import { ModalNewNameGroup } from "views/Group/EditGroup/ModalNewNameGroup"
import { ModalDeleteGroup } from "views/Group/DeleteGroup/ModalDeleteGroup"
import { ModalCedeDevice } from "views/Devices/CedeDevice/ModalCedeDevice"
import { ModalMoveDevice } from "views/Master/MoveMaster/ModalMoveDevice"
import { RemoveProject } from "views/Project/DeleteProject/RemoveProject"
import { ModalShareGroup } from "views/Group/ShareGroup/ModalShareGroup"
import { NewSubgroup } from "views/SubGroup/CreateSubGroup/NewSubgroup"
import { ModalCedeGroup } from "views/Group/CedeGroup/ModalCedeGroup"
import { EditProject } from "views/Project/EditProject/EditProject"
import { NewGroup } from "views/Group/CreateGroup/NewGroup"

/**
 * @param {permissions:"string", project {}, group {}, subgroup {}, handleClose, loading: boolean} Recibimos los datos de los menus que tengamos y vamos pintando según
 * lo que nos llega de la llamada de permisos
 * @return desplegamos las opciones para menu de Projecto, grupo, subgrupo y dispositivo.
 */
export const PermissionsForMenuProject = ({ permissions, project, handleClose, loading, centroControl }) => {
    const permission = useSelector((state) => state.permission.permission)
    const [open1, setOpen1] = useState(false)
    const [index1, setIndex1] = useState()
    const toggle = () => {
        setOpen1(!open1)
    }

    const handleClic = (e, index) => {
        toggle()
        setIndex1(index)
        handleClose(e)
    }

    // create el dictionary de permisos
    const dicnary = ["updateProject", "shared", "cede", "moveDevice", "deleteProject", "createGroup"]

    // filter array permissions
    let perm = permissions?.permissions ? permissions?.permissions : permissions
    const permissionsOrderMenuProject = permissions ? permission?.[perm]?.filter((ele) => dicnary?.includes(ele)) : []
    // order array permissions
    permissionsOrderMenuProject?.sort((a, b) => {
        const nivel1 = dicnary.indexOf(a)
        const nivel2 = dicnary.indexOf(b)
        return nivel1 - nivel2
    })

    return (
        <>
            {permissions && permissionsOrderMenuProject?.map((component, index) => {
                switch (component) {
                    case "updateProject":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Editar proyecto
                                </MenuItem>
                                {index1 === index &&
                                    <EditProject
                                        project={project}
                                        loading={loading}
                                        open1={open1}
                                        setOpen1={setOpen1}
                                        toggle={toggle}
                                    />
                                }
                            </React.Fragment>
                        )
                    case "cede":
                        return (
                            <React.Fragment key={index}>
                                {project.category !== "enerSIM" &&
                                    <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                        Ceder proyecto
                                    </MenuItem>}
                                {index1 === index &&
                                    <ModalCedeProject
                                        project={project}
                                        menuDrop={true}
                                        open1={open1}
                                        setOpen1={setOpen1}
                                        toggle={toggle}
                                    />
                                }
                            </React.Fragment>
                        )
                    case "moveDevice":
                        return (
                            <React.Fragment key={index}>
                                {!centroControl &&
                                    <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                        Mover dispositivos
                                    </MenuItem>}
                                {index1 === index &&
                                    <ModalMoveAllDevices
                                        project={project}
                                        menuDrop={true}
                                        open1={open1}
                                        setOpen1={setOpen1}
                                        toggle={toggle}
                                        projects={project}
                                    />
                                }
                            </React.Fragment>
                        )
                    case "createGroup":
                        return (
                            <React.Fragment key={index}>
                                {!centroControl &&
                                    <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                        Nuevo grupo
                                    </MenuItem>
                                }
                                {index1 === index &&
                                    <NewGroup
                                        project={project}
                                        open1={open1}
                                        setOpen1={setOpen1}
                                        toggle={toggle}
                                        loading={loading}
                                    />
                                }
                            </React.Fragment>
                        )
                    case "shared":
                        return (
                            <React.Fragment key={index}>
                                {project.category !== "enerSIM" &&
                                    <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                        Compartir proyecto
                                    </MenuItem>}
                                {index1 === index &&
                                    <ModalShareProject
                                        project={project}
                                        setModal={setOpen1}
                                        modal={open1}
                                        toggle={toggle}
                                    />
                                }
                            </React.Fragment>
                        )
                    case "deleteProject":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Eliminar proyecto
                                </MenuItem>
                                {index1 === index &&
                                    <RemoveProject
                                        project={project}
                                        loading={loading}
                                        open1={open1}
                                        setOpen1={setOpen1}
                                        toggle={toggle}
                                    />
                                }
                            </React.Fragment>
                        )
                    default:
                        return null
                }
            })}
        </>
    )
}

export const PermissionsForMenuGroup = ({ permissions, group, project, handleClose, loading, setProjects, setResultSearch }) => {
    const permission = useSelector((state) => state.permission.permission)
    const [open1, setOpen1] = useState(false)
    const [index1, setIndex1] = useState()
    const toggle = () => {
        setOpen1(!open1)
    }

    const handleClic = (e, index) => {
        setIndex1(index)
        toggle()
        handleClose(e)
    }

    //create el dictionary de permisos
    const dicnary = ["updateGroup", "shared", "cede", "deleteGroup", "createSubgroup", "createDevice"]

    //filter array permissions 
    let perm = permissions?.permissions ? permissions?.permissions : permissions
    const permissionsOrderMenuGroup = permissions ? permission?.[perm]?.filter((ele) => dicnary?.includes(ele)) : []

    //order array permissions
    permissionsOrderMenuGroup?.sort((a, b) => {
        const nivel1 = dicnary.indexOf(a)
        const nivel2 = dicnary.indexOf(b)
        return nivel1 - nivel2
    })

    return (
        <>
            {permissions && permissionsOrderMenuGroup?.map((component, index) => {
                switch (component) {
                    case "updateGroup":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Editar grupo
                                </MenuItem>
                                {index1 === index &&
                                    <ModalNewNameGroup
                                        projects={project}
                                        info={group}
                                        type="id_group"
                                        iconPlus="dropdown"
                                        setProjects={setProjects}
                                        open={open1}
                                        title={"Editar grupo"}
                                        setOpen={setOpen1}
                                        group={group}
                                        toggle={toggle}
                                        loading={loading}
                                    />
                                }
                            </React.Fragment>
                        )
                    case "createSubgroup":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Nuevo subgrupo
                                </MenuItem>
                                {index1 === index &&
                                    <NewSubgroup
                                        group={group} setResultSearch={setResultSearch} setUpdateGroups={setResultSearch}
                                        idGroupSelected={group.id_group} setProjects={setProjects} modal={open1} setModal={setOpen1}
                                        toggle={toggle}
                                    />
                                }
                            </React.Fragment>
                        )
                    case "createDevice":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Nueva instalación
                                </MenuItem>
                                {index1 === index &&
                                    <ModalAddCcMasterGuided project={project} iconPlus="dropdown" id_group={group?.id_group}
                                        id_project={project?.id_project} groups={group}
                                        where="id_group"
                                        setProjects={setProjects} open={open1}
                                        setOpen={setOpen1}
                                    />
                                }
                            </React.Fragment>
                        )
                    case "cede":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Ceder grupo
                                </MenuItem>
                                {index1 === index &&
                                    <ModalCedeGroup project={project} menuDrop={true} groups={group} modal={open1} setModal={setOpen1} toggle={toggle} />
                                }
                            </React.Fragment>
                        )
                    case "shared":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Compartir grupo
                                </MenuItem>
                                {index1 === index &&
                                    <ModalShareGroup project={project} groups={group} modal={open1} setModal={setOpen1} toggle={toggle} />
                                }
                            </React.Fragment>
                        )
                    case "deleteGroup":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Eliminar grupo
                                </MenuItem>
                                {index1 === index &&
                                    <ModalDeleteGroup
                                        groups={group}
                                        loading={loading}
                                        setUpdateGroups={setResultSearch}
                                        groupId={group?.id_group}
                                        setProjects={setProjects}
                                        open={open1} setOpen={setOpen1}
                                        toggle={toggle}
                                        project={project}
                                    />
                                }
                            </React.Fragment>
                        )
                    default:
                        return null
                }
            })}
        </>
    )
}

export const PermissionsForMenuSubgroup = ({ permissions, group, subgroup, project, handleClose, loading, setUpdateGroups, idGroupSelected,
    setProjects, groupId, select }) => {
    const permission = useSelector((state) => state.permission.permission)
    const [open1, setOpen1] = useState(false)
    const [index1, setIndex1] = useState()
    const toggle = () => {
        setOpen1(!open1)
    }

    const handleClic = (e, index) => {
        setIndex1(index)
        toggle()
        handleClose(e)
    }

    //create el dictionary de permisos
    const dicnary = ["createSubgroup", "updateSubgroup", "shared", "cede", "deleteGroup", "createDevice"]

    //filter array permisos
    let perm = permissions?.permissions ? permissions?.permissions : permissions
    const permissionsOrderSubgroup = permissions ? permission?.[perm]?.filter((ele) => dicnary?.includes(ele)) : []
    //order array permissions 

    permissionsOrderSubgroup?.sort((a, b) => {
        const nivel1 = dicnary.indexOf(a)
        const nivel2 = dicnary.indexOf(b)
        return nivel1 - nivel2
    })

    return (
        <>
            {permissions && permissionsOrderSubgroup?.map((component, index) => {
                switch (component) {
                    case "updateSubgroup":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Editar subgrupo
                                </MenuItem>
                                {index1 === index &&
                                    <ModalNewNameGroup select={select} setUpdateGroups={setUpdateGroups} info={subgroup}
                                        idGroupSelected={idGroupSelected} projects={project} setProjects={setProjects} type="id_subgroup" open={open1}
                                        setOpen={setOpen1}
                                        title={"Editar subgrupo"}
                                        toggle={toggle} />
                                }
                            </React.Fragment>
                        )
                    case "createSubgroup":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Nuevo subgrupo
                                </MenuItem>
                                {index1 === index &&
                                    <NewSubgroup
                                        loading={loading}
                                        group={group}
                                        idGroupSelected={idGroupSelected}
                                        projects={project}
                                        modal={open1}
                                        setModal={setOpen1}
                                        toggle={toggle}
                                    />
                                }
                            </React.Fragment>
                        )
                    case "createDevice":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Nueva instalación
                                </MenuItem>
                                {index1 === index &&
                                    <ModalAddCcMasterGuided
                                        id_project={project?.id_project} project={project} iconPlus="dropdown"
                                        id_group={group?.id_group}
                                        id_subgroup={subgroup?.id_subgroup}
                                        subgroup={subgroup}
                                        groups={group}
                                        where="id_subgroup"
                                        open={open1}
                                        setOpen={setOpen1}
                                    />
                                }
                            </React.Fragment>
                        )
                    case "cede":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Ceder subgrupo
                                </MenuItem>
                                {index1 === index &&
                                    <ModalCedeSubgroup project={project} menuDrop={true} subgroups={subgroup} modal={open1} setModal={setOpen1}
                                        toggle={toggle} />
                                }
                            </React.Fragment>
                        )
                    case "shared":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Compartir subgrupo
                                </MenuItem>
                                {index1 === index &&
                                    <ModalShareSubgroup projects={project} groups={group} subgroups={subgroup} modal={open1} setModal={setOpen1}
                                        toggle={toggle} />
                                }
                            </React.Fragment>
                        )
                    case "deleteGroup":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Eliminar subgrupo
                                </MenuItem>
                                {index1 === index &&
                                    <ModalDeleteSubGroup groups={group} subgrp={subgroup} setUpdateGroups={setUpdateGroups}
                                        idGroupSelected={idGroupSelected} setProjects={setProjects} groupId={groupId} open={open1} setOpen={setOpen1} toggle={toggle} />
                                }
                            </React.Fragment>
                        )
                    default:
                        return null
                }
            })}
        </>
    )
}


export const PermissionsForMenuMaster = ({ permissions, scada, groups, subgroups, master, project, handleClose, loading, data, token, licence }) => {
    const location = useLocation()
    const appScada = location.pathname.split("/")[2]
    const permission = useSelector((state) => state.permission.permission)
    const [open1, setOpen1] = useState(false)
    const [index1, setIndex1] = useState()
    const toggle = () => {
        setOpen1(!open1)
    }

    const handleClic = (e, index) => {
        setIndex1(index)
        toggle()
        handleClose(e)
    }

    //create el dictionary de permisos
    const dicnary = ["updateDeviceInfo", "moveDevice", "shared", "cede", "deleteGroup", "replaceDevice"]

    //filter array permissions
    let perm = permissions?.permissions ? permissions?.permissions : permissions
    const permissionsOrderMaster = permissions ? permission?.[perm]?.filter((ele) => dicnary?.includes(ele)) : []

    //order array permissions
    permissionsOrderMaster?.sort((a, b) => {
        const nivel1 = dicnary.indexOf(a)
        const nivel2 = dicnary.indexOf(b)
        return nivel1 - nivel2
    })

    return (
        <>
            {permissions && permissionsOrderMaster?.map((component, index) => {
                switch (component) {
                    case "updateDeviceInfo":
                        return (
                            <React.Fragment key={index}>
                                <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                    Información
                                </MenuItem>
                                {index1 === index &&
                                    <ModalViewInfoCCMasters
                                        master={master}
                                        loading={loading}
                                        data={data}
                                        scada={scada}
                                        firstToggle={toggle}
                                        firstModal={open1}
                                        setFirstModal={setOpen1}
                                    />
                                }
                            </React.Fragment>
                        )
                    case "moveDevice":
                        return (
                            <React.Fragment key={index}>
                                {!scada && (!licence || licence === 'premium') &&
                                    <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                        Mover instalación
                                    </MenuItem>
                                }
                                {index1 === index &&
                                    <ModalMoveDevice projects={project} menuDrop={true} master={master} group={groups} subgroup={subgroups} modal={open1} setModal={setOpen1} />
                                }
                            </React.Fragment>
                        )
                    case "cede":
                        return (
                            <React.Fragment key={index}>
                                {(!licence || licence === 'premium') &&
                                    <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                        Ceder instalación
                                    </MenuItem>
                                }
                                {index1 === index &&
                                    <ModalCedeDevice project={project} menuDrop={true} devices={master} master={master} modal={open1} setModal={setOpen1} toggle={toggle} />
                                }
                            </React.Fragment>
                        )
                    case "shared":
                        return (
                            <React.Fragment key={index}>
                                {(!licence || licence === 'premium') &&
                                    <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                        Compartir instalación
                                    </MenuItem>
                                }
                                {index1 === index &&
                                    <ModalShareDevice projects={project} groups={groups} subgroups={subgroups} devices={master} master={master} modal={open1} setModal={setOpen1} toggle={toggle} />
                                }
                            </React.Fragment>
                        )
                    case "deleteGroup":
                        return (
                            <React.Fragment key={index}>
                                {!scada &&
                                    <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                        Eliminar instalación
                                    </MenuItem >
                                }
                                {index1 === index && appScada !== "scada" &&
                                    <ModalDeleteMaster groups={groups} subgrp={subgroups} master={master} modal={open1} setModal={setOpen1} />
                                }
                            </React.Fragment>
                        )
                    case "replaceDevice":
                        return (
                            <React.Fragment key={index}>
                                {(master?.deviceType === dispositivos.ccmabt || master?.id_tipo_dispositivo === dispositivos.ccmabt) &&
                                    <MenuItem className="pr-5 px-3 text-black" onClick={(e) => handleClic(e, index)}>
                                        Reemplazar placa de control
                                    </MenuItem >
                                }
                                {index1 === index &&
                                    <ModalReplaceDevice token={token} device={master} modal={open1} setModal={setOpen1} />
                                }
                            </React.Fragment>
                        )
                    default:
                        return null
                }
            })}
        </>
    )
}

export const PermissionsForMenuDevice = ({ permissions, handleClose, scada,
    device, start, end, devices, loading, icon, updateCcM, instalacion, reloadFetch,
    setReloadFetch, loose, setRefresh, refresh, masterPermission, setDevices
}) => {
    const permission = useSelector((state) => state.permission.permission)

    const [open1, setOpen1] = useState(false)
    const [index1, setIndex1] = useState()
    const toggle = () => {
        setOpen1(!open1)
    }

    const handleClic = (e, index) => {
        setIndex1(index);
        toggle();
        handleClose(e);
    };
    //create el dictionary de permisos
    const dicnary = ["updateDeviceInfo", "getCurrentData", "deleteFromParent"];

    //filter array permissions
    let perm = permissions?.permissions ? permissions?.permissions : permissions;
    const permissionsOrderMenuDevice = permissions
        ? permission?.[perm]?.filter((ele) => dicnary?.includes(ele))
        : [];

    // order array permissions
    permissionsOrderMenuDevice?.sort((a, b) => {
        const nivel1 = dicnary?.indexOf(a);
        const nivel2 = dicnary?.indexOf(b);
        return nivel1 - nivel2;
    });


    return (
        <>
            {permissions &&
                permissionsOrderMenuDevice?.map((component, index) => {
                    switch (component) {
                        case "updateDeviceInfo":
                            return (
                                <React.Fragment key={index}>
                                    <MenuItem
                                        className="pr-5 px-3 text-dark"
                                        onClick={(e) => handleClic(e, index)}
                                    >
                                        Información dispositivo
                                    </MenuItem>
                                    {index1 === index && (
                                        <ModalViewInfoDevices
                                            loading={loading}
                                            icon={icon}
                                            device={device}
                                            devices={devices ? devices : device}
                                            permissions={permissions}
                                            updateCcM={updateCcM}
                                            instalacion={instalacion}
                                            reloadFetch={reloadFetch}
                                            setReloadFetch={setReloadFetch}
                                            setDevices={setDevices}
                                            loose={loose}
                                            setRefresh={setRefresh}
                                            refresh={refresh}
                                            modal={open1}
                                            setModal={setOpen1}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        case "getCurrentData":
                            return (
                                <React.Fragment key={index}>
                                    {device?.deviceType !== dispositivos.ccmabtm && (
                                        <>
                                            <MenuItem
                                                className="pr-5 px-3 text-dark"
                                                onClick={(e) => handleClic(e, index)}
                                            >
                                                Más datos
                                            </MenuItem>
                                            {index1 === index && (
                                                <ModalAdvanceRegisterData
                                                    end={end}
                                                    start={start}
                                                    dataSliced={device}
                                                    instalacion={instalacion}
                                                    modal={open1}
                                                    setModal={setOpen1}
                                                />
                                            )}
                                        </>
                                    )}
                                </React.Fragment>
                            );
                        case "deleteFromParent":
                            return (
                                <React.Fragment key={index}>
                                    {(device?.master_serial ||
                                        device?.mabt_serial ||
                                        device?.id_padre ||
                                        !scada) && (
                                            <MenuItem
                                                className="pr-5 px-3 text-dark"
                                                onClick={(e) => handleClic(e, index)}
                                            >
                                                Eliminar dispositivo
                                            </MenuItem>
                                        )}
                                    {(device?.master_serial ||
                                        device?.mabt_serial ||
                                        device?.id_padre) &&
                                        index1 === index && (
                                            <ModalDeleteDeviceNew
                                                device={[device]}
                                                devices={device}
                                                permissions={
                                                    masterPermission?.[
                                                    device?.master_serial
                                                        ? device?.master_serial
                                                        : device?.mabt_serial
                                                            ? device?.mabt_serial
                                                            : device?.id_padre
                                                    ]
                                                }
                                                modal={open1}
                                                setModal={setOpen1}
                                            />
                                        )}
                                    {!(
                                        device?.master_serial ||
                                        device?.mabt_serial ||
                                        device?.id_padre
                                    ) &&
                                        index1 === index && (
                                            <ModalDeleteDevice
                                                device={[device]}
                                                devices={device}
                                                modal={open1}
                                                setModal={setOpen1}
                                            />
                                        )}
                                </React.Fragment>
                            );
                        default:
                            return null;
                    }
                })}
        </>
    );
};