import React from "react"
import { Button } from "@mui/material"
import './buttonStyle.scss'

export const ButtonMenu = ({
    onClick,
    children,
    tab,
    disabled,
    style,
    className
}) => {

    return (
        <Button
            className={`${tab ? "button-menu-active" : "button-menu"} ${className}`}
            onClick={onClick}
            disabled={disabled}
            style={style}
        >
            {children}
        </Button>
    )
}