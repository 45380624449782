import React from 'react'
import { CSVLink } from 'react-csv'
import { Grid, Button } from '@mui/material'
import moment from 'moment'
import CalendarComponentV2 from 'components/Calendar/CalendarComponentV2'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { Loader } from 'components/Loader/Loader'
import { IconDownloadFile } from 'utils/icon-centrocontrol'
import { MenuDescripcionMabt } from './MenuDescripcionMabt'
import { GraficaMabt } from './GraficaMabt'
import { TablaMabt } from './TablaMabt'
import { MenuMabt } from './MenuMabt'

export const InformeMabt = ({ activeTabDeviceMenuGrafica, selectTabMenuGrafica, handleDate, activeTabDeviceGrafica, selectTabGrafica, start, visible, datosTabla, datosGrafica, loadingGrafica, tipoEscala, mobile, errorGrafica, loadingTabla, tipoGrafica, master, errorTabla }) => {
    const maxDate = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 })

    return (
        <>
            <Grid item xs={12} md={12} lg={12} xl={12} className='pl-3 mb-4'>
                <MenuMabt activeTab={activeTabDeviceMenuGrafica} selectTab={selectTabMenuGrafica} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='pl-3 mb-5 pr-3'>
                <Grid container>
                    <Grid item xs={mobile ? 12 : 8} md={window.innerWidth < 945 ? 12 : 8} lg={8} xl={8}>
                        <MenuDescripcionMabt medidores={activeTabDeviceMenuGrafica} activeTab={activeTabDeviceGrafica} selectTab={selectTabGrafica} start={start} grafica={true} visible={visible} />
                    </Grid>
                    <Grid item xs={mobile ? 12 : 12} md={window.innerWidth < 1000 ? 12 : 4} lg={4} xl={4} className={`${window.innerWidth < 1000 && 'mt-2'}`}>
                        <div className='flex-end'>
                            <CalendarComponentV2 maxDate={maxDate} handleDate={handleDate} select={true} />
                            <Button className="pt-1 pl-0" disabled={!datosTabla}>
                                <CSVLink data={datosTabla ? datosTabla : []} disabled={!datosTabla} separator={";"} style={{ color: 'white' }} filename={`${master.nick}.csv`}>
                                    <HtmlTooltip title={<span>Descargar datos</span>}>
                                        <IconDownloadFile fill={(datosTabla && datosTabla.length > 0) && '#FF8A1F'} />
                                    </HtmlTooltip>
                                </CSVLink>
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {!loadingGrafica && !loadingTabla ?
                <>
                    {datosGrafica &&
                        <Grid container columns={28}>
                            <Grid item xs={1} md={1} lg={1} xl={1} />
                            <Grid item xs={26} md={26} lg={26} xl={26} className='mb-8'>
                                {!errorGrafica && <GraficaMabt datosGrafica={datosGrafica} escalaTipo={tipoEscala} tipoGrafica={tipoGrafica} activeTabDeviceMenuGrafica={activeTabDeviceMenuGrafica} />}
                            </Grid>
                            <Grid item xs={1} md={1} lg={1} xl={1} />
                        </Grid>
                    }
                    {errorGrafica &&
                        <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 mb-6'>
                            <h3 className='texto-error-carga'>
                                Error al cargar los datos de la gráfica, contacte con el soporte técnico.
                            </h3>
                        </Grid>
                    }
                    {datosTabla &&
                        <Grid container columns={28}>
                            <Grid item xs={1} md={1} lg={1} xl={1} />
                            <Grid item xs={26} md={26} lg={26} xl={26} className='mb-4'>
                                <TablaMabt datosTabla={datosTabla} />
                            </Grid>
                            <Grid item xs={1} md={1} lg={1} xl={1} />
                        </Grid>
                    }
                    {errorTabla &&
                        <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 mb-6'>
                            <h3 className='texto-error-carga'>
                                Error al cargar los datos de la tabla, contacte con el soporte técnico.
                            </h3>
                        </Grid>
                    }
                </>
            :
                <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 mb-6'>
                    <div className="justify-content-center">
                        <Loader />
                    </div>
                </Grid>
            }
        </>
    )
}