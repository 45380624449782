export default function ChartInline({ total = 30, used = 29.5 }) {
    const barStyle = 'min-w-min shrink-0 whitespace-nowrap px-1 py--1 text-center'
    const percentage = (used / total) * 100

    return (
        <div className='w-full'>
            <div className='my-2 flex w-full min-w-[180px] overflow-hidden rounded-sm font-medium text-white'>
              <div
                className={`${barStyle} bg-[#73bf45] dark:bg-green-500`}
                style={{ width: percentage + '%' }}
              >
                <p className="text-sm font-bold">{used}%</p>
              </div>
              <div
                className={`${barStyle} flex-1 bg-[#d9d9d9] dark:bg-gray-300`}
              ></div>
            </div>
        </div>
      )
  }
  